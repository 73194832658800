namespace Simplex.WebComponents.Project.ProjectDashboardSetupHelper {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import ProjectTask = Simplex.Project.Models.ProjectTask;

    @WebComponent("ui-dashboard-project-setuphelper-current-task")
    export class ProjectDashboardSetupHelperCurrentTask extends ABWebComponent {
        private readonly contentTemplate;
        private readonly completedContentTemplate;
        private readonly _taskIndex: number = 0;
        private readonly _totalTasks: number = 0;
        private _rendered: boolean = false;
        private readonly _currentTask?: ProjectTask;
        private readonly _nextTask?: ProjectTask;
        
        public constructor(taskIndex: number, totalTasks: number, currentTask?: ProjectTask, nextTask?: ProjectTask) {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/Dashboard/ProjectDashboardSetupHelper', 'ProjectDashboardSetupHelperCurrentTask') as TemplateCallback;
            this.completedContentTemplate = this.app.getTemplate('WebComponents/Project/Dashboard/ProjectDashboardSetupHelper', 'ProjectDashboardSetupHelperCompleted') as TemplateCallback;
            this._taskIndex = taskIndex;
            this._totalTasks = totalTasks;
            this._currentTask = currentTask;
            this._nextTask = nextTask;
        }

        private onComplete = (event:Event) =>{
            event.stopPropagation();
            event.preventDefault();
            this.dispatchEvent(new CustomEvent("completeClicked", {bubbles: true}));
        }
        
        public async render() {
            if(this._taskIndex === this._totalTasks) {
                this.innerHTML = this.completedContentTemplate({});
                const completeButton = this.querySelector('.complete');
                if(completeButton) {
                    completeButton.addEventListener('click', this.onComplete)
                }
            } else {
                this.innerHTML = this.contentTemplate({
                    taskIndex: this._taskIndex,
                    totalTasks: this._totalTasks,
                    currentTask: this._currentTask,
                    nextTask: this._nextTask
                });
            }
            this._rendered = true;
        }

        public async connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if(!this._rendered) {
                await this.render();
            }
        }

        disconnectedCallback() {
            const completeButton = this.querySelector('.complete');
            if(completeButton) {
                completeButton.addEventListener('click', this.onComplete)
            }
        }
    }
}
