namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class BudgetGraphData extends ABModel {

        public projectId: string;
        public projectCurrency: string;
        public mainCategories: CostCategorySummary[];
        public periodType: string | null = null;
        public periods: BudgetGraphDataPeriod[] = [];

        constructor(
            projectId: string,
            projectCurrency: string,
            periods: BudgetGraphDataPeriod[],
            mainCategories: CostCategorySummary[],
            periodType: string | null) {
                super();
                this.projectId = projectId;
                this.projectCurrency = projectCurrency;
                this.periodType = periodType;
                this.mainCategories = mainCategories;
                this.periods = periods;
        }
    }
}