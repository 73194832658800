namespace Simplex.WebComponents.FormElements.SubdomainInputText.Models {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class SubdomainAvailableResponse extends ABModel {

        public error: CheckDomainValidationErrorType;

        constructor(error: CheckDomainValidationErrorType) {
            super();
            this.error = error;
        }
    }

    export enum CheckDomainValidationErrorType {
        Required = "Required",
        TooShort = "TooShort",
        Invalid = "Invalid",
        Exists = "Exists",
        NotAllowed = "NotAllowed"
    }

}