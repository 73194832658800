namespace Simplex.WebComponents.Project {
    import WebComponent = Simplex.Decorators.WebComponent;
    import ListView = Simplex.WebComponents.LayoutComponents.ListView;
    import ListViewOptions = Simplex.WebComponents.LayoutComponents.ListViewOptions;
    import Milestone = Simplex.WebComponents.Project.ProjectMilestonesList.Models.Milestone;
    import ListViewEvents = Simplex.WebComponents.LayoutComponents.ListViewEvents;

    @WebComponent("ui-project-milestones-dashboard-list-view")
    export class ProjectMilestonesDashboardListView extends ListView<Milestone> {

        public constructor() {
            super();
        }
        

        protected onCustomEvent = (eventName: ListViewEvents, data: any):void => {
            switch(eventName) {
                case "ItemSelected":
                    this.app.navigateTo(`/project/${data.projectId}/planning`);
                    break;
            }
        }


        protected getListViewSettings (): ListViewOptions {
            return new ListViewOptions('jsTableContainer', true, Messages('project.milestones.listView.noResults'), null, {
                    property: 'date',
                    direction: 'ASC'
                }, {
                    listview: 'overview__listview'
                }, {
                    path: 'WebComponents/Project/ProjectMilestonesDashboardList',
                    file: 'itemRow'
                }
            )
        }
    }
}
