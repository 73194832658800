namespace Simplex.WebComponents {
    import WebComponent = Simplex.Decorators.WebComponent;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import ViewScopeSortable = Simplex.WebComponents.Project.ViewScopeSortable;
    import APIResult = Simplex.Utils.APIResult;

    @WebComponent("ui-scopelist-sortable")
    export class ScopeListSortable extends ABWebComponent {
        private readonly _depth: number;
        private readonly _scopes: Simplex.Models.Project.Scope[];
        private readonly _viewScopes: ViewScopeSortable[] = [];
        private readonly _projectId: string;
        private readonly _projectCurrency: string;
        private readonly _parentScopeId: string;
        private _rendered: boolean = false;
        private _sortable: any;
        private readonly notice: any;
        private readonly _multipleYears: boolean;
        private _openState: boolean = false;
        private _uiscopeList!: HTMLElement;
        private _scopePlanning!: HTMLElement;
        
        public constructor(depth: number, scopes: Simplex.Models.Project.Scope[], projectId: string, projectCurrency: string, parentScopeId: string, multipleYears:boolean) {
            super();
            this._depth = depth;
            this._scopes = scopes;
            this._projectId = projectId;
            this._projectCurrency = projectCurrency;
            this._parentScopeId = parentScopeId;
            this._multipleYears = multipleYears;
            this.notice = this.app.getComponent('AmbreroComponents.Notice');
        }
        get parentScopeId(): string {
            return this._parentScopeId ?? Simplex.Utils.GUID_EMPTY;
        }
        render() {
            this.createSortableList(this._scopes, this._depth);
            this._uiscopeList = document.querySelector('ui-scopelist') as HTMLElement;
            this._scopePlanning = document.querySelector('.scopeplanning') as HTMLElement;
            this._rendered = true;
        }
        
        private createSortableList = (scopes: Simplex.Models.Project.Scope[], depth: number) :void => {
            for (const scope of scopes) {
                const viewScopeSortable = new Simplex.WebComponents.Project.ViewScopeSortable(this._projectId, this._projectCurrency, scope, depth, this._multipleYears);
                if(scope.children.length > 0) {
                    viewScopeSortable.classList.add('has--children');
                }
                this.appendChild(viewScopeSortable);
                this._viewScopes.push(viewScopeSortable);
            }
            // @ts-ignore
            this._sortable = Sortable.create(this, {
                sort: true,
                group: 'scopes',
                swapThreshold: 0.65,
                animation: 100,
                selectedClass: "is--active",
                ghostClass: "sortable-ghost",
                chosenClass: "sortable-chosen",
                dragClass: "sortable-drag",
                // filter: ".ignore-elements",
                draggable: 'ui-view-scope-sortable',
                handle: '.scope__handle',
                //onMove: this.onSortMove,
                onUnchoose: this.onUnchoose,
                onChoose: this.onChoose,
                onEnd: this.onSortEnd,
                onStart: this.onSortStart,
                emptyInsertThreshold: 5 // this is the default
            });
        }
        public getViewScopeSortable = (scopeId: string): ViewScopeSortable | undefined => {
            let foundViewScope;
            this._viewScopes.forEach(viewScope => {
                if(viewScope.scopeId === scopeId) {
                    foundViewScope = viewScope as ViewScopeSortable;
                }
            });
            return foundViewScope;
        }

        public setState = (state:boolean): void => {
            this._openState = state;
            if(this._openState) {
                this.classList.add('is--open');
            } else {
                this.classList.remove('is--open');
            }
            ([...this.querySelectorAll('ui-view-scope-sortable').values()] as ViewScopeSortable[]).forEach((viewScopeSortable: ViewScopeSortable) => {
                viewScopeSortable.setState(state);
            });
        }

        public toggleState = (scopeId: string, state: boolean): void => {
            const viewScope = this.getViewScopeSortable(scopeId) as ViewScopeSortable;

            if(viewScope) {
                viewScope.setState(state);
                ([...viewScope.querySelectorAll('ui-scopelist-sortable').values()] as ScopeListSortable[]).forEach((childList: ScopeListSortable) => {
                    childList.setState(state);
                });
            }
        }

        private onChoose = (event: CustomEvent) => {
            
            //@ts-ignore
            const currentViewScope = event.item as ViewScopeSortable;
            const snapshotChange = currentViewScope.snapshotChange;
            if(snapshotChange === 'Deleted') {
                return;
            }
            
            window.setTimeout(() => {
                const mainSortable = document.querySelector('.mainsortable') as HTMLElement;
                this._uiscopeList.style.display = "none";
                this._scopePlanning.classList.add('is--sorting');
                mainSortable.classList.add('is--sorting');
            },1);
        }
        
        private onUnchoose = () => {
            this._uiscopeList.style.display = "block";
            this.classList.remove('is--sorting');
            this._scopePlanning.classList.remove('is--sorting');
        }
        
        private onSortStart = (evt: any) => {
            const item = evt.item;
            let startDepth = parseInt(item.getAttribute('depth'),10);
            let depths:any[] = [];
            const children = item.querySelectorAll('ui-view-scope');
            let difference = 0;
            if(children.length > 0) {
                children.forEach((child:any) => {
                    depths.push(parseInt(child.getAttribute('depth'),10));
                });
                difference = Math.max(...depths) - startDepth;
            }
            this.dispatchEvent(new CustomEvent("sortStart", {detail: difference}));
        };
        
        private onSortEnd = async (event: CustomEvent): Promise<void> => {
            const changeScopeSortingRequest = new Simplex.Models.Project.ChangeScopeSortingRequest();
            //@ts-ignore
            const currentViewScope = event.item;
            //@ts-ignore
            const targetScopeListSortable = event.to as Simplex.WebComponents.Project.ScopeListSortable;

            //@ts-ignore
            const newIndex = event.newIndex;
            const scopeId = currentViewScope.scopeId;

            const newParentScopeId = targetScopeListSortable.parentScopeId;
            
            if(currentViewScope.scopeIndex === newIndex && currentViewScope.parentScopeId == targetScopeListSortable.parentScopeId) {
                // No changes to sorting
                this.onUnchoose();
                return;
            }
            changeScopeSortingRequest.newIndex = newIndex;
            changeScopeSortingRequest.newParentScopeId = newParentScopeId
            this._uiscopeList.classList.add('is--saving');
            const updateSortingResult = await this.request.put<APIResult<void>>(`/api/project/${this._projectId}/scopes/${scopeId}/sorting`, changeScopeSortingRequest);
            if (updateSortingResult.isSuccess) {
                this.dispatchEvent(new CustomEvent("sortUpdate", {bubbles: true, detail: new Simplex.Models.Project.ScopeSortChanged(changeScopeSortingRequest.newParentScopeId, targetScopeListSortable.parentScopeId, scopeId)} ) );
            } else {
                if (updateSortingResult.data?.messages && updateSortingResult.data?.messages.length > 0) {
                    this.notice.addMessage(Messages(updateSortingResult.data.messages[0].key), 'warning');
                }
                this._uiscopeList.classList.remove('is--saving');
                // Update failed!
                this.onUnchoose();
            }
        }

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if (!this._rendered) {
                this.render();
            }
        }
    }
}