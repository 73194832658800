namespace Simplex.Models.Authentication {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class UserTenant extends ABModel {

        public id: string;
        public name: string;
        public admin: boolean;
        
        constructor(id: string = "", name: string = "", admin: boolean = false) {
            super();
            this.id = id;
            this.name = name;
            this.admin = admin;
        }
        
    }
}