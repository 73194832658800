namespace Simplex.WebComponents {
    import WebComponent = Simplex.Decorators.WebComponent;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import ToggleEvent = Simplex.WebComponents.Project.ToggleEvent;
    import EditEvent = Simplex.WebComponents.Project.EditEvent;
    import AddEvent = Simplex.WebComponents.Project.AddEvent;
    import ViewScope = Simplex.WebComponents.Project.ViewScope;

    @WebComponent("ui-scopelist")
    export class ScopeList extends ABWebComponent {
        private readonly _scopes: Simplex.Models.Project.Scope[];
        private readonly _projectCurrency: string;
        private readonly _mainViewScopes: ViewScope[] = [];
        private _allViewScopes: ViewScope[] = [];
        private readonly _projectId: string;
        private readonly _multipleYears: boolean;
        private _rendered: boolean = false;
        private readonly notice: any;
        private _scopeListSortable?: Simplex.WebComponents.ScopeListSortable;
        private _scopeViewSortableContainer?: HTMLElement;
        
        public constructor(scopes: Simplex.Models.Project.Scope[], projectId: string, projectCurrency: string, multipleYears:boolean) {
            super();
            this._scopes = scopes;
            this._projectId = projectId;
            this._projectCurrency = projectCurrency;
            this._multipleYears = multipleYears;
            this.notice = this.app.getComponent('AmbreroComponents.Notice');
        }
        
        render() {
            this._scopes.forEach((scope: Models.Project.Scope, index: number) => {
                let isOdd = true;
                if(index % 2 === 1) {
                    isOdd = false;
                }
                
                const viewScope = new Simplex.WebComponents.Project.ViewScope(scope, isOdd, this._projectId, this._projectCurrency, this._scopes, 0, this._multipleYears, false);
                if(scope.children.length > 0) {
                    viewScope.classList.add('has--children');
                }
                
                
                this._mainViewScopes.push(viewScope);
                this.appendChild(viewScope);
                
            });
            this._allViewScopes = [...this.querySelectorAll('ui-view-scope').values()] as ViewScope[];
            this.addEventToViewScopes();
            this._scopeListSortable = new Simplex.WebComponents.ScopeListSortable(0, this._scopes, this._projectId, this._projectCurrency, Simplex.Utils.GUID_EMPTY, this._multipleYears);
            this._scopeViewSortableContainer = document.querySelector(".scopeview") as HTMLDivElement;
            
            this._scopeListSortable.classList.add("mainsortable");    
            if (this._scopeViewSortableContainer && this._scopeListSortable) {
                this._scopeViewSortableContainer.prepend(this._scopeListSortable);
            }
            this._rendered = true;
        }

        private propagateDrag = (event: Event) => {
            this._scopeViewSortableContainer!.style.display = "block";
            this._allViewScopes.forEach(viewScope => {
                viewScope.style.display = "none";
            });

            const sortableScopeListView = this._scopeViewSortableContainer?.querySelector('ui-scopelist-sortable');

            const currentMouseEvent = event as MouseEvent;
            const mouseEvent = new MouseEvent('pointerdown',
                {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    clientY: currentMouseEvent.clientY,
                    clientX: currentMouseEvent.clientX,
                });
            sortableScopeListView?.dispatchEvent(mouseEvent);
            event.stopPropagation();
        }
        
        private addEventToViewScopes = () : void => {
            this._allViewScopes.forEach((viewScope) => {
                viewScope.addEventListener('stateToggled', this.onStateToggled);
                viewScope.addEventListener('stateEdit', this.onStateEdit);
                viewScope.addEventListener('stateAdd', this.onStateAdd);
                const dragHandle = viewScope.querySelector('.scope__handle');
                dragHandle?.setAttribute('draggable', 'true');
                dragHandle?.addEventListener('mousedown', this.propagateDrag);
            });
        }
        
        public removeAllHighlights = () => {
           
            for (let viewScope of this._allViewScopes) {
                viewScope.removeHighlight();
            }
        }

        public highlightScopes = async (scopeIds: string[]) => {

            for (let viewScope of this._allViewScopes) {
                if(scopeIds.includes(viewScope.scopeId)){
                    viewScope.showHighlightAndEnsureParentOpened(this._allViewScopes);
                } else {
                    viewScope.removeHighlight();
                } 
            }
        }
        
        public getViewScope = (scopeId: string): ViewScope | undefined => {
            let foundViewScope;
            this._allViewScopes.forEach(viewScope => {
                if(viewScope.scopeId === scopeId) {
                    foundViewScope = viewScope as ViewScope;
                }
            });
            return foundViewScope;
        }
        
        public toggleAll = (open: boolean, ids: string[] | null = null) => {
            this._mainViewScopes.forEach(viewScope => {
                if(ids !== null && !ids.includes(viewScope.scopeId)) {
                    return;
                }
                viewScope.onToggle(open);
            });
        }

        public getScopes = ():Simplex.Models.Project.Scope[] => {
            return this._scopes;
        }

        private toggleNextSubScope = (viewScope: ViewScope, depth: number, open?: boolean) => {
            const nextScope = viewScope.nextElementSibling as ViewScope;
            if (!nextScope) {
                return;
            }
            const tag = nextScope.tagName.toLowerCase();
            if(tag === 'ui-edit-scope-form' || tag === 'ui-add-scope' ) {
                this.toggleNextSubScope(nextScope, depth, open); 
                return;
            }
            if(tag !== "ui-view-scope") {
                return;
            }
            let currentDepth = parseInt(nextScope.getAttribute('depth')!,10);
            if(currentDepth > depth) {
                nextScope.onToggle(open);
                this.toggleNextSubScope(nextScope, depth, open);
            }            
        }
        
        onStateToggled = (event: Event) => {
            event.preventDefault();
            event.stopPropagation();

            const customEvent = event as CustomEvent<ToggleEvent>;
            // check all subscopes
            const viewScope = this.getViewScope(customEvent.detail.id) as ViewScope;
            let depth = viewScope.getAttribute('depth');
            
            if(viewScope && depth) {
                this.toggleNextSubScope(viewScope, parseInt(depth,10), customEvent.detail.open);
            }
            this._scopeListSortable?.toggleState(customEvent.detail.id, customEvent.detail.open);
            this.dispatchEvent(new CustomEvent('scopeToggled', {detail: {id: customEvent.detail.id, open: customEvent.detail.open, renderReference: customEvent.detail.renderReference}}));

        }

        onStateEdit = (evt: Event) => {
            evt.stopPropagation();
            const customEvent = evt as CustomEvent<EditEvent>;
            this.dispatchEvent(new CustomEvent('editToggled', {detail: {id: customEvent.detail.id, editing: customEvent.detail.editing}}));
        }
        
        onStateAdd = (evt: Event) => {
            evt.stopPropagation();
            const customEvent = evt as CustomEvent<AddEvent>;
            this.dispatchEvent(new CustomEvent('addToggled', {detail: {id: customEvent.detail.id, adding: customEvent.detail.adding}}));
        }
        
        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if (!this._rendered) {
                this.render();
            }
        }
    }
}