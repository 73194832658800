namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class SnapshotSummary extends ABModel {

        public id: string;
        public projectId: string;
        public name: string;
        public description: string;
        public date: string;
        public baseline: boolean;
        public budget: number|null;
        public startDate: string|null;
        public endDate: string|null;
        public created: string|null;

        constructor(
            id: string,
            projectId: string,
            name: string,
            description: string,
            date: string,
            baseline: boolean,
            budget: number|null,
            startDate: string|null,
            endDate: string|null,
            created: string|null
        ) {
            super();
            this.id = id;
            this.projectId = projectId;
            this.name = name;
            this.description = description;
            this.date = date;
            this.baseline = baseline;
            this.budget = budget;
            this.startDate = startDate;
            this.endDate = endDate;
            this.created = created;
        }
    }
}