/* COPYRIGHT (C) Ambrero Software B.V. - All rights reserved
 * This file is part of the Ambrero Base Framework and supplied under license.
 * Unauthorized copying of this file is strictly prohibited.
 * It is permitted to make changes to this file, as long as it is only used in this project.
 */

/// <reference path="../Interfaces/Scoped.ts" />
/// <reference path="ABComponent.ts"/>

namespace Simplex.Components {
    import Application = Ambrero.AB.Application;
    import Scoped = Simplex.Interfaces.Scoped;
    import ScopeProxy = Simplex.Interfaces.ScopeProxy;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;

    export class Content<TContext extends Object> extends ABComponent implements Scoped {
        private readonly contentTemplate;
        private targetContainer: JQuery;
        private context?: TContext;
        readonly scopeProxy: ScopeProxy;

        constructor(app: Application, templatePath: string) {
            super();
            const parts = templatePath.split('/');
            const templateName = parts.pop();

            this.contentTemplate = app.getTemplate(parts.join('/'), templateName || "") as TemplateCallback;
            this.targetContainer = $(''); // lege JQuery
            this.scopeProxy = this.createScopeProxy();
        }

        createScopeProxy(): ScopeProxy {
            return new ScopeProxy((eventName: string, args: any[]) => {
                const eventArgs = Simplex.Utils.createEventArgs({
                    arguments: args,
                    event: event
                }, this);
                this.emit(eventName, eventArgs);
                return !eventArgs.handled;
            });
        }

        private redraw(): void {
            this.emit('beforedraw', Simplex.Utils.createEventArgs(null, this));
            const html = this.contentTemplate(this.context);
            this.targetContainer.html(html).data('$scope', this.scopeProxy);
            this.emitDrawEvent();
            this.emit('afterdraw', Simplex.Utils.createEventArgs(null, this));
        }

        private emitDrawEvent(): void {
            const eventArgs = Simplex.Utils.createEventArgs({
                container: this.targetContainer
            }, this);
            this.emit('draw', eventArgs);
        }

        public refreshSections(sectionSelector: string): boolean {
            this.emit('beforeDrawSection', Simplex.Utils.createEventArgs(null, this));
            
            const html = $(this.contentTemplate(this.context));
            const newContent = html.find(sectionSelector).addBack(sectionSelector);
            
            const currentContent = this.targetContainer.find(sectionSelector);
            if (currentContent.length !== newContent.length) {
                console.warn(`refresh sections failed for selector ${sectionSelector}, newcontent length differs from oldcontent`);
                return false;
            }
            
            for (let i = 0; i < currentContent.length; i++) {
                const cc = $(currentContent.get(i));
                cc.replaceWith(newContent.get(i));
            }

            const replacedContent = this.targetContainer.find(sectionSelector);

            this.emit('drawSection', Simplex.Utils.createEventArgs( {
                    container: this.targetContainer,
                    section: replacedContent
            }, this));
            return true;
        }

        public refreshElementClasses(sectionSelector: string): boolean {

            const html = $(this.contentTemplate(this.context));
            const newContent = html.find(sectionSelector).addBack(sectionSelector);

            const currentContent = this.targetContainer.find(sectionSelector);
            if (currentContent.length !== newContent.length) {
                console.warn(`refresh element classes failed for selector ${sectionSelector}, newcontent length differs from oldcontent`);
                return false;
            }
            for (let i = 0; i < currentContent.length; i++) {
                const cc = currentContent.get(i);
                const newCc = newContent.get(i);

                for(let value of cc.classList) {
                    if (!newCc.classList.contains(value)) {
                        cc.classList.remove(value);
                    }
                }
                for(let value of newCc.classList) {
                    if (!cc.classList.contains(value)) {
                        cc.classList.add(value);
                    }
                }
            }

            return true;
        }

        public setContext(context: TContext): void {
            this.context = context;
        }

        public getContext(): TContext {
            if (this.context === undefined) {
                throw new Error("Context not set");
            }
            return this.context;
        }

        public hasContext = (): boolean => { 
            return this.context !== undefined;
        }
        
        public draw(): void {
            this.redraw();
        }
        
        public selectJqueryElement(selector: string):JQuery {
            return $(selector,this.targetContainer);
        }
        public selectElement(selector: string):HTMLElement|null {
            const $element = $(selector,this.targetContainer);
            if ($element.length > 0) {
                return $element[0];
            }
            return null;
        }
        
        public selectElements(selector: string):HTMLElement[] {
            const $element = $(selector,this.targetContainer);
            const elements:HTMLElement[] = [];
            $element.each((_:number, element:HTMLElement):void => {
                elements.push(element);
            });
                
            return elements;
        }
        
        public getTargetContainer(): JQuery {
            return this.targetContainer;
        }

        public getTargetElement(): HTMLElement {
            return this.targetContainer[0];
        }

        public setTargetContainer(container: string) {
            this.targetContainer = $(container);
        }
        public setTargetContainerElement(container: HTMLElement) {
            this.targetContainer = $(container);
        }
    }
}