/// <reference path="../Declarations/Index.d.ts" />
/// <reference path="../Decorators/Decorators.d.ts" />

/* COPYRIGHT (C) Ambrero Software B.V. - All rights reserved
 * This file is part of the Ambrero Base Framework and supplied under license.
 * Unauthorized copying of this file is strictly prohibited.
 * It is permitted to make changes to this file, as long as it is only used in this project.
 */
namespace Simplex.Components {
    import Application = Ambrero.AB.Application;

    @Simplex.Decorators.Singleton(0)
    export class FormattingHelper extends ABComponent {

        private auth: Simplex.Components.Auth | null = null;

        public constructor() {
            super();
        }

        public get dateFormat(): string {
            if (!this.auth) {
                this.auth = Ambrero.AB.Application.Instance!.getSystemComponent('Auth') as Simplex.Components.Auth;
            }
            return this.convertDateFormat(this.auth.dateFormat);
        }

        public get timeFormat(): string {
            if (!this.auth) {
                this.auth = Ambrero.AB.Application.Instance!.getSystemComponent('Auth') as Simplex.Components.Auth;
            }
            return this.convertTimeFormat(this.auth.timeFormat);
        }

        public get numberFormat(): string {
            if (!this.auth) {
                this.auth = Ambrero.AB.Application.Instance!.getSystemComponent('Auth') as Simplex.Components.Auth;
            }
            return this.auth.numberFormat;
        }

        private convertTimeFormat = function(format: string): string {
            return format==='Time12h' ? 'hh:mm A' : 'HH:mm';
        }

        public convertCurrency = function(currency: string|null): string {
            switch (currency) {
                case 'Dollar':
                    return '$';
                case 'Pound':
                    return '£';
                default:
                    return '€';
            }
        }
        
        private convertDateFormat = function(format: string): string {
            switch (format) {
                case 'mmddyyyy':
                    return 'MM/DD/YYYY';
                case 'ddmmmyyyy':
                    return 'DD MMM YYYY';
                case 'dmmmApostropheyy':
                    return 'D MMM \'YY';
                case 'mmmdyy':
                    return 'MMM D YY';
                default:
                    return 'DD-MM-YYYY';
            }
        }
    }
}