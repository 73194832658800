/* COPYRIGHT (C) Ambrero Software B.V. - All rights reserved
 * This file is part of the Ambrero Base Framework and supplied under license.
 * Unauthorized copying of this file is strictly prohibited.
 * It is permitted to make changes to this file, as long as it is only used in this project.
 */

/// <reference path="../Declarations/Index.d.ts" />
/// <reference path="../Decorators/Decorators.d.ts" />

namespace Simplex.Components {
  import Application = Ambrero.AB.Application;
  import EventArgs = Ambrero.AB.Components.EventArgs;

  interface NavPageState {
    pageTitle: string;
    link: string;
    hash: string;
    search?: string;
    controller: string;
    action: string;
    activeRouteParams?: any[];
  }

  export interface Route {
    type: "action" | "controller";
    target: any;
    method: string;
    controller: string;
    route?: string;
    params?: string[];
    routeMatch?: RegExp;
    manualLoadEvent: boolean;
  }

  export interface RouteParam {
    type: ParamType;
    controller: string;
    method: string;
    name: string;
    index: number;
    valueType?: ValueType;
    required: boolean;
    arguments?: any[];
  }

  export interface InjectedComponent {
    target: any;
    componentName: string;
    propertyName: string;
    instance?: any;
  }

  export enum ParamType {
    Path,
    Query,
    Component,
  }

  export enum ValueType {
    String,
    Integer,
    Float,
    Boolean,
    Array,
    Date,
    Guid = 6,
  }

  export class NotFoundError extends Error {
    constructor(message: any) {
      super(message); // (1)
      this.name = "NotFoundError";
    }
  }

  @Simplex.Decorators.Singleton()
  export class Router {
    public static LoadedEvent: string = "actionLoaded";
    public static Loaded: boolean;

    private static routes: Route[] = [];
    private static routeParams: RouteParam[] = [];
    private static routeRestrictions: Map<string, string[]> = new Map<
      string,
      string[]
    >();
    private static routeViewLayouts: Map<string, string> = new Map<
      string,
      string
    >();
    private static activeRouteParams?: any[];
    private static injectedComponents: InjectedComponent[] = [];

    private readonly app: Application;
    private readonly parser: DOMParser;

    private currentLink: string;
    private currentHash: string;
    private currentSearch?: string;
    private readonly origin: string;

    private readonly layout: Layout;
    private readonly auth: Auth;

    private onBeforeApplyRoute = async () => {};

    public constructor(app: Application) {
      document.dispatchEvent(
        new CustomEvent("init-ambrero-router", {
          detail: {
            setOnBeforeApplyRoute: (handler: () => Promise<void>) => {
              this.onBeforeApplyRoute = handler;
            },
          },
          bubbles: true,
          composed: true,
          cancelable: false,
        })
      );
      this.app = app;
      this.parser = new DOMParser();
      this.currentLink = document.location.pathname;
      this.currentHash = document.location.hash;
      this.currentSearch = document.location.search;
      this.origin = document.location.origin;

      this.layout = this.app.getSystemComponent("Layout") as Layout;
      this.auth = this.app.getSystemComponent("Auth") as Auth;

      app.getEventBus().on("reset", this.subscribeEventBus);
      this.subscribeEventBus();
      this.registerGlobalEvents();
      this.saveState(this.currentLink, this.currentHash, this.currentSearch);

      this.ensureLayoutCreated();

      this.checkAuthenticated();
    }

    static getQueryParam(name: string): any | undefined {
      const urlParams = new URLSearchParams(window.location.search);
      const value = urlParams.get(name);
      return value !== null ? value : undefined;
    }

    static getRouteParams(name: string, methodName: string): RouteParam[] {
      return this.routeParams.filter(
        (r) => r.controller === name && r.method === methodName
      );
    }

    static registerParam(
      type: ParamType,
      controller: string,
      method: string,
      paramName: string,
      paramIndex: number,
      required: boolean,
      valueType?: ValueType,
      args?: any[]
    ) {
      Router.routeParams.push({
        type: type,
        controller: controller,
        method: method,
        name: paramName,
        index: paramIndex,
        valueType: valueType,
        required: required,
        arguments: args,
      });
    }

    static addRouteControllerRestriction(controller: string, roles?: string[]) {
      Router.routeRestrictions.set(
        Router.fixMinimizedControllerName(controller),
        roles ?? []
      );
    }

    static addRouteActionRestriction(
      controller: string,
      action: string,
      roles?: string[]
    ) {
      Router.routeRestrictions.set(
        `${Router.fixMinimizedControllerName(controller)}::${action}`,
        roles ?? []
      );
    }

    static addRouteControllerLayout(controller: string, layout: string) {
      Router.routeViewLayouts.set(
        Router.fixMinimizedControllerName(controller),
        layout
      );
    }

    private static fixMinimizedControllerName(controllerName: string) {
      if (controllerName.indexOf("_") !== -1) {
        return controllerName.substring(0, controllerName.indexOf("_"));
      }
      return controllerName;
    }

    public static getRouteParam(id: string): string | undefined {
      if (Router.activeRouteParams === undefined) {
        return undefined;
      }
      // @ts-ignore
      if (id in Router.activeRouteParams) {
        // @ts-ignore
        return Router.activeRouteParams[id];
      }
      return;
    }

    public static addRoute(
      type: "action" | "controller",
      target: any,
      method: string,
      controller: any,
      route?: string,
      manualLoadEvent?: boolean
    ): void {
      let params: any[] | undefined;
      let regex: RegExp | undefined;

      if (route) {
        let routeEscaped = route.replace(/\//gi, "\\/");
        params = [];
        const matchRegexp = /{(.*?)}[\/]?/g;
        let match = matchRegexp.exec(route);
        do {
          if (match !== null) {
            // id:int
            // anders
            const paramMatch = match[1];
            let paramName = paramMatch;
            let paramType = ValueType.String;
            let paramRegex = "[\\.a-zA-Z0-9-]+";
            if (paramName.indexOf(":") != -1) {
              const typeSlit = paramMatch.split(":");
              const type = typeSlit[1];
              paramName = typeSlit[0];
              switch (type) {
                case "int":
                  paramType = ValueType.Integer;
                  paramRegex = "[0-9-]+";
                  break;
                case "string":
                  paramType = ValueType.String;
                  break;
                case "float":
                  paramRegex = "[+-]?[0-9]*[.]?[0-9]+";
                  paramType = ValueType.Float;
                  break;
                case "bool":
                  paramRegex = "true|false";
                  paramType = ValueType.Boolean;
                  break;
                case "guid":
                  paramRegex =
                    "[\\da-zA-Z]{8}-([\\da-zA-Z]{4}-){3}[\\da-zA-Z]{12}";
                  paramType = ValueType.Guid;
                  break;
                case "date":
                  paramRegex =
                    "\\d{4}(-\\d\\d(-\\d\\d(T\\d\\d:\\d\\d(:\\d\\d)?(\\.\\d+)?(([+-]\\d\\d:\\d\\d)|Z)?)?)?)?";
                  paramType = ValueType.Date;
                  break;
              }
            }
            params.push({
              name: paramName,
              type: paramType,
            });
            routeEscaped = routeEscaped.replace(
              `{${paramMatch}}`,
              `(?<${paramName}>${paramRegex})`
            );
          }
        } while ((match = matchRegexp.exec(route)) !== null);

        regex = new RegExp(`^${routeEscaped}$`);
      }

      this.routes.push({
        type: type,
        target: target,
        method: method,
        controller: Router.fixMinimizedControllerName(controller),
        route: route,
        params: params,
        routeMatch: regex,
        manualLoadEvent: manualLoadEvent ?? false,
      });
    }

    private static convertRoutePrams = (
      inputParams: any,
      paramTypes: any
    ): any[] => {
      const result = inputParams;

      for (let paramType of paramTypes) {
        switch (paramType.type) {
          case ValueType.Integer:
            result[paramType.name] = parseInt(result[paramType.name]);
            break;
          case ValueType.Float:
            result[paramType.name] = parseFloat(result[paramType.name]);
            break;
          case ValueType.Boolean:
            result[paramType.name] = result[paramType.name] == "true";
            break;
          case ValueType.Date:
            result[paramType.name] = new Date(result[paramType.name]);
            break;
        }
      }

      return result;
    };

    private static getRoute(path: string): Route | undefined {
      this.activeRouteParams = undefined;
      let actionRoute = this.routes.find((route: Route): boolean => {
        if (route.routeMatch) {
          const tmp = route.routeMatch.exec(path);
          if (tmp !== null && tmp.length > 0) {
            // @ts-ignore
            this.activeRouteParams = Router.convertRoutePrams(
              tmp.groups,
              route.params
            );
            return true;
          }
        }
        return false;
      });

      if (!actionRoute) {
        const controllerRoutes = this.routes.filter((route: Route): boolean => {
          return route.type == "controller" && route.route != undefined;
        });

        controllerRoutes.sort(
          (a: Route, b: Route) => b.route!.length - a.route!.length
        );

        const baseController = controllerRoutes.find(
          (route: Route): boolean => {
            return path.indexOf(route.route!) === 0;
          }
        );
        if (baseController == undefined) {
          return undefined;
        }
        const newPath = path.substring(
          baseController.route!.length +
            (baseController.route!.endsWith("/") ? 0 : 1)
        );
        actionRoute = this.routes.find((route: Route): boolean => {
          if (
            route.routeMatch &&
            route.controller == baseController.controller
          ) {
            const tmp = route.routeMatch.exec(newPath);
            if (tmp !== null && tmp.length > 0) {
              // @ts-ignore
              this.activeRouteParams = Router.convertRoutePrams(
                tmp.groups,
                route.params
              );
              return true;
            }
          }
          return false;
        });
      }

      if (actionRoute != undefined && actionRoute.type == "controller") {
        actionRoute = this.routes.find((route: Route): boolean => {
          return (
            route.route == undefined &&
            route.controller == actionRoute!.controller
          );
        });
      }

      return actionRoute;
    }

    private readonly ensureLayoutCreated = (): void => {
      this.layout.ensureCreated();
      this.layout.setState(LayoutState.Loading);
    };

    private readonly checkAuthenticated = (): void => {
      this.auth.isAuthenticated(
        (authResult: Simplex.Models.Authentication.AuthResult): void => {
          if (authResult.authenticated) {
            if (authResult.tenantSelect) {
              this.app.navigateTo("/tenantSelect", { alwaysNavigate: true });
            }
            this.currentSearch = document.location.search;
            this.currentHash = document.location.hash;
            this.currentLink = document.location.pathname;
            this.checkRouting(false);
            this.replaceState();
          } else {
            if (this.isRestrictedRoute()) {
              this.app.navigateTo("/login", { alwaysNavigate: true });
            } else {
              this.currentSearch = document.location.search;
              this.currentHash = document.location.hash;
              this.currentLink = document.location.pathname;
              this.checkRouting(false);
              this.replaceState();
            }
          }
        }
      );
    };

    private readonly checkRestrictions = (
      controllerRestricted?: string[],
      actionRestricted?: string[]
    ): boolean => {
      let authorized = false;
      let roleFound = controllerRestricted && controllerRestricted.length <= 0;
      if (controllerRestricted) {
        for (let requiredRole of controllerRestricted) {
          if (this.auth.hasRole(requiredRole)) {
            roleFound = true;
            break;
          }
        }
      }

      if (roleFound) {
        if (actionRestricted) {
          for (let requiredRole of actionRestricted) {
            if (this.auth.hasRole(requiredRole)) {
              authorized = true;
              break;
            }
          }
        } else {
          authorized = true;
        }
      }
      return authorized;
    };

    private readonly checkRouting = (
      reload: boolean,
      url?: string
    ): boolean => {
      if (!url) {
        url = document.location.pathname;
      }
      if (url.indexOf("?") > -1) {
        url = url.substring(0, url.indexOf("?"));
      }

      const route = Router.getRoute(url);

      if (route === undefined) {
        $(document.body).data("controller", "Error");
        $(document.body).data("action", "NotFound");
      } else {
        $(document.body).data("controller", route.controller);
        $(document.body).data("action", route.method);
        const controllerRestricted = Router.routeRestrictions.get(
          route.controller
        );
        const actionRestricted = Router.routeRestrictions.get(
          `${route.controller}::${route.method}`
        );
        if (controllerRestricted || actionRestricted) {
          if (!this.auth.isSessionActive()) {
            $(document.body).data("controller", "Error");
            $(document.body).data("action", "NotAuthenticated");
          } else {
            let authorized = this.checkRestrictions(
              controllerRestricted,
              actionRestricted
            );
            if (!authorized) {
              $(document.body).data("controller", "Error");
              $(document.body).data("action", "NotAuthorized");
            }
          }
        }
      }
      this.onBeforeApplyRoute().then(() => {
        this.transitionContent(false, reload);
      });

      return true;
    };

    private readonly isRestrictedRoute = (url?: string): boolean => {
      if (!url) {
        url = document.location.pathname;
      }
      if (url.indexOf("?") > -1) {
        url = url.substring(0, url.indexOf("?"));
      }
      const route = Router.getRoute(url);
      if (route === undefined) {
        return false;
      }

      const restrictedController = Router.routeRestrictions.get(
        route.controller
      );
      const restrictedAction = Router.routeRestrictions.get(
        `${route.controller}::${route.method}`
      );
      if (restrictedController || restrictedAction) {
        return true;
      }
      return false;
    };

    private readonly registerGlobalEvents = (): void => {
      $(document).on("click", "a", this.checkLink);
      window.onpopstate = this.windowPopStateHandler;
    };

    private readonly windowPopStateHandler = (e: PopStateEvent): void => {
      if (e.state) {
        const state = e.state as NavPageState;

        this.app
          .getEventBus()
          .publish(
            "historyPop",
            Simplex.Utils.createEventArgs({ state: state }, this)
          );
        $(document.body).data("controller", state.controller);
        $(document.body).data("action", state.action);
        document.title = state.pageTitle;
        Router.activeRouteParams = state.activeRouteParams;

        const needsAuthentication =
          Router.routeRestrictions.has(state.controller) ||
          Router.routeRestrictions.has(`${state.controller}::${state.action}`);

        if (needsAuthentication && !this.auth.isSessionActive()) {
          this.app.navigateTo("/login");
          return;
        }

        this.transitionContent(false, true);
        this.currentLink = state.link;
        this.currentHash = state.hash;
        this.currentSearch = state.search;
      }
    };

    private readonly transitionContent = (
      save: boolean,
      reloadController: boolean
    ): void => {
      this.layout.setState(LayoutState.Transitioning);

      if (save) {
        this.saveState(this.currentLink, this.currentHash, this.currentSearch);
      }
      const controllerName = $(document.body).data("controller");
      const methodName = $(document.body).data("action");
      const route = Router.routes.find((route: Route): boolean => {
        return route.method == methodName && route.controller == controllerName;
      });

      const viewLayout = Router.routeViewLayouts.get(controllerName);
      if (viewLayout) {
        this.layout.setLayout(viewLayout);
      } else {
        this.layout.setLayout("default");
      }

      if (reloadController) {
        try {
          // @ts-ignore
          this.app.loadController();

          Router.Loaded = true;
        } catch (error) {
          if (
            Simplex.Utils.createErrorMessage(error).indexOf("Routing(path):") !=
            -1
          ) {
            $(document.body).data("controller", "Error");
            $(document.body).data("action", "NotFound");
          } else {
            $(document.body).data("controller", "Error");
            $(document.body).data("action", "BadRequest");
          }
          // @ts-ignore
          this.app.loadController();
        }
      }

      if (route === undefined || route.manualLoadEvent === false) {
        this.layout.setState(LayoutState.Idle);
      }
    };

    private readonly hashChangeOnce = (): void => {
      this.replaceState();
    };

    private readonly navItemClicked = (
      link: string,
      forceNavigate: boolean
    ): boolean => {
      if (link === "#") {
        return false;
      }
      if (link.indexOf("/") !== 0) {
        // a/b/c (no starting /)

        let parts = [];
        if (link.indexOf("#") === 0) {
          link = this.currentLink + link;
        } else {
          parts = this.currentLink.split("/");
          parts.pop();
          link = `${parts.join("/")}/${link}`;
        }
      }

      if (
        link === document.location.pathname + document.location.hash &&
        (forceNavigate === undefined || !forceNavigate)
      ) {
        return true;
      }

      if (
        link.indexOf("#") !== -1 &&
        this.currentHash.length > 0 &&
        this.currentLink === link.substring(0, link.indexOf("#"))
      ) {
        this.replaceState();
        this.currentLink = link.substring(0, link.indexOf("#"));
        this.currentHash = link.substring(link.indexOf("#"));
        if (link.indexOf("?") !== -1) {
          this.currentSearch = link.substring(
            link.indexOf("?"),
            this.currentHash.length > 0 ? link.indexOf("#") : undefined
          );
        } else {
          this.currentSearch = "";
        }
        $(window).one("hashchange", this.hashChangeOnce);
        this.app
          .getEventBus()
          .publish(
            "hashChanged",
            Simplex.Utils.createEventArgs({ newHash: this.currentHash }, this)
          );
        return false;
      }

      if (this.checkRouting(false, link)) {
        if (link.indexOf("#") !== -1) {
          this.currentLink = link.substring(0, link.indexOf("#"));
          this.currentHash = link.substring(link.indexOf("#"));
        } else {
          this.currentLink = link.substring(
            0,
            link.indexOf("?") !== -1 ? link.indexOf("?") : undefined
          );
          this.currentHash = "";
        }
        if (link.indexOf("?") !== -1) {
          this.currentSearch = link.substring(
            link.indexOf("?"),
            this.currentHash.length > 0 ? link.indexOf("#") : undefined
          );
        } else {
          this.currentSearch = "";
        }
        this.transitionContent(true, true);
      } else {
        console.error("route failed", link);
      }
      return true;
    };

    private readonly saveState = (
      link: string,
      hash: string,
      search?: string
    ): void => {
      const currentBody = $(document.body);
      const controller = currentBody.data("controller");
      const action = currentBody.data("action");

      window.history.pushState(
        {
          pageTitle: document.title,
          link: link,
          hash: hash,
          controller: controller,
          action: action,
          search: search,
          activeRouteParams: Router.activeRouteParams,
        } as NavPageState,
        document.title,
        link + (search ? search : "") + hash
      );
    };

    public readonly replaceState = (): void => {
      const link = this.currentLink;
      const hash = this.currentHash;
      const search = this.currentSearch;

      const currentBody = $(document.body);
      const controller = currentBody.data("controller");
      const action = currentBody.data("action");

      window.history.replaceState(
        {
          pageTitle: document.title,
          link: link,
          hash: hash,
          search: search,
          controller: controller,
          action: action,
          activeRouteParams: Router.activeRouteParams,
        } as NavPageState,
        document.title,
        link + (search ? search : "") + hash
      );
    };

    private readonly checkLink = (e: JQuery.ClickEvent): boolean => {
      if ($(e.currentTarget).hasClass("no-nav")) {
        return true;
      }
      const forceNavigate = $(e.currentTarget).hasClass("always-nav");
      const href = $(e.currentTarget).attr("href");
      if (
        href !== undefined &&
        (href.indexOf("/") === 0 || href.indexOf("#") === 0)
      ) {
        if (this.navItemClicked.call(self, href, forceNavigate)) {
          e.preventDefault();
          return false;
        }
      }
      return true;
    };

    private readonly createIframeDownloadTarget =async (url: string): Promise<void> => {

      const resp = await fetch(url);
      if(!resp.ok) return;

      const h = resp.headers.get("Content-Disposition")?.split(";").find(x=>x.trim().startsWith("filename"))
      let fileName= h?.split("=").pop();
      fileName = fileName?.replace(/"/g, '')

      const blob= await resp.blob();
      const href = URL.createObjectURL(blob);

      const aElement = document.createElement('a');
      aElement.setAttribute('download',fileName ?? "report.pdf");      
      aElement.href = href;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(href);
    };

    private readonly onActionLoaded = (
      source: any,
      eventArgs: EventArgs
    ): void => {
      this.layout.setState(LayoutState.Idle);
    };

    private readonly appNavigation = (
      source: any,
      eventArgs: EventArgs
    ): void => {
      let alwaysNavigate: boolean = false;
      let href: string = "";

      if (
        eventArgs.data &&
        eventArgs.data.arguments &&
        "fullReload" in eventArgs.data.arguments
      ) {
        return;
      }
      if (
        typeof eventArgs.data.arguments !== "undefined" &&
        "download" in eventArgs.data.arguments
      ) {
        eventArgs.handled = true;
        this.createIframeDownloadTarget(eventArgs.data.url);
        return;
      }
      if (eventArgs.elementData?.download && eventArgs.elementData?.url) {
        eventArgs.handled = true;
        this.createIframeDownloadTarget(eventArgs.elementData.url);
        return;
      }

      if (
        typeof eventArgs.data.arguments !== "undefined" &&
        "alwaysNavigate" in eventArgs.data.arguments
      ) {
        alwaysNavigate = eventArgs.data.arguments["alwaysNavigate"];
      }

      if (eventArgs.data.url) {
        href = eventArgs.data.url;
      } else if (eventArgs.elementData?.url) {
        href = eventArgs.elementData.url;
      }

      if (href.indexOf("/") === 0 || href.indexOf("#") === 0) {
        if (this.navItemClicked.call(self, href, alwaysNavigate) === true) {
          eventArgs.handled = true;
        }
      }
    };

    public static actionLoaded = (): void => {
      Ambrero.AB.Application.Instance!.getEventBus().publish(
        Router.LoadedEvent,
        Simplex.Utils.createEventArgs({}, null)
      );
    };

    private readonly subscribeEventBus = (): void => {
      this.app.getEventBus().subscribe("navigateTo", this.appNavigation, this);
      this.app
        .getEventBus()
        .subscribe(Router.LoadedEvent, this.onActionLoaded, this);
    };
  }
}
