namespace Simplex.WebComponents.Project.DashboardProjectPeriod.Models {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class PeriodData extends ABModel {

        public startDate: string|null;
        public snapshotStartDate: string|null;
        public endDate: string|null;
        public snapshotEndDate: string|null;
        
        public periodChanged: boolean;        
        public multipleYears: boolean;        
        public totalDays: number|null;
        public totalDaysSnapshot: number|null;

        constructor(startDate: string|null = null,
                    snapshotStartDate: string|null = null,
                    startSmallerThanSnapshot: boolean = false,
                    startBiggerThanSnapshot: boolean = false,
                    endDate: string|null = null,
                    snapshotEndDate: string|null = null,
                    multipleYears: boolean,
                    periodChanged: boolean,
                    totalDays: number|null,
                    totalDaysSnapshot: number|null
        ) {
            super();
            this.startDate = startDate;
            this.snapshotStartDate = snapshotStartDate;
            this.endDate = endDate;
            this.snapshotEndDate = snapshotStartDate;
            
            this.multipleYears = multipleYears;
            this.periodChanged = periodChanged;
            this.totalDays = totalDays;
            this.totalDaysSnapshot = totalDaysSnapshot;
        }
    }
}