namespace Simplex.WebComponents {
  import WebComponent = Simplex.Decorators.WebComponent;
  import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
  import ABWebComponent = Simplex.Components.ABWebComponent;
  import APIResult = Simplex.Utils.APIResult;
  import ScopesData = Simplex.Models.Project.ScopesData;
  import PagedResult = Simplex.Models.PagedResult;
  import SnapshotSummary = Simplex.Models.Project.SnapshotSummary;
  import DropdownMenu = Simplex.WebComponents.LayoutComponents.DropdownMenu;
  import ProjectTasksManager = Simplex.Components.ProjectTasksManager;
  import EventArgs = Ambrero.AB.Components.EventArgs;
  import AddScope = Simplex.WebComponents.Project.AddScope;
  import EditScopeForm = Simplex.WebComponents.Project.EditScopeForm;
  import ProjectTaskType = Simplex.Project.Models.ProjectTaskType;
  import ScopeRow = Simplex.WebComponents.Project.ScopeRow;
  import ScopeSortChanged = Simplex.Models.Project.ScopeSortChanged;

  @WebComponent("ui-planning")
  export class Planning extends ABWebComponent {
    private readonly _projectId: string;
    private readonly contentTemplate;
    private readonly _projectTasksManager: ProjectTasksManager;
    private _scopeList?: Simplex.WebComponents.ScopeList;
    private _calenderGrid?: Simplex.WebComponents.CalenderGrid;
    private _scopeViewContainer?: HTMLElement;
    private _calenderContainer?: HTMLElement;
    private _rendered: boolean = false;
    private _addScopeContainer?: HTMLElement;
    private readonly _filterModelSessionStorageKey: string;
    private _filterModel: Simplex.WebComponents.Project.Planning.Models.FilterModel =
      new Simplex.WebComponents.Project.Planning.Models.FilterModel();

    private snapshots: SnapshotSummary[] = [];
    private _openedScopeIds: string[] = [];
    private _addScopeForm?: AddScope;
    private _editScopeForm?: EditScopeForm;
    private _selectedScopeRow?: ScopeRow;
    private _interval: string;
    private _scrollOffset: number = 0;
    private _scrollingElement!: Element;
    private _planningFilter?: PlanningFilter;
    private _filterTags?: PlanningFilterTags;
    private _toggleFilter?: HTMLElement;
    private readonly scopeLevelPrefix = "max-scope-depth--";
    static get observedAttributes() {
      return ["startdate", "enddate"];
    }

    public constructor() {
      super();
      this._projectId = this.getAttribute("project-id") || "";
      this._filterModelSessionStorageKey =
        Simplex.Utils.getPlanningFilterModelCacheKey(this._projectId);
      this.contentTemplate = this.app.getTemplate(
        "WebComponents/Project/Planning",
        "Planning"
      ) as TemplateCallback;
      this._projectTasksManager = this.app.getComponent(
        "ProjectTasksManager"
      ) as ProjectTasksManager;
      this.loadFilterModelFromSessionStorage();
      this._interval = this._filterModel.periodType || "Month";
    }

    set scopeLevel(level: number) {
      this.removeClassesByPrefix(this.scopeLevelPrefix);
      this.classList.add(`${this.scopeLevelPrefix}${level}`);
    }

    get scopeLevel(): number {
      const regx = new RegExp(this.scopeLevelPrefix + "\\d", "g");
      return parseInt(
        this.className.match(regx)![0].replace(this.scopeLevelPrefix, "")
      );
    }

    private removeClassesByPrefix(prefix: string): void {
      const regx = new RegExp(prefix + "\\d", "g");
      this.className = this.className.replace(regx, "");
    }

    private onMilestonesToggled = (state: boolean) => {
      if (state) {
        this.classList.add("milestones-only");
      } else {
        this.classList.remove("milestones-only");
      }
    };

    private onRelationsToggled = (state: boolean) => {
      if (state) {
        this.classList.remove("hide-relations");
      } else {
        this.classList.add("hide-relations");
      }
    };

    private onBudgetToggled = (state: boolean) => {
      if (state) {
        this.classList.remove("no-budgets");
      } else {
        this.classList.add("no-budgets");
      }
    };
    private onCriticalPathToggled = (state: boolean) => {
      if (state) {
        this.classList.add("no-criticalpath");
      } else {
        this.classList.remove("no-criticalpath");
      }
    };

    private onSnapshotsChangesOnlyToggled = (state: boolean) => {
      if (state) {
        this.classList.add("snapshot-changes-only");
      } else {
        this.classList.remove("snapshot-changes-only");
      }
    };

    private toggleScopeRow = (state: boolean, id: string) => {
      if (state) {
        this._openedScopeIds.push(id);
      } else {
        this._openedScopeIds = this._openedScopeIds.filter((e) => e !== id);
      }
    };

    private onScopeToggled = (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      const customEvent = event as CustomEvent;
      if (this._calenderGrid && customEvent.detail.id) {
        this._calenderGrid.setScopeVisibility(
          customEvent.detail.id,
          customEvent.detail.open
        );
        this.toggleScopeRow(customEvent.detail.open, customEvent.detail.id);
        const scopeRow = this._calenderGrid.getScopeRow(
          customEvent.detail.id
        ) as ScopeRow;
        let depth = scopeRow.getAttribute("depth");

        if (scopeRow && depth) {
          this.toggleNextSubScope(
            scopeRow,
            parseInt(depth, 10),
            customEvent.detail.open
          );
        }
      }
      if (customEvent.detail.renderReference) {
        this._calenderGrid!.createScopeReferences();
      }
    };

    private toggleNextSubScope = (
      scopeRow: ScopeRow,
      depth: number,
      openState: boolean
    ) => {
      const nextScope = scopeRow.nextElementSibling as ScopeRow;
      if (!nextScope) {
        return;
      }
      if (nextScope.tagName.toLowerCase() !== "ui-scope-row") {
        return;
      }
      let currentDepth = parseInt(nextScope.getAttribute("depth")!, 10);
      if (currentDepth > depth && nextScope.scope.id) {
        this._calenderGrid!.setScopeVisibility(nextScope.scope.id, openState);
        this.toggleScopeRow(openState, nextScope.scope.id);
        this.toggleNextSubScope(nextScope, depth, openState);
      }
    };

    private onEditToggled = (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      if (!this._scopeList) {
        return;
      }
      const customEvent = event as CustomEvent;
      const viewScope = this._scopeList.getViewScope(customEvent.detail.id);
      let formHeight = 0;
      if (viewScope) {
        formHeight = viewScope.getFormHeight();
      }
      if (this._calenderGrid && customEvent.detail.id) {
        this._calenderGrid.setScopeEditing(
          customEvent.detail.id,
          customEvent.detail.editing,
          formHeight
        );
      }
    };
    private onRelationEditToggled = (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      if (!this._scopeList) {
        return;
      }
      const customEvent = event as CustomEvent;
      const viewScope = this._scopeList.getViewScope(customEvent.detail.id);
      let formHeight = 0;
      if (viewScope) {
        viewScope.onEdit(event);
        formHeight = viewScope.getFormHeight();
      }
      if (this._calenderGrid && customEvent.detail.id) {
        this._calenderGrid.setScopeEditing(
          customEvent.detail.id,
          customEvent.detail.editing,
          formHeight
        );
      }
    };

    private onAddToggled = (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      const customEvent = event as CustomEvent;
      if (this._calenderGrid && customEvent.detail.id) {
        this._calenderGrid.setScopeAdding(
          customEvent.detail.id,
          customEvent.detail.adding
        );
      }
    };

    private onScopeSelected = async (event: Event) => {
      event.stopPropagation();
      const newScopeRow = (event as CustomEvent).detail as ScopeRow;
      if (
        this._selectedScopeRow !== undefined &&
        this._selectedScopeRow !== newScopeRow
      ) {
        this._selectedScopeRow.onDeselectBar();
      }
      this._selectedScopeRow = newScopeRow;
    };
    private onScopeDeselected = async (event: Event) => {
      event.stopPropagation();
      this._selectedScopeRow = undefined;
    };

    public hasSelectedScope = (): boolean => {
      return this._selectedScopeRow !== undefined;
    };

    private onScopeChanged = async (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      if (
        document.scrollingElement &&
        document.scrollingElement.scrollTop > 0
      ) {
        this._scrollOffset = document.scrollingElement!.scrollTop;
      }
      this.dispatchEvent(new Event("changed"));
      await this.loadScopes();
    };

    private onScopeCreated = async (event: Event) => {
      const scopeCreated = (event as CustomEvent)
        .detail as Simplex.Models.Project.ScopeCreated;
      if (scopeCreated.parentScopeId) {
        this._openedScopeIds.push(scopeCreated.parentScopeId);
      }
      event.preventDefault();
      event.stopPropagation();
      this.removeAllHighlights();
      if (
        document.scrollingElement &&
        document.scrollingElement.scrollTop > 0
      ) {
        this._scrollOffset = document.scrollingElement!.scrollTop;
      }
      await this.loadScopes();
    };

    private resetAddState = () => {
      if (
        this._addScopeContainer &&
        this._editScopeForm &&
        this._addScopeForm
      ) {
        this._addScopeContainer.removeChild(this._editScopeForm);
        this._editScopeForm = undefined;
        this._addScopeContainer.appendChild(this._addScopeForm);
      }
    };

    private onAddScopeExpand = (): void => {
      this._editScopeForm = new Simplex.WebComponents.Project.EditScopeForm(
        this._projectId,
        this._scopeList?.getScopes() ?? []
      );
      this._editScopeForm.addEventListener("cancel", this.resetAddState);
      this._editScopeForm.addEventListener(
        "scopeCreated",
        async (event: Event) => {
          await this.onScopeCreated(event);
          this.resetAddState();
        }
      );
      const name = this._addScopeForm!.getValue();
      this._editScopeForm.scope.name = name ?? "";
      this._editScopeForm.scope.parentScopeId = Simplex.Utils.GUID_EMPTY;
      this._editScopeForm.scope.index = -1;
      if (
        this._addScopeContainer &&
        this._editScopeForm &&
        this._addScopeForm
      ) {
        this._addScopeContainer.removeChild(this._addScopeForm);
        this._addScopeContainer.appendChild(this._editScopeForm);
      }
      let bounding = this._editScopeForm.getBoundingClientRect();
      if (bounding.bottom > window.innerHeight) {
        this._editScopeForm.scrollIntoView(false);
      } else if (bounding.top < 0) {
        this._editScopeForm.scrollIntoView();
      }
    };
    private onRelationCreated = async (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      if (
        document.scrollingElement &&
        document.scrollingElement.scrollTop > 0
      ) {
        this._scrollOffset = document.scrollingElement!.scrollTop;
      }
      await this.loadScopes();
    };
    private onRelationDeleted = async (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      if (
        document.scrollingElement &&
        document.scrollingElement.scrollTop > 0
      ) {
        this._scrollOffset = document.scrollingElement!.scrollTop;
      }
      await this.loadScopes();
    };
    private onSortUpdated = async (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      if (
        document.scrollingElement &&
        document.scrollingElement.scrollTop > 0
      ) {
        this._scrollOffset = document.scrollingElement!.scrollTop;
      }
      await this.loadScopes();
    };

    private onScopeDeleted = async (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      const scopeId = (event as CustomEvent).detail.id;
      const deleteResult = await this.request.delete<APIResult<void>>(
        `/api/project/${this._projectId}/scopes/${scopeId}`
      );
      if (deleteResult.isSuccess) {
        await this.onScopeChanged(event);
      }
    };

    private removeAllHighlights = () => {
      this._addScopeForm?.classList.remove("is--highlighted");
      this._scopeList?.removeAllHighlights();
      this._calenderGrid?.removeAllHighlights();
    };

    private onProjectTaskManagerRemoveHighLights = (event: EventArgs) => {
      event.handled = true;
      this.removeAllHighlights();
    };

    private onProjectTaskListItemClicked = async (event: EventArgs) => {
      event.handled = true;
      const task = event.data as Simplex.Project.Models.ProjectTask;
      this.removeAllHighlights();
      if (task.type === ProjectTaskType.MissingScopes) {
        this._addScopeForm?.classList.add("is--highlighted");
        this._addScopeForm?.focus();
      } else {
        this._scopeList?.highlightScopes(task.scopeIds);
        this._calenderGrid?.highlightScopes(task.scopeIds);
      }
    };

    private loadScopes = async (): Promise<void> => {
      const result = await this.request.get<APIResult<ScopesData>>(
        `/api/project/${this._projectId}/scopes?includeDeleted=true${
          this._filterModel?.compareWithSnapshotId !== null
            ? `&compareWithSnapshotId=${this._filterModel.compareWithSnapshotId}`
            : ""
        }`
      );
      if (result.isSuccess && result.data.data) {
        this._scopeList = new Simplex.WebComponents.ScopeList(
          result.data.data.scopes,
          this._projectId,
          result.data.data.projectCurrency,
          result.data.data.multipleYears
        );
        this._scopeList.addEventListener("scopeToggled", this.onScopeToggled);
        this._scopeList.addEventListener("editToggled", this.onEditToggled);
        this._scopeList.addEventListener("addToggled", this.onAddToggled);
        this._scopeList.addEventListener("scopeChanged", this.onScopeChanged);
        this._scopeList.addEventListener("scopeCreated", this.onScopeCreated);
        this._scopeViewContainer?.addEventListener(
          "sortUpdate",
          this.onSortUpdated
        );
        this._calenderGrid = new Simplex.WebComponents.CalenderGrid(
          this._projectId,
          result.data.data.scopes,
          this.getAttribute("project-startdate") ?? "",
          this.getAttribute("project-enddate") ?? "",
          this._interval,
          this._filterModel.scopeLevel
        );
        this._calenderGrid.addEventListener(
          "scopeChanged",
          this.onScopeChanged
        );
        this._calenderGrid.addEventListener(
          "scopeDeselected",
          this.onScopeDeselected
        );
        this._calenderGrid.addEventListener(
          "scopeSelected",
          this.onScopeSelected
        );
        this._calenderGrid.addEventListener(
          "relationDeleted",
          this.onRelationDeleted
        );
        this._calenderGrid.addEventListener(
          "relationCreated",
          this.onRelationCreated
        );
        this._calenderGrid.addEventListener(
          "relationEdit",
          this.onRelationEditToggled
        );
      }
      const fixedItems = this.querySelectorAll(".is--fixed");
      fixedItems.forEach((element) => {
        element.classList.remove("is--fixed");
      });

      if (this._scopeViewContainer && this._scopeList) {
        this._scopeViewContainer.innerHTML = "";
        this._scopeViewContainer.appendChild(this._scopeList);
      }

      if (this._calenderContainer && this._calenderGrid) {
        this._calenderContainer.innerHTML = "<div class='currentyear'></div>";
        this._calenderContainer.appendChild(this._calenderGrid);
      }
      this._scopeList?.toggleAll(true, this._openedScopeIds);
      this._calenderGrid?.toggleAll(true, this._openedScopeIds);
      if (this._addScopeForm && this._scopeList) {
        this._addScopeForm.index = this._scopeList.getScopes().length;
      }
      if (this._scrollOffset && document.scrollingElement) {
        this._scrollingElement = document.scrollingElement;
        //@ts-ignore
        this._scrollingElement.scrollTo({
          left: 0,
          top: this._scrollOffset,
          behavior: "instant",
        });
        this._scrollOffset = 0;
      }

      this._calenderGrid?.startWithDragOffset();

      await this._projectTasksManager.showTasks(
        this._projectId,
        Simplex.WebComponents.ProjectTasksType.Planning
      );
      if (this._filterModel.scopeLevel > 1) {
        this.scopeLevelChanged();
      }
    };

    async render() {
      localStorage.removeItem("horizontalScrollOffset");
      const snapshotsResult = await this.request.post<
        APIResult<PagedResult<SnapshotSummary>>
      >(`/api/project/${this._projectId}/snapshots/search`);
      this.snapshots =
        snapshotsResult.isSuccess && snapshotsResult.data.data
          ? snapshotsResult.data.data.items
          : [];
      if (this._filterModel.compareWithSnapshotId === null) {
        const selectedSnapshot = this.snapshots.filter((s) => s.baseline);
        if (selectedSnapshot.length > 0) {
          this._filterModel.compareWithSnapshotName = selectedSnapshot[0].name;
          this._filterModel.compareWithSnapshotId = selectedSnapshot[0].id;
          this.saveFilterModelToSessionStorage();
        }
      }
      this.innerHTML = this.contentTemplate({
        interval: this._interval,
        filterModel: this._filterModel,
        snapshots: this.snapshots,
        snapshotsFilter: this.snapshots.length > 0,
        projectId: this._projectId,
      });

      this._scopeViewContainer = this.querySelector(
        ".scopeview"
      ) as HTMLElement;
      this._calenderContainer = this.querySelector(
        ".scopeplanning"
      ) as HTMLElement;
      this._addScopeContainer = this.querySelector(
        ".add-container"
      ) as HTMLElement;
      this._addScopeForm = new AddScope();
      this._addScopeContainer?.appendChild(this._addScopeForm);
      this._addScopeForm.projectId = this._projectId;
      this._addScopeForm.addEventListener("cancel", () =>
        this._addScopeForm?.reset()
      );
      this._addScopeForm.addEventListener("scopeCreated", this.onScopeCreated);
      this._addScopeForm.addEventListener("expand", this.onAddScopeExpand);

      this._toggleFilter = this.querySelector(
        ".JSFilterPlanning"
      ) as HTMLElement;
      this._filterTags = this.querySelector(
        "ui-planning-filter-tags"
      ) as PlanningFilterTags;

      if (this._filterTags) {
        this._filterTags?.addEventListener(
          "filterChanged",
          this.onFilterChanged
        );
        this._filterTags.setSnapshots(this.snapshots);
        for (const key in this._filterModel) {
          this._filterTags.addTag(key, this._filterModel[key]);
        }
      }

      this._toggleFilter.addEventListener("click", this.onToggleFilter);
      this.scopeLevelChanged();
      this._rendered = true;
    }

    private onToggleFilter = (event: Event) => {
      if (!this._planningFilter) {
        this._planningFilter = new PlanningFilter(
          this._projectId,
          this.snapshots,
          []!,
          this._interval
        );
        this._planningFilter?.addEventListener(
          "filterChanged",
          this.onFilterChanged
        );
        this._filterTags?.addEventListener(
          "refreshFilter",
          this.onFilterRefresh
        );
        this._toggleFilter?.after(this._planningFilter);
      }
      this._planningFilter.toggle();
    };

    private onFilterRefresh = () => {
      if (this._planningFilter) {
        this._planningFilter.onFilterChanged();
      }
    };

    private removeFromFilter = async (filterItemName: string) => {
      switch (filterItemName) {
        case "milestones":
          this._filterModel.milestones = false;
          this.onMilestonesToggled(this._filterModel.milestones);
          break;
        case "relations":
          this._filterModel.relations = true;
          this.onRelationsToggled(this._filterModel.relations);
          break;
        case "snapshots":
          this._filterModel.snapshots = false;
          this.onSnapshotsChangesOnlyToggled(this._filterModel.snapshots);
          break;
        case "budgets":
          this._filterModel.budgets = true;
          this.onBudgetToggled(this._filterModel.budgets);
          break;
        case "criticalpath":
          this._filterModel.criticalpath = false;
          this.onCriticalPathToggled(this._filterModel.criticalpath);
          break;
        case "scopeLevel":
          this._filterModel.scopeLevel = 1;
          this.scopeLevelChanged();
          break;
        case "compareWithSnapshotId":
          this._filterModel.compareWithSnapshotId = "";
          await this.loadScopes();
          break;
        case "periodType":
          this._filterModel.periodType =
            Simplex.WebComponents.Project.Planning.Models.PlanningPeriodType.Month;
          this._interval = this._filterModel.periodType;
          await this.loadScopes();
          break;
      }
      if (this._planningFilter) {
        this.saveFilterModelToSessionStorage();
        this._planningFilter.onFilterChanged();
      }
    };

    private onFilterChanged = async (event: Event) => {
      if (!this._filterTags) {
        return;
      }
      const { name, value } = (event as CustomEvent).detail;

      switch (name) {
        case "periodType":
          this._filterModel.periodType = value;
          this._interval = this._filterModel.periodType;
          await this.loadScopes();
          break;
        case "milestones":
          this._filterModel.milestones = value;
          this.onMilestonesToggled(value);
          break;
        case "relations":
          this._filterModel.relations = value;
          this.onRelationsToggled(value);
          break;
        case "snapshots":
          this._filterModel.snapshots = value;
          this.onSnapshotsChangesOnlyToggled(value);
          break;
        case "budgets":
          this._filterModel.budgets = value;
          this.onBudgetToggled(value);
          break;
        case "criticalpath":
          this._filterModel.criticalpath = value;
          this.onCriticalPathToggled(value);
          break;
        case "scopeLevel":
          this._filterModel.scopeLevel = value;
          this.scopeLevelChanged();
          break;
        case "compareWithSnapshotId":
          this._filterModel.compareWithSnapshotId = value;
          await this.loadScopes();
          break;
        case "tagRemoved":
          await this.removeFromFilter(value);
      }
      this._filterTags.checkTag(name, value);
      this.saveFilterModelToSessionStorage();
    };
    private scopeLevelChanged(): void {
      this.scopeLevel = this._filterModel.scopeLevel;
      if (this._calenderGrid) {
        this._calenderGrid!.setMaxScopeDepth(this._filterModel.scopeLevel);
      }
      let openScopes = this.scopeLevel !== 1;
      this.onToggleAllScopesClicked(new Event("click"), openScopes);
    }

    private saveFilterModelToSessionStorage = () => {
      localStorage.setItem(
        this._filterModelSessionStorageKey,
        JSON.stringify(this._filterModel)
      );
    };
    private onToggleAllScopesClicked = (event: Event, open: boolean): void => {
      event.preventDefault();
      event.stopPropagation();
      this._scopeList?.toggleAll(open);
      this._calenderGrid?.toggleAll(open);
      this._openedScopeIds = open
        ? this._scopeList?.getScopes().map((s) => s.id ?? "") ?? []
        : [];
    };

    private bindElementEventListeners(): void {
      const openAllScopesAnchor = this.querySelector("a.open");
      if (openAllScopesAnchor) {
        openAllScopesAnchor.addEventListener("click", (event: Event) =>
          this.onToggleAllScopesClicked(event, true)
        );
      }
      const closeAllScopesAnchor = this.querySelector("a.close");
      if (closeAllScopesAnchor) {
        closeAllScopesAnchor.addEventListener("click", (event: Event) =>
          this.onToggleAllScopesClicked(event, false)
        );
      }

      this.addEventListener("deleteScope", this.onScopeDeleted);
      this._projectTasksManager.on(
        "removeAllHighLights",
        this.onProjectTaskManagerRemoveHighLights
      );
      this._projectTasksManager.on(
        "projectTaskListItemClicked",
        this.onProjectTaskListItemClicked
      );
    }

    private removeElementEventListeners(): void {
      const openAnchor = this.querySelector("a.open");
      if (openAnchor) {
        openAnchor.removeEventListener("click", (event: Event) =>
          this.onToggleAllScopesClicked(event, true)
        );
      }
      const closeAnchor = this.querySelector("a.close");
      if (closeAnchor) {
        closeAnchor.removeEventListener("click", (event: Event) =>
          this.onToggleAllScopesClicked(event, false)
        );
      }
      this.removeEventListener("deleteScope", this.onScopeDeleted);
      this._projectTasksManager.off(
        "removeAllHighLights",
        this.onProjectTaskManagerRemoveHighLights
      );
      this._projectTasksManager.off(
        "projectTaskListItemClicked",
        this.onProjectTaskListItemClicked
      );
    }
    private loadFilterModelFromSessionStorage = () => {
      const filterModelFromSessionStorage = localStorage.getItem(
        this._filterModelSessionStorageKey
      );
      if (filterModelFromSessionStorage) {
        const filterModelFromSessionStorageJson = JSON.parse(
          filterModelFromSessionStorage
        ) as Simplex.WebComponents.Project.Planning.Models.FilterModel;

        this._filterModel.compareWithSnapshotId =
          filterModelFromSessionStorageJson.compareWithSnapshotId;
        this._filterModel.compareWithSnapshotName =
          filterModelFromSessionStorageJson.compareWithSnapshotId;
        this._filterModel.scopeId =
          filterModelFromSessionStorageJson.compareWithSnapshotId;
        this._filterModel.periodType =
          filterModelFromSessionStorageJson.periodType;
        this._filterModel.scopeLevel =
          filterModelFromSessionStorageJson.scopeLevel ?? 3;
        this._filterModel.snapshots =
          filterModelFromSessionStorageJson.snapshots;

        this._filterModel.budgets = filterModelFromSessionStorageJson.budgets;
        this._filterModel.relations =
          filterModelFromSessionStorageJson.relations;
        this._filterModel.milestones =
          filterModelFromSessionStorageJson.milestones;
        this._filterModel.criticalpath =
          filterModelFromSessionStorageJson.criticalpath;

        this.onSnapshotsChangesOnlyToggled(this._filterModel.snapshots);
        this.onBudgetToggled(this._filterModel.budgets);
        this.onRelationsToggled(this._filterModel.relations);
        this.onMilestonesToggled(this._filterModel.milestones);
        this.onCriticalPathToggled(this._filterModel.criticalpath);
      } else {
        this._filterModel.scopeLevel = 3;
      }
    };

    async connectedCallback() {
      if (!this.isConnected) {
        return;
      }
      if (!this._rendered) {
        await this.render();
        this.bindElementEventListeners();
      }
      await this.loadScopes();
    }
    disconnectedCallback() {
      this.removeElementEventListeners();
    }
  }
}
