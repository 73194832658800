/// <reference path="../Declarations/Handlebars.d.ts" />

namespace Simplex.HandlebarHelper {

	export const i18n: Handlebars.HelperDelegate = function(context?: any, arg1?: any, arg2?: any, arg3?: any, arg4?: any, arg5?: any) {

		const params: [string, ...(string | number)[]] = [context];

		for(let i = 1; i < arguments.length; i++) {
			if (arguments[i] != null) {
				params.push(arguments[i].toString());
			} else {
				params.push("null");
			}
		}

		return Messages.apply(Messages, params);
	}
}

Handlebars.registerHelper("i18n", Simplex.HandlebarHelper.i18n);