namespace Simplex.Controllers {
  import Auth = Simplex.Components.Auth;
  import Content = Simplex.Components.Content;
  import EventArgs = Ambrero.AB.Components.EventArgs;
  import FetchRequestHandler = Simplex.Components.FetchRequestHandler;
  import Layout = Simplex.Components.Layout;
  import Inject = Simplex.Decorators.Inject;
  import Path = Simplex.Decorators.Path;
  import Get = Simplex.Decorators.Get;
  import Authenticated = Simplex.Decorators.Authenticated;
  import Controller = Simplex.Decorators.Controller;

  interface IndexContext {}

  interface DetailContext {
    tenantId?: string;
  }

  @Authenticated()
  @Controller("/admin")
  export class Admin extends Ambrero.AB.Controllers.Controller {
    @Inject("Layout")
    private layout!: Layout;

    @Inject("FetchRequestHandler")
    private request!: FetchRequestHandler;

    @Inject("Auth")
    private auth!: Auth;

    @Get("tenant")
    public Index(): void {
      if (!this.auth.isAdminTenant) {
        this.app.navigateTo("/");
        return;
      }

      const content = this.app.getComponent(
        "Content",
        "Admin/Tenant/index"
      ) as Content<IndexContext>;
      content.setTargetContainerElement(this.layout.getContentRenderTarget());
      content.setContext({} as IndexContext);
      const onCreate = async (event: EventArgs): Promise<boolean> => {
        event.handled = true;
        this.app.navigateTo("/admin/tenant/create");
        return false;
      };
      content.on("submit", onCreate);
      content.draw();
    }

    @Get("tenant/{id:guid}")
    public async Edit(@Path("id") id: string): Promise<void> {
      if (!this.auth.isAdminTenant) {
        this.app.navigateTo("/");
        return;
      }

      const content = this.app.getComponent(
        "Content",
        "Admin/Tenant/detail"
      ) as Content<DetailContext>;
      content.setTargetContainerElement(this.layout.getContentRenderTarget());

      content.setContext({
        tenantId: id,
      } as DetailContext);
      content.draw();
    }
    @Get("tenant/{id:guid}/projects")
    public async TenantProjects(@Path("id") id: string): Promise<void> {
      if (!this.auth.isAdminTenant) {
        this.app.navigateTo("/");
        return;
      }

      //   //@ts-ignore
      //   window.tempState = {
      //     //@ts-ignore
      //     ...window.tempState,
      //     ...{
      //       currentTenant: id,
      //     },
      //   };

      const content = this.app.getComponent(
        "Content",
        "Home/index",
        id
      ) as Content<IndexContext>;
      content.setTargetContainerElement(this.layout.getContentRenderTarget());
      content.setContext({} as IndexContext);
      content.draw();
    }

    @Get("tenant/create")
    public async Create(): Promise<void> {
      if (!this.auth.isAdminTenant) {
        this.app.navigateTo("/");
        return;
      }

      const content = this.app.getComponent(
        "Content",
        "Admin/Tenant/detail"
      ) as Content<DetailContext>;
      content.setTargetContainerElement(this.layout.getContentRenderTarget());

      content.setContext({} as DetailContext);
      content.draw();
    }
  }
}
