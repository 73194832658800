namespace Simplex.WebComponents.Project.ProjectDashboardScopes {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import APIResult = Simplex.Utils.APIResult;
    import ScopesData = Simplex.Models.Project.ScopesData;
    
    @WebComponent("ui-dashboard-project-scopes-widget")
    export class ProjectDashboardScopesWidget extends ABWebComponent {
        private readonly contentTemplate;
        private readonly _projectId: string;
        private _scopesData: ScopesData|null = null;
        private _filterAllScopesSwitch: HTMLElement|null = null;

        public constructor() {
            super();
                this._projectId = this.getAttribute("project-id") || "";
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/Dashboard/ProjectDashboardScopes', 'ProjectDashboardScopes') as TemplateCallback;
        }

        private getProjectScopesData = async (): Promise<ScopesData | null> => {

            const result = await this.request.get<APIResult<ScopesData>>(`/api/project/${this._projectId}/scopes`);
            if (!result.isSuccess) {
                return null;
            }
            return result.data.data ?? null;
        }

        public async render(filter:FilterType) {
            this.removeElementEventListeners();
            if(this._scopesData === null) {
                this._scopesData = await this.getProjectScopesData();
                if (!this._scopesData) {
                    return;
                }
            }
            this.innerHTML = this.contentTemplate(
                {
                    Data: this._scopesData, 
                    FilteredScopes: this._scopesData.snapshotCreated === null ? this._scopesData.scopes : this._scopesData.scopes.filter(s=> filter === FilterType.All || (filter === FilterType.ChangesOnly && s.scopeChanged)),
                    Filter:FilterType[filter]
                });
            this.bindElementEventListeners();
        }

        public async connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            await this.render(FilterType.ChangesOnly);
        }

        disconnectedCallback() {
            this.removeElementEventListeners();
        }
        
        private switchFilterType = async (evt: Event): Promise<void> => {
            evt.stopPropagation();
            const targetElement = evt.target as HTMLElement;
            if (targetElement && targetElement.tagName === 'INPUT') {
                //@ts-ignore
                if (targetElement.checked) {
                    await this.render(FilterType.ChangesOnly);
                } else {
                    await this.render(FilterType.All);
                }
            }
        }
        
        private bindElementEventListeners(): void {
            this._filterAllScopesSwitch = this.querySelector('ui-input-switch') as HTMLElement;
            if (this._filterAllScopesSwitch) {
                this._filterAllScopesSwitch.addEventListener('click', this.switchFilterType);
            }
        }

        private removeElementEventListeners(): void {
            if (this._filterAllScopesSwitch) {
                this._filterAllScopesSwitch.removeEventListener('click', this.switchFilterType);
            }
        }
    }

    enum FilterType {
        All,
        ChangesOnly
    }
}
