namespace Simplex.WebComponents.FormElements {
  import WebComponent = Simplex.Decorators.WebComponent;
  import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
  import ABWebComponent = Simplex.Components.ABWebComponent;

  @WebComponent("ui-input-switch")
  export class InputSwitch extends ABWebComponent {
    private readonly contentTemplate;

    public constructor() {
      super();
      this.contentTemplate = this.app.getTemplate(
        "WebComponents/FormElements/InputSwitch",
        "InputSwitch"
      ) as TemplateCallback;
    }

    render() {
      this.innerHTML = this.contentTemplate({
        name: this.getAttribute("name"),
        checked:
          this.getAttribute("checked") === "" ||
          this.getAttribute("checked") === "true" ||
          this.getAttribute("checked") === "checked",
        switchValue: this.getAttribute("switchValue"),
        label: this.getAttribute("label"),
        yesText: this.getAttribute("yesText"),
        noText: this.getAttribute("noText"),
        class: this.getAttribute("class"),
        tabindex: this.getAttribute("tabindex"),
        disabled: this.getAttribute("disabled") === "",
        required: this.getAttribute("required") === "",
      });
    }

    connectedCallback() {
      if (!this.isConnected) {
        return;
      }
      this.render();
    }
  }
}
