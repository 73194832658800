namespace Simplex.WebComponents.Project.DashboardProjectPeriod.Models {
    import ABModel = Ambrero.AB.Models.ABModel;
    import IBoundModel = Ambrero.AB.Models.IBoundModel;

    export class ChangeProjectPeriodRequest extends ABModel {

        public startDate: string|null;
        public endDate: string|null;

        constructor(startDate: string|null = null, endDate: string|null = null) {
            super();
            this.startDate = startDate;
            this.endDate = endDate;
        }
    }
}