namespace Simplex.WebComponents.FormElements {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;


    @WebComponent("ui-color-picker")
    export class ColorPicker extends ABWebComponent {

        private readonly contentTemplate: TemplateCallback;
        private _color: string| null = null;
        private _picker: HTMLElement | null = null;
        private _options: any;
        private input: HTMLInputElement|null = null;

        get color(): string | null {
            return this._color
        }
        
        static get observedAttributes() { return ['color']; }

        public constructor() {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/FormElements/ColorPicker', 'ColorPicker') as TemplateCallback;
            this._color = this.getAttribute('color');
        }

        onToggle = (event:MouseEvent|null) => {
            const isOpen = this.getAttribute("open");
            if (!isOpen) {
                this.setAttribute("open","true");
            } else {
                this.removeAttribute("open");
            }
        }
        onSelectColor = (color: string) => {
            this._color = color;
            this.setAttribute("color", this._color);
            this.removeAttribute("open");
            if(this.input !== null) {
                this.input.value = this._color;
                this.input.dispatchEvent(new Event('change'));
            }
        }
        
        render() {
            this.innerHTML = this.contentTemplate({
                name: this.getAttribute("name"),
                color: this._color
            });
            this._options = this.querySelectorAll('.colorpicker .color');
            this._picker = this.querySelector('.activecolor');
            this.input = this.querySelector('input');
            if (this._picker) {
                this._picker.addEventListener("click", this.onToggle);
            }
          
            for(let i = 0; i < this._options.length; i++) {
                this._options[i].addEventListener("click", this.onSelectColor.bind(this, this._options[i].dataset.color));
            }
        }
        attributeChangedCallback(name: string, oldValue: string, newValue: string):void {
            if (this._picker) {
                switch (name) {
                    case 'color':
                        if(oldValue) {
                            this._picker.classList.remove(`color--${oldValue}`);
                        }
                        if(newValue) {
                            this._picker.classList.add(`color--${newValue}`);
                        }
                        break;
                }
            }
        }
        
        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            this.render();
        }
    }
}
