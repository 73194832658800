/* COPYRIGHT (C) Ambrero Software B.V. - All rights reserved
 * This file is part of the Ambrero Base Framework and supplied under license.
 * Unauthorized copying of this file is strictly prohibited.
 * It is permitted to make changes to this file, as long as it is only used in this project.
 */

/// <reference path="../Decorators/Decorators.d.ts" />


namespace Simplex.Components {
    import Application = Ambrero.AB.Application;
    import EventArgs = Ambrero.AB.Components.EventArgs;
    import APIResult = Simplex.Utils.APIResult;
    
    import SystemInject = Simplex.Decorators.SystemInject;

    @Simplex.Decorators.Singleton()
    export class Language {
        @SystemInject("Monitor")
        private readonly monitor!: Monitor;

        private readonly app: Application;
        private readonly trackedElements: HTMLElement[] = [];
        private readonly messageStore: any[] = [];

        public constructor(app: Application) {
            this.app = app;
            this.monitor = this.app.getSystemComponent('Monitor');

            this.app.getEventBus().on('reset', this.subscribeEventBus);
            this.subscribeEventBus();

            this.monitor.addWatch(".i18n", this.checkElement);
            this.trackElements();
        }

        private readonly determineKey = (element: HTMLElement): string => {
            const $element = $(element);
            const key = $element.data('key');

            if (!key) {
                if (element instanceof HTMLInputElement && (element.type === "text" || element.type === "password")) {
                    return element.placeholder;
                }
                return $element.text().trim();
            }

            return key;
        }

        private readonly setTranslation = (element: HTMLElement, messageKey: string): void => {
            const $element = $(element);
            if (element instanceof HTMLInputElement && (element.type === "text" || element.type === "password")) {
                $element.attr('placeholder', Messages(messageKey));
            } else {
                $element.text(Messages(messageKey));
            }
        }

        private readonly translateElement = (element: HTMLElement): void => {
            const $element = $(element);
            const key = this.determineKey(element);
            if (key !== "") {
                this.setTranslation(element, key);
            }

            $element.data('key', key);
            $element.data('translated', true);
        };

        private readonly checkElement = (element: HTMLElement, newElement: boolean): void => {
            if (newElement) {
                const $element = $(element);
                if ($element.data('translated')) {
                    return;
                }
                this.translateElement(element);
                this.trackedElements.push(element);
            } else {
                const indexOfTrackedElement = this.trackedElements.findIndex(function (item) {
                    return item.isSameNode(element);
                });

                if (indexOfTrackedElement !== -1) {
                    this.trackedElements.splice(indexOfTrackedElement, 1);
                }
            }
        };

        private readonly trackElements = (): void => {
            for (const element of $('.i18n').get()) {
                this.checkElement(element, true);
            }
        };

        public readonly languageChanged = async (): Promise<void> => {
            this.app.getRequestHandler().get('/js/dynamic/messages.js', (result:any) => {
                const currentCulture = window.Messages("_culture");
                const newCulture = result.data._culture;
                if (currentCulture === newCulture) {
                    return;
                }
                this.messageStore.push(result.data);
                //@ts-ignore
                window.MessageStore = result.data;
                this.languageLoaded();

            });        
        };

        public readonly languageLoaded = (): void => {

            this.app.getEventBus().publish('languageChanged', Simplex.Utils.createEventArgs({}, this));

            this.trackedElements.forEach((item) => {
                this.translateElement(item);
            });
        };

        private readonly applicationLoaded = (): void => {
            const actionName = $(document.body).data("action");
            const controller = this.app.getController();
            if (controller !== undefined) {
                window.document.title = Messages(`pages.${controller.controllerName}.${actionName}`) + " | Simjo";
            }
        };

        private readonly subscribeEventBus = (): void => {
            this.app.getEventBus().subscribe('applicationLoaded', this.applicationLoaded, self);
        }
    }
}
