namespace Simplex.Components {
  import Application = Ambrero.AB.Application;
  import EventArgs = Ambrero.AB.Components.EventArgs;
  import ModelBinder = Ambrero.AB.Components.ModelBinder;
  import APIResult = Simplex.Utils.APIResult;

  interface SlideInContext {
    Model: any;
    Loading: boolean;
    New: boolean;
    ProjectId: string;
    projectCurrency: string;
    CostCategories: CostCategory[];
    Scopes: any[];
    HasErrors: boolean;
    AmountError: boolean;
    PoNumberError: boolean;
    SuggestedPoNumbers: string[];
    HasNext: boolean;
  }

  interface CostCategory {
    id: string;
    name: string;
    code: string;
  }

  @Simplex.Decorators.Singleton()
  export class FinanceEntrySlidein extends ABComponent {
    private app: Ambrero.AB.Application;
    private slideIn: any;
    private targetContainer?: HTMLElement;
    private readonly _content: Simplex.Components.Content<SlideInContext>;
    private readonly _binder: ModelBinder;
    private readonly _model: Simplex.Models.Project.FinanceEntryEdit;
    private readonly request: FetchRequestHandler;
    private readonly createCostCategoryPopup: NewCostCategoryPopup;
    private _listView?: WebComponents.Project.Report.FinanceList;
    private readonly _notice: any;

    constructor(app: Application) {
      super();
      this.app = app;
      this.request = this.app.getComponentType<FetchRequestHandler>(
        "FetchRequestHandler"
      )!;
      this._notice = this.app.getComponent("AmbreroComponents.Notice");
      this.createCostCategoryPopup =
        this.app.getComponentType<NewCostCategoryPopup>(
          "NewCostCategoryPopup"
        )!;

      this._model = new Simplex.Models.Project.FinanceEntryEdit();
      this._binder = this.app.getComponentType<ModelBinder>("ModelBinder")!;

      this._content = this.app.getComponent(
        "Content",
        "Project/financeEntrySlidein"
      ) as Content<SlideInContext>;

      this._content.setContext({
        Model: this._model,
        Loading: true,
        New: true,
      } as SlideInContext);

      app.getEventBus().on("reset", this.subscribeEventBus);
      this.subscribeEventBus();
      this._content.on(["draw", "drawSection"], this.onContentDrawn);

      window.addEventListener("keyup", this.onWindowKeyUp);

      this.createCostCategoryPopup.on("created", this.onNewCostCategoryCreated);
    }
    private readonly subscribeEventBus = (): void => {
      this.app.getEventBus().subscribe("pageUnload", this.pageNavigation);
      this.app
        .getEventBus()
        .subscribe("newFinanceEntry", this.showNewFinanceEntry, this);
    };

    private readonly onWindowKeyUp = (event: KeyboardEvent): void => {
      if (event.key === "Escape") {
        this.close();
        return;
      }
    };

    public setListView = (
      listView: WebComponents.Project.Report.FinanceList
    ): void => {
      this._listView = listView;
    };

    private readonly pageNavigation = (): void => {
      if (this.targetContainer?.style.display === "block") {
        this.close();
      }
    };
    private onNewCostCategoryCreated = (eventArgs: EventArgs): void => {
      const categoryId = eventArgs.data.id;
      const categoryName = eventArgs.data.name;
      const categoryCode = eventArgs.data.code;

      this._content.getContext().CostCategories.push({
        id: categoryId,
        name: categoryName,
        code: categoryCode,
      });

      this._content.getContext().Model.costCategoryId = categoryId;
      this._content.refreshSections(".JSCostCategories");
    };

    private onContentDrawn = (): void => {
      if (!this.targetContainer) {
        return;
      }
      // this.targetContainer.querySelectorAll("ui-button[action='import']").forEach(item => {
      //     item.removeEventListener("click", this.show);
      //     item.addEventListener("click", this.show);
      // });
      this.bindEvents();
    };

    private recalculateAmountEx = () => {
      let amountEx = 0;
      //@ts-ignore
      const tmp = this._model.toJSON();
      tmp.scopes.forEach((scope: any) => {
        if (scope.amountEx) {
          amountEx += scope.amountEx;
        }
      });
      this._model.amountEx = amountEx;
    };
    private validateAmountEx = () => {
      let amountEx = 0;
      //@ts-ignore
      const tmp = this._model.toJSON();
      tmp.scopes.forEach((scope: any) => {
        if (scope.amountEx) {
          amountEx += parseFloat(scope.amountEx);
        }
      });
      this._content.getContext().AmountError = this._model.amountEx != amountEx;

      this._content.getContext().HasErrors =
        this._content.getContext().AmountError ||
        this._content.getContext().PoNumberError;

      this._content.refreshSections(".JSErrors, .JSFooter");
    };

    private modelPropertyChanged = (event: EventArgs) => {
      // scopes.0.amountEx
      const amountMatch = event.data.field.match(/scopes\.(\d+)\.amountEx/);
      if (amountMatch) {
        const scopeIndex = parseInt(amountMatch[1]);
        if (this._content.getContext().New) {
          this.recalculateAmountEx();
          this._content.refreshSections(".JSTotalAmount");
        } else {
          this.validateAmountEx();
        }
      }
      if (event.data.field.indexOf("scopes") !== -1) {
        const tmp = this._model.toJSON();
        if (
          tmp.scopes[tmp.scopes.length - 1].amountEx !== null &&
          tmp.scopes[tmp.scopes.length - 1].scopeId !== null &&
          tmp.scopes[tmp.scopes.length - 1].scopeId !== ""
        ) {
          this._model.scopes.push(
            new Simplex.Models.Project.FinanceEntryScope()
          );
          this._content.refreshSections(".JSScopes");
        }
      }

      switch (event.data.field) {
        case "type":
          this._content.refreshSections(".JSTypeSpecificFields");
          break;
      }
    };

    private createTargetContainer = () => {
      if (this.targetContainer) {
        return;
      }
      this.targetContainer = document.createElement("aside");
      this.targetContainer.classList.add("finance-entry");

      document.body.appendChild(this.targetContainer);
      this._content.setTargetContainerElement(this.targetContainer);
    };

    private bindEvents = () => {
      if (!this.targetContainer) {
        return;
      }
      this.targetContainer
        .querySelectorAll("ui-button[data-action='close']")
        .forEach((item) => {
          item.removeEventListener("click", this.close);
          item.addEventListener("click", this.close);
        });
      this.targetContainer
        .querySelectorAll("ui-button[data-action='add']")
        .forEach((item) => {
          item.removeEventListener("click", this.add);
          item.addEventListener("click", this.add);
        });
      this.targetContainer
        .querySelectorAll(".JSAddCostCategory")
        .forEach((item) => {
          item.removeEventListener("click", this.createCostCategory);
          item.addEventListener("click", this.createCostCategory);
        });
      this.targetContainer
        .querySelectorAll(".JSRemoveScope")
        .forEach((item) => {
          item.removeEventListener("click", this.removeScope);
          item.addEventListener("click", this.removeScope);
        });

      this.targetContainer
        .querySelectorAll("ui-button[data-action='next']")
        .forEach((item) => {
          item.removeEventListener("click", this.nextItem);
          item.addEventListener("click", this.nextItem);
        });
      this.targetContainer
        .querySelectorAll("ui-button[data-action='previous']")
        .forEach((item) => {
          item.removeEventListener("click", this.previousItem);
          item.addEventListener("click", this.previousItem);
        });

      this.targetContainer
        .querySelectorAll("ui-button[data-action='processAndNext']")
        .forEach((item) => {
          item.removeEventListener("click", this.processAndNext);
          item.addEventListener("click", this.processAndNext);
        });
      this.targetContainer
        .querySelectorAll("ui-button[data-action='process']")
        .forEach((item) => {
          item.removeEventListener("click", this.process);
          item.addEventListener("click", this.process);
        });
      this.targetContainer
        .querySelectorAll("ui-button[data-action='ignore']")
        .forEach((item) => {
          item.removeEventListener("click", this.ignore);
          item.addEventListener("click", this.ignore);
        });
    };

    private close = () => {
      if (!this.targetContainer) {
        return;
      }
      this.targetContainer.style.display = "none";
      this.emit("close");
    };

    private createCostCategory = async (event: Event): Promise<void> => {
      event.stopPropagation();
      event.preventDefault();
      this.createCostCategoryPopup.show(
        <HTMLElement>event.target,
        this._content.getContext().ProjectId
      );
    };
    private removeScope = async (event: Event): Promise<void> => {
      event.stopPropagation();
      event.preventDefault();
      const element = event.currentTarget as HTMLElement;

      this._content
        .getContext()
        .Model.scopes.splice(parseInt(element.dataset.index!), 1);
      this._content.refreshSections(".JSScopes");
      this.validateAmountEx();
    };

    private nextItem = async (): Promise<boolean> => {
      if (!this._listView) {
        return false;
      }
      if (!(await this._listView.selectNextItem(true))) {
        return false;
      }
      return true;
    };
    private previousItem = async (): Promise<boolean> => {
      if (!this._listView) {
        return false;
      }
      if (!(await this._listView.selectPreviousItem())) {
        return false;
      }
      return true;
    };

    private process = async (): Promise<void> => {
      this.validateAmountEx();
      if (this._content.getContext().AmountError) {
        return;
      }

      if (await this.save()) {
        await this._listView!.reload();
        this.close();
      }
    };
    private ignore = async (): Promise<void> => {
      await this.request.put(
        `/api/project/${this._content.getContext().ProjectId}/finance/${
          this._content.getContext().Model.id
        }/ignore`,
        {}
      );
      await this._listView!.reload();
      this.close();
    };
    private processAndNext = async (): Promise<void> => {
      this.validateAmountEx();
      if (this._content.getContext().AmountError) {
        return;
      }
      if (await this.save()) {
        if (!(await this.getNextItem())) {
          await this._listView!.reload();
          this.close();
        }
      }
    };

    private getNextItem = async (): Promise<boolean> => {
      if (!this._listView) {
        return false;
      }
      const filter = this._listView.getFilter();
      const result = await this.request.post<
        APIResult<{
          page?: number | null;
          itemId?: string | null;
          hasNext?: boolean | null;
        }>
      >(
        `/api/project/${
          this._content.getContext().ProjectId
        }/finance/nextitem?lastId=${
          this._content.getContext().Model.id
        }&${this._listView.constructDataUrlQuery()}`,
        filter
      );
      if (
        result.isSuccess &&
        result.data.data &&
        result.data.data.itemId !== null
      ) {
        this._content.getContext().HasNext = result.data.data.hasNext ?? false;
        await this._listView.setPageNumber(result.data.data.page!);
        return this._listView.selectItemById(result.data.data.itemId);
      }
      return false;
    };
    private hasNextItem = async (): Promise<boolean> => {
      if (!this._listView) {
        return false;
      }
      const filter = this._listView.getFilter();
      const result = await this.request.post<
        APIResult<{
          page?: number | null;
          itemId?: string | null;
          hasNext?: boolean | null;
        }>
      >(
        `/api/project/${
          this._content.getContext().ProjectId
        }/finance/nextitem?lastId=${
          this._content.getContext().Model.id
        }&${this._listView.constructDataUrlQuery()}`,
        filter
      );
      if (result.isSuccess && result.data.data) {
        return result.data.data.hasNext ?? false;
      }
      return false;
    };

    private save = async (): Promise<boolean> => {
      const model = this._content.getContext().Model;
      const lastIndex = model.scopes.length - 1;
      this._binder.disableFieldValidation(
        model,
        `scopes.${lastIndex}.scopeId`,
        `scopes.${lastIndex}.amountEx`
      );

      if (!this._binder.validate(model)) {
        this._binder.enableFieldValidation(
          model,
          `scopes.${lastIndex}.scopeId`,
          `scopes.${lastIndex}.amountEx`
        );
        return false;
      }
      const updateModel = new Simplex.Models.Project.UpdateFinanceEntry();
      const data = this._model.toJSON();
      updateModel.status = "Processed";
      updateModel.poNumber = data.poNumber;
      updateModel.date = data.date;
      updateModel.supplier = data.supplier;
      updateModel.costCategoryId = data.costCategoryId;
      updateModel.log = data.log;
      updateModel.description = data.description;
      updateModel.scopes = [];
      data.scopes.forEach((scope: any) => {
        const newScope = new Simplex.Models.Project.FinanceEntryScope();
        newScope.amount = scope.amountEx;
        newScope.scopeId = scope.scopeId;
        if (
          newScope.amount !== null &&
          newScope.amount !== "" &&
          newScope.scopeId !== null &&
          newScope.newScope !== ""
        ) {
          updateModel.scopes.push(newScope);
        }
      });

      const createResult = await this.request.put<APIResult<void>>(
        `/api/project/${this._content.getContext().ProjectId}/finance/${
          model.id
        }`,
        updateModel
      );
      if (createResult.isSuccess) {
        this.app
          .getEventBus()
          .publish(
            "financeEntryUpdated",
            Simplex.Utils.createEventArgs(null, this)
          );
        if (data.type === "Actual") {
          this._notice.addMessage(
            Messages("finance.slidein.save.actual.success"),
            "success"
          );
        } else {
          this._notice.addMessage(
            Messages("finance.slidein.save.expense.success"),
            "success"
          );
        }

        return true;
      }

      return false;
    };

    private add = async (): Promise<void> => {
      const createModel = new Simplex.Models.Project.CreateFinanceEntry();
      const data = this._model.toJSON();

      createModel.type = data.type;
      createModel.reference = data.reference;
      createModel.poNumber = data.poNumber;
      createModel.bookingCode = data.bookingCode;
      createModel.date = data.date;
      createModel.supplier = data.supplier;
      createModel.amountEx = data.amountEx;
      createModel.costCategoryId = data.costCategoryId;
      createModel.log = data.log;
      createModel.description = data.description;
      createModel.scopes = [];
      data.scopes.forEach((scope: any) => {
        const newScope = new Simplex.Models.Project.CreateFinanceEntryScope();
        newScope.amountEx = scope.amountEx;
        newScope.scopeId = scope.scopeId;
        if (newScope.amountEx !== null && newScope.amountEx) {
          createModel.scopes.push(newScope);
        }
      });

      const createResult = await this.request.post<APIResult<void>>(
        `/api/project/${this._content.getContext().ProjectId}/finance`,
        createModel
      );
      if (createResult.isSuccess) {
        this.close();
        this.app
          .getEventBus()
          .publish(
            "newFinanceEntryCreated",
            Simplex.Utils.createEventArgs(null, this)
          );
      }
    };

    private loadEntry = async (id: string): Promise<void> => {
      const projectId = this._content.getContext().ProjectId;
      const result = await this.request.get<APIResult<any>>(
        `/api/project/${projectId}/finance/${id}`
      );
      if (result.isSuccess && result.data.data) {
        for (let k in result.data.data) {
          this._model[k] = result.data.data[k];
        }
        this._content.getContext().PoNumberError =
          result.data.data.type === "Actual" &&
          (result.data.data.poNumber === "" ||
            result.data.data.poNumber === null);
        this._content.getContext().SuggestedPoNumbers =
          result.data.data.suggestedPoNumbers;
        this._content.getContext().HasErrors =
          this._content.getContext().AmountError ||
          this._content.getContext().PoNumberError;
      }
    };

    public showEditFinanceEntry = async (
      projectId: string,
      financeEntryId: string
    ): Promise<void> => {
      this.createTargetContainer();
      if (!this.targetContainer) {
        return;
      }
      if (this._content.getContext().ProjectId !== projectId) {
        this._content.getContext().Scopes = [];
        this._content.getContext().CostCategories = [];
      }
      this._content.getContext().ProjectId = projectId;
      this._content.getContext().New = false;
      this._content.getContext().HasErrors = false;
      this._content.getContext().AmountError = false;
      this._content.getContext().PoNumberError = false;
      this._binder
        .bind(this._model, this._content)
        .on("PropertyChanged", this.modelPropertyChanged);
      this._binder.reset(this._model);

      await this.loadEntry(financeEntryId);
      this._content.getContext().HasNext = await this.hasNextItem();

      this._model.scopes.push(new Simplex.Models.Project.FinanceEntryScope());

      await Promise.all([
        this.loadCostCategories(),
        this.loadProject(),
        this.loadScopes(),
      ]);

      this._content.draw();

      this.bindEvents();
      this.targetContainer.style.display = "block";
    };

    public showNewFinanceEntry = async (
      source: any,
      event: EventArgs
    ): Promise<void> => {
      event.handled = true;
      this.createTargetContainer();
      if (!this.targetContainer) {
        return;
      }
      const projectId = event.elementData!.projectId;

      if (this._content.getContext().ProjectId !== projectId) {
        this._content.getContext().Scopes = [];
        this._content.getContext().CostCategories = [];
      }

      this._content.getContext().ProjectId = projectId;
      this._content.getContext().New = true;
      this._content.getContext().HasErrors = false;
      this._content.getContext().AmountError = false;
      this._content.getContext().PoNumberError = false;
      this._content.getContext().SuggestedPoNumbers = [];
      this._content.getContext().HasNext = true;
      this._binder
        .bind(this._model, this._content)
        .on("PropertyChanged", this.modelPropertyChanged);
      this._binder.reset(this._model);

      this._model.scopes = [];
      this._model.type =
        source.target?.cellType === "Expense" ? "Expense" : "Actual";
      this._model.bookingCode = "";
      this._model.log = "";
      this._model.amountEx = 0;
      this._model.costCategoryId = null;
      this._model.costCategory = null;
      this._model.costDescription = null;
      this._model.date = null;
      this._model.poNumber = null;
      this._model.reference = null;
      this._model.description = null;
      this._model.supplier = null;

      this._model.scopes.push(new Simplex.Models.Project.FinanceEntryScope());
      if (localStorage.getItem("presetNewActuals")) {
        const preset = JSON.parse(localStorage.getItem("presetNewActuals")!);
        this._model.costCategoryId = preset.costCategory;
        this._model.scopes[0].scopeId = preset.scope;
        localStorage.removeItem("presetNewActuals");
      }

      await Promise.all([
        this.loadCostCategories(),
        this.loadProject(),
        this.loadScopes(),
      ]);

      this._content.draw();

      this.bindEvents();
      this.targetContainer.style.display = "block";
    };

    private loadCostCategories = async (): Promise<void> => {
      if (
        this._content.getContext().CostCategories &&
        this._content.getContext().CostCategories.length > 0
      ) {
        return;
      }

      const projectId = this._content.getContext().ProjectId;
      const result = await this.request.get<APIResult<CostCategory[]>>(
        `/api/project/${projectId}/costCategories/all`
      );
      if (result.isSuccess && result.data.data) {
        this._content.getContext().CostCategories = result.data.data;
      }
    };

    private loadScopes = async (): Promise<void> => {
      const projectId = this._content.getContext().ProjectId;
      // if (
      //   this._content.getContext().Scopes &&
      //   this._content.getContext().Scopes.length > 0
      // ) {
      //   return;
      // }
      const scopesResult = await this.request.get<APIResult<any>>(
        `/api/project/${projectId}/scopes`
      );
      this._content.getContext().Scopes =
        scopesResult.isSuccess && scopesResult.data.data
          ? scopesResult.data.data.scopes
          : [];
    };

    private loadProject = async (): Promise<void> => {
      const projectId = this._content.getContext().ProjectId;
      if (this._content.getContext().projectCurrency) {
        return;
      }
      const projectResult = await this.request.get<
        APIResult<Simplex.Models.Project.Project>
      >(`/api/project/${projectId}`);
      if (projectResult.isSuccess && projectResult.data.data) {
        this._content.getContext().projectCurrency =
          projectResult.data.data.currency;
      }
    };
  }
}
