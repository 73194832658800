namespace Simplex.WebComponents {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import SnapshotSummary = Simplex.Models.Project.SnapshotSummary;

    @WebComponent("ui-planning-filter-tags")
    export class PlanningFilterTags extends ABWebComponent {
        private readonly contentTemplate;
        private _rendered: boolean = false;
        private _tags: Map<string, FilterTag>;
        private snapshots: SnapshotSummary[] = [];
        
        public constructor() {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/Budget/BudgetFilter', 'FilterTag') as TemplateCallback;
            this._tags = new Map<string, FilterTag>();
        }

        render() {
            this.innerHTML = '';
            this._rendered = true;
            
        }
        
        public setSnapshots = (snapshots: SnapshotSummary[]) => {
            this.snapshots = snapshots;
        }
        
        private createTag = (key: string, label: string) => {
            const tag = new FilterTag(key, label);
            tag.addEventListener('removeFilterOption', this.removeTag);
            this.appendChild(tag);
            this._tags.set(key,tag);
        }
        
        public addTag = (tag: string, value: string) => {
            switch(tag) {
                case 'compareWithSnapshotId':
                    if(value) {
                        // lookup snapshot name
                        const selectedSnapshot = this.snapshots.filter(s=>s.id === value);
                        if(selectedSnapshot.length === 0) {
                            break;
                        }
                        const label = Messages('project.planning.filter.compareWith', selectedSnapshot[0].name)
                        this.createTag(tag, label);
                    }
                    break;
                case 'periodType':
                    if(value !== Simplex.WebComponents.Project.Planning.Models.PlanningPeriodType.Month) {
                        const label = Messages(`project.planning.periods.${value}`);
                        this.createTag(tag, label);
                    }
                    break;
                case 'milestones':
                    if(value) {
                        // lookup scope name
                        const label = Messages('project.planning.filter.milestones')
                        this.createTag(tag, label);
                    }
                    break;
                case 'budgets':
                    if(!value) {
                        // lookup cost name
                        const label = Messages('project.planning.filter.budgets')
                        this.createTag(tag, label);
                    }
                    break;
                case 'relations':
                    if(!value) {
                        const label = Messages('project.planning.filter.relations')
                        this.createTag(tag, label);
                    }
                    break;
                case 'criticalpath':
                    if(value) {
                        const label = Messages('project.planning.filter.criticalpath')
                        this.createTag(tag, label);
                    }
                    break;
                case 'snapshots':
                    if(value) {
                        const label = Messages('project.planning.filter.snapshots')
                        this.createTag(tag, label);
                    }
                    break;
                case 'scopeLevel':
                    if(parseInt(value) > 1) {
                        const label = Messages('project.planning.filter.scopeLevel', value)
                        this.createTag(tag, label);
                    }
                    break;
                default:
                    return;
            }
        }
        
        public checkTag = (tag: string, value: string) => {
            const currentTag = this._tags.get(tag) as FilterTag;
            if(!currentTag) {
                this.addTag(tag, value);
                return;
            }
            switch(tag) {
                case 'milestones':
                    if(!value) {
                        this.removeTag(new CustomEvent('removeFilterOption', {detail: {key: tag}}))
                    }
                    break;
                case 'relations':
                    if(value) {
                        this.removeTag(new CustomEvent('removeFilterOption', {detail: {key: tag}}))
                    }
                    break;
                case 'compareWithSnapshotId':
                    if(value == "") {
                        this.removeTag(new CustomEvent('removeFilterOption', {detail: {key: tag}}))
                    } else {
                        const selectedSnapshot = this.snapshots.filter(s=>s.id === value);
                        if(selectedSnapshot.length === 0) {
                            break;
                        }
                        currentTag.updateLabel(Messages('project.planning.filter.compareWith', selectedSnapshot[0].name));
                    }
                    break;
                case 'periodType':
                    if(value === Simplex.WebComponents.Project.Planning.Models.PlanningPeriodType.Month) {
                        this.removeTag(new CustomEvent('removeFilterOption', {detail: {key: tag}}))
                    } else {
                        currentTag.updateLabel(Messages(`project.budget.periods.${value}`));
                    }
                    break;
                case 'snapshots':
                    if(!value) {
                        this.removeTag(new CustomEvent('removeFilterOption', {detail: {key: tag}}))
                    }
                    break;
                case 'budgets':
                    if(value) {
                        this.removeTag(new CustomEvent('removeFilterOption', {detail: {key: tag}}))
                    }
                    break;
                case 'criticalpath':
                    if(!value) {
                        this.removeTag(new CustomEvent('removeFilterOption', {detail: {key: tag}}))
                    }
                    break;
                case 'scopeLevel':
                    if(parseInt(value) <= 1) {
                        this.removeTag(new CustomEvent('removeFilterOption', {detail: {key: tag}}))
                    } else {
                        currentTag.updateLabel(Messages('project.planning.filter.scopeLevel', value));
                    }
                    break;
            }
        }
        
        public removeTag = (event: Event) => {
            const customEvent = event as CustomEvent;
            const tag = this._tags.get(customEvent.detail.key) as FilterTag;
            tag.onRemove();
            this.dispatchEvent(new CustomEvent('filterChanged', { detail:{ name: 'tagRemoved', value: customEvent.detail.key }, bubbles: true }));
            this._tags.delete(customEvent.detail.key);
        };

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if(!this._rendered) {
                this.render();
            }
        }

    }
}