namespace Simplex.WebComponents.LayoutComponents {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import InitializedComponentEvent = Simplex.Components.InitializedComponentEvent;
    import ListViewDataLoadedEvent = Simplex.WebComponents.LayoutComponents.ListViewDataLoadedEvent;

    @WebComponent("ui-pagination")
    export class Pagination extends ABWebComponent {
        private readonly contentTemplate;
        private readonly listIdentifier;
        private _navigationOnly: boolean;

        private listView: IListView | null = null;

        public constructor() {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/LayoutComponents/Pagination', 'Pagination') as TemplateCallback;
            this.listIdentifier = this.getAttribute("list");
            this._navigationOnly = this.getAttribute("navigationOnly") === "";
        }

        public setListView(listView: IListView): void {
            if (this.listView !== listView) {
                this.listView = listView;
                this.listView.setPagination(this);
                this.render();
            }
        }

        protected getIdentifier(): string | null {
            return this.listIdentifier;
        }

        private render(): void {
            if (this.listView) {
                const searchParameters = this.listView.getSearchParameters();
                this.innerHTML = this.contentTemplate({
                    navigationOnly: this._navigationOnly,
                    totalItemCount: searchParameters.totalItemCount,
                    itemCount: searchParameters.itemCount,
                    pageCount: searchParameters.pageCount,
                    pageNumber: searchParameters.pageNumber,
                    firstElementIndex: searchParameters.firstElementIndex,
                    lastElementIndex: searchParameters.lastElementIndex,
                    hasNext: searchParameters.pageNumber < searchParameters.pageCount,
                });
                this.bindElementEventListeners();
            }
        }

        private readonly onNavigatePrevious = async (event: Event): Promise<void> => {
            event.stopPropagation();
            event.preventDefault();
            if (this.listView) {
                await this.listView.setPageNumber(this.listView.getSearchParameters().pageNumber - 1);
            }
        }

        private readonly onNavigateNext = async (event: Event): Promise<void> => {
            event.stopPropagation();
            event.preventDefault();
            if (this.listView) {
                await this.listView.setPageNumber(this.listView.getSearchParameters().pageNumber + 1);
            }
        }

        private readonly onNavigateToPage = async (event: Event): Promise<void> => {
            event.stopPropagation();
            event.preventDefault();
            if (this.listView) {
                const element = event.target as HTMLElement;
                const page = element.dataset.page;
                if (page) {
                    const pageNumber = parseInt(page);
                    if (!isNaN(pageNumber)) {
                        await this.listView.setPageNumber(pageNumber);
                    }
                }
            }
        }

        private onListViewInitialized = (event: InitializedComponentEvent) => {
            if (event.identifier === this.listIdentifier) {
                
                this.setListView(event.component as IListView);
                this.render();
            }
        }

        private onListViewDataLoaded = (event: ListViewDataLoadedEvent) => {
            if (event.identifier === this.listIdentifier) {
                this.render();
            }
        }

        private readonly triggerInitializedEvent = (): void => {
            this.app.getEventBus().emit(`Pagination.Initialized`, {
                identifier: this.getIdentifier(),
                component: this
            } as InitializedComponentEvent);
        }
        
        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            this.bindApplicationEventListeners();
            this.triggerInitializedEvent();
        }

        disconnectedCallback() {
            this.removeApplicationEventListeners();
        }

        private bindElementEventListeners(): void {
            const navigatePreviousButtons = this.querySelectorAll('.jsNavigatePrevious');
            if (navigatePreviousButtons && navigatePreviousButtons.length > 0) {
                for (const navigatePreviousButton of navigatePreviousButtons) {
                    navigatePreviousButton.addEventListener('click', this.onNavigatePrevious);
                }
            }

            const navigateNextButtons = this.querySelectorAll('.jsNavigateNext');
            if (navigateNextButtons && navigateNextButtons.length > 0) {
                for (const navigateNextButton of navigateNextButtons) {
                    navigateNextButton.addEventListener('click', this.onNavigateNext);
                }
            }

            const navigateToPageButtons = this.querySelectorAll('.jsNavigateToPage');
            if (navigateToPageButtons && navigateToPageButtons.length > 0) {
                for (const navigateToPageButton of navigateToPageButtons) {
                    navigateToPageButton.addEventListener('click', this.onNavigateToPage);
                }
            }
        }

        private bindApplicationEventListeners(): void {
            this.app.getEventBus().addEventListener('ListView.Initialized', this.onListViewInitialized);
            this.app.getEventBus().addEventListener('ListView.DataLoaded', this.onListViewDataLoaded);
        }

        private removeApplicationEventListeners(): void {
            this.app.getEventBus().removeEventListener('ListView.Initialized', this.onListViewInitialized);
            this.app.getEventBus().removeEventListener('ListView.DataLoaded', this.onListViewDataLoaded);
        }
    }
}
