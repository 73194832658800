namespace Simplex.Decorators {

    export function Get(route?: string, manualLoadEvent?: boolean): Function {

        return function (object: Object, methodName: string, descriptor: any) {

            function convertToType(value: any, type?: Simplex.Components.ValueType): any {
                switch (type) {
                    case Simplex.Components.ValueType.Integer:
                        const parsedInt = parseInt(value);
                        if (isNaN(parsedInt)) {
                            throw new Error(`${value} is not a valid integer`);
                        }
                        return parsedInt;
                    case Simplex.Components.ValueType.Float:
                        const parsedFloat = parseInt(value);
                        if (isNaN(parsedFloat)) {
                            throw new Error(`${value} is not a valid float`);
                        }
                        return parsedFloat;
                    case Simplex.Components.ValueType.Boolean:
                        if (value !== "true" && value !== "false") {
                            throw new Error(`${value} is not a valid boolean`);
                        }
                        return value === "true";
                    case Simplex.Components.ValueType.Guid:
                        if (!Simplex.Utils.isGuid(value)) {
                            throw new Error(`${value} is not a valid id`);
                        }
                        return true;
                    case Simplex.Components.ValueType.Date:
                        const parsedDate = Date.parse(value);
                        if (isNaN(parsedDate)) {
                            throw new Error(`${value} is not a valid date`);
                        }
                        return new Date(parsedDate);
                    case Simplex.Components.ValueType.Array:
                        if (value === "") {
                            throw new Error(`${value} is not a valid array`);
                        }
                        return value.split(",");
                    case Simplex.Components.ValueType.String:
                    default:
                        return value;
                }
            }

            if (descriptor === undefined) {
                descriptor = Object.getOwnPropertyDescriptor(object, methodName);
            }
            const originalMethod = descriptor.value;
            descriptor.value = function () {
                const args = [];
                const methodParams: Simplex.Components.RouteParam[] = Simplex.Components.Router.getRouteParams(object.constructor.name, methodName);

                try {
                    for (const methodParam of methodParams) {

                        switch (methodParam.type) {
                            case Simplex.Components.ParamType.Path:

                                const pathValue = Simplex.Components.Router.getRouteParam(methodParam.name);
                                if (pathValue === undefined && !methodParam.required) {
                                    args[methodParam.index] = undefined;
                                    continue;
                                }
                                try {
                                    args[methodParam.index] = convertToType(Simplex.Components.Router.getRouteParam(methodParam.name), methodParam.valueType);
                                } catch (error) {
                                    throw new Error(`Routing(path): ${methodParam.name} is invalid, ${Simplex.Utils.createErrorMessage(error)}`);
                                }
                                break;
                            case Simplex.Components.ParamType.Query:
                                const queryValue = Simplex.Components.Router.getQueryParam(methodParam.name);
                                if (queryValue === undefined && !methodParam.required) {
                                    args[methodParam.index] = undefined;
                                    continue;
                                }
                                try {
                                    args[methodParam.index] = convertToType(queryValue, methodParam.valueType);
                                } catch (error) {
                                    throw new Error(`Routing(query): ${methodParam.name} is invalid, ${Simplex.Utils.createErrorMessage(error)}`);
                                }
                                break;
                            case Simplex.Components.ParamType.Component:
                                let component = Ambrero.AB.Application.Instance!.getSystemComponent(methodParam.name)
                                if (component === undefined || component === null) {
                                    component = Ambrero.AB.Application.Instance!.getComponent(methodParam.name)
                                }
                                if (component === undefined || component === null) {
                                    throw new Error(`Required component ${methodParam.name} for ${object.constructor.name}.${methodName} not found`);
                                }
                                args[methodParam.index] = component;
                                break;
                        }
                    }
                } catch (error) {
                    if (!Simplex.Components.Router.Loaded) {
                        if (Simplex.Utils.createErrorMessage(error).indexOf("Routing(path):") !== -1) {
                            document.location = "/error/notfound";
                        } else {
                            console.error(error);
                            document.location = "/error/badrequest";
                        }
                    } else {
                        console.error(error);
                        throw error;
                    }

                }

                return originalMethod.apply(this, args);
            }

            let controllerName = object.constructor.name;
            if (controllerName.indexOf('_') !== -1) {
                controllerName = controllerName.substring(0, controllerName.indexOf('_'));
            }

            Simplex.Components.Router.addRoute('action', object.constructor, methodName, controllerName, route!, manualLoadEvent);
        };
    }
}
