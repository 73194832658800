namespace Simplex.Components {
    import Application = Ambrero.AB.Application;
    import EventArgs = Ambrero.AB.Components.EventArgs;
    import ModelBinder = Ambrero.AB.Components.ModelBinder;
    import APIResult = Simplex.Utils.APIResult;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import Button = Simplex.WebComponents.FormElements.Button;
    
    export class NewCostCategoryPopup extends ABComponent {
        private readonly _popupTemplate;
        private readonly _popupContainer: PopupContainer;
        private readonly _newCostCategory: Simplex.Models.Project.CreateCostCategoryRequest;
        private readonly _binder: ModelBinder;
        private addButton: HTMLElement|null = null;
        private readonly _notice: any;
        private readonly request: FetchRequestHandler;
        private _addButton?: Button;
        private _projectId?: string;
        private popupContext: any;
        
        constructor(app: Application, direction: string) {
            super();
            if(!direction) {
                direction = 'rightof';
            }
            this._newCostCategory = new Simplex.Models.Project.CreateCostCategoryRequest();
            this._binder = app.getComponentType<ModelBinder>("ModelBinder")!;
            this.request = app.getComponentType<FetchRequestHandler>("FetchRequestHandler")!;
            this._popupTemplate = app.getTemplate('Components/NewCostCategoryPopup', 'NewCostCategoryPopup') as TemplateCallback;
            this.popupContext = {};
            this._popupContainer = app.getComponentType<PopupContainer>("PopupContainer",direction, this._popupTemplate, this.popupContext)!;
            this._binder.bind(this._newCostCategory, this._popupContainer);
            this._popupContainer.on("created", this.onNewUserPopupCreated);
            
        }

        private loadCostCategories = async():Promise<void> => {

            if (this.popupContext.CostCategories && this.popupContext.CostCategories.length > 0) {
                return;
            }

            const result = await this.request.get<APIResult<any[]>>(`/api/project/${this._projectId}/costCategories/all`);
            if (result.isSuccess && result.data.data) {
                this.popupContext.CostCategories = result.data.data;
            }
        }
        
        public show = async (element:HTMLElement, projectId: string): Promise<void> => {
            this._projectId = projectId;
            await this.loadCostCategories();
            this._popupContainer.showPopup(element);
        }

        onNewUserPopupCreated =  (eventArgs: EventArgs):void => {
            const element = eventArgs.data.element as HTMLElement;
            this._addButton = element.querySelector("ui-button") as Button;
            if (this._addButton) {
                this._addButton.addEventListener("click", this.onPopupAddClicked)
            }
        };

        onPopupAddClicked = async ():Promise<void> => {
            if (!this._binder.validate(this._newCostCategory)) {
                return;
            }
            const request = new Simplex.Models.Project.CreateCostCategoryRequest(this._newCostCategory);
            this._binder.reset(this._newCostCategory);

            if (this._addButton) {
                this._addButton.loading = true;
            }
            
            const createResult = await this.request.post<APIResult<{id: string}>>(`/api/project/${this._projectId}/costCategories`, request)

            if (this._addButton) {
                this._addButton.loading = false;
            }

            if (createResult.isSuccess) {
                this._popupContainer.hidePopup();
                this.emit("created", Simplex.Utils.createEventArgs({id: createResult.data.data!.id, name: request.name, code: request.code}, this));
            } else {
                if(createResult.data.messages.length > 0) {
                    this._notice.addMessage(Messages(createResult.data.messages[0].key), 'warning');
                }
            }
        };
        
        
    }
    
}
