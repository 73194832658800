namespace Simplex.WebComponents.Project {
  import WebComponent = Simplex.Decorators.WebComponent;
  import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
  import ABWebComponent = Simplex.Components.ABWebComponent;
  import ModelBinder = Ambrero.AB.Components.ModelBinder;
  import EventArgs = Ambrero.AB.Components.EventArgs;
  import APIResult = Simplex.Utils.APIResult;
  import CreateProjectResponse = Simplex.Models.Project.CreateProjectResponse;
  import PopupContainer = Simplex.Components.PopupContainer;
  import Button = Simplex.WebComponents.FormElements.Button;

  @WebComponent("ui-create-project-popup")
  export class CreateProjectPopup extends ABWebComponent {
    private _contentTemplate: TemplateCallback | undefined;
    private _popupTemplate: TemplateCallback | undefined;
    private _submitButton: Button | null = null;
    private _createModel:
      | Simplex.Models.Project.CreateProjectRequest
      | undefined;
    private _binder: ModelBinder | undefined;
    private _location: string | undefined;
    private _popupContainer: PopupContainer | undefined;

    public constructor() {
      super();
      this.init();
    }

    async init() {
      this._contentTemplate = this.app.getTemplate(
        "WebComponents/Project/CreateProjectPopup",
        "CreateProjectPopup"
      ) as TemplateCallback;

      this._popupTemplate = this.app.getTemplate(
        "WebComponents/Project/CreateProjectPopup",
        "Popup"
      ) as TemplateCallback;
      var templates: { id: string; name: string }[] = await (
        await fetch("/api/project/templates")
      ).json();
      const popupContext = {
        Templates: templates,
        showTemplates: templates.length > 0,
      };
      this._popupContainer = this.app.getComponentType<PopupContainer>(
        "PopupContainer",
        "below",
        this._popupTemplate,
        popupContext
      )!;

      this.addEventListener("click", this.onClick);

      this._createModel = new Simplex.Models.Project.CreateProjectRequest();
      this._binder = this.app.getComponentType<ModelBinder>("ModelBinder")!;
      this._binder.bind(this._createModel, this._popupContainer);

      this._location = this.getAttribute("location") || "below";
      this._popupContainer.on("created", this.onPopupContainerCreated);
      this._popupContainer.on("closed", this.onPopupContainerClosed);
    }

    private onPopupContainerCreated = (event: EventArgs): void => {
      this._submitButton = event.data.element.querySelector("ui-button");
      if (this._submitButton) {
        this._submitButton.addEventListener("click", this.onSubmit);
      }
    };

    private onPopupContainerClosed = (event: EventArgs): void => {
      if (this._createModel) this._binder?.reset(this._createModel);
    };

    static get observedAttributes() {
      return ["location"];
    }

    attributeChangedCallback(
      name: string,
      oldValue: string,
      newValue: string
    ): void {
      switch (name) {
        case "location":
          this._location = newValue;
          //@ts-ignore
          this._popupContainer?.setLocation(newValue);
          break;
      }
    }

    onClick = (event: MouseEvent): void => {
      event.preventDefault();
      event.stopPropagation();
      this._popupContainer?.showPopup(this);
    };

    onSubmit = async (event: MouseEvent): Promise<void> => {
      event.preventDefault();
      event.stopPropagation();
      this._submitButton!.loading = true;
      if (this._createModel && !this._binder?.validate(this._createModel)) {
        return;
      }
      const notice = this.app.getComponent("AmbreroComponents.Notice");

      const createResponse = await this.request.post<
        APIResult<CreateProjectResponse>
      >("/api/project", this._createModel);
      if (!createResponse.isSuccess || createResponse.data.data === undefined) {
        notice.addMessage("Fout bij het aanmaken van het project", "warning");
        return;
      }
      this._popupContainer?.hidePopup();
      notice.addMessage("Project aangemaakt", "success");

      const projectId = createResponse.data.data.id;
      this.app.navigateTo(`/project/${projectId}/edit`);
    };

    render() {
      if (this._contentTemplate) this.innerHTML = this._contentTemplate({});
    }

    connectedCallback() {
      if (!this.isConnected) {
        return;
      }
      this.render();
    }
  }
}
