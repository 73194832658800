namespace Simplex.WebComponents.LayoutComponents {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;


    @WebComponent("ui-context-menu")
    export class ContextMenu extends ABWebComponent {

        private readonly contentTemplate: TemplateCallback;
        private readonly optionTemplate: TemplateCallback;
        private readonly _options: HTMLElement[];
        private readonly _class: string| null = null;
        private _rendered: boolean = false;
        
        public constructor() {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/LayoutComponents/ContextMenu', 'ContextMenu') as TemplateCallback;
            this.optionTemplate = this.app.getTemplate('WebComponents/LayoutComponents/ContextMenu', 'ContextMenuOption') as TemplateCallback;
            this._options = [...this.querySelectorAll('ui-context-menu-option').values()] as HTMLElement[];
            this._class = this.getAttribute('class');
        }

        render() {
            this.innerHTML = this.contentTemplate({class: this._class});
            const targetList = this.querySelector('ul');
            if (targetList) {
                const parser = new DOMParser();
                
                this._options.forEach(option => {
                    const element = parser.parseFromString( this.optionTemplate({ name: option.innerHTML, disabled: option.getAttribute("disabled") === ""}), "text/html").body.firstElementChild as HTMLElement;
                    if (element) {
                        targetList.appendChild(element);
                        element.querySelector("a")!.addEventListener("click", this.onOptionClicked.bind(this, option.getAttribute("action")));
                    }
                });
                this.addEventListener("click", this.ignoreClicked);
            }
            this._rendered = true;
        }

        ignoreClicked = (event:MouseEvent):void => {
            event.preventDefault();
            event.stopPropagation();
        }
        onOptionClicked = (action: string|null, event:MouseEvent):void => {
            event.preventDefault();
            event.stopPropagation();
            if (action) {
                this.dispatchEvent(new CustomEvent("action", {detail: action, bubbles: true}))
            }
        }

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if (!this._rendered) {
                this.render();
            }
        }
    }
}
