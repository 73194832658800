namespace Simplex.WebComponents.Project.DashboardProjectCurrentBaseline {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import APIResult = Simplex.Utils.APIResult;
    import SnapshotData = Simplex.WebComponents.Project.DashboardProjectSnapshot.Models.SnapshotData;

    @WebComponent("ui-dashboard-project-current-baseline")
    export class DashboardProjectCurrentBaseline extends ABWebComponent {
        private readonly contentTemplate;
        private readonly _name: string;
        private readonly _date: string;

        public constructor() {
            super();
            this._name = this.getAttribute("name") || "";
            this._date = this.getAttribute("date") || "";
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/Dashboard/ProjectDashboardCurrentBaseLine', 'ProjectDashboardCurrentBaseLine') as TemplateCallback;
        }



        public async render() {
            if (this._name) {
                this.innerHTML = this.contentTemplate({name: this._name, date: this._date});
            }
        }

        public async connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            await this.render();
        }
    }
}
