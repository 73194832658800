namespace Simplex.WebComponents.FormElements {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;

    @WebComponent("ui-button-switch")
    export class ButtonSwitch extends ABWebComponent {

        private readonly _contentTemplate;
        private readonly _optionTemplate;
        private readonly _options: HTMLElement[];
        private _input: HTMLInputElement|null = null;
        private readonly _optionElements: HTMLElement[] = [];

        public constructor() {
            super();
            this._contentTemplate = this.app.getTemplate('WebComponents/FormElements/ButtonSwitch', 'ButtonSwitch') as TemplateCallback;
            this._optionTemplate = this.app.getTemplate('WebComponents/FormElements/ButtonSwitch', 'ButtonSwitchOption') as TemplateCallback;
            this._options = [...this.querySelectorAll('ui-button-switch-option').values()] as HTMLElement[];
        }

        render() {
            const currentValue = this.getAttribute('value');
            this.innerHTML = this._contentTemplate({
                name: this.getAttribute('name') || '',
                value: currentValue
            });
            this._input = this.querySelector(`input[name='${this.getAttribute('name') || ''}']`);
            this._input?.addEventListener("change", this.onInputChanged);
            const targetList = this.querySelector('.buttonswitch');
            if (targetList) {
                const parser = new DOMParser();
                this._options.forEach(option => {
                    const element = parser.parseFromString( this._optionTemplate({
                        title: option.innerHTML, 
                        checked: option.getAttribute('value') === currentValue
                    }), "text/html").body.firstElementChild as HTMLElement;
                    element.addEventListener("click", this.onSelectItem.bind(this, option.getAttribute('value')));
                    element.dataset.value = option.getAttribute('value') || '';
                    if (element) {
                        targetList.appendChild(element);
                    }
                    this._optionElements.push(element);
                });
            }
        }

        private _selfChanged: boolean = false;
        onInputChanged = (event:Event): void => {
            if (this._selfChanged) {
                return;
            }
            this.querySelectorAll(".switchoption").forEach(item => {
                item.classList.remove("checked");
                //@ts-ignore
                if (item.dataset.value === this._input?.value) {
                    item.classList.add("checked");
                }
            });
            
            
        }
        
        onSelectItem = (value: any, event: Event): void => {
            this._optionElements.forEach(e =>
                e.classList.remove('checked')
            );
            let currentElement = event.target as HTMLElement; 
            if(currentElement instanceof HTMLSpanElement) {
                currentElement = currentElement.parentElement as HTMLElement;
            }
            if(currentElement) {
                currentElement.classList.add('checked');
            }
            if (this._input) {
                this._input.value = value;
                this._selfChanged = true;
                this._input.dispatchEvent(new Event('change'));
                this._selfChanged = false;
            }
        }

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            this.render();
        }
    }
}
