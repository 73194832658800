/// <reference path="../Declarations/Handlebars.d.ts" />

namespace Simplex.HandlebarHelper {

	function resolveArgumentAsString(argument: number|string): string {
		if(typeof argument !== "string") {
			return argument.toString();
		}
		return argument;
	}

	export const concat: Handlebars.HelperDelegate = function(...args: any[]): string {
		const options = args.pop();
		return args.map(arg => resolveArgumentAsString(arg)).join("");
	}
}

Handlebars.registerHelper('concat', Simplex.HandlebarHelper.concat);
