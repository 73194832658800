namespace Simplex.WebComponents.Project.DashboardProjectBudget {
    import WebComponent = Simplex.Decorators.WebComponent;
    import ModelBinder = Ambrero.AB.Components.ModelBinder;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import APIResult = Simplex.Utils.APIResult;
    import ContentNotifier = Simplex.Components.ContentNotifier;
    import Inject = Simplex.Decorators.Inject;
    import InputCurrency = Simplex.WebComponents.FormElements.InputCurrency;
    import Button = Simplex.WebComponents.FormElements.Button;
    import BudgetData = Simplex.WebComponents.Project.DashboardProjectBudget.Models.BudgetData;

    @WebComponent("ui-dashboard-project-budget-widget")
    export class DashboardProjectBudgetWidget extends ABWebComponent {
        private readonly contentTemplate;
        private readonly _projectId: string;
        private readonly _binder: ModelBinder;
        @Inject("ContentNotifier")
        private readonly _contentNotifier!: ContentNotifier;
        private _inputCurrency: InputCurrency|null = null;
        private readonly _changeProjectBudgetRequest: Simplex.WebComponents.Project.DashboardProjectBudget.Models.ChangeProjectBudgetRequest|null = null;
        private _saveButton?: Button;
        public constructor() {
            super();
            this._projectId = this.getAttribute("project-id") || "";
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/Dashboard/ProjectDashboardBudget', 'ProjectDashboardBudget') as TemplateCallback;
            this._binder = this.app.getComponentType<ModelBinder>("ModelBinder")!;
            this._changeProjectBudgetRequest = new Simplex.WebComponents.Project.DashboardProjectBudget.Models.ChangeProjectBudgetRequest();
            this._binder.bind(this._changeProjectBudgetRequest, this._contentNotifier);
        }

        private getBudgetData = async (): Promise<BudgetData | undefined> => {

            const result = await this.request.get<APIResult<BudgetData>>(`/api/project/${this._projectId}/budget`);
            if (!result.isSuccess) {
                return undefined;
            }
            return result.data.data;
        }

        public async render() {
            const budgetData = await this.getBudgetData();
            if(!budgetData) {
                return;
            }
            this.classList.remove('is--editable');
            this.innerHTML = this.contentTemplate(budgetData);
            if (!budgetData.budget) {
                this.classList.add('is--editable');
                const editForm = this.querySelector("div.edit") as HTMLElement;
                if (!editForm) {
                    return;
                }
                this._inputCurrency = editForm.querySelector("ui-input-currency") as InputCurrency;
                if(this._inputCurrency) {
                    this._inputCurrency.addEventListener("keydown", this.onKeyDown);
                }
                this._saveButton = editForm.querySelector("ui-button") as Button;
                if (this._saveButton) {
                    this._saveButton.addEventListener("click", this.onBudgetSubmit);
                }
            }
            this._contentNotifier.notifyDraw(this);
        }

        onKeyDown = async (event:KeyboardEvent):Promise<void> => {
            if(event.code === 'Enter' || event.code === 'NumpadEnter') {
                this._inputCurrency?.blur();
                this._inputCurrency?.focus();

                await this.onBudgetSubmit();
            }
        }

        private onBudgetSubmit = async (event: MouseEvent|null = null): Promise<void> => {
            if(event) {
                event.preventDefault();
                event.stopPropagation();
            }
            if (!this._changeProjectBudgetRequest || this._binder === null || !this._binder.validate(this._changeProjectBudgetRequest)) {
                return;
            }
            if (this._saveButton) {
                this._saveButton.loading = true;
            }
            const updateBudgetResult = await this.request.put<APIResult<void>>(`/api/project/${this._projectId}/budget`, this._changeProjectBudgetRequest);
            if (this._saveButton) {
                this._saveButton.loading = false;
            }
            if (updateBudgetResult.isSuccess) {
                const budgetData = await this.getBudgetData();
                if(!budgetData) {
                    return;
                }
                this.innerHTML = this.contentTemplate(budgetData);
            }
        }

        private isClickInInputElement = (node: HTMLElement): boolean => {
            while (node !== document.body && node.parentElement !== null) {
                if (node.classList.contains('input__element')) {
                    return true;
                }
                node = node.parentElement;
            }
            return false;
        }
        
        private onClickedWidget(event:Event): void {
            if(this.isClickInInputElement(event.target as HTMLElement)){
                return;
            }
            this.app.navigateTo(`/project/${this._projectId}/edit`);
        }
        
        private bindElementEventListeners(): void {
            this.addEventListener('click', this.onClickedWidget)
        }
        
        private removeElementEventListeners(): void {
            this.removeEventListener('click', this.onClickedWidget)
        }
        
        public async connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            await this.render();
            this.bindElementEventListeners();
        }

        disconnectedCallback() {
            this.removeElementEventListeners();
        }
    }
}
