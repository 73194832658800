namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class CreateCostCategoryRequest extends ABModel {

        public parentCostCategoryId: string | null = Simplex.Utils.GUID_EMPTY;
        public name: string = '';
        public index: number = -1;
        public active: boolean = true;
        public code: string | null = null;
        
        constructor(scope?: Simplex.Models.Project.CostCategory|CreateCostCategoryRequest) {
            super();
            if (scope) {
                this.parentCostCategoryId = scope.parentCostCategoryId;
                this.name = scope.name;
                this.code = scope.code;
                this.index = scope.index;
                this.active = scope.active;
            }
        }
    }
}