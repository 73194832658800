namespace Simplex.Components {
    import WebComponent = Simplex.Decorators.WebComponent;
    
    import APIResult = Simplex.Utils.APIResult;
    import ImportFinanceEntries = Simplex.Models.Project.ImportFinanceEntries;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import ModelBinder = Ambrero.AB.Components.ModelBinder;
    import Content = Simplex.Components.Content;
    import EventArgs = Ambrero.AB.Components.EventArgs;
    import FileUploadButton = Simplex.WebComponents.FileUploadButton;
    @WebComponent("ui-project-finance-import")
    
    export class ImportEntriesModal extends ABWebComponent {
        private _dialog?: HTMLDialogElement;
        private readonly _model: ImportFinanceEntries;
        private readonly _binder: ModelBinder;
        private _rendered: boolean = false;

        private readonly _content: Simplex.Components.Content<any>;
        private readonly _projectId: string;
        
        public constructor() {
            super();
            this._binder = this.app.getComponentType<ModelBinder>("ModelBinder")!;
            this._model = new Simplex.Models.Project.ImportFinanceEntries();
            this._content = this.app.getComponent('Content', 'WebComponents/Project/Finance/ImportEntriesModal/ImportEntriesModal') as Content<any>;
            this._content.setTargetContainerElement(this);
            this._content.setContext({
                model: this._model,
                validated: false,
                validateResult: {
                    total: 0, 
                    import: 0,
                    existing: [],
                    errors: []
                }
            });
            this._binder.bind(this._model, this._content).on("PropertyChanged", this.modelPropertyChanged);
            this._projectId = this.getAttribute("project-id") ?? "";
            this._content.on(['draw','drawSection'], this.onContentDrawn);
            
        }
        
        private modelPropertyChanged = (event:EventArgs) => { 
            switch(event.data.field) {
                case "type":
                    
                    if (this._model.type === "Actual") {
                        
                    }
                    this._content.getContext().validated = false;
                    this._content.refreshSections("div.JSContent,div.JSResults, .JSButtons");
                    
                    break;
            }
        }
        
        private onContentDrawn = ():void => {
            this._dialog = this.querySelector('dialog') as HTMLDialogElement;
            this.querySelectorAll("ui-button[action='import']").forEach(item => {
                item.removeEventListener("click", this.show);
                item.addEventListener("click", this.show);
            });
            this._dialog.querySelectorAll("ui-fileupload-button").forEach(item => {
                item.removeEventListener("stateChanged", this.fileUploadStateChanged);
                item.addEventListener("stateChanged", this.fileUploadStateChanged);
            });
            this._dialog.querySelectorAll(".close").forEach(item => {
                item.removeEventListener("click", this.hide)
                item.addEventListener("click", this.hide);
            });
            this._dialog.querySelectorAll(".JSButtons ui-button").forEach(item => {
                item.removeEventListener("click", this.buttonClick.bind(this, item as HTMLElement));
                item.addEventListener("click", this.buttonClick.bind(this, item as HTMLElement));
            });
        }
        
        async render() {
            this._content.draw();
            
        }
        
        private fileUploadStateChanged = async (event: Event): Promise<void> => { 
            const fileUpload = event.target as FileUploadButton;
            if (fileUpload.isSuccess()) { 
                this._content.getContext().model.fileId = fileUpload.currentFileId;
                await this.validate();
            }
        };
        
        private validate = async (): Promise<void> => { 
            const result = await this.request.post<APIResult<{failedRecords: string[], existingRecords: string[], newRecords: number}>>(`/api/project/${this._projectId}/finance/validate/${this._model.fileId}/${this._model.type}`);
            
            if (result.isSuccess && result.data.data) {
                this._content.getContext().validateResult.errors = result.data.data.failedRecords;
                this._content.getContext().validateResult.existing = result.data.data.existingRecords;
                this._content.getContext().validateResult.import = result.data.data.newRecords;
                this._content.getContext().validateResult.total = this._content.getContext().validateResult.errors.length +  this._content.getContext().validateResult.existing.length + this._content.getContext().validateResult.import;
                this._content.getContext().validated = true;
                this._content.refreshSections(".JSResults, .JSButtons");
                
            }
        }
        
        private show = async (): Promise<void> => {
            if (!this._dialog) {
                return;
            }
            this._content.getContext().validated = false;
            this._content.getContext().model.fileId = null;
            this._content.getContext().model.type = "Actual";
            this._content.draw();
            this._dialog.showModal();
        }
        
        private hide = () => {
            if(this._dialog) {
                this._dialog.close();
            }
        }

        private import = async (): Promise<void> => {
            const result = await this.request.post<APIResult<any>>(`/api/project/${this._projectId}/finance/import/${this._model.fileId}/${this._model.type}`);
            if (result.isSuccess) {
                this.dispatchEvent(new Event("imported"));
                this.hide();
            }
        }
        
        private buttonClick = async (button: HTMLElement): Promise<void> => {
            const action = button.dataset.action;
            switch(action) {
                case "add":
                    await this.import();
                    break;
                case "retry":
                    this._content.getContext().validated = false;
                    this._content.refreshSections(".JSResults, .JSButtons");
                    break;
                case "close":
                    this.hide();
                    break;
            }

        }
        
        async connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if(!this._rendered) {
                await this.render();
            }
        }
    }
}