namespace Simplex.WebComponents.FormElements {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import Emitter = Ambrero.AB.Components.Emitter;


    @WebComponent("ui-input-checkbox")
    export class InputCheckbox extends ABWebComponent {

        private readonly contentTemplate;
        private input: HTMLInputElement|null = null;
        private readonly _emitter: Emitter;

        public constructor() {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/FormElements/InputCheckbox', 'InputCheckbox') as TemplateCallback;
            this._emitter = new Emitter();
        }

        render() {
            this.innerHTML = this.contentTemplate({
                name: this.getAttribute("name"),
                checked: this.getAttribute("checked") === "",
                checkboxValue: this.getAttribute("checkboxValue"),
                label: this.getAttribute("label"),
                class: this.getAttribute("class"),
                tabindex: this.getAttribute("tabindex"),
                disabled: this.getAttribute("disabled") === "",
                required: this.getAttribute("required") === ""
            });
            this.input = this.querySelector("input");
            if(this.input) 
            {
                this.input.addEventListener('change', this.toggle);
            }
        }

        private toggle = (): void => {
            this.dispatchEvent(new CustomEvent("toggle", {detail: this.input?.checked}))
        }
        
        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            this.render();
        }
    }
}
