namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class FinanceEntryScope extends ABModel {
        public scopeId: string|null = null;
        public scopeName: string|null = null;
        public parentScopeName: string|null = null;
        public amountEx: number|null = null;

        public convertField = (fieldName: string, direction: "up" | "down", value: any): any => {
            switch (fieldName) {
                case "amountEx":
                    if (direction === "down") {
                        value = value === "" || value === null ? null : parseFloat(value);
                    }
                    break;
            }
            return value;
        }
        
    }
}