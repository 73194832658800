namespace Simplex.WebComponents {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import ScopeRow = Simplex.WebComponents.Project.ScopeRow;
    import DateGrid = Simplex.WebComponents.DateGrid;
    import Scope = Simplex.Models.Project.Scope;
    import ScopeRelation = Simplex.WebComponents.Project.ScopeRelation;

    interface IntervalItem {
        date: string,
        year: number,
        isNewYear: boolean
    }
    
    interface ScopeReference {
        id: string,
        index: number,
        direction: string,
        type: string,
        startScopeId: string,
        endScopeId: string,
        distance: number,
        dateDiff: number,
        multiStartCount: number,
        critical: boolean
    }

    @WebComponent("ui-calender-grid")
    export class CalenderGrid extends ABWebComponent {
        private readonly _scopes: Simplex.Models.Project.Scope[];
        private _rendered: boolean = false;
        private readonly projectId?: string;
        private readonly _projectStartdate: string;
        private readonly _projectEnddate: string;
        private readonly popupTemplate;
        private _totalDaySpan: number;
        private _intervalList: IntervalItem[];
        private _mousePosition: any;
        private _ganttElement?: HTMLElement;
        private readonly _scopeRows: Map<string, ScopeRow>;
        private _scopeReferences: Map<string, ScopeReference>;
        private _startDate: any;
        private _endDate: any;
        private _dateGrid?: DateGrid;
        private readonly contentTemplate;
        private readonly _interval: string;
        private _maxScopeDepth: number;

            static get observedAttributes() { return ['startdate', 'enddate']; }

        public constructor(projectId:string, scopes: Simplex.Models.Project.Scope[], projectStartDate: string, projectEndDate: string, interval: string, maxDepth: number = 1) {
            super();
            this.projectId = projectId;
            this._scopes = scopes;
            this._projectStartdate = projectStartDate;
            this._projectEnddate = projectEndDate;
            this._totalDaySpan = 0;
            this._intervalList = [];
            this._maxScopeDepth = maxDepth;
            this._interval = interval;
            this._mousePosition = { top: 0, left: 0, x: 0, y: 0 };
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/CalenderGrid', 'CalenderGrid') as TemplateCallback;
            this.popupTemplate = this.app.getTemplate('WebComponents/Project/ScopeRelation', 'ScopeRelationPopup') as TemplateCallback;
            this._scopeRows = new Map<string, Simplex.WebComponents.Project.ScopeRow>();
            this._scopeReferences = new Map<string, ScopeReference>();
            this.determineProjectDateRange();
        }
        
        public getScopeRow = (id: string): ScopeRow | undefined => {
            if(this._scopeRows.has(id)) {
                return this._scopeRows.get(id)!;
            }
        }
        
        private determineProjectDateRange () {
            let start = moment();
            if(this._projectStartdate !== "" && moment(this._projectStartdate).isBefore(start)) {
                start = moment(this._projectStartdate);
            }

            let end = moment();
            if(this._projectEnddate !== "" && moment(this._projectEnddate).isAfter(end)) {
                end = moment(this._projectEnddate);
            }

            this._scopes.forEach(scope => {
                if(moment(scope.startDate).isBefore(start)) {
                    start = moment(scope.startDate);
                }
                if(moment(scope.endDate).isAfter(end)) {
                    end = moment(scope.endDate);
                }
            });

            this._startDate = start;
            this._endDate = end;
            this.createIntervalList();
            
        };
        
        private isInvalidTarget = (target: HTMLElement): boolean => {
            const classNames = ['bar', 'bar__edge', 'relation-direction', 'mutate-relation'];
            return [...target.classList].some(className => classNames.indexOf(className) !== -1);
        }
        
        public startWithDragOffset = () => {
            const scrollLeft = localStorage.getItem('horizontalScrollOffset');
            if(scrollLeft) {
                this.scrollLeft = parseInt(scrollLeft);
            }
        }
        
        public dragStartHandler (evt: PointerEvent): void {
            const target = evt.target as HTMLElement;
            if(this.isInvalidTarget(target)) {
                return;
            }
            this.setPointerCapture(evt.pointerId);
            this.style.cursor = 'grabbing';
            this.style.userSelect = 'none';
            this._mousePosition = {
                left: this.scrollLeft,
                x: evt.clientX,
            };
            this.addEventListener('pointermove', this.pointerMoveHandler);
            this.addEventListener('pointerup', this.pointerUpHandler, {once: true});
        };
        
        public pointerMoveHandler (evt: PointerEvent): void {
            const dx = evt.clientX - this._mousePosition.x;
            this.scrollLeft = this._mousePosition.left - dx;
            this._dateGrid?.dragAlong(this.scrollLeft);
            this.updateStickyYears();
            localStorage.setItem('horizontalScrollOffset', `${this.scrollLeft}`);
        };
        
        public pointerUpHandler (evt: PointerEvent): void {
            this.removeEventListener('pointermove', this.pointerMoveHandler);
            this.style.cursor = 'grab';
            this.style.removeProperty('user-select');
        };

        public calculateTickWidth = (days: number = 1): number => {
            const firstDate = this.querySelector('.date.first') as HTMLElement;
            const intervalWidth = firstDate.getBoundingClientRect().width;
            switch (this._interval) {
                case 'Day':
                    if(days  === 0) {
                        return intervalWidth;
                    }
                    return intervalWidth * days;
                case 'Week':
                    if(days  === 0) {
                        return intervalWidth / 7;
                    }
                    return intervalWidth / 7 * days;
                default:
                case 'Month':
                    if(days  === 0) {
                        return intervalWidth / 30 ;
                    }
                    return intervalWidth / 30 * days;
                case 'Quarter':
                    if(days  === 0) {
                        return intervalWidth / 13;    
                    }
                    return intervalWidth / 13 * (days / 7);
                case 'Year':
                    if(days  === 0) {
                        return intervalWidth / 52;
                    }
                    return intervalWidth / 52 * (days / 7);
            }

        }
        
        private updateStickyYears (): void {
            const years = this.querySelectorAll('.year');
            const currentyear = document.querySelector('.currentyear') as HTMLElement;
            if(!currentyear) {
                return;
            }
            const offsetLeft = 540;
            years.forEach(year => {
                if(year.getBoundingClientRect().left < offsetLeft) {
                    currentyear.innerHTML = year.innerHTML;
                }
            });
        };
        
        private createIntervalList = () => {
            switch(this._interval) {
                case 'Day':
                    this._intervalList = this.createDayList();
                    break;
                case 'Week':
                    this._intervalList = this.createWeekList();
                    break;
                case 'Month':
                    this._intervalList = this.createMonthList();
                    break;
                case 'Quarter':
                    this._intervalList = this.createQuarterList();
                    break;
                case 'Year':
                    this._intervalList = this.createYearList();
                    break;
                        
            }
            
        }

        private createDayList (): IntervalItem[] {
            let start = this._startDate.clone();
            let end = this._endDate.clone().add(1, 'day');

            const dayList = [];
            let isNewYear = true;
            while (start.isSameOrBefore(end)) {
                dayList.push({
                    date: start.format("D MMM"),
                    year: start.format("YYYY"),
                    isNewYear: isNewYear
                });
                start = start.add(1, 'day');
                isNewYear = start.dayOfYear() === 1;
            }
            while( dayList.length < 6) {
                end = end.add(1, 'day');
                isNewYear = end.dayOfYear() === 1;
                dayList.push({
                    date: start.format("D MMM"),
                    year: end.format("YYYY"),
                    isNewYear: isNewYear
                });
            }
            this._totalDaySpan = end.diff(this._startDate,'days');
            return dayList;
        }

        private createWeekList (): IntervalItem[] {
            let start = this._startDate.startOf('week').clone();
            let end = this._endDate.clone().add(1, 'week').startOf('week');

            const weekList = [];
            let isNewYear = true;
            while (start.isSameOrBefore(end)) {
                weekList.push({
                    date: 'week ' + start.format("w"),
                    year: start.format("YYYY"),
                    isNewYear: isNewYear
                });
                start = start.add(1, 'week');
                isNewYear = start.week() === 1;
            }
            while( weekList.length < 6) {
                end = end.add(1, 'week');
                isNewYear = end.week() === 1;
                weekList.push({
                    date: 'week ' + start.format("w"),
                    year: end.format("YYYY"),
                    isNewYear: isNewYear
                });
            }
            this._totalDaySpan = end.diff(this._startDate,'days');
            return weekList;
        }
        
        private createMonthList (): IntervalItem[] {
            let start = this._startDate.startOf('month').clone();
            let end = this._endDate.clone().add(1, 'month').startOf('month');
            
            const monthList = [];
            let isNewYear = true;
            while (start.isSameOrBefore(end)) {
                monthList.push({
                    date: start.format("1 MMM"),
                    year: start.format("YYYY"),
                    isNewYear: isNewYear
                });
                start = start.add(1, 'month');
                isNewYear = start.month() === 0;
            }
            while( monthList.length < 6) {
                end = end.add(1, 'month');
                isNewYear = end.month() === 0;
                monthList.push({
                    date: end.format("1 MMM"),
                    year: end.format("YYYY"),
                    isNewYear: isNewYear
                });
            }
            this._totalDaySpan = end.diff(this._startDate,'days');
            return monthList;
        }

        private createQuarterList (): IntervalItem[] {
            let start = this._startDate.startOf('quarter').clone();
            let end = this._endDate.clone().add(1, 'quarter').startOf('quarter');

            const quarterList = [];
            let isNewYear = true;
            while (start.isSameOrBefore(end)) {
                quarterList.push({
                    date: start.format("1 MMM ([Q]Q)"),
                    year: start.format("YYYY"),
                    isNewYear: isNewYear
                });
                start = start.add(1, 'quarter');
                isNewYear = start.quarter() === 1;
            }
            while( quarterList.length < 6) {
                end = end.add(1, 'quarter');
                isNewYear = end.quarter() === 1;
                quarterList.push({
                    date: end.format("1 MMM ([Q]Q)"),
                    year: end.format("YYYY"),
                    isNewYear: isNewYear
                });
            }
            this._totalDaySpan = end.diff(this._startDate,'days');
            return quarterList;
        }
        private createYearList (): IntervalItem[] {
            let start = this._startDate.startOf('year').clone();
            let end = this._endDate.clone().add(1, 'year').startOf('year');

            const yearList = [];
            let isNewYear = true;
            while (start.isSameOrBefore(end)) {
                yearList.push({
                    date: start.format("YYYY"),
                    year: start.format("YYYY"),
                    isNewYear: isNewYear
                });
                start = start.add(1, 'year');
            }
            while( yearList.length < 6) {
                end = end.add(1, 'year');
                yearList.push({
                    date: end.format("YYYY"),
                    year: end.format("YYYY"),
                    isNewYear: isNewYear
                });
            }
            this._totalDaySpan = end.diff(this._startDate,'days');
            return yearList;
        }
        
        private calculateDomElements () {
            const dateDifference = moment().diff(this._startDate, 'days');
            const ganttElement = this.querySelector('.ganttchart') as HTMLElement;
            const dateBackground = this.querySelector('ui-date-grid .background') as HTMLElement;
            const firstDate = this.querySelector('.date.first') as HTMLElement;
            const monthWidth = firstDate.getBoundingClientRect().width;
            let offset = (monthWidth / 2);
            const today = this.querySelector('.today') as HTMLElement; // width after i18n = 56px
            const todayOffset = (this.scrollWidth - monthWidth) * (dateDifference / this._totalDaySpan) + offset - 28; // (offset left)
            
            today.style.left = `${todayOffset}px`;
            ganttElement.style.width = `${this.scrollWidth}px`; // (width for overflow)
            dateBackground.style.width = `${this.scrollWidth}px`;
        };

        public toggleAll = (open: boolean, ids: string[] | null = null) => {
            this._scopes.forEach(scope => {
                if (ids !== null && scope.id !== null && !ids.includes(scope.id)) {
                    return;
                }
                this.setScopeVisibility(scope.id!, open);
            });
            this.createScopeReferences();
        }
        
        render() {
            this.innerHTML = this.contentTemplate({});
            this._dateGrid = new Simplex.WebComponents.DateGrid(this._intervalList as [], this._interval === 'years');
            this.prepend(this._dateGrid);
            this._ganttElement = this.querySelector('.ganttchart') as HTMLElement;
            this.calculateDomElements();

            if(this._ganttElement) {
                this.addScopeRows(this._scopes);
                const root = document.documentElement;
                root.style.setProperty('--tick-width',`${this.calculateTickWidth(1)}px`);
                this.createScopeReferences();
            }
            this._rendered = true;
            this.addEventListener('pointerdown', this.dragStartHandler);
            this.updateStickyYears();
            this.calculateGridHeight();
        }
        
        private setScopeReferences = (scopeRow: ScopeRow, index: number): number => {
            const scope = scopeRow.scope;

            if((scope.depth > 0 && !scopeRow.visibility)) {
                return index;
            }
            
            scope.references?.forEach(reference => {
                let distance = -1;
                let direction = 'down';
                let key = `${reference.startScopeId}${reference.referenceType}${reference.endScopeId}`;
                let referenceLookup = this._scopeReferences.get(key);
                let dateDiff = 1;
                if(referenceLookup) {
                    distance = index - referenceLookup.index;
                    if(referenceLookup.startScopeId === scope.id) {
                        direction = 'up';
                    }
                }
                if(reference.referenceType === 'StartAfterEnd') {
                    const dateOffset = moment(reference.startScopeStartDate).diff(moment(reference.endScopeEndDate), 'days')
                    if(dateOffset > 1) {
                        dateDiff = dateOffset;
                    }
                }                
                this._scopeReferences.set(key, {
                    id: reference.id,
                    type: reference.referenceType,
                    index: index,
                    direction: direction,
                    startScopeId: reference.startScopeId,
                    endScopeId: reference.endScopeId,
                    distance: distance,
                    dateDiff: dateDiff,
                    multiStartCount: reference.multiStartCount,
                    critical: reference.critical
                } as ScopeReference);
            })
            return ++index;
        }

        private setScopeDepthVisibility = (scope: Scope) => {
            if(scope.depth < this._maxScopeDepth) {
                this.setScopeVisibility(scope.id!, true);
            } else {
                this.setScopeVisibility(scope.id!, false);
            }
            if(scope.hasChildren) {
                scope.children.forEach((childscope: Scope) =>  { this.setScopeDepthVisibility(childscope)});
            }
        };
        
        public setMaxScopeDepth = (level: number) => {
            this._maxScopeDepth = level;
            this._scopes.forEach((scope: Scope) => { this.setScopeDepthVisibility(scope)});
            
            this.createScopeReferences();
        }
        
        public createScopeReferences = ()  => {
            let index = 0;
            this._scopeReferences.clear();
            for(let [scopeId, scopeRow] of this._scopeRows) {
                if(scopeRow.scope.depth < this._maxScopeDepth) {
                    index = this.setScopeReferences(scopeRow, index);
                }
            }
            this.drawScopeReferences();
        }
        
        private addScopeRows( scopes: Scope[], parentIsOdd?: boolean) {
            scopes.forEach((scope: Scope, index: number) => {
                let isOdd = true;
                if(parentIsOdd !== undefined) {
                    isOdd = parentIsOdd;
                } else {
                    if(index % 2 === 1) {
                        isOdd = false;
                    }
                }
                const scopeRow = new Simplex.WebComponents.Project.ScopeRow(this.projectId!, scope, isOdd, this._totalDaySpan, this._startDate, this._interval, this._intervalList as [], this._maxScopeDepth);
  
                this._scopeRows.set(scope.id!, scopeRow);
                this._ganttElement!.appendChild(scopeRow);
                
                if(scope.children && scope.children.length>0){
                    this.addScopeRows(scope.children, isOdd);
                }
            });
        }

        private addMultiCountToReference = (reference: HTMLElement, multiStart: string, startScopeId: string) => {
            if(reference.querySelector('.multistart')) {
                return;
            }
            reference.insertAdjacentHTML('afterbegin', multiStart);
            const circle = reference.querySelector('.JSRelationEdit') as HTMLElement;
            if(circle) {
                circle.dataset.scopeId = startScopeId;
                circle.addEventListener('click', this.onMultiRelationClick);
            }
        }

        private onMultiRelationClick = (event: Event) => {
            event.preventDefault();
            event.stopPropagation();

            const target = event.target as HTMLElement;
     
            const gridRelation = document.querySelector('.JSRelation') as HTMLElement;
            gridRelation.innerHTML = this.popupTemplate({multi: true});
            const gridPosition = this.getBoundingClientRect();
            const position = target.getBoundingClientRect();

            let leftOffsetRelationPopup = 0;
            if(gridPosition.x + gridPosition.width < position.x + 180) {
                leftOffsetRelationPopup = 200;
            }
            gridRelation.style.left = `${position.x - gridPosition.x + this.scrollLeft + 25 - leftOffsetRelationPopup}px`;
            gridRelation.style.top = `${(position.y - gridPosition.y) - 36}px`;
            gridRelation.style.display = `block`;

            const editOption = gridRelation.querySelector('.JSedit') as HTMLElement;
            if(editOption) {
                editOption.dataset.scopeId = target.dataset.scopeId;
                editOption.addEventListener('click', this.onEditRelation);
            }
            document.addEventListener('click', this.onDocumentClick);
        }

        private closeRelationContext = () => {
            const gridRelation = document.querySelector('.JSRelation') as HTMLElement;
            gridRelation.innerHTML = '';
            gridRelation.style.display = `none`;
        };
        
        private onEditRelation = (event: Event) => {
            this.closeRelationContext();
            const target = event.target as HTMLElement;
            this.dispatchEvent(new CustomEvent("relationEdit", {bubbles: true, detail: { id: target.dataset.scopeId, editing: true}} ) );
        }
        private isClickInPopup = (node: HTMLElement): boolean => {
            const gridRelation = document.querySelector('.JSRelation') as HTMLElement;
            while (node !== document.body && node.parentElement !== null) {
                if (node == gridRelation) {
                    return true;
                }
                node = node.parentElement;
            }
            return false;
        }

        private onDocumentClick = (event: MouseEvent): void => {
            if (!this.isClickInPopup(event.target as HTMLElement)) {
                const gridRelation = document.querySelector('.JSRelation') as HTMLElement;
                gridRelation.innerHTML = '';
                gridRelation.style.display = `none`;
            }
        };

        private addConnectorToEdge = (edgeElement: HTMLElement, reference: ScopeRelation) => {
            if(edgeElement.querySelector('.connection-dot')) {
                return;
            }
            edgeElement!.innerHTML += reference.generateConnector();
        }
        private drawScopeReferences = () => {
            const allEdges = document.querySelectorAll('.bar__edge');
            allEdges.forEach(element => {
                const edge = element as HTMLElement;
                edge.innerHTML = "";
            })
            
            this._scopeReferences.forEach(reference => {
                const scopeReference = new Simplex.WebComponents.Project.ScopeRelation(this.projectId!, reference, this.calculateTickWidth(reference.dateDiff));
                const startScopeRow = this._scopeRows.get(reference.startScopeId);
                const endScopeRow = this._scopeRows.get(reference.endScopeId);
                if(!startScopeRow || !endScopeRow || reference.distance <= 0) {
                    return;
                }
                const endRowEdgeStartElement = endScopeRow.querySelector('.bar__edge--start') as HTMLElement;
                const startRowEdgeStartElement = startScopeRow.querySelector('.bar__edge--start') as HTMLElement;
                const endRowEdgeEndElement = endScopeRow.querySelector('.bar__edge--end') as HTMLElement;
                const startRowEdgeEndElement = startScopeRow.querySelector('.bar__edge--end') as HTMLElement;

                switch(reference.type) {
                    case "EqualStart":
                        if(reference.direction === "up")  {
                            this.addConnectorToEdge(startRowEdgeStartElement, scopeReference);
                            endRowEdgeStartElement!.append(scopeReference);
                        } else {
                            this.addConnectorToEdge(endRowEdgeStartElement, scopeReference);
                            startRowEdgeStartElement!.append(scopeReference);
                        }
                        break;
                    case "EqualEnd":
                        if(reference.direction === "up")  {
                            this.addConnectorToEdge(startRowEdgeEndElement, scopeReference);
                            endRowEdgeEndElement!.append(scopeReference);
                        } else {
                            this.addConnectorToEdge(endRowEdgeEndElement, scopeReference);
                            startRowEdgeEndElement!.append(scopeReference);
                        }
                        break;
                    case "StartAfterEnd":
                        if(reference.direction === "up") {
                            this.addConnectorToEdge(startRowEdgeStartElement, scopeReference);
                            endRowEdgeEndElement!.append(scopeReference);
                        } else {
                            this.addConnectorToEdge(endRowEdgeEndElement, scopeReference);
                            startRowEdgeStartElement!.append(scopeReference);
                        }
                        if(reference.multiStartCount > 1) {
                            this.addMultiCountToReference(startRowEdgeStartElement, scopeReference.generateMultiCount(reference.multiStartCount), reference.startScopeId);
                        }
                        break;    
                }
            });
        }
        
        public removeAllHighlights = () => {
            for (let [id, scopeRow] of this._scopeRows) {
                scopeRow.removeHighlight();
            }
        }

        public highlightScopes = async (scopeIds: string[]) => {
            for (let [id, scopeRow] of this._scopeRows) {
                if(scopeIds.includes(id)){
                    scopeRow.showHighlight();
                } else {
                    scopeRow.removeHighlight();
                }
            }
        }

        async connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if(!this._rendered) {
                this.render();
            }
        }

        calculateGridHeight = () => {
            const root = document.documentElement;
            root.style.setProperty('--calender-grid-height', `${this.getBoundingClientRect().height}px`);
        }
        
        setScopeVisibility = (id: string, open: boolean) => {
            if(this._scopeRows.has(id)) {
                const row = this._scopeRows.get(id)!;
                row.visibility = open;
                this.calculateGridHeight();
            }
        }

        setScopeEditing = (id: string, editing: boolean, blockSpacer: number) => {
            if(this._scopeRows.has(id)) {
                const row = this._scopeRows.get(id)!;
                row.style.setProperty('--block-spacer', `${blockSpacer}px`);
                row.edit = editing;
                this.calculateGridHeight();
            }
        }
        
        setScopeAdding = (id: string, adding: boolean, blockspacer: number = 60) => {
            if(this._scopeRows.has(id)) {
                const row = this._scopeRows.get(id)!;
                row.style.setProperty('--block-spacer', `${blockspacer}px`);
                row.add = adding;
                this.calculateGridHeight();
            }
        }
        
    }
}
