namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class UpdateFinanceEntry extends ABModel {

        
        public status: "New"|"Processed" = "Processed";
        public poNumber: string|null = null;
        public date: string|null = null;
        public supplier: string|null = null;
        public scopes: FinanceEntryScope[] = [];
        public costCategoryId: string|null = null;
        public description: string|null = null;
        public log: string|null = null;

      
    }
}