namespace Simplex.WebComponents.Project.Budget.Models {
  import ABModel = Ambrero.AB.Models.ABModel;

  export class FilterModel extends ABModel {
    public compareWithSnapshotId: string | null = null;
    public showTable: boolean = true;
    public graphViewType: BudgetViewType | null = null;
    public scopeId: string | null = null;
    public costCategoryId: string | null = null;
    public periodType: BudgetPeriodType = BudgetPeriodType.Month;
    public projectId: string;
    public scopeLevel: number;
    public costCategoryLevel: number;
    public verticalCollapsed: boolean;

    constructor(
      scopeId: string | null = null,
      costCategoryId: string | null = null,
      periodType: BudgetPeriodType = BudgetPeriodType.Month,
      showTable: boolean = true,
      graphViewType: BudgetViewType | null = null,
      viewType: BudgetViewType = BudgetViewType.GraphBudget,
      compareWithSnapshotId: string | null = null,
      projectId: string = "",
      scopeLevel: number = 0,
      costCategoryLevel: number = 0,
      verticalCollapsed: boolean = false
    ) {
      super();
      this.scopeId = scopeId;
      this.periodType = periodType;
      this.showTable = showTable;
      this.graphViewType = graphViewType;
      this.costCategoryId = costCategoryId;
      this.compareWithSnapshotId = compareWithSnapshotId;
      this.projectId = projectId;
      this.scopeLevel = scopeLevel;
      this.costCategoryLevel = costCategoryLevel;
      this.verticalCollapsed = verticalCollapsed;
    }
  }

  export enum BudgetViewType {
    GraphBudget = "GraphBudget",
    GraphActualCosts = "GraphActualCosts",
  }

  export enum BudgetPeriodType {
    Year = "Year",
    Quarter = "Quarter",
    Month = "Month",
    Week = "Week",
    Day = "Day",
  }
}
