namespace Simplex.WebComponents.Project {
  import WebComponent = Simplex.Decorators.WebComponent;
  import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
  import ABWebComponent = Simplex.Components.ABWebComponent;
  import ModelBinder = Ambrero.AB.Components.ModelBinder;
  import APIResult = Simplex.Utils.APIResult;
  import Button = Simplex.WebComponents.FormElements.Button;
  import ContentNotifier = Simplex.Components.ContentNotifier;
  import Inject = Simplex.Decorators.Inject;

  @WebComponent("ui-tenant-edit-form")
  export class TenantEditForm extends ABWebComponent {
    private readonly contentTemplate;
    private readonly _createModel: Simplex.Models.Admin.TenantCreate;
    private _tenant?: Simplex.Models.Admin.Tenant;
    private readonly _binder: ModelBinder;
    @Inject("ContentNotifier")
    private readonly _contentNotifier!: ContentNotifier;
    private readonly _tenantId?: string;
    private _submit?: Button;
    private _editOwner?: Button;
    private _editContact: boolean;

    public constructor() {
      super();
      this.contentTemplate = this.app.getTemplate(
        "WebComponents/Admin/TenantEditForm",
        "TenantEditForm"
      ) as TemplateCallback;
      this._binder = this.app.getComponentType<ModelBinder>("ModelBinder")!;
      this._tenantId = this.getAttribute("tenant-id") ?? "";
      this._editContact = !this.getAttribute("tenant-id");
      this._createModel = new Simplex.Models.Admin.TenantCreate();
      this._binder.bind(this._createModel, this._contentNotifier);
    }

    private checkForTenantId = async () => {
      if (this._tenantId) {
        const tenant = await this.LoadTenant(this._tenantId);
        if (tenant === undefined) {
          throw "Tenant not found";
        }
        this._tenant = tenant;
      }
      this.render();
    };

    private LoadTenant = async (
      tenantId: string
    ): Promise<Simplex.Models.Admin.Tenant | undefined> => {
      const result = await this.request.get<
        APIResult<Simplex.Models.Admin.Tenant>
      >(`/api/admin/tenant/${tenantId}`);
      if (!result.isSuccess) {
        return undefined;
      }
      return result.data.data;
    };

    render() {
      this.innerHTML = this.contentTemplate({
        model: this._createModel,
        tenant: this._tenant,
        editContact: this._editContact,
      });

      this._submit = this.querySelector(".submitButton") as Button;
      if (this._submit) {
        this._submit.addEventListener("click", this.onSubmit);
      }

      this._editOwner = this.querySelector(".editOwner") as Button;
      if (this._editOwner) {
        this._editOwner.addEventListener("click", this.onEditOwner);
      }
      this._contentNotifier.notifyDraw(this);
    }

    reset = (): void => {
      this._binder.reset(this._createModel);
    };

    onEditOwner = async (): Promise<void> => {
      this._editContact = true;
      this.render();
    };

    onSubmit = async (event: MouseEvent): Promise<void> => {
      event.preventDefault();
      event.stopPropagation();

      if (this.error) {
        this.error.remove();
      }

      if (!this._binder.validate(this._createModel)) {
        return;
      }
      if (this._submit) {
        this._submit.loading = true;
      }
      if (this._tenant) {
        const updateResult = await this.request.put<APIResult<void>>(
          `/api/admin/tenant/${this._tenantId}`,
          this._createModel
        );
        if (updateResult.isSuccess) {
          this.app.navigateTo("/admin/tenant");
        } else {
          let msg =
            updateResult.data.messages[0].key || "Failed to update tenant";
          this.addError(msg);
        }
      } else {
        const createResult = await this.request.post<APIResult<void>>(
          `/api/admin/tenant`,
          this._createModel
        );
        if (createResult.isSuccess) {
          this.app.navigateTo("/admin/tenant");
        } else {
          let msg =
            createResult.data.messages[0].key || "Failed to create tenant";

          this.addError(msg);
        }
      }
      if (this._submit) {
        this._submit.loading = false;
      }
    };

    error: HTMLDivElement | undefined = undefined;
    addError(message: string) {
      this.error = document.createElement("div");
      this.error.style.color = "red";
      this.error.style.paddingTop = "20px";

      this.error.innerHTML = message;
      this.appendChild(this.error);
    }

    async connectedCallback() {
      if (!this.isConnected) {
        return;
      }

      await this.checkForTenantId();
    }
  }
}
