namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class Period extends ABModel {

        public startDate: string;
        public endDate: string;

        constructor(
            startDate: string,
            endDate: string) {
            super();
            this.startDate = startDate;
            this.endDate = endDate;

        }
    }
}