namespace Simplex.Components {

    export interface IEmitter {
        on(eventName: string, fn: CallableFunction): void;
        addEventListener(eventName: string, fn: CallableFunction): void;
        once(eventName: string, fn: CallableFunction): void;
        off(eventName: string, fn?: CallableFunction): void;
        removeListener(eventName: string, fn: CallableFunction): void;
        removeAllListeners(eventName: string, fn: CallableFunction): void;
        removeEventListener(eventName: string, fn: CallableFunction): void;
        emit(eventName: string, ...args: any[]): void;
        listeners(eventName: string): CallableFunction[];
        hasListeners(eventName: string): boolean;
    }

    type CallbackEntry = {
        [key: string]: CallableFunction[]
    }

    export class Emitter implements IEmitter {
        public _callbacks: CallbackEntry = {};

        /**
         * Listen on the given `event` with `fn`.
         */
        public on(event: string, fn: CallableFunction) {
            const eventCallbackName = `$${event}`;
            if (!(eventCallbackName in this._callbacks)) {
                this._callbacks[eventCallbackName] = new Array<CallableFunction>();
            }
            this._callbacks[eventCallbackName].push(fn);
            return this;
        }

        public addEventListener(event: string, fn: CallableFunction) {
            return this.on(event, fn);
        }

        /**
         * Adds an `event` listener that will be invoked a single
         * time then automatically removed.
         */
        public once(event: string, fn: CallableFunction): Emitter {

            const on = (...args: any[]): void => {
                this.off(event, on);
                fn(...args);
            };

            on.prototype = {fn: fn};

            this.on(event, on);
            return this;
        }

        /**
         * Remove the given callback for `event` or all
         * registered callbacks.
         */
        public off(event: string, fn?: CallableFunction): Emitter {

            // all
            if (0 === arguments.length) {
                this._callbacks = {};
                return this;
            }

            // specific event
            const eventCallbackName = `$${event}`;
            const callbacks = this._callbacks[eventCallbackName];
            if (callbacks && callbacks.length > 0) {
                // remove all handlers
                if (fn === undefined) {
                    delete this._callbacks[eventCallbackName];
                    return this;
                }

                const pos = callbacks.findIndex(cb => fn === cb || (fn.prototype != null && fn.prototype.fn && fn.prototype.fn === cb));
                if (pos >= 0) {
                    callbacks.splice(pos, 1);
                }
            }
            return this;
        }

        public removeListener(event: string, fn: CallableFunction): Emitter {
            return this.off(event, fn);
        }

        public removeAllListeners(event: string, fn: CallableFunction): Emitter {
            return this.off(event, fn);
        }

        public removeEventListener(event: string, fn: CallableFunction): Emitter {
            return this.off(event, fn);
        }

        /**
         * Emit `event` with the given args.
         */
        public emit(event: string, ...args: any[]): Emitter {
            const eventCallbackName = `$${event}`;
            let callbacks = this._callbacks[eventCallbackName];

            if (this._callbacks[eventCallbackName]) {
                callbacks = this._callbacks[eventCallbackName].slice(0);
                callbacks.forEach(cb => Promise.resolve(cb(...args)));
            }

            return this;
        }

        /**
         * Return array of callbacks for `event`.
         */
        public listeners(event: string): CallableFunction[] {
            return this._callbacks[`$${event}`] || [];
        }

        /**
         * Check if this emitter has `event` handlers.
         */
        public hasListeners(event: string): boolean {
            return !!this.listeners(event).length;
        }
    }
}