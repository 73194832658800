namespace Simplex.Decorators {

    export function SystemInject(componentName: string, ...args: any[]): Function {

        return function(target: Object, propertyKey: string) {
            const getter = function() {

                if (!('components' in target)) {
                    // @ts-ignore
                    target.components = {};
                }
                const key = `${componentName}-${propertyKey}`;

                let component = Ambrero.AB.Application.Instance!.getSystemComponent(componentName);
                if (component === undefined || component === null) {
                    // @ts-ignore
                    if (target.components[key]) { 
                        // @ts-ignore
                        return target.components[key];
                    }
                    //@ts-ignore
                    component = Ambrero.AB.Application.Instance!.loadComponent(componentName,...args)
                }
                if (component === undefined || component === null) {
                    throw new Error(`Required component ${componentName} for ${target.constructor.name}.${propertyKey} not found`);
                }

                // @ts-ignore
                target.components[key] = component;
                return component;
            };
            Object.defineProperty(target, propertyKey, {
                get: getter,
                set: function(value) { }
            });

        };
    }
}

