namespace Simplex.WebComponents.FormElements {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;


    @WebComponent("ui-input-textarea")
    export class InputTextArea extends ABWebComponent {

        private readonly contentTemplate;
        private readonly options: string | null = null;

        public constructor() {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/FormElements/InputTextArea', 'InputTextArea') as TemplateCallback;
            this.options = this.innerHTML;
        }

        render() {
            this.innerHTML = this.contentTemplate({
                name: this.getAttribute("name"),
                value: this.getAttribute("value"),
                label: this.getAttribute("label"),
                placeholder: this.getAttribute("placeholder"),
                class: this.getAttribute("class"),
                tabindex: this.getAttribute("tabindex"),
                rows: this.getAttribute("rows"),
                required: this.getAttribute("required") === ""
            });
        }

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            this.render();
        }
    }
}
