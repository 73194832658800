namespace Simplex.Components {
    import Application = Ambrero.AB.Application;
    import APIResult = Simplex.Utils.APIResult;
    import ListViewFilter = Simplex.WebComponents.LayoutComponents.ListViewFilter;
    import IListView = Simplex.WebComponents.LayoutComponents.IListView;
    import ModelBinder = Ambrero.AB.Components.ModelBinder;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import EventArgs = Ambrero.AB.Components.EventArgs;

    import WebComponent = Simplex.Decorators.WebComponent;
    import Inject = Simplex.Decorators.Inject;
    import IModel = Ambrero.AB.Models.IModel;

    @WebComponent("ui-project-finance-list-filters")
    export class FinanceListFilters extends ABWebComponent {
        private readonly contentTemplate;
        private readonly _binder?: ModelBinder;
        @Inject("ContentNotifier")
        private readonly _contentNotifier!: ContentNotifier;
        private listView: IListView | null = null;
        private _filterModel: IModel | null = null;
        private rendered: boolean = false;
        private _dialog?: HTMLDialogElement;

        public constructor(app: Application) {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/Finance/FinanceListFilters', 'FinanceListFilters') as TemplateCallback;
        }

        connectedCallback() {
            this.app.getEventBus().on(`ListView.Initialized`, this.setListView);
        }
        
        
        disconnectedCallback() {
            this.app.getEventBus().off(`ListView.Initialized`, this.setListView);
        }
        
        private setListView = (event:EventArgs)=> {
            //@ts-ignore
            this.listView = event.component as IListView;
            //@ts-ignore
            event.component.addEventListener("loadData", this.listLoadingData);
            this.render();
        }
        
        private listLoadingData = (): void => {
            this.render();
            
        }
        
        private removeFilter = (event: Event): void => {
            //@ts-ignore
            switch(event.target.dataset.type) {
                case "range":
                    //@ts-ignore
                    this.listView?.getFilter()?.startDate = null;
                    //@ts-ignore
                    this.listView?.getFilter()?.endDate = null;
                    break;
                case "startDate":
                    //@ts-ignore
                    this.listView?.getFilter()?.startDate = null;
                    break;
                case "endDate":
                    //@ts-ignore
                    this.listView?.getFilter()?.endDate = null;
                    break;
                case "status":
                    //@ts-ignore
                    this.listView?.getFilter()?.status = "";
                    break;
                case "type":
                    //@ts-ignore
                    this.listView?.getFilter()?.type = "";
                    break;
            }
            this.listView?.setPageNumber(0);
        }
        
        private render(): void { 
            this.innerHTML = this.contentTemplate({
                filter: this.listView?.getFilter(),
                //@ts-ignore
                hasDateRange: this.listView?.getFilter()?.hasDateRange() ?? false,
                //@ts-ignore
                hasFromDate: this.listView?.getFilter()?.startDate != null,
                //@ts-ignore
                hasEndDate: this.listView?.getFilter()?.endDate != null,
            });
            this.querySelectorAll(".close").forEach(item => {             
                item.addEventListener("click", this.removeFilter.bind(this));
            });
        }
    }
}