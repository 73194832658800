namespace Simplex.WebComponents.FormElements.DateRangePicker {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import ModelBinder = Ambrero.AB.Components.ModelBinder;
    import EventArgs = Ambrero.AB.Components.EventArgs;
    import PopupContainer = Simplex.Components.PopupContainer;
    import Button = Simplex.WebComponents.FormElements.Button;

    @WebComponent("ui-date-range-picker")
    export class DateRangePicker extends ABWebComponent  {

        private readonly _contentTemplate;
        private readonly _popupTemplate;
        private _submitButton: Button | null = null;
        private readonly _periodModel: Simplex.WebComponents.FormElements.DateRangePicker.Models.Period;
        private readonly _binder: ModelBinder;
        private _location: string;
        private readonly _popupContainer: PopupContainer;

        public constructor() {
            super();
            this._contentTemplate = this.app.getTemplate('WebComponents/FormElements/DateRangePicker', 'DateRangePicker') as TemplateCallback;


            this._popupTemplate = this.app.getTemplate('WebComponents/FormElements/DateRangePicker', 'Popup') as TemplateCallback;
            const popupContext = {};
            this._popupContainer = this.app.getComponentType<PopupContainer>("PopupContainer","leftOf", this._popupTemplate, popupContext)!;

            this._periodModel = new Simplex.WebComponents.FormElements.DateRangePicker.Models.Period();
            this._binder = this.app.getComponentType<ModelBinder>("ModelBinder")!;
            this._binder.bind(this._periodModel, this._popupContainer);

            this._location = this.getAttribute("location") || 'below';
            this._popupContainer.on('created', this.onPopupContainerCreated)
            this._popupContainer.on('closed', this.onPopupContainerClosed)
        }

        private onPopupContainerCreated = (event:EventArgs):void => {
            this._submitButton = event.data.element.querySelector('ui-button');
            if (this._submitButton) {
                this._submitButton.addEventListener('click', this.onSubmit);
            }
        }

        private onPopupContainerClosed = (event:EventArgs):void => {
            this._binder.reset(this._periodModel);
        }

        static get observedAttributes() {
            return ['location'];
        }

        attributeChangedCallback(name: string, oldValue: string, newValue: string):void {
            switch (name) {
                case "location":
                    this._location = newValue;
                    //@ts-ignore
                    this._popupContainer.setLocation(newValue);
                    break;
            }
        }

        onClick = (event: MouseEvent): void => {
            event.preventDefault();
            event.stopPropagation();
            this._popupContainer.showPopup(this);
        }

        onSubmit = async (event: MouseEvent): Promise<void> => {
            event.preventDefault();
            event.stopPropagation();
            this._submitButton!.loading = true;
            if (!this._binder.validate(this._periodModel)) {
                return;
            }
            this.dispatchEvent(new CustomEvent("periodChosen", {detail: this._periodModel}));
            this._popupContainer.hidePopup();
        };

        render() {
            this.innerHTML = this._contentTemplate({});
            const setPeriodButton = this.querySelector('ui-button') as Button;
            if(setPeriodButton) {
                setPeriodButton.addEventListener('click', this.onClick);
            }
        }

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            this.render();
        }
    }
}
