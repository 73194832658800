//@ts-ignore
window.GUID_EMPTY = "00000000-0000-0000-0000-000000000000";
(async () => {
  //@ts-ignore
  await window.authReady;
  Ambrero.AB.Init(
    "Simplex",
    (settings) => {
      settings.logLevel = -1;
      //document.location.hostname.indexOf(".localtest.me") !== -1 ? 4 : 1;
      settings.handlebars = {
        allowProtoPropertiesByDefault: true,
      };
      settings.systemComponents = [];
      moment.updateLocale("nl", {
        monthsShort: [
          "jan",
          "feb",
          "mrt",
          "apr",
          "mei",
          "jun",
          "jul",
          "aug",
          "sep",
          "okt",
          "nov",
          "dec",
        ],
      });
      const sorted = Simplex.Decorators.Singletons.sort(
        (a: any, b: any) => a.order - b.order
      );
      for (let k in sorted) {
        settings.systemComponents.push([sorted[k].name, ...sorted[k].args]);
      }
    },
    function () {
      console.log("%c%s", "color: #000000; font-size: large;", "Simplex");
      $(document.body).removeClass("is--pageloading");
    }
  );
})();
