namespace Simplex.WebComponents.Project.DashboardProjectPeriod {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import APIResult = Simplex.Utils.APIResult;
    import PeriodData = Simplex.WebComponents.Project.DashboardProjectPeriod.Models.PeriodData;
    import InputDate = Simplex.WebComponents.FormElements.InputDate;

    @WebComponent("ui-dashboard-project-period-widget")
    export class ProjectDashboardPeriodWidget extends ABWebComponent {
        private readonly contentTemplate;
        private readonly _projectId: string;
        private _inputEndDate: InputDate|null = null;
        
        public constructor() {
            super();
            this._projectId = this.getAttribute("project-id") || "";
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/Dashboard/ProjectDashboardPeriod', 'ProjectDashboardPeriod') as TemplateCallback;
        }

        private getPeriodData = async (): Promise<PeriodData | undefined> => {

            const result = await this.request.get<APIResult<PeriodData>>(`/api/project/${this._projectId}/period`);
            if (!result.isSuccess) {
                return undefined;
            }
            return result.data.data;
        }

        private showEditForm(periodData: Simplex.WebComponents.Project.DashboardProjectPeriod.Models.PeriodData) : boolean{
            return !periodData.startDate;
        }
        
        public async render() {
            const periodData = await this.getPeriodData();
            if(!periodData) {
                return;
            }
            this.innerHTML = this.contentTemplate(periodData);
            this.classList.remove('is--editable');
            if (this.showEditForm(periodData)) {
                this.classList.add('is--editable');
                const editForm = this.querySelector("div.edit") as HTMLElement;
                if (!editForm) {
                    return;
                }
                this._inputEndDate = editForm.querySelector("[name='end']") as InputDate;
                if(this._inputEndDate) {
                    this._inputEndDate.addEventListener("keydown", this.onKeyDown);
                }
            }
        }

        onKeyDown = async (event:KeyboardEvent):Promise<void> => {
            if(event.code === 'Enter' || event.code === 'NumpadEnter') {
                this._inputEndDate?.blur();
                this._inputEndDate?.focus();

                await this.onPeriodChosen();
            }
        }

        private onPeriodChosen = async (event: Event|null = null): Promise<void> => {
            if(event) {
                event.preventDefault();
                event.stopPropagation();
            }
            const periodChange = ((event as CustomEvent).detail) as Simplex.WebComponents.FormElements.DateRangePicker.Models.Period|null;
            if(!periodChange) {
                return;
            }
            const changeProjectPeriodRequest = new Simplex.WebComponents.Project.DashboardProjectPeriod.Models.ChangeProjectPeriodRequest(moment(periodChange.startDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(periodChange.endDate, 'DD-MM-YYYY').format('YYYY-MM-DD'));
            const updatePeriodResult = await this.request.put<APIResult<void>>(`/api/project/${this._projectId}/period`, changeProjectPeriodRequest);
            if (updatePeriodResult.isSuccess) {
                const periodData = await this.getPeriodData();
                if(!periodData) {
                    return;
                }
                this.innerHTML = this.contentTemplate(periodData);
            }
        }

        public async connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            await this.render();
            this.bindElementEventListeners();
        }

        private bindElementEventListeners(): void {
            const dateRangeSelector = this.querySelector('ui-date-range-picker');
            if (dateRangeSelector) {
                dateRangeSelector.addEventListener('periodChosen', this.onPeriodChosen);
            }
            this.addEventListener('click', this.onClickedWidget)
        }

        private onClickedWidget(): void {
            this.app.navigateTo(`/project/${this._projectId}/planning`);
        }
        
        disconnectedCallback() {
            this.removeElementEventListeners();
        }
        private removeElementEventListeners(): void {
            const dateRangeSelector = this.querySelector('ui-date-range-picker');
            if (dateRangeSelector) {
                dateRangeSelector.removeEventListener('periodChosen', this.onPeriodChosen);
            }
            this.removeEventListener('click', this.onClickedWidget)
        }
    }
}
