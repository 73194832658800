namespace Simplex.WebComponents.Project {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import ModelBinder = Ambrero.AB.Components.ModelBinder;
    import ContentNotifier = Simplex.Components.ContentNotifier;
    import Inject = Simplex.Decorators.Inject;
    import APIResult = Simplex.Utils.APIResult;
    import SnapshotSummary = Simplex.Models.Project.SnapshotSummary;
    import Button = Simplex.WebComponents.FormElements.Button;
    import ProjectTasksManager = Simplex.Components.ProjectTasksManager;
    import EventArgs = Ambrero.AB.Components.EventArgs;
    import ProjectTaskType = Simplex.Project.Models.ProjectTaskType;
    @WebComponent("ui-edit-project-snapshot-form")
    export class EditProjectSnapshotForm extends ABWebComponent {
        private readonly _projectTasksManager : ProjectTasksManager;
        private readonly contentTemplate;
        private readonly _editModel: Simplex.Models.Project.EditSnapshotRequest;
        private readonly _binder: ModelBinder;
        @Inject("ContentNotifier")
        private readonly _contentNotifier!: ContentNotifier;
        private readonly _projectId: string;
        private readonly _snapshotId: string | null;
        private submit?: Button;
        private cancel?: Button;
        private _rendered: boolean = false;
        
        public constructor() {
            super();
            this._projectTasksManager = this.app.getComponent('ProjectTasksManager') as ProjectTasksManager;
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/Snapshot/EditProjectSnapshotForm', 'EditProjectSnapshotForm') as TemplateCallback;
            this._binder = this.app.getComponentType<ModelBinder>("ModelBinder")!;
            this._projectId = this.getAttribute("project-id") ?? "";
            this._snapshotId = this.getAttribute("snapshot-id") ?? null;
            if(this._snapshotId === ''){
                this._snapshotId = null;
            }
            this._editModel = new Simplex.Models.Project.EditSnapshotRequest();
            this._binder.bind(this._editModel, this._contentNotifier);
        }

        private LoadProject = async (projectId: string): Promise<Simplex.Models.Project.Project | undefined> => {

            const result = await this.request.get<APIResult<Simplex.Models.Project.Project>>(`/api/project/${projectId}`);
            if (!result.isSuccess) {
                return undefined;
            }
            return result.data.data;
        }

        private LoadSnapshot = async (projectId: string, id: string): Promise<SnapshotSummary | undefined> => {

            const result = await this.request.get<APIResult<SnapshotSummary>>(`/api/project/${projectId}/snapshots/${id}`);
            if (!result.isSuccess) {
                return undefined;
            }
            return result.data.data;
        }

        onSubmit = async (event: MouseEvent): Promise<void> => {
            event.preventDefault();
            event.stopPropagation();

            this.submit?.removeEventListener("click", this.onSubmit);
            
            if (!this._binder.validate(this._editModel)) {
                return;
            }
            if (this.submit) {
                this.submit.loading = true;
            }
            if (this._snapshotId !== null) {
                const updateResult = await this.request.put<APIResult<void>>(`/api/project/${this._projectId}/snapshots/${this._snapshotId}`, this._editModel);
                if (updateResult.isSuccess) {
                    this.app.navigateTo(`/project/${this._projectId}/snapshots`);
                } else {
                    // alert("Fout?");
                }
            } else {
                const createResult = await this.request.post<APIResult<void>>(`/api/project/${this._projectId}/snapshots`, this._editModel);
                if (createResult.isSuccess) {
                    this.app.navigateTo(`/project/${this._projectId}/snapshots`);
                } else {
                    // alert("Fout?");
                }
            }
            if (this.submit) {
                this.submit.loading = false;
            }
        }

        onCancel = async (event: MouseEvent): Promise<void> => {
            event.preventDefault();
            event.stopPropagation();

            this.app.navigateTo(`/project/${this._projectId}/snapshots`);
        }

        private removeHighLights = () => {
            const highlightedElements = this.querySelectorAll('.is--highlighted');
            highlightedElements.forEach(e=>e.classList.remove('is--highlighted'));
        }
        private onProjectTaskManagerRemoveHighLights = (event: EventArgs) => {
            event.handled = true;
            this.removeHighLights();
        }

        private onProjectTaskListItemClicked = async (event: EventArgs) => {
            event.handled = true;
            const task = event.data as Simplex.Project.Models.ProjectTask;
            this.removeHighLights();

            if(task.type === ProjectTaskType.MissingSnapshot || task.type === ProjectTaskType.ProjectScopeAddedOrRemovedMakeSnapshot) {
                this.querySelector('.JsAddSnapshotButton')?.classList.add('is--highlighted');
            }
            else if(task.type === ProjectTaskType.MissingBaseline) {
                this.querySelector('.JsSnapshotContextMenu')?.classList.add('is--highlighted');
            }
        }

        private bindElementEventListeners(): void {
            this.cancel = this.querySelector(".cancelButton") as Button;
            this.submit = this.querySelector(".submitButton") as Button;
            this.submit?.addEventListener("click", this.onSubmit);
            this.cancel?.addEventListener("click", this.onCancel);
            
            this._projectTasksManager.on('removeAllHighLights', this.onProjectTaskManagerRemoveHighLights);
            this._projectTasksManager.on('projectTaskListItemClicked', this.onProjectTaskListItemClicked);
        }

        private removeElementEventListeners(): void {
            this.cancel = this.querySelector(".cancelButton") as Button;
            this.submit = this.querySelector(".submitButton") as Button;
            this.submit?.removeEventListener("click", this.onSubmit);
            this.cancel?.removeEventListener("click", this.onCancel);
            
            this._projectTasksManager.off('removeAllHighLights', this.onProjectTaskManagerRemoveHighLights);
            this._projectTasksManager.off('projectTaskListItemClicked', this.onProjectTaskListItemClicked);
        }

        async render() {
            this.innerHTML = this.contentTemplate(
                {
                    project: await this.LoadProject(this._projectId),
                    snapshot: this._snapshotId !== null ? await this.LoadSnapshot(this._projectId, this._snapshotId) : null
                });

            this._contentNotifier.notifyDraw(this);
            this._rendered = true;
        }

        public async connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if (!this._rendered) {
                await this.render();
                this.bindElementEventListeners();
                await this._projectTasksManager.showTasks(this._projectId, Simplex.WebComponents.ProjectTasksType.Snapshots);
            }
        }

        disconnectedCallback() {
            this.removeElementEventListeners();
        }
    }
}
