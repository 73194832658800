namespace Simplex.WebComponents.FormElements {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;

    type KeyPressHandler = (event: KeyboardEvent) => void

    @WebComponent("ui-input-password")
    export class InputPassword extends ABWebComponent {

        private readonly contentTemplate;
        private input: HTMLInputElement|null = null;
        private feedbackTarget: HTMLElement|null = null;
        
        public constructor() {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/FormElements/InputPassword', 'InputPassword') as TemplateCallback;
        }
        
        onKeyPress( listener: KeyPressHandler ) {
            if (this.input) {
                this.input.addEventListener("keypress", listener);
            }
        }
        
        blur = ():void => {
            if (this.input) {
                this.input.blur();
            }
        }

        focus = ():void => {
            if (this.input) {
                this.input.focus();
            }
        }
        render() {
            this.innerHTML = this.contentTemplate({
                name: this.getAttribute("name"),
                value: this.getAttribute("value"),
                label: this.getAttribute("label"),
                placeholder: this.getAttribute("placeholder"),
                class: this.getAttribute("class"),
                tabindex: this.getAttribute("tabindex"),
                required: this.getAttribute("required") === ""
            });

            this.input = this.querySelector("input");
            this.feedbackTarget = this.querySelector(".input__feedback");

            if (this.input) {
                this.input.addEventListener("Valid", this.inputValidated);
                this.input.addEventListener("Invalid", this.inputInvalid);
            }
        }

        inputValidated = (event:Event):void => {
            if (this.feedbackTarget) {
                this.feedbackTarget.innerHTML = "";
            }
        }

        inputInvalid = (event:Event):void => {
            if (this.feedbackTarget && this.input) {
                this.feedbackTarget.innerHTML = Messages(this.input.dataset.validationMessage ?? "");
            }
        }

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            this.render();
        }
    }
}
