namespace Simplex.WebComponents.LayoutComponents {
  import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
  import ABWebComponent = Simplex.Components.ABWebComponent;
  import Model = Ambrero.AB.Models.Model;
  import InitializedComponentEvent = Simplex.Components.InitializedComponentEvent;
  import APIResult = Simplex.Utils.APIResult;
  import PagedResult = Simplex.Models.PagedResult;
  import IModel = Ambrero.AB.Models.IModel;

  export type ListViewEvents =
    | "ItemSelected"
    | "ActiveItemSelected"
    | "ItemDeselected";

  export interface ListViewDataLoadedEvent {
    identifier: string | null;
  }

  export interface IListView {
    setFilter(listViewFilter: IListViewFilter): void;
    getFilter(): IModel | null;
    setPagination(pagination: Pagination): void;
    getSearchParameters(): ListSearchParameters;
    setPageNumber(pageNumber: number): Promise<void>;
  }

  export interface ListSearchParameters {
    pageNumber: number;
    itemCount: number;
    pageSize: number;
    orderByProperty: string;
    totalItemCount: number;
    pageCount: number;
    firstElementIndex: number;
    lastElementIndex: number;
  }

  export class ListViewOptions {
    public readonly id: string;
    public readonly clickable: boolean;
    public readonly noResultsMessage: string | null;
    public readonly loadingMessage: string | null;
    public readonly sorting: ListSorting;
    public readonly classes: ListClasses | null;
    public readonly rowTemplate: TemplateLocation;
    public readonly headerTemplate: TemplateLocation | null;
    public readonly filter: IModel | null;
    public readonly isAdmin: boolean;

    constructor(
      id: string,
      clickable: boolean,
      noResultsMessage: string | null,
      loadingMessage: string | null,
      sorting: ListSorting,
      classes: ListClasses | null,
      rowTemplate: TemplateLocation,
      headerTemplate: TemplateLocation | null = null,
      filter: IModel | null = null
    ) {
      this.id = id;
      this.clickable = clickable;
      this.noResultsMessage =
        noResultsMessage ?? Messages("listView.noResults");
      this.loadingMessage = loadingMessage ?? Messages("listView.loading");
      this.sorting = sorting;
      this.classes = classes;
      this.rowTemplate = rowTemplate;
      this.headerTemplate = headerTemplate;
      this.filter = filter;

      //@ts-ignore
      this.isAdmin = window.userContext.user.roles.includes("GlobalAdmin");
    }
  }

  interface TemplateLocation {
    path: string;
    file: string;
  }

  interface ListSorting {
    property: string;
    direction: string;
  }

  interface ListClasses {
    listview: string;
  }

  interface ListContext {
    options: ListViewOptions;
    filter: IModel | null;
    sorting: ListSorting;
    currentPage: number;
    initialLoad: boolean;
    hasError: boolean;
    errorMessage: string | null;
    searchParameters: ListSearchParameters;
    customData: any;
    items: any[];
  }

  export abstract class ListView<T>
    extends ABWebComponent
    implements IListView
  {
    private static readonly LOADING_CLASS = "is--loading";
    private static readonly LOADED_CLASS = "is--done";
    private static readonly FAILED_CLASS = "is--failed";
    private static readonly ACTIVE_CLASS = "is--active";

    private static readonly DOUBLE_CLICK_DELAY_IN_MS = 200;

    private readonly contentTemplate;
    private readonly options: ListViewOptions;
    private readonly url: string | null;

    private currentIndicatorClass: string | null = null;
    // private searchInputText: SearchInputText | null = null;
    private pagination: Pagination | null = null;
    private listViewFilter: ListViewFilter | null = null;
    private readonly listIdentifier: string | null;
    private readonly context: ListContext;

    protected tId: string | undefined;

    protected constructor() {
      super();
      this.listIdentifier = this.getAttribute("list");
      if (this.listIdentifier === null) {
        throw new Error(
          "Could not initialize list as the identifier is unknown"
        );
      }

      this.contentTemplate = this.app.getTemplate(
        "WebComponents/LayoutComponents/ListView",
        "ListView"
      ) as TemplateCallback;
      this.options = this.getListViewSettings();

      this.url = this.getAttribute("url");
      if (this.url === undefined) {
        throw new Error("Could not initialize list as the url is not set");
      }

      this.context = this.constructDefaultContext(this.options);
      this.loadStoredState();
    }

    // public setSearchInputText(searchInputText: SearchInputText): void {
    //     if (this.searchInputText !== searchInputText) {
    //         this.searchInputText = searchInputText;
    //         this.searchInputText.setListView(this);
    //     }
    // }

    public setPagination(pagination: Pagination): void {
      if (this.pagination !== pagination) {
        this.pagination = pagination;
        this.pagination.setListView(this);
      }
    }

    public setFilter(listViewFilter: ListViewFilter): void {
      if (this.listViewFilter !== listViewFilter) {
        this.listViewFilter = listViewFilter;
        this.listViewFilter.setListView(this);
      }
    }

    public async setPageNumber(pageNumber: number): Promise<void> {
      this.context.currentPage = pageNumber;
      await this.loadData();
    }
    public async selectItemById(id: any): Promise<boolean> {
      this.removeActiveClass();
      for (let i = 0; i < this.context.items.length; i++) {
        if (this.context.items[i].id !== id) {
          continue;
        }
        const nextItem: any = this.querySelector(
          `.jsListViewItem[data-index="${i}"]`
        );
        if (nextItem) {
          nextItem.click();
          return true;
        }
      }
      return false;
    }

    public getSearchParameters(): ListSearchParameters {
      return this.context.searchParameters;
    }

    public on(eventName: ListViewEvents, callback: (event: Event) => void) {
      this.addEventListener(eventName, callback);
    }

    protected getIdentifier(): string | null {
      return this.listIdentifier;
    }
    private constructDefaultContext(options: ListViewOptions): ListContext {
      return {
        options: options,
        filter: options.filter,
        sorting: options.sorting,
        currentPage: 0,
        initialLoad: true,
        hasError: false,
        errorMessage: null,
        searchParameters: {
          pageNumber: 0,
          itemCount: 0,
          pageSize: 0,
          pageCount: 0,
          totalItemCount: 0,
          orderByProperty: "",
          firstElementIndex: 0,
          lastElementIndex: 0,
        },
        customData: {},
        items: [],
      };
    }

    private setIndicator(indicatorClass: string) {
      if (this.currentIndicatorClass !== null) {
        this.classList.remove(this.currentIndicatorClass);
      }
      this.currentIndicatorClass = indicatorClass;
      this.classList.add(this.currentIndicatorClass);
    }

    private setIsLoading(): void {
      this.setIndicator(ListView.LOADING_CLASS);
    }

    private saveState(): void {
      if (this.options) {
        const key = `${$(document.body).data("controller")}/${$(
          document.body
        ).data("action")}/table${this.options.id}`;
        const data = {
          // filter: this.context.filter != null ? this.context.filter.getData() : null,
          page: this.context.currentPage,
          sorting: this.context.sorting,
        };
        sessionStorage.setItem(key, JSON.stringify(data));
      }
    }

    private loadStoredState(): void {
      if (this.options) {
        const key = `${$(document.body).data("controller")}/${$(
          document.body
        ).data("action")}/table${this.options.id}`;
        const data = sessionStorage.getItem(key);
        if (data !== null) {
          const parsedData = JSON.parse(data);
          // if (parsedData.filter !== null && this.context.filter !== null) {
          //     this.context.filter.Load(parsedData.filter);
          // }
          this.context.currentPage = parsedData.page;
          if (this.context.currentPage < 0) {
            this.context.currentPage = 0;
          }
          this.context.sorting = parsedData.sorting as ListSorting;
        }
      }
    }

    private constructDataUrl(): string {
      return `${this.url}?${this.constructDataUrlQuery()}`;
    }
    public constructDataUrlQuery(): string {
      let constructedUrl = `pageNumber=${this.context.currentPage}`;

      if (this.context.sorting.property) {
        constructedUrl = `${constructedUrl}&orderByProperty=${encodeURIComponent(
          this.context.sorting.property
        )}`;
      }
      if (this.context.sorting.direction) {
        constructedUrl = `${constructedUrl}&orderByDirection=${encodeURIComponent(
          this.context.sorting.direction
        )}`;
      }
      if (this.tId) constructedUrl += `&tenantId=${this.tId}`;
      return constructedUrl;
    }

    private dataLoaded(response: PagedResult<T>): void {
      this.setIndicator(ListView.LOADED_CLASS);
      this.context.initialLoad = false;

      //const _loadedData = response.data;
      this.context.items = response.items || [];
      for (const i of this.context.items) {
        i.isAdmin = this.options.isAdmin;
      }
      this.context.hasError = false;
      this.context.searchParameters = {
        pageNumber: response.page,
        itemCount: response.items.length,
        pageSize: response.size,
        orderByProperty: "", //@TODO
        totalItemCount: response.totalItemCount,
        pageCount: response.pageCount,
        firstElementIndex: response.page * response.size,
        lastElementIndex: response.page * response.size + response.itemCount,
      };

      this.triggerDataLoadedEvent();
      this.render();
    }

    private dataLoadFailed(): void {
      this.setIndicator(ListView.FAILED_CLASS);

      this.context.initialLoad = false;
      this.context.hasError = true;
      // this.context.errorMessage = Simplex.Utils.resolveMessageFromAjaxResponse(xhr, false);

      this.render();
    }

    private async loadData(): Promise<void> {
      this.setIsLoading();
      this.saveState();
      this.removeActiveClass();

      this.dispatchEvent(new Event("loadData"));
      const response = await this.request.post<APIResult<PagedResult<T>>>(
        this.constructDataUrl(),
        this.context.filter
      );

      if (response.isSuccess) {
        this.dataLoaded(response.data.data!);
      } else {
        this.dataLoadFailed();
      }
    }
    public async reload(): Promise<void> {
      await this.loadData();
    }

    private determineIndex(element: DOMStringMap): number | undefined {
      const index = element.index;
      if (index !== undefined) {
        const indexAsNumber = parseInt(index);
        if (!isNaN(indexAsNumber)) {
          return indexAsNumber;
        }
      }
      return undefined;
    }

    private determineDataForItemIndex(index: number): any {
      if (index >= 0 && index < this.context.items.length) {
        return this.context.items[index];
      }
      return null;
    }

    private readonly triggerDataLoadedEvent = (): void => {
      if (this.options) {
        this.app.getEventBus().emit(`ListView.DataLoaded`, {
          identifier: this.getIdentifier(),
        } as ListViewDataLoadedEvent);
      }
      this.onDataLoadedEvent();
    };

    protected getContext = (): ListContext => {
      return this.context;
    };

    public getFilter = (): IModel | null => {
      return this.context.filter;
    };

    private removeActiveClass(): void {
      const listItems = this.querySelectorAll(".jsListViewItem");
      if (listItems && listItems.length > 0) {
        for (const listItem of listItems) {
          const item = listItem as HTMLElement;
          const index = this.determineIndex(item.dataset);
          if (
            index !== undefined &&
            item.classList.contains(ListView.ACTIVE_CLASS)
          ) {
            const data = this.determineDataForItemIndex(index);
            this.dispatchCustomEvent("ItemDeselected", data);
          }

          item.classList.remove(ListView.ACTIVE_CLASS);
        }
      }
    }

    private setTimestampOfClick(element: HTMLElement): void {
      element.dataset.timestampLatestClick = new Date().toISOString();
    }

    private determineTimestampOfLatestClick(
      element: HTMLElement
    ): Date | undefined {
      const timestampLatestClickData = element.dataset.timestampLatestClick;
      if (timestampLatestClickData !== undefined) {
        const timestamp = new Date(timestampLatestClickData);
        // @ts-ignore
        if (!isNaN(timestamp)) {
          return timestamp;
        }
      }
      return undefined;
    }

    private dispatchCustomEvent = (
      eventName: ListViewEvents,
      data: any
    ): void => {
      this.onCustomEvent(eventName, data);
      const event = new CustomEvent(eventName, { detail: { item: data } });
      this.dispatchEvent(event);
    };

    protected onCustomEvent = (
      eventName: ListViewEvents,
      data: any
    ): void => {};
    protected onDataLoadedEvent = (): void => {};

    private isDoubleClick(timestampOfLatestClick: Date | undefined): boolean {
      if (timestampOfLatestClick === undefined) {
        return false;
      }

      return (
        new Date().getTime() - timestampOfLatestClick.getTime() <
        ListView.DOUBLE_CLICK_DELAY_IN_MS
      );
    }

    private render(): void {
      this.innerHTML = this.contentTemplate(this.context);
      this.bindElementEventListeners();
    }

    private onItemClicked = (event: Event): void => {
      const clickedItem = event.currentTarget as HTMLElement;
      const index = this.determineIndex(clickedItem.dataset);
      if (index !== undefined) {
        const data = this.determineDataForItemIndex(index);
        const timestampOfLatestClick =
          this.determineTimestampOfLatestClick(clickedItem);

        this.setTimestampOfClick(clickedItem);
        if (
          clickedItem.classList.contains(ListView.ACTIVE_CLASS) &&
          this.isDoubleClick(timestampOfLatestClick)
        ) {
          this.removeActiveClass();
          this.dispatchCustomEvent("ActiveItemSelected", data);
        } else {
          this.removeActiveClass();
          clickedItem.classList.add(ListView.ACTIVE_CLASS);
          this.dispatchCustomEvent("ItemSelected", data);
        }
      }
    };

    private getCurrentlySelectedIndex = (): number | undefined => {
      const listItems = this.querySelectorAll(".jsListViewItem");
      if (listItems && listItems.length > 0) {
        for (const listItem of listItems) {
          const item = listItem as HTMLElement;
          if (item.classList.contains(ListView.ACTIVE_CLASS)) {
            return this.determineIndex(item.dataset);
          }
        }
      }
      return undefined;
    };

    public selectNextItem = async (loop: boolean): Promise<boolean> => {
      let currentIndex = this.getCurrentlySelectedIndex();
      if (currentIndex === undefined) {
        currentIndex = -1;
      }
      this.removeActiveClass();

      const nextIndex = currentIndex + 1;
      let nextItem: any;

      if (nextIndex > this.context.items.length - 1) {
        if (
          this.context.currentPage >=
          this.context.searchParameters.pageCount - 1
        ) {
          if (!loop) {
            return false;
          }
          await this.setPageNumber(0);
          nextItem = this.querySelector(`.jsListViewItem[data-index="0"]`);
        } else {
          await this.setPageNumber(this.context.currentPage + 1);
          nextItem = this.querySelector(`.jsListViewItem[data-index="0"]`);
        }
      } else {
        nextItem = this.querySelector(
          `.jsListViewItem[data-index="${nextIndex}"]`
        );
      }
      if (nextItem) {
        nextItem.click();
        return true;
      }

      return false;
    };
    public selectPreviousItem = async (): Promise<boolean> => {
      let currentIndex = this.getCurrentlySelectedIndex();
      if (currentIndex === undefined) {
        currentIndex = -1;
      }
      this.removeActiveClass();
      const previousIndex = currentIndex - 1;
      let previousItem: any;

      if (previousIndex < 0) {
        if (this.context.currentPage === 0) {
          await this.setPageNumber(this.context.searchParameters.pageCount - 1);
          previousItem = this.querySelector(
            `.jsListViewItem[data-index="${this.context.items.length - 1}"]`
          );
        } else {
          await this.setPageNumber(this.context.currentPage - 1);
          previousItem = this.querySelector(
            `.jsListViewItem[data-index="${this.context.items.length - 1}"]`
          );
        }
      } else {
        previousItem = this.querySelector(
          `.jsListViewItem[data-index="${previousIndex}"]`
        );
      }
      if (previousItem) {
        previousItem.click();
        return true;
      }

      return false;
    };

    private onSortableHeaderClicked = (event: Event): void => {
      const clickedItem = event.currentTarget as HTMLElement;
      const orderByProperty = clickedItem.dataset.property;

      if (orderByProperty) {
        const orderByDirection =
          this.context.sorting.property === orderByProperty &&
          this.context.sorting.direction.toLowerCase() === "asc"
            ? "DESC"
            : "ASC";

        this.context.currentPage = 0;
        this.context.sorting.property = orderByProperty;
        this.context.sorting.direction = orderByDirection;

        this.loadData();
      }
    };

    private onSearchInputTextInitialized = (
      event: InitializedComponentEvent
    ) => {
      // if (this.options && event.identifier === this.options.id) {
      //     this.setSearchInputText(event.component as SearchInputText);
      // }
    };

    private onPaginationInitialized = (event: InitializedComponentEvent) => {
      if (this.options && event.identifier === this.getIdentifier()) {
        this.setPagination(event.component as Pagination);
      }
    };

    private onListViewFilterInitialized = (
      event: InitializedComponentEvent
    ) => {
      if (this.options && event.identifier === this.getIdentifier()) {
        this.setFilter(event.component as ListViewFilter);
      }
    };
    private readonly triggerInitializedEvent = (): void => {
      this.app.getEventBus().emit(`ListView.Initialized`, {
        identifier: this.getIdentifier(),
        component: this,
      } as InitializedComponentEvent);
    };
    async connectedCallback() {
      if (!this.isConnected) {
        return;
      }
      this.bindApplicationEventListeners();
      this.triggerInitializedEvent();
      await this.loadData();
    }

    disconnectedCallback() {
      this.removeApplicationEventListeners();
    }

    private bindElementEventListeners(): void {
      if (this.options?.clickable) {
        const listItems = this.querySelectorAll(".jsListViewItem");
        if (listItems && listItems.length > 0) {
          for (const listItem of listItems) {
            const item = listItem as HTMLElement;
            item.addEventListener("click", this.onItemClicked);
          }
        }
      }

      const sortableHeaderItems = this.querySelectorAll(".jsSortableColumn");
      if (sortableHeaderItems && sortableHeaderItems.length > 0) {
        for (const sortableHeaderItem of sortableHeaderItems) {
          const item = sortableHeaderItem as HTMLElement;
          item.addEventListener("click", this.onSortableHeaderClicked);
        }
      }
    }

    private bindApplicationEventListeners(): void {
      // this.app.getEventBus().addEventListener('SearchInputText.Initialized', this.onSearchInputTextInitialized);
      this.app
        .getEventBus()
        .addEventListener(
          "Pagination.Initialized",
          this.onPaginationInitialized
        );
      this.app
        .getEventBus()
        .addEventListener(
          "ListViewFilter.Initialized",
          this.onListViewFilterInitialized
        );
    }

    private removeApplicationEventListeners(): void {
      // this.app.getEventBus().removeEventListener('SearchInputText.Initialized', this.onSearchInputTextInitialized);
      this.app
        .getEventBus()
        .removeEventListener(
          "Pagination.Initialized",
          this.onPaginationInitialized
        );
      this.app
        .getEventBus()
        .removeEventListener(
          "ListViewFilter.Initialized",
          this.onListViewFilterInitialized
        );
    }

    protected getListViewSettings(): ListViewOptions {
      return new ListViewOptions(
        "jsProjectMessageTableContainer",
        true,
        null,
        null,
        {
          property: "name",
          direction: "ASC",
        },
        {
          listview: "overview__listview",
        },
        {
          path: "Project",
          file: "ListView/itemRow",
        },
        {
          path: "Project",
          file: "ListView/headerTemplate",
        }
      );
    }
  }
}
