namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class ScopeReference extends ABModel {

        public startScopeId: string|null = Simplex.Utils.GUID_EMPTY;
        public referenceType: ScopeReferenceType|null = ScopeReferenceType.StartAfterEnd;
        public endScopeId: string|null = Simplex.Utils.GUID_EMPTY;

        constructor(startScopeId: string|null = null,
                    referenceType: ScopeReferenceType|null = null,
                    endScopeId: string|null = null) {
            super();
            this.startScopeId = startScopeId;
            this.referenceType = referenceType;
            this.endScopeId = endScopeId;
        }

        public convertField = (fieldName: string, direction: "up" | "down", value: any): any => {
            switch (fieldName) {
                case "startScopeId":
                case "endScopeId":
                    if (direction === "down") {
                        value = value !== null && value !== "" ? value : null;
                    }
            }
            return value;
        }
    }

    export enum ScopeReferenceType {
        StartAfterEnd = "StartAfterEnd",
        EqualStart = "EqualStart",
        EqualEnd = "EqualEnd"
    }
}