namespace Simplex.Models.Authentication {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class AuthenticationResult extends ABModel {

        public success: boolean;
        public redirectUrl: string;
        
        constructor(success: boolean = false, redirectUrl: string = "") {
            super();
            this.success = success;
            this.redirectUrl = redirectUrl;
            this.redirectUrl = redirectUrl;
        }
        
    }
}