namespace Simplex.WebComponents.Project.DashboardProjectSnapshot {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;

    @WebComponent("ui-dashboard-project-milestones-widget")
    export class ProjectDashboardMilestonesWidget extends ABWebComponent {
        private readonly contentTemplate;
        private readonly _projectId: string;
        private readonly _listType: string;

        public constructor() {
            super();
            this._listType = this.getAttribute("listtype") || "";
            this._projectId = this.getAttribute("project-id") || "";
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/Dashboard/ProjectDashboardMilestones', "ProjectDashboardMilestones") as TemplateCallback;
        }

        public async render() {
            this.innerHTML = this.contentTemplate({projectId: this._projectId, listType: this._listType});
        }

        public async connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            await this.render();
        }
    }
}
