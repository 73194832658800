namespace Simplex.Components {
    import Application = Ambrero.AB.Application;

    interface fetchRequestEventArgs {
        data: any,
        messages: [],
        global: boolean,
        handled: boolean,
    }
    
    export class FetchErrorHandler extends ABComponent{      
        private app: Ambrero.AB.Application;
        private readonly notice: any;

        constructor(app:Application) {
            super();
            this.app = app;
            this.notice = this.app.getComponent('AmbreroComponents.Notice');
            this.subscribeEventBus();
        }
        
        private readonly handleRequestError = (eventArgs: fetchRequestEventArgs): void => {
            eventArgs.handled = true;
            
            eventArgs.messages.forEach((message:any) => {
                if(message !== null) {
                    this.notice.addMessage(Messages(message), 'warning', 8000);    
                }
            });
        }

        private readonly subscribeEventBus = (): void => 
        {
            this.app.getEventBus().subscribe('requestFetchError', this.handleRequestError, this);
            this.app.getEventBus().subscribe('requestFetchNotFound', this.handleRequestError, this);
        }        
    }
}