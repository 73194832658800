namespace Simplex.Models.Authentication {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class ValidateCodeRequest extends ABModel {

        public email: string;
        public code: string;

        constructor(email: string = "", code: string = "") {
            super();
            this.email = email;
            this.code = code;
        }

    }
}