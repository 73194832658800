namespace Simplex.WebComponents.Project {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import ModelBinder = Ambrero.AB.Components.ModelBinder;
    import ContentNotifier = Simplex.Components.ContentNotifier;
    import Inject = Simplex.Decorators.Inject;
    import APIResult = Simplex.Utils.APIResult;
    
    @WebComponent("ui-add-scope")
    export class AddScope extends ABWebComponent {

        private readonly contentTemplate;
        private readonly _createModel: Simplex.Models.Project.CreateScopeRequest;
        private readonly _binder: ModelBinder;
        @Inject("ContentNotifier")
        private readonly _contentNotifier!: ContentNotifier;
        private _saveButton?: HTMLElement;
        private _cancelButton?: HTMLElement;
        private _timer?: number;
        private _input: HTMLInputElement | null = null;
        private _projectId?: string;
        private test: number = Math.floor(Math.random() * 10000) + 1;

        public constructor() {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/AddScope', 'AddScope') as TemplateCallback;
            this._binder = this.app.getComponentType<ModelBinder>("ModelBinder")!;
            this._createModel = new Simplex.Models.Project.CreateScopeRequest();
            this._binder.bind(this._createModel, this._contentNotifier);
        }

        render() {
            this.innerHTML = this.contentTemplate({ });
            this._input = this.querySelector("input");
            this._saveButton = this.querySelector("#addScopeButton") as HTMLElement;
            this._cancelButton = this.querySelector("ui-button[transparent]") as HTMLElement;
            if (this._input) {
                this._input.addEventListener("keydown", this.onKeyDown);
                this._input.addEventListener("input", this.onInput);
            }
            if(this._saveButton) {
                this._saveButton.addEventListener("click", this.onSubmit);
            }
            if(this._cancelButton) {
                this._cancelButton.addEventListener("click", () => {
                    this.reset();
                    this.toggleButtonStates();
                    this.setSavedState();
                    this.dispatchEvent(new Event("cancel", {bubbles: true}));
                });
            }
            
            this.querySelector(".addscope__toggle")!.addEventListener("click", (event:Event) => {
                event.preventDefault();
                event.stopPropagation();
                this.dispatchEvent(new Event("expand"));
            });

            this._contentNotifier.notifyDraw(this);
        }

        getValue() {
            return this._input?.value;
        }
        
        set parentId(id: string|null) {
            this._createModel.parentScopeId = id;
        }
        get parentId() {
            return this._createModel.parentScopeId;
        }

        set index(index: number) {
            this._createModel.index = index;
        }
        get index() {
            return this._createModel.index;
        }
        
        set projectId(id: string) {
            this._projectId = id; 
        }
        get projectId() {
            return this._projectId || ""; 
        }
        
        public reset = ():void => {
            this._binder.reset(this._createModel);
            this.classList.remove('is--dirty');
            if (this._input) {
                this._input.value = "";
            }
            this.toggleButtonStates();
        }
        
        public focus = ():void => {
            if (this._input) {
                this._input.focus();
            }
        }
        
        private toggleButtonStates = (): void => {
            if(this._input &&  this._input.value === "") {
                this.classList.remove('is--cancelable');
                this._saveButton?.setAttribute('disabled','');
                return;
            }
            this.classList.add('is--cancelable');
            this._saveButton?.removeAttribute('disabled');
        }
        
        private setSavedState = (): void => {
            window.clearTimeout(this._timer);
            if(this._input &&  this._input.value === "") {
                this.classList.remove('is--dirty');
                return;
            }
            this.classList.add('is--dirty');
        }

        public cancelAdd = async ():Promise<void> => {
            this.reset();
            this.toggleButtonStates();
            this.setSavedState();
            this.dispatchEvent(new Event("cancel", {bubbles: true}));
        }
        
        onInput = (): void => {
            window.clearTimeout(this._timer);
            this._timer = window.setTimeout(this.setSavedState, 2000);
            this.toggleButtonStates();
        }
        
        onKeyDown = async (event:KeyboardEvent):Promise<void> => {
            if(event.code === 'Enter' || event.code === 'NumpadEnter') {
                this._input?.blur();
                this._input?.focus();

                await this.onSubmit();
            }
            if (event.code === "Escape") {
                this.app.getEventBus().publish('closeScope', Simplex.Utils.createEventArgs(null,this));

            }
        }
        
        onSubmit = async ():Promise<void> => {
            if (!this._binder.validate(this._createModel)) {
                return;
            }
            const createResult = await this.request.post<APIResult<void>>(`/api/project/${this.projectId}/scopes`, this._createModel);
            if (createResult.isSuccess) {
                this.dispatchEvent(new CustomEvent("scopeCreated", {bubbles: true, detail: new Simplex.Models.Project.ScopeCreated(this._createModel.parentScopeId)} ) );
                this.reset();
            } else {
                alert("Fout?");
            }
        }

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }

            this.render();
        }        
    }
}
