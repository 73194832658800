namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class ScopeSortChanged extends ABModel {

        public scopeId: string;
        public originalParentScopeIdChange: string;
        public parentScopeId: string;

        constructor(parentScopeId: string, originalParentScopeIdChange: string, scopeId: string) {
            super();
            this.parentScopeId = parentScopeId;
            this.originalParentScopeIdChange = originalParentScopeIdChange;
            this.scopeId = scopeId;
        }
    }
}