namespace Simplex.WebComponents.FormElements.SubdomainInputText {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import APIResult = Simplex.Utils.APIResult;
    
    @WebComponent('ui-subdomain-input-text')
    export class SubdomainInputText extends ABWebComponent implements Node {
        private readonly contentTemplate;
        private _input: HTMLInputElement | null = null;
        private _inputDomainField: HTMLInputElement | null = null;
        private _feedbackTarget: HTMLElement|null = null;
        private _valid: boolean = false;
        private _validating: boolean = false;
        private _triggerValidationAgain: boolean = false;
        private readonly _tenantId: string|null = null;
        private readonly _subdomainAvailableRequest: Simplex.WebComponents.FormElements.SubdomainInputText.Models.SubdomainAvailableRequest;
        private timeout:number = 0;

        public constructor() {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/FormElements/SubdomainInputText', 'SubdomainInputText') as TemplateCallback;
            const tenantId = this.getAttribute('tenant-id')
            if(tenantId && tenantId !== '') {
                this._tenantId = tenantId;
            }
            this._subdomainAvailableRequest = new Simplex.WebComponents.FormElements.SubdomainInputText.Models.SubdomainAvailableRequest(this._tenantId);
        }

        async render() {
            const fieldName = this.getAttribute("name"); 
            this.innerHTML = this.contentTemplate({
                name: fieldName,
                value: this.getAttribute("value"),
                label: this.getAttribute("label"),
                fieldPrefix: this.getAttribute("field-prefix"),
                fieldAffix: this.getAttribute("field-affix"),
                placeholder: this.getAttribute("placeholder"),
                help: this.getAttribute("help"),
                class: this.getAttribute("class"),
                tabindex: this.getAttribute("tabindex"),
                max: this.getAttribute("max"),
                disabled: this.getAttribute("disabled") === "",
                hidden: this.getAttribute("hidden") === "",
                required: this.getAttribute("required") === ""
            });

            this._input = this.querySelector(`input[name='${fieldName}']`);
            this._inputDomainField = this.querySelector(`input[name='${fieldName}__subdomain']`);
            this._feedbackTarget = this.querySelector(".input__feedback");
            
            if (this._inputDomainField) {
                this._inputDomainField.addEventListener("keyup", this.onKeyPress);
            }
        }

        private updateResult = (error: Simplex.WebComponents.FormElements.SubdomainInputText.Models.CheckDomainValidationErrorType | null = null) => {
            this._valid = error === null;
            if(!this._input || !this._inputDomainField){
                return;
            }
            if(this._valid){
                this._inputDomainField.classList.remove('is--invalid');
                this._inputDomainField.classList.add('is--valid');
            } else {
                this._inputDomainField.classList.add('is--invalid');
                this._inputDomainField.classList.remove('is--valid');
            }
            if (this._feedbackTarget) {
                if (this._valid) {
                    this._feedbackTarget.innerHTML = "";
                } else {
                    let errorMessage = '';
                    switch (error){
                        case Simplex.WebComponents.FormElements.SubdomainInputText.Models.CheckDomainValidationErrorType.Required:
                            errorMessage = 'error.required';
                            break;
                        case Simplex.WebComponents.FormElements.SubdomainInputText.Models.CheckDomainValidationErrorType.TooShort:
                            errorMessage = 'error.domain.too_short';
                            break;
                        case Simplex.WebComponents.FormElements.SubdomainInputText.Models.CheckDomainValidationErrorType.Invalid:
                            errorMessage = 'error.domain.invalid.chars';
                            break;
                        case Simplex.WebComponents.FormElements.SubdomainInputText.Models.CheckDomainValidationErrorType.Exists:
                            errorMessage = 'error.domain.exists';
                            break;
                        case Simplex.WebComponents.FormElements.SubdomainInputText.Models.CheckDomainValidationErrorType.NotAllowed:
                            errorMessage = 'error.domain.not_allowed';
                            break;
                    }
                    
                    this._feedbackTarget.innerHTML = Messages(errorMessage);
                }
            }
            this._input.value = this._valid ? this._inputDomainField.value : '';
            this._input.dispatchEvent(new Event('change'));
        }
        
        private doValidation = async (): Promise<void> => {
            if (!this._input || !this._inputDomainField) {
                this.updateResult();
                return;
            }
            if (this._validating) {
                this._triggerValidationAgain = true;
                return;
            }            
            const value = this._inputDomainField!.value;
            const requiredValid = $.trim(value) !== "";
            if(!requiredValid) {
                this.updateResult(Simplex.WebComponents.FormElements.SubdomainInputText.Models.CheckDomainValidationErrorType.Required);
                return;
            }
            if($.trim(value).length < 2) {
                this.updateResult(Simplex.WebComponents.FormElements.SubdomainInputText.Models.CheckDomainValidationErrorType.TooShort);
                return;
            }

            this._subdomainAvailableRequest.subDomain = value;
            const validationResult = await this.request.post<APIResult<Simplex.WebComponents.FormElements.SubdomainInputText.Models.SubdomainAvailableResponse>>('/api/admin/tenant/subdomaincheck', this._subdomainAvailableRequest);
            if(validationResult.isSuccess && validationResult.data.data) {
                this.updateResult(validationResult.data.data.error);
            } else {
                this.updateResult();
            }

            this._validating = false;
            if (this._triggerValidationAgain) {
                this._triggerValidationAgain = false;
                await this.validate();
            }
        }
        
        private validate = async (): Promise<void> => {
            clearTimeout(this.timeout);

            this.timeout = setTimeout(() =>{
                this.doValidation();
            }, 200);
        }

        private onKeyPress = async (_: KeyboardEvent) => {
            await this.validate();
        }

        async connectedCallback() {
            if (!this.isConnected) {
                return;
            }

            await this.render();
        }
    }
}
