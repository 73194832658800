namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class CostCategory extends ABModel {

        public id: string|null;
        public parentCostCategoryId: string|null;
        public name: string;
        public code: string|null;
        public index: number;
        public active: boolean;
        public usedInScopeBudget: boolean;
        public maxDepth: boolean;
        public children: CostCategory[];

        constructor(id: string|null = null,
                    parentCostCategoryId: string|null = null,
                    name: string = "",
                    code: string|null = null,
                    index: number = 0,
                    active: boolean = true,
                    usedInScopeBudget: boolean = true,
                    maxDepth: boolean = false,
                    children: CostCategory[] = []) {
            super();
            this.id = id;
            this.parentCostCategoryId = parentCostCategoryId;
            this.name = name;
            this.code = code;
            this.active = active;
            this.usedInScopeBudget = usedInScopeBudget;
            this.index = index;
            this.maxDepth = maxDepth;
            this.children = children;
        }        
    }
}