namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class ScopeClicked extends ABModel {

        public scopeId: string|null;
        public scopeName: string;
        public scopeColor: number;

        constructor(scopeId: string|null, scopeName: string, scopeColor: number) {
            super();
            this.scopeId = scopeId;
            this.scopeName = scopeName;
            this.scopeColor = scopeColor;
        }
    }
}