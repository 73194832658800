namespace Simplex.Components {
    import Application = Ambrero.AB.Application;
    import EventArgs = Ambrero.AB.Components.EventArgs;
    import APIResult = Simplex.Utils.APIResult;

    import SystemInject = Simplex.Decorators.SystemInject;
    import IObservableContent = Ambrero.AB.Components.IObservableContent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;

    export type PopupContainerLocation  = "leftof"|"rightof"|"above"|"below";
    

    export class PopupContainer extends Emitter implements IObservableContent {
        
        private readonly _app: Application;
        private readonly _popupContents: TemplateCallback;
        private readonly _containerTemplate: TemplateCallback;
        private _popupElement: HTMLElement | null = null;
        private _location: PopupContainerLocation;
        private readonly _contentContext: any;
        
        constructor(app: Application, location: PopupContainerLocation, contents: TemplateCallback, contentContext: any) {
            super();
            this._app = app;
            this._popupContents = contents;
            this._location = location;
            this._contentContext = contentContext;
            this._containerTemplate = app.getTemplate('Components/PopupContainer', 'container') as TemplateCallback;

        }

        get containerElement() {
            return this._popupElement;
        }
        
        public setLocation = (location: PopupContainerLocation) => {
            if (this._popupElement !== null) {
                this._popupElement.remove();
                this._popupElement = null;
            }
            this._location = location;
        }

        createPopup = (): void => {
            if (this._popupElement !== null) {
                return;
            }
            
            this._popupElement = new DOMParser().parseFromString(this._containerTemplate({location: this._location, content: this._popupContents(this._contentContext) }), 'text/html').body.firstChild! as HTMLElement;

            const closeButton = this._popupElement.querySelector("a.close") as HTMLLinkElement;
            if (closeButton) {
                closeButton.addEventListener('click', this.onClose);
            }
            this.emit("created", Simplex.Utils.createEventArgs({element: this._popupElement}, this));

        };

        private onClose = (event: MouseEvent): void => {
            event.preventDefault();
            event.stopPropagation();
            this.hidePopup();
        }

        public showPopup = (anchorElement: HTMLElement): void => {
            this.createPopup();
            if (this._popupElement) {
                document.body.appendChild(this._popupElement);
                this.setPopupLocation(anchorElement);
                this._popupElement.classList.add('is--active');
                this.emit("draw", Simplex.Utils.createEventArgs({container: this._popupElement}, this));
                this.emit("opened", Simplex.Utils.createEventArgs({element: this._popupElement}, this));
                document.addEventListener('click', this.onDocumentClick);
                document.addEventListener('keyup', this.onDocumentKeyUp);
                const popupInput = this._popupElement.querySelector("input");
                if (popupInput) {
                    popupInput.focus();
                }
            }
        }

        public hidePopup = (): void => {
            if (this._popupElement) {
                document.body.removeChild(this._popupElement);
                document.removeEventListener('click', this.onDocumentClick);
                document.removeEventListener('keyup', this.onDocumentKeyUp);
                this.emit("closed", Simplex.Utils.createEventArgs({element: this._popupElement}, this));
            }
        };

        private isClickInPopup = (node: HTMLElement): boolean => {
            while (node !== document.body && node.parentElement !== null) {
                if (node == this._popupElement!) {
                    return true;
                }
                node = node.parentElement;
            }
            return false;
        }

        private onDocumentClick = (event: MouseEvent): void => {
            if (!this.isClickInPopup(event.target as HTMLElement)) {
                this.hidePopup();
            }
        };
        private onDocumentKeyUp = (event: KeyboardEvent): void => {
            if (event.code === "Escape") {
                this.hidePopup();
            }
        };

        private setPopupLocation = (anchorElement: HTMLElement): void => {
            const positionRect = anchorElement.getBoundingClientRect();
            if (this._popupElement) {
                const popupElementRect = this._popupElement.getBoundingClientRect();
                switch (this._location) {
                    case 'leftof':
                        this._popupElement.style.top = (positionRect.top + positionRect.height/2 - popupElementRect.height/2) + 'px';
                        this._popupElement.style.left = (positionRect.left + positionRect.width + 6) + 'px';
                        break;
                    case 'rightof':
                        this._popupElement.style.top = (positionRect.top + positionRect.height/2 - popupElementRect.height/2) + 'px';
                        this._popupElement.style.left = (positionRect.left - 6 - popupElementRect.width) + 'px';
                        break;
                    case 'above':
                        this._popupElement.style.top = (positionRect.top - popupElementRect.height - 6) + 'px';
                        this._popupElement.style.left = (positionRect.left + (positionRect.width/2) - (popupElementRect.width/2)) + 'px';
                        break;
                    case 'below':
                    default:
                        this._popupElement.style.top = (positionRect.top + positionRect.height + 6) + 'px';
                        this._popupElement.style.left = (positionRect.left + (positionRect.width/2) - (popupElementRect.width/2)) + 'px';
                        break;

                }
            }
        }
    }
}