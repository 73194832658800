/* COPYRIGHT (C) Ambrero Software B.V. - All rights reserved
 * This file is part of the Ambrero Base Framework and supplied under license.
 * Unauthorized copying of this file is strictly prohibited.
 * It is permitted to make changes to this file, as long as it is only used in this project.
 */
/// <reference path="../Decorators/Decorators.d.ts" />


namespace Simplex.Components {
    import Application = Ambrero.AB.Application;

    @Simplex.Decorators.Singleton()
    export class DatePicker {

        private readonly app: Application;

        private calendarTemplate: any = null;

        private $activeCalendar: any = null;

        private inputDate: any = null;
        private displayDate: any = null;
        private $inputField: any = null;
        private config: any = null;
        private closeOnBlurTimer: any = null;

        private state = 'month'; // month / year

        constructor(app: Application) {
            this.app = app;
            this.calendarTemplate = app.getTemplate('Components', 'DatePicker');
            this.closeOnBlurTimer = 50000;
            $(document).on('focus.dateselector', 'input[data-datepicker]', this.inputFocus.bind(this));
            $(document).on('click.dateselector', '.open-datepicker', this.inputClick.bind(this));
            $(document).on('click.dateselectorIcon', 'span.input__before', this.iconClick.bind(this));


        }

        private createEventArgs = (data: any) => {
            var eventArgs = {
                handled: false,
                data: data,
                global: false,
                source: this
            };
            return eventArgs;
        };

        private removeDatePicker = () => {
            if (this.$inputField !== null && this.$inputField.length > 0) {
                this.$inputField.off('keyup.datepicker');
                this.$inputField.off('blur.datepicker');
                var val = this.$inputField.val();
                if (val.indexOf('-') === -1 && val.length > 0) {
                    var testDate = moment(val, this.config.format, false);
                    if (testDate.isValid()) {
                        this.$inputField.val(testDate.format(this.config.format));
                        this.$inputField.trigger('change');
                        this.$inputField.blur();
                    }
                }
                this.$inputField = null;
            }
            if (this.$activeCalendar !== null && this.$activeCalendar.length > 0) {
                this.$activeCalendar.remove();
                this.$activeCalendar = null;
            }

            if (this.closeOnBlurTimer !== null) {
                clearTimeout(this.closeOnBlurTimer);
            }

            this.closeOnBlurTimer = null;
            this.app.getEventBus().unsubscribe('click', this.checkInsideCalendar);

        };

        public getDateFromInput = ($input: any) => {
            return moment($input.val(), this.config.format);
        };

        public selectMonth = (e: any, month: any) => {
            this.displayDate.month(month);

            var dateStr = this.displayDate.format(this.config.format);


            this.$inputField.val(dateStr);
            this.$inputField.trigger('change');
            this.$inputField.blur();
            if (this.config.abEvent !== null) {
                this.app.getEventBus().publish(this.config.abEvent, this.createEventArgs({date: dateStr}));
            }
            this.removeDatePicker();
            return false;
        };

        public selectDay = (event: any, day: any) => {
            event.preventDefault();
            event.stopPropagation();

            this.displayDate.date(day);

            var dateStr = this.displayDate.format(this.config.format);


            this.$inputField.val(dateStr);
            this.$inputField.trigger('change');
            this.$inputField.blur();
            if (this.config.abEvent !== null) {
                this.app.getEventBus().publish(this.config.abEvent, this.createEventArgs({date: dateStr}));
            }
            this.removeDatePicker();
            return false;
        };

        public selectWeek = (event: any, week: any, year: any) => {


            this.displayDate.isoWeekYear(year).isoWeekday(1).isoWeek(week);


            var dateStr = this.displayDate.format(this.config.format);


            this.$inputField.val(dateStr);
            this.$inputField.trigger('change');
            this.$inputField.blur();
            if (this.config.abEvent !== null) {
                this.app.getEventBus().publish(this.config.abEvent, this.createEventArgs({date: dateStr}));
            }
            this.removeDatePicker();
            return false;

        };

        public nextMonth = (event: any, btn: any) => {
            event.stopPropagation();
            this.displayDate.add(1, 'month');
            this.renderCalendar();
            return false;
        };

        public previousMonth = (event: any, btn: any) => {
            event.stopPropagation();
            this.displayDate.subtract(1, 'month');
            this.renderCalendar();
            return false;
        };

        public nextYear = (event: any, btn: any) => {
            event.stopPropagation();
            this.displayDate.add(8, 'year');
            this.renderCalendar();
            return false;
        };

        public previousYear = (event: any, btn: any) => {
            event.stopPropagation();
            this.displayDate.subtract(8, 'year');
            this.renderCalendar();
            return false;
        };

        public selectYear = (event: any, year: any) => {
            event.stopPropagation();
            this.state = 'month';
            this.displayDate.isoWeekYear(year);
            this.renderCalendar();
            return false;
        };

        public showYears = (event: any) => {
            event.stopPropagation();
            this.state = 'year';
            this.renderCalendar();
            return false;
        };

        private isDateDisabled = (date: any) => {

            if (this.config.rangeStart !== null) {
                if (date.isBefore(this.config.rangeStart)) {
                    return true;
                }
            }
            if (this.config.blockWeekend === true && (date.isoWeekday() === 6 || date.isoWeekday() === 7)) {
                return true;
            }


            return false;
        };

        private renderCalendar = () => {
            var month = this.displayDate.format('MMMM');
            month = month.substr(0, 1).toUpperCase() + month.substr(1);
            var context = {
                month: month,
                year: this.displayDate.year(),
                state: this.state,
                currentYear: 0,
                currentMonth: 0,
                selectedYear: 0,
                selectedMonth: 0,
                type: '',
                years: [] as number[],
                weeks: [] as any[],
                months: [] as string[]
            };


            if (this.state === "year") {
                context.years = [];

                var year = this.displayDate.isoWeekYear();
                context.currentYear = moment().isoWeekYear();
                context.selectedYear = this.inputDate.isoWeekYear();
                for (var i = year - 4; i < year + 4; i++) {
                    context.years.push(i);
                }


            } else {
                context.type = this.config.type;

                if (this.config.type === "month") {
                    context.months = moment.months();
                    context.currentMonth = moment().month();
                    context.currentYear = moment().year();
                    context.selectedMonth = this.inputDate.month();
                    context.selectedYear = this.inputDate.year();
                }

                if (this.config.type === "day" || this.config.type === "week") {
                    var today = moment();
                    var weeks = [] as any[];

                    this.displayDate.date(1);

                    var weekDay = this.displayDate.weekday();
                    weeks[0] = {days: []};
                    for (var i = 0; i < weekDay; i++) {
                        weeks[0].days.push({
                            day: '',
                            state: '',
                            disabled: false
                        });
                    }
                    var daysInMonth = this.displayDate.endOf('month').date();
                    var week = 0;
                    for (i = 1; i <= daysInMonth; i++) {
                        if (weeks[week].days.length === 7) {
                            week++;
                            weeks[week] = {days: []};
                        }

                        this.displayDate.date(i);

                        weeks[week].week = this.displayDate.isoWeek();
                        weeks[week].year = this.displayDate.isoWeekYear();
                        var day = {
                            day: i,
                            state: '',
                            disabled: false
                        };

                        if (this.isDateDisabled(this.displayDate)) {
                            day.state += "is--disabled ";
                            day.disabled = true;
                        }
                        if (this.displayDate.isSame(today, 'day')) {
                            day.state += "datepicker-currentday ";
                        }
                        if (this.displayDate.isSame(this.inputDate, 'day')) {
                            day.state += "datepicker-selectedday ";
                        }

                        weeks[week].days.push(day);
                    }

                    context.weeks = weeks;
                }
            }

            this.$activeCalendar.html(this.calendarTemplate(context));

        };

        private updateCalendarFromInput = (e: KeyboardEvent) => {
            if (e.code === "Escape") {
                this.$inputField.blur();
                this.removeDatePicker();
                return;
            }

            var testDate = moment(this.$inputField.val(), this.config.format, false);
            if (testDate.isValid()) {
                this.inputDate = testDate;
                this.displayDate = moment(this.inputDate);
                this.renderCalendar();
            }
        };

        private checkBlurReason = (e: any) => {
            this.closeOnBlurTimer = setTimeout(() => {
                this.removeDatePicker();
            }, 300);
        };

        private openCalendarFromInput = ($input: any) => {
            if (this.$inputField !== null && this.$inputField.get(0) === $input.get(0)) {
                return;
            }
            // removeDatePicker();
            this.$inputField = $input;

            this.config = {
                format: $input.data('format'),
                type: $input.data('datepicker-type') || "day",
                abEvent: $input.data('event') || null,
                rangeStart: $input.data('rangestart') || null,
                rangeEnd: null,
                blockWeekend: false,
                allowedDates: null
            };
            if (this.config.rangeStart !== null) {
                this.config.rangeStart = moment(this.config.rangeStart, "YYYY-MM-DD");
            }

            this.state = 'month';
            if (this.$inputField.attr('placeholder') === undefined) {
                this.$inputField.attr('placeholder', this.config.format);
            }

            if (this.$inputField.val().length > 0) {
                this.inputDate = moment(this.$inputField.val(), this.config.format, true);
                if (this.config.type === "week") {
                    this.inputDate.startOf('isoWeek');
                }
            } else {
                this.inputDate = moment();
            }

            this.displayDate = moment(this.inputDate);


            this.$activeCalendar = $('<div class="datepicker input__datepicker"></div>');
            this.$activeCalendar.data('$scope', this);
            this.$activeCalendar.insertAfter($input);

            this.app.getEventBus().subscribe('click', this.checkInsideCalendar, this);

            $input.on('keyup.datepicker', this.updateCalendarFromInput.bind(this));
            $input.on('blur.datepicker', this.checkBlurReason.bind(this));
            this.renderCalendar();
        };

        private inputFocus = (e: any) => {
            this.openCalendarFromInput($(e.currentTarget));
        };

        private inputClick = (e: any) => {
            const $target = $(e.currentTarget);
            const $input = $target.parent().find('input').not('[readonly]');
            if ($input.length > 0) {
                this.openCalendarFromInput($input);
            }
        };


        private checkInsideCalendar = (element: any, eventData: any) => {
            let $parent = this.$activeCalendar.parent('.input__datepicker');
            if ($parent.length === 0) {
                $parent = this.$activeCalendar;
            }
            if (!Ambrero.AB.Utils.isDescendant($parent.get(0), element)) {
                this.removeDatePicker();
            } else {
                if (this.closeOnBlurTimer !== null) {
                    clearTimeout(this.closeOnBlurTimer);
                    this.closeOnBlurTimer = null;
                }
            }
        };

        private iconClick = (e: any) => {
            const currentTarget = e.currentTarget;
            const parent = currentTarget.parentNode;
            this.openCalendarFromInput($(parent.lastElementChild));
        }


        public _dispose = () => {
            $(document).off('focus.dateselector');
            $(document).off('click.dateselector');
            $(document).off('click.dateselectorIcon');
            this.app.getEventBus().unsubscribe('click', this.checkInsideCalendar);
        };

    }
}