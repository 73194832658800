namespace Simplex.WebComponents.Project.Report.Models {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class ReportCreate extends ABModel {

        public id?: string;
        public projectId?: string;
        public name?: string;
        public generalExplanation?: string;
        public planningExplanation?: string;
        public budgetExplanation?: string;


        constructor(
            id?: string,
            projectId?: string,
            name?: string,
            generalExplanation?: string,
            planningExplanation?: string,
            budgetExplanation?: string,
        ) {
            super();
            this.id = id;
            this.projectId = projectId;
            this.name = name;
            this.generalExplanation = generalExplanation;
            this.planningExplanation = planningExplanation;
            this.budgetExplanation = budgetExplanation;
        }
    }
}