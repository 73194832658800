namespace Simplex.WebComponents.Project {
  import WebComponent = Simplex.Decorators.WebComponent;
  import ProjectSummary = Simplex.Models.Project.ProjectSummary;
  import ListView = Simplex.WebComponents.LayoutComponents.ListView;
  import ListViewOptions = Simplex.WebComponents.LayoutComponents.ListViewOptions;
  import ListViewEvents = Simplex.WebComponents.LayoutComponents.ListViewEvents;
  import ConfirmDialog = Simplex.Components.ConfirmDialog;
  import APIResult = Simplex.Utils.APIResult;

  @WebComponent("ui-project-list")
  export class ProjectListView extends ListView<ProjectSummary> {
    private _notice: any;
    private _projectListViewFilter?: ProjectListViewFilter;

    public constructor() {
      super();
      var t = location.href.includes("admin/tenant");
      if (t) {
        super.tId = location.href.split("/").reverse()[1];
      }

      this._notice = this.app.getComponent("AmbreroComponents.Notice");
      this.addEventListener("action", this.onContextMenuClick);
    }

    protected onCustomEvent = (eventName: ListViewEvents, data: any): void => {
      switch (eventName) {
        case "ItemSelected":
          this.app.navigateTo(`/project/${data.id}`);
          break;
      }
    };

    private onArchiveProjectConfirmed = async (
      projectId: string
    ): Promise<void> => {
      const deleteResult = await this.request.delete<APIResult<void>>(
        `/api/project/${projectId}`
      );
      if (deleteResult.isSuccess) {
        await this.setPageNumber(0);
      } else {
        this._notice.addMessage(
          Messages("project.overview.archive.failed"),
          "warning"
        );
      }
    };

    private onArchiveProject = async (projectId: string): Promise<void> => {
      const confirmDialog = this.app.getComponent(
        "ConfirmDialog",
        Messages("project.overview.archive.confirm.title"),
        Messages("project.overview.archive.confirm.text")
      ) as ConfirmDialog;
      confirmDialog.show();
      confirmDialog.on("yes", () => this.onArchiveProjectConfirmed(projectId));
    };

    private onActivateProject = async (projectId: string): Promise<void> => {
      const result = await this.request.put<APIResult<void>>(
        `/api/project/${projectId}/activate`,
        null
      );
      if (result.isSuccess) {
        await this.setPageNumber(0);
      } else {
        this._notice.addMessage(
          Messages("project.overview.option.activate.failed"),
          "warning"
        );
      }
    };
    private onDeactivateProject = async (projectId: string): Promise<void> => {
      const result = await this.request.put<APIResult<void>>(
        `/api/project/${projectId}/deactivate`,
        null
      );
      if (result.isSuccess) {
        await this.setPageNumber(0);
      } else {
        this._notice.addMessage(
          Messages("project.overview.option.deactivate.failed"),
          "warning"
        );
      }
    };

    private onCopyProjectConfirmed = async (
      projectId: string
    ): Promise<void> => {
      const result = await this.request.post<APIResult<void>>(
        `/api/project/${projectId}/copy`,
        null
      );
      if (result.isSuccess) {
        this.app.navigateTo(`/project/${result.data.data}`);
      } else {
        this._notice.addMessage(
          Messages("project.overview.option.copy.failed"),
          "warning"
        );
      }
    };

    private onCopyProject = async (projectId: string): Promise<void> => {
      const confirmDialog = this.app.getComponent(
        "ConfirmDialog",
        Messages("project.overview.copy.confirm.title"),
        Messages("project.overview.copy.confirm.text")
      ) as ConfirmDialog;
      confirmDialog.show();
      confirmDialog.on("yes", () => this.onCopyProjectConfirmed(projectId));
    };

    private onContextMenuClick = async (event: Event): Promise<void> => {
      event.stopPropagation();
      const customEvent = event as CustomEvent;
      const target = customEvent.target as HTMLElement;
      if (!target) {
        return;
      }
      const projectId = target.getAttribute("project-id");
      if (!projectId) {
        return;
      }
      switch (customEvent.detail) {
        case "edit":
          this.app.navigateTo(`/project/${projectId}/edit`);
          break;
        case "details":
          this.app.navigateTo(`/project/${projectId}`);
          break;
        case "archive":
          await this.onArchiveProject(projectId);
          break;
        case "activate":
          await this.onActivateProject(projectId);
          break;
        case "deactivate":
          await this.onDeactivateProject(projectId);
          break;
        case "copy":
          await this.onCopyProject(projectId);
          break;
        case "toggleIsTemplate":
          await fetch(`/api/project/${projectId}/toggleIsTemplate`, {
            method: "POST",
          });
          this.setPageNumber(0);
          break;
      }
    };

    protected getListViewSettings(): ListViewOptions {
      if (!this._projectListViewFilter) {
        this._projectListViewFilter = new ProjectListViewFilter();
      }
      return new ListViewOptions(
        "jsTableContainer",
        true,
        Messages("project.listView.noResults"),
        null,
        {
          property: "name",
          direction: "ASC",
        },
        {
          listview: "overview__listview",
        },
        {
          path: "Project",
          file: "ListView/itemRow",
        },
        {
          path: "Project",
          file: "ListView/headerTemplate",
        },
        this._projectListViewFilter
      );
    }
  }
}
