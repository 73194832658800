namespace Simplex.WebComponents.LayoutComponents {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;

    @WebComponent("ui-dropdown-menu")
    export class DropdownMenu extends ABWebComponent {

        private _dropdownMenu?: HTMLElement;
        private readonly _dropdownMenuTemplate: TemplateCallback;
        private readonly _dropdownItemTemplate: TemplateCallback;
        private readonly _itemElements: HTMLElement[];
        private readonly _class: string| null = null;
        private _rendered: boolean = false;

        static get observedAttributes() {
            return ['disabled'];
        }
        
        public constructor() {
            super();
            this._dropdownMenuTemplate = this.app.getTemplate('WebComponents/LayoutComponents/DropdownMenu', 'DropdownMenu') as TemplateCallback;
            this._dropdownItemTemplate = this.app.getTemplate('WebComponents/LayoutComponents/DropdownMenu', 'DropdownMenuItem') as TemplateCallback;
            
            this._itemElements = [...this.querySelectorAll('ui-dropdown-menu-item').values()] as HTMLElement[];
            this._class = this.getAttribute('class');
        }

        render() {
            const title = Messages(this.getAttribute("title") ?? '');
            this.innerHTML = this._dropdownMenuTemplate({
                class: this._class,
                title: title,
                hideDropdownIcon: this.getAttribute("hideDropdownIcon") === '',
                icon: this.getAttribute("icon")
            });
            this.setAttribute("title", title);
            const targetList = this.querySelector('ul');
            if (targetList) {
                const parser = new DOMParser();
                
                this._itemElements.forEach(option => {
                    const title = option.getAttribute('title');
                    const subTitle = option.getAttribute('sub-title');
                    const element = parser.parseFromString(this._dropdownItemTemplate({
                        title: title ? Messages(title) : option.innerHTML,
                        subTitle: subTitle ? Messages(subTitle) : null,
                        selected: option.getAttribute("selected") === '',
                        hideOnEmptySelectedValue: option.getAttribute("hideOnEmptySelectedValue") === '',
                        hidden: option.getAttribute("hidden") === '',
                        disabled: option.getAttribute("disabled") === '',
                        icon: option.getAttribute("icon")
                    }), "text/html").body.firstElementChild as HTMLElement;
                    if (element) {
                        targetList.appendChild(element);
                        element.addEventListener("click", this.onItemClicked.bind(this, option.getAttribute("link"), option.getAttribute("action"), option.getAttribute("details")));
                    }
                });
            }
            this._rendered = true;
        }

        private closeDropdown = ():void => {
            if (!this._dropdownMenu) {
                return;
            }
            this._dropdownMenu.classList.remove("is--active");
        }

        private openDropdown = ():void => {
            if (!this._dropdownMenu) {
                return;
            }
            this._dropdownMenu.classList.add("is--active");
            document.addEventListener("click", (event):void => {
                this.closeDropdown();
            }, { once: true })
        }

        public setFilterTitle = (title:string):void => {
            const titleElement = this.querySelector('.dropdown-title') as HTMLElement;
            if(titleElement) {
                titleElement.innerHTML = title;
            }
        }

        public setHeaderBlock = (text:string):void => {
            const dropdownMenuItems = this.querySelector('.dropdown__menu__items');
            if(dropdownMenuItems) {
                const divHeader = document.createElement('DIV');
                divHeader.innerHTML = text;
                divHeader.classList.add('dropdown__menu__header-block');
                dropdownMenuItems.prepend(divHeader);
                
            }
        }

        public onToggleMenu = (event:MouseEvent):void => {
            event.preventDefault();
            event.stopPropagation();
            if (!this._dropdownMenu) {
                this._dropdownMenu = this.querySelector('.dropdown') as HTMLElement;
            }
            if (this._dropdownMenu) {
                if (this._dropdownMenu.classList.contains("is--active")) {
                    this.closeDropdown();
                } else {
                    this.openDropdown();
                }
            }
        }

        private setSelectedItem(event:Event, details: string|null) {
            let element = event.target as HTMLElement;
            if(element.tagName !==  'LI') {
                element = element.closest('LI') as HTMLElement;
            }
            const hideOnEmptySelectedValues = this.querySelectorAll('LI.hideOnEmptySelectedValue');
            if(hideOnEmptySelectedValues.length > 0) {
                if (details !== null && details !== '') {
                    hideOnEmptySelectedValues.forEach(e => e.classList.remove('hidden'));
                } else {
                    hideOnEmptySelectedValues.forEach(e => e.classList.add('hidden'));
                }
            }
            this.querySelectorAll('LI.selected').forEach(e=>e.classList.remove('selected'));
            if(element) {
                element.classList.add('selected');
            }
        }

        private bindElementEventListeners(): void {
            this.addEventListener("click", this.onToggleMenu);
        }

        private removeElementEventListeners(): void {
            this.removeEventListener("click", this.onToggleMenu);
        }
        
        onItemClicked = (link: string|null, action: string|null, details: string|null, event:MouseEvent):void => {
            event.preventDefault();
            event.stopPropagation();
            this.setSelectedItem(event, details);
            if (link) {
                this.app.navigateTo(link);
                this.dispatchEvent(new CustomEvent("navigated"));
            } else {
                this.dispatchEvent(new CustomEvent("action", {
                    detail: {
                        action,
                        details
                    },
                    bubbles: true
                }));
            }
            this.closeDropdown();
        }

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if(!this._rendered) {
                this.render();
                this.bindElementEventListeners();
            }
        }

        disconnectedCallback() {
            this.removeElementEventListeners();
        }

        attributeChangedCallback(name:string, oldValue:string, newValue:string) {
            if (name === 'disabled' && (newValue === '' || newValue === 'disabled')) {
                this.classList.add('disabled');
            } else {
                this.classList.remove('disabled');
            }
        }   
    }
}
