namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class EditSnapshotRequest extends ABModel {

        public name: string = '';
        public description: string = '';
        public baseline: boolean = false;
        
        constructor(snapshot?: Simplex.Models.Project.SnapshotSummary) {
            super();
            if (snapshot) {
                this.name = snapshot.name;
                this.description = snapshot.description;
                this.baseline = snapshot.baseline;
            }
        }
    }
}