namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class NewUser extends ABModel {

        public email: string;
        public projectManager: boolean;
        public responsibleManager: boolean;

        constructor(email: string = "", projectManager: boolean = false, responsibleManager: boolean = false) {
            super();
            this.email = email;
            this.projectManager = projectManager;
            this.responsibleManager = responsibleManager;
        }


    }
}