namespace Simplex.WebComponents.Project {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import ModelBinder = Ambrero.AB.Components.ModelBinder;
    import APIResult = Simplex.Utils.APIResult;
    import CostCategory = Simplex.Models.Project.CostCategory;


    @WebComponent("ui-edit-cost-category")
    export class EditCostCategory extends ABWebComponent implements Node{
        private readonly _contentTemplate:TemplateCallback;
        private readonly _binder: ModelBinder;
        private readonly _projectId: string;
        private readonly _depth: number = 0;
        private readonly _costCategory?: CostCategory;
        private readonly _children?: CostCategory[];
        private _toggle: HTMLElement | null = null;
        private _add: HTMLElement | null = null;
        private _addCostCategoryElement?: AddCostCategory;
        private readonly _editCostCategoryForm: EditCostCategoryForm;
        private _createCostCategoryForm?: EditCostCategoryForm;
        private _currentVisibleForm?: EditCostCategoryForm;
        private _rendered: boolean = false;

        private readonly childCostCategoryContainerClassName = '.childCostCategoryContainer';
        
        public constructor(projectId: string, children?: CostCategory[], costCategory?:CostCategory, depth?: number) {
            super();
            this._projectId = projectId ?? this.getAttribute("project-id");
            this._costCategory = costCategory;
            this._children = children;
            this._depth = depth ?? 0;
            this._contentTemplate = this.app.getTemplate('WebComponents/Project/EditCostCategories/EditCostCategory', 'EditCostCategory') as TemplateCallback;
            this._binder = this.app.getComponentType<ModelBinder>("ModelBinder")!;

            this._editCostCategoryForm = new Simplex.WebComponents.Project.EditCostCategoryForm(this._projectId, this._costCategory);
            this._editCostCategoryForm.addEventListener("costCategoryChanged", this.costCategoryChanged);
            this.addEventListener("sortStart", (event:Event) => this.onSortStartSetOrdering((event as CustomEvent).detail));
            this.addEventListener("sortEnd", (_:Event) => this.onSortEndSetOrdering());
        }
        
        async render() {
            this.setAttribute("depth", `${this._depth}`);
            if (this._costCategory && this._depth === 2) {
                this._costCategory.maxDepth = true;
            }
            this.innerHTML = this._contentTemplate({
                costCategory: this._costCategory
            });
            this._toggle = this.querySelector(".costCategory__details");
            this._add = this.querySelector(".addCostCategory");

            this._addCostCategoryElement = this.querySelector("ui-add-cost_category") as AddCostCategory;
            if (this._addCostCategoryElement) {
                this._addCostCategoryElement.addEventListener("expand", this.onAddCostCategoryExpand);
            }

            if(this._costCategory) {
                if (this._toggle) {
                    this._toggle.addEventListener("click", this.onToggle);
                }
                if (this._add) {
                    this._add.addEventListener("click", this.onAddCostCategory);
                }
            }
            if (this._children) {
                this.createChildrenCostCategoryElements(this._children);
            }

            const addCostCategories = this.querySelectorAll('ui-add-cost_category');
            if (addCostCategories && addCostCategories.length > 0) {
                const addCostCategory = addCostCategories[addCostCategories.length - 1] as AddCostCategory;
                addCostCategory.index = -1;
                addCostCategory.projectId = this._projectId;
                addCostCategory.parentId = this._costCategory?.id ?? Simplex.Utils.GUID_EMPTY;
            }
            if (!this._costCategory) {
                this.onAddCostCategory();
            }
            this._rendered = true;
        }

        private onAddCostCategoryExpand = (event:Event):void => {
            event.preventDefault();
            event.stopPropagation();
            const name = this._addCostCategoryElement!.getValue();
            if (this._createCostCategoryForm === undefined) {
                this._createCostCategoryForm = new Simplex.WebComponents.Project.EditCostCategoryForm(this._projectId);
                this._createCostCategoryForm.addEventListener("costCategoryChanged", this.costCategoryChanged);
            }

            this._createCostCategoryForm.costCategory.name = name ?? "";
            this._createCostCategoryForm.costCategory.index = this._children?.length ?? 0;
            this._createCostCategoryForm.costCategory.parentCostCategoryId = this._costCategory?.id ?? Simplex.Utils.GUID_EMPTY;

            this.removeAttribute("addsub");
            this.app.getEventBus().publish('closeCostCategory', Simplex.Utils.createEventArgs({container: this}, this));
            this.app.getEventBus().subscribe('closeCostCategory', this.closeCostCategory);

            this.setAttribute("open", "true");


            if (this._currentVisibleForm) {
                this._currentVisibleForm.replaceWith(this._createCostCategoryForm);
            } else {
                const editCostCategoryFormContainers = this.querySelectorAll('.editCostCategoryFormContainer');
                const editCostCategoryFormContainer = editCostCategoryFormContainers[editCostCategoryFormContainers.length - 1];
                if (editCostCategoryFormContainer === null) {
                    return;
                }
                editCostCategoryFormContainer.replaceWith(this._createCostCategoryForm);
            }
            this._currentVisibleForm = this._createCostCategoryForm;
        }

        private costCategoryChanged = (): void => {
            const isOpen = this.getAttribute("open");
            if(isOpen !== null){
                this.onToggle();
            }
        }

        private createEditCostCategoryFormContainer = ():void => {
            if (this._currentVisibleForm) {
                this._currentVisibleForm.replaceWith(this._editCostCategoryForm);
            } else {
                const editCostCategoryFormContainer = this.querySelector('.editCostCategoryFormContainer');
                if (editCostCategoryFormContainer === null) {
                    return;
                }
                editCostCategoryFormContainer.replaceWith(this._editCostCategoryForm);
            }
            this._currentVisibleForm = this._editCostCategoryForm;
        }

        private createChildrenCostCategoryElements = (children:CostCategory[]):void => {
            const costCategoriesContainer = this.querySelector(this.childCostCategoryContainerClassName);
            if (children.length === 0 || costCategoriesContainer === null) {
                if(costCategoriesContainer !== null && this._depth < 2) {
                    costCategoriesContainer.classList.add('empty');
                }
                this.setSortable();
                return;
            }
            for (let i = 0; i < children.length; i++){
                const costCategory = children[i];
                const editCostCategory = new Simplex.WebComponents.Project.EditCostCategory(this._projectId, costCategory.children, costCategory, this._depth + 1);
                costCategoriesContainer.appendChild(editCostCategory);
            }
            this.setSortable();
        }
        
        private onSortMove = (evt: any) => {
            if(evt.related.classList.contains('ignore-elements')) {
                return false;
            }
        }
        
        private onSortStart = (evt: any) => {
            const item = evt.item;
            let startDepth = parseInt(item.getAttribute('depth'),10);
            let depths:any[] = [];
            const children = item.querySelectorAll('ui-edit-cost-category');
            let difference = 0;
            if(children.length > 0) {
                children.forEach((child:any) => {
                    depths.push(parseInt(child.getAttribute('depth'),10));
                });
                difference = Math.max(...depths) - startDepth;
            }
            this.dispatchEvent(new CustomEvent("sortStart", {detail: difference}));
        }

        private onSortStartSetOrdering = (treeDepth: number) => {
            let querySelector:string = "ui-edit-cost-category[depth='2'] .childCostCategoryContainer.empty";
            switch(treeDepth) {
                case 1:
                    querySelector += ", ui-edit-cost-category[depth='2'], ui-edit-cost-category[depth='1'] .childCostCategoryContainer";
                    break;
                case 2:
                    querySelector += ", ui-edit-cost-category[depth='2'], ui-edit-cost-category[depth='1'], ui-edit-cost-category[depth='1'] .childCostCategoryContainer, ui-edit-cost-category[depth='0'] .childCostCategoryContainer";
                    break;
            }
            
            this.querySelectorAll(querySelector).forEach(element => {
                element.classList.add('ignore-elements');
            });
        }

        private onSortEndSetOrdering = () => {
            this.querySelectorAll(`ui-edit-cost-category, ${this.childCostCategoryContainerClassName}.empty`).forEach(element => {
                element.classList.remove('ignore-elements');
            });
        }
        
        private onSortEnd = async (event: Event): Promise<void> => {
            this.onSortEndSetOrdering();
            this.querySelectorAll('.drag-allowed').forEach(element => {
                element.classList.remove('drag-allowed');
            });
            const changeCostCategorySortingRequest = new Simplex.Models.Project.ChangeCostCategorySortingRequest();
            //@ts-ignore
            if (!event.to.parentElement._costCategory) {
                changeCostCategorySortingRequest.newParentCostCategoryId = Simplex.Utils.GUID_EMPTY;
            } else {
                //@ts-ignore
                changeCostCategorySortingRequest.newParentCostCategoryId = event.to.parentElement._costCategory.id!;
            }

            //@ts-ignore
            changeCostCategorySortingRequest.newIndex = event.newIndex;
            //@ts-ignore
            const costCategoryId = event.item._costCategory.id;

            const updateSortingResult = await this.request.put<APIResult<void>>(`/api/project/${this._projectId}/costCategories/${costCategoryId}/sorting`, changeCostCategorySortingRequest);
            if (updateSortingResult.isSuccess) {
                this.dispatchEvent(new Event("costCategoryChanged",{bubbles:true}));
            } else {
                alert("Fout?");
            }
        }
        
        private setSortable = () : void => {
            // @ts-ignore
            Sortable.create(this.querySelector(this.childCostCategoryContainerClassName), {
                sort: true,
                group: 'costCategories',
                swapThreshold: 0.65,
                fallbackOnBody: true,
                selectedClass: "is--active",
                ghostClass: "sortable-ghost",
                chosenClass: "sortable-chosen",
                dragClass: "sortable-drag",
                filter: ".ignore-elements",
                draggable: 'ui-edit-cost-category',
                handle: '.costCategory__handle',
                onMove: this.onSortMove,
                onEnd: this.onSortEnd,
                onStart: this.onSortStart
            });
        }

        private closeCostCategory = (): void => {
            if(this._costCategory) {
                this.removeAttribute("open");
                this.removeAttribute("addsub");
                this.app.getEventBus().unsubscribe('closeCostCategory', this.closeCostCategory);
            }
        }
        
        onToggle = (event?:MouseEvent) => {
            if(event) {
                event.preventDefault();
                event.stopPropagation();
            }
            
            const isOpen = this.getAttribute("open");
            this.removeAttribute("addsub");
            if (isOpen === null) {
                this.app.getEventBus().publish('closeCostCategory',  Simplex.Utils.createEventArgs( {container: this}, this));
                this.setAttribute("open","true");
                this.createEditCostCategoryFormContainer();
                this.app.getEventBus().subscribe('closeCostCategory', this.closeCostCategory);
            } else {
                this.app.getEventBus().unsubscribe('closeCostCategory', this.closeCostCategory);
                this.removeAttribute("open");
            }
        }

        onAddCostCategory = (event?:MouseEvent) => {
            if(event) {
                event.preventDefault();
                event.stopPropagation();
            }
            if (this._depth >= 2) {
                return;
            }
            const isOpen = this.getAttribute("open");
            
            if (isOpen !== null) {
                this.removeAttribute("open");
                this.removeAttribute("addsub");
                this.app.getEventBus().unsubscribe('closeCostCategory', this.closeCostCategory);
            } else {
                if (this._addCostCategoryElement) {
                    this._addCostCategoryElement.reset();
                    this._addCostCategoryElement.index = -1;
                    this._addCostCategoryElement.parentId = this._costCategory?.id || Simplex.Utils.GUID_EMPTY;
                    this._addCostCategoryElement.projectId = this._projectId;
                }

                this.app.getEventBus().publish('closeCostCategory',  Simplex.Utils.createEventArgs( {container: this}, this));
                this.app.getEventBus().subscribe('closeCostCategory', this.closeCostCategory, this);
                this.setAttribute("open","");
                this.setAttribute("addsub","");
                if(event) {
                    this._addCostCategoryElement?.focus();
                }
            }
            

        };

        async connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if (!this._rendered) {
                await this.render();
            }
        }
        disconnectedCallback() {
            this.app.getEventBus().unsubscribe('closeCostCategory', this.closeCostCategory);
        }
        
        get costCategoryId(): string | null {
            return this._costCategory?.id ?? Simplex.Utils.GUID_EMPTY;
        }
    }
}
