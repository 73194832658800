namespace Simplex.Components {
    import Application = Ambrero.AB.Application;
    import APIResult = Simplex.Utils.APIResult;
    
    export class AddCostCategoryModal extends ABComponent {
        private _app: Application;
        private _dialog?: HTMLDialogElement;
        private _costCategoryForm?: AddCostCategoryModalForm;
        private _request: Simplex.Components.FetchRequestHandler;
        private _projectId?: string
        public constructor(app: Application) {
            super();
            this._app = app;
            this._request = app.getComponentType<FetchRequestHandler>('FetchRequestHandler')!;
        }
        
        public show = async (projectId: string): Promise<void> => {
            this._projectId = projectId;
            this._dialog = this._app.applyTemplate('WebComponents/Project/Budget/BudgetTable/AddCostCategoryModal', 'AddCostCategoryModal', 
                {
                    projectId: projectId
                },
                $("body"),
                "prepend")[0] as HTMLDialogElement;
            
            const closeElements = [...this._dialog.querySelectorAll('.close').values()] as HTMLElement[];
            for (let closeElement of closeElements) {
                closeElement.addEventListener("click", this.hide);
            }

            this._dialog.querySelector('.save')?.addEventListener("click", this.save);
            this._costCategoryForm = this._dialog.querySelector('ui-add-cost-category-modal-form') as AddCostCategoryModalForm;
            this._dialog.addEventListener('close', (event) => {
                if(this._dialog) {
                    this._dialog.remove();
                }
            });
            this._dialog.showModal();
        }

        private hide = () => {
            if(this._dialog) {
                this._dialog.close();
            }
        }

        private save = async () => {
            if (this._costCategoryForm?.createModelValid) {
                const createModel = this._costCategoryForm?.createModel;
                const createResult = await this._request.post<APIResult<void>>(`/api/project/${this._projectId}/scopes/${createModel.subScopeId !== null && createModel.subScopeId !== '' ? createModel.subScopeId : createModel.scopeId}/budget`, createModel);
                if (createResult.isSuccess) {
                    this.emit("scopeCostCategoryCreated", Simplex.Utils.createEventArgs({}, this));
                    this.hide();
                }
            }
        }
    }
}