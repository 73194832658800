namespace Simplex.Components {
    export class QueueHandler {
        private readonly _items: any[];
        private _pendingPromise: boolean;

        constructor() {
            this._items = [];
            this._pendingPromise = false;
        }
                
        public enqueue = (action: any):Promise<any> => {
            return new Promise((resolve, reject) => {
                this.pushItem({ action,resolve,reject});
                this.dequeue();
            });
        }

        private dequeue = async ():Promise<boolean> => {
            if (this._pendingPromise) {
                return false;
            }

            let item = this.shiftItem();

            if (!item) {
                return false;
            }

            try {
                this._pendingPromise = true;
                let payload = await item.action(this);
                this._pendingPromise = false;
                item.resolve(payload);
            } catch (e) {
                this._pendingPromise = false;
                item.reject(e);
            } finally {
                await this.dequeue();
            }

            return true;
        }
        
        private pushItem = (item:any) => {
            this._items.push(item);
        }

        private shiftItem = () => {
            return this._items.shift();
        }
    }
}