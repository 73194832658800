namespace Simplex.WebComponents.Project.Dashboard {
  import WebComponent = Simplex.Decorators.WebComponent;
  import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
  import Auth = Simplex.Components.Auth;
  import ABWebComponent = Simplex.Components.ABWebComponent;
  import EventArgs = Ambrero.AB.Components.EventArgs;
  import APIResult = Simplex.Utils.APIResult;
  import ModelBinder = Ambrero.AB.Components.ModelBinder;
  import PopupContainer = Simplex.Components.PopupContainer;
  import NewUser = Simplex.Models.Project.NewUser;
  import Button = Simplex.WebComponents.FormElements.Button;
  import ContextMenu = Simplex.WebComponents.LayoutComponents.ContextMenu;
  import ConfirmDialog = Simplex.Components.ConfirmDialog;

  @WebComponent("ui-dashboard-users")
  export class DashboardUsers extends ABWebComponent {
    private readonly contentTemplate;
    private readonly users: DashboardUser[];
    private readonly projectId: string;
    private readonly auth: Auth;
    private readonly _popupTemplate;
    private readonly _popupContainer: PopupContainer;
    private readonly _newUserModel: Simplex.Models.Project.NewUser;
    private readonly _binder: ModelBinder;
    private addButton: HTMLElement | null = null;
    private _inviteButton?: Button;
    private readonly _notice: any;

    private get canAddUser() {
      //@ts-ignore
      const userContext = window.userContext as {
        projects: { projectId: string; roles: string[] }[];
      };

      var currProj = userContext.projects.find(
        (x) => x.projectId == this.projectId
      );
      if (!currProj) return false;

      const res = currProj.roles.includes("ProjectManager");
      return res;
    }

    public constructor() {
      super();
      this.contentTemplate = this.app.getTemplate(
        "WebComponents/Project/Dashboard/ProjectDashboardUsers",
        "DashboardUsers"
      ) as TemplateCallback;
      this.auth = this.app.getSystemComponent("Auth") as Auth;
      this.users = [];
      this.projectId = this.getAttribute("project-id") || "";

      this._newUserModel = new Simplex.Models.Project.NewUser();
      this._binder = this.app.getComponentType<ModelBinder>("ModelBinder")!;

      this._popupTemplate = this.app.getTemplate(
        "WebComponents/Project/Dashboard/ProjectDashboardUsers",
        "NewUserPopup"
      ) as TemplateCallback;
      const popupContext = {};
      this._popupContainer = this.app.getComponentType<PopupContainer>(
        "PopupContainer",
        "rightof",
        this._popupTemplate,
        popupContext
      )!;
      this._notice = this.app.getComponent("AmbreroComponents.Notice");

      this._popupContainer.on("created", this.onNewUserPopupCreated);
      this._binder.bind(this._newUserModel, this._popupContainer);
    }

    onNewUserPopupCreated = (eventArgs: EventArgs): void => {
      const element = eventArgs.data.element as HTMLElement;
      this._inviteButton = element.querySelector("ui-button") as Button;
      if (this._inviteButton) {
        this._inviteButton.addEventListener("click", this.onPopupAddClicked);
      }
    };

    onPopupAddClicked = async (): Promise<void> => {
      if (!this._binder.validate(this._newUserModel)) {
        return;
      }
      const newUser = new NewUser(
        this._newUserModel.email,
        this._newUserModel.projectManager,
        this._newUserModel.responsibleManager
      );
      this._binder.reset(this._newUserModel);

      if (this._inviteButton) {
        this._inviteButton.loading = true;
      }

      const createResult = await this.request.post<APIResult<void>>(
        `/api/project/${this.projectId}/users`,
        newUser
      );

      if (this._inviteButton) {
        this._inviteButton.loading = false;
      }

      if (createResult.isSuccess) {
        this._popupContainer.hidePopup();
        await this.render();
      } else {
        if (createResult.data.messages.length > 0) {
          this._notice.addMessage(
            Messages(createResult.data.messages[0].key),
            "warning"
          );
        }
      }
    };

    getProjectUsers = async (): Promise<
      Simplex.Models.Project.ProjectUser[] | undefined
    > => {
      const result = await this.request.get<
        APIResult<Simplex.Models.Project.ProjectUser[]>
      >(`/api/project/${this.projectId}/users`);
      if (!result.isSuccess) {
        return undefined;
      }
      return result.data.data;
    };

    async render() {
      const projectUsers = await this.getProjectUsers();
      if (projectUsers) {
        const currentUserId = this.auth.userId();
        this.users.length = 0;
        for (let i = 0; i < projectUsers.length; i++) {
          const projectUser = projectUsers[i];
          this.users.push(
            new Simplex.WebComponents.Project.Dashboard.DashboardUser(
              projectUser.id,
              projectUser.firstName,
              projectUser.lastName,
              projectUser.email,
              projectUser.projectManager,
              projectUser.responsibleManager,
              projectUser.id === currentUserId
            )
          );
        }
      }

      this.innerHTML = this.contentTemplate({
        users: this.users,
        canAddUser: this.canAddUser,
      });
      const links = [
        ...this.querySelectorAll("ui-context-menu"),
      ] as ContextMenu[];
      for (let i = 0; i < links.length; i++) {
        const link = links[i];
        const index = link.dataset["index"];
        if (index !== undefined) {
          link.addEventListener(
            "action",
            this.onUserAction.bind(this, this.users[parseInt(index)])
          );
        }
      }
      this.addButton = this.querySelector("div.add") as HTMLElement;
      if (this.addButton) {
        this.addButton.addEventListener("click", this.onAddNewUser);
      }
    }

    onAddNewUser = (event: MouseEvent): void => {
      event.preventDefault();
      event.stopPropagation();
      this._popupContainer.showPopup(this.addButton!);
    };

    private onDeleteUserConfirmed = async (
      user: UserListUser
    ): Promise<void> => {
      const deleteResult = await this.request.delete<APIResult<void>>(
        `/api/project/${this.projectId}/users/${user.id}`
      );
      if (deleteResult.isSuccess) {
        await this.render();
      } else {
        // notice!
        alert("Fout?!");
      }
    };

    onUserAction = async (user: UserListUser, event: Event): Promise<void> => {
      event.preventDefault();
      event.stopPropagation();
      const customEvent = event as CustomEvent;
      const action = customEvent.detail;

      switch (action) {
        case "remove":
          const confirmDialog = this.app.getComponent(
            "ConfirmDialog",
            Messages("project.dashboard.users.delete.confirm.title"),
            Messages(
              "project.dashboard.users.delete.confirm.text",
              user.firstName ? `${user.firstName} ${user.lastName}` : user.email
            )
          ) as ConfirmDialog;
          confirmDialog.show();
          confirmDialog.on("yes", () => this.onDeleteUserConfirmed(user));
          break;

        case "removeProjectManager":
        case "addProjectManager":
          user.projectManager = !user.projectManager;
          const updateProjectManagerResult = await this.request.put<
            APIResult<void>
          >(`/api/project/${this.projectId}/users/${user.id}/ProjectManager`, {
            projectManager: user.projectManager,
          });
          if (updateProjectManagerResult.isSuccess) {
            await this.render();
          } else {
            // notice!
            alert("Fout?!");
          }
          break;

        case "removeResponsibleManager":
        case "addResponsibleManager":
          user.responsibleManager = !user.responsibleManager;
          const updateResponsibleManagerResult = await this.request.put<
            APIResult<void>
          >(
            `/api/project/${this.projectId}/users/${user.id}/ResponsibleManager`,
            { responsibleManager: user.responsibleManager }
          );
          if (updateResponsibleManagerResult.isSuccess) {
            await this.render();
          } else {
            // notice!
            alert("Fout?!");
          }
          break;
      }

      //this.dispatchEvent(new CustomEvent<UserMutationEvent>("change", { detail:{ user: user, action: action } }))
    };

    async connectedCallback() {
      if (!this.isConnected) {
        return;
      }

      await this.render();
    }
  }
}
