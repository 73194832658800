namespace Simplex.Models.Authentication {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class Register extends ABModel {

        public email: string;

        constructor(email: string = "") {
            super();
            this.email = email;
        }
    }
}