/// <reference path="../Declarations/Index.d.ts" />
/// <reference path="../Decorators/Decorators.d.ts" />


/* COPYRIGHT (C) Ambrero Software B.V. - All rights reserved
 * This file is part of the Ambrero Base Framework and supplied under license.
 * Unauthorized copying of this file is strictly prohibited.
 * It is permitted to make changes to this file, as long as it is only used in this project.
 */
namespace Simplex.Components {
    import Application = Ambrero.AB.Application;

    @Simplex.Decorators.Singleton()
    export class Monitor {

        private readonly app: Application;
        private listeners: Listener[] = [];
        private observer: MutationObserver | null = null;

        public constructor(app: Application) {
            this.app = app;
        }

        private readonly queryDom = (selector: string, context?: Document): HTMLElement[] => {
            context = context || document;
            // Redirect simple selectors to the more performant function
            if (/^(#?[\w-]+|\.[\w-.]+)$/.test(selector)) {
                switch (selector.charAt(0)) {
                    case '#':
                        // Handle ID-based selectors
                        const elements: HTMLElement[] = [];
                        const element = context.getElementById(selector.substr(1));
                        if (element != null) {
                            elements.push(element);
                        }
                        return elements;
                    case '.':
                        // Handle class-based selectors
                        // Query by multiple classes by converting the selector
                        // string into single spaced class names
                        const classes = selector.substr(1).replace(/\./g, ' ');
                        return [].slice.call(context.getElementsByClassName(classes));
                    default:
                        // Handle tag-based selectors
                        return [].slice.call(context.getElementsByTagName(selector));
                }
            }
            // Default to `querySelectorAll`
            return [].slice.call(context.querySelectorAll(selector));
        }

        private readonly checkSelectors = (): void => {
            for (let i = 0, len = this.listeners.length, listener: Listener, elements; i < len; i++) {
                listener = this.listeners[i];
                elements = this.queryDom(listener.selector);
                const elementCount = elements.length;
                for (let j = 0; j < elementCount; j++) {
                    const element = elements[j];
                    const trackedId = `tracked_${listener.id}`;
                    if (element !== null && !element.hasAttribute(trackedId)) {
                        element.setAttribute(trackedId, "true");
                        listener.fn.call(element, element, true);
                        listener.tracked.push(element);
                    }
                }
                for (let j = listener.tracked.length - 1; j >= 0; j--) {
                    let found = false;
                    for (let k = 0; k < elementCount; k++) {
                        if (listener.tracked[j] === elements[k]) {
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        listener.fn.call(listener.tracked[j], listener.tracked[j], false);
                        listener.tracked.splice(j, 1);
                    }
                }
            }
        }

        public addWatch(selector: string, cb: CallBackFunction): void {
            this.listeners.push(new Listener(selector, cb, [], Ambrero.AB.Utils.Identifier(4)));
            if (this.observer === null && window.MutationObserver) {
                this.observer = new window.MutationObserver(this.checkSelectors);
                if (this.observer !== null) {
                    this.observer.observe(document.documentElement, {
                        childList: true,
                        subtree: true
                    });
                }
            }
            this.checkSelectors();
        }

        public removeWatch(selector: string, cb: CallBackFunction): void {

            const index = this.listeners.findIndex(function (item) {
                return (item.selector === selector && item.fn === cb);
            });
            if (index !== undefined) {
                this.listeners.splice(index, 1);
            }
        }

        public _dispose = (): void => {
            this.listeners.length = 0;
        }
    }

    type CallBackFunction = (element: HTMLElement, newElement: boolean) => void;

    class Listener {
        constructor(public selector: string, public fn: CallBackFunction, public tracked: HTMLElement[], public id: string) {
        }
    }
}