namespace Simplex.Models.Authentication {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class Profile extends ABModel {

        public firstName: string;
        public lastName: string;
        public language: string | null;
        public numberFormat: string | null;
        public dateFormat: string | null;
        public timeFormat: string | null;


        constructor(firstName: string = "", lastName: string = "",
                    language: string | null = null,
                    numberFormat: string | null = null,
                    dateFormat: string | null = null,
                    timeFormat: string | null = null) {
            super();
            this.firstName = firstName;
            this.lastName = lastName;
            this.language = language;
            this.numberFormat = numberFormat;
            this.dateFormat = dateFormat;
            this.timeFormat = timeFormat;
        }
    }
}