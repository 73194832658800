/// <reference path="../Declarations/Handlebars.d.ts" />

namespace Simplex.HandlebarHelper {
	export function reduceOp(args: IArguments, reducer: any): any {
		const params: any[] = Array.from(args);
		params.pop(); // => options
		const first = params.shift();
		return params.reduce(reducer, first);
	}
}

Handlebars.registerHelper({
	eq  : function(){ return Simplex.HandlebarHelper.reduceOp(arguments, (a: any,b: any) => a === b); },
	ne  : function(){ return Simplex.HandlebarHelper.reduceOp(arguments, (a: any,b: any) => a !== b); },
	lt  : function(){ return Simplex.HandlebarHelper.reduceOp(arguments, (a: any,b: any) => a  <  b); },
	gt  : function(){ return Simplex.HandlebarHelper.reduceOp(arguments, (a: any,b: any) => a  >  b); },
	lte : function(){ return Simplex.HandlebarHelper.reduceOp(arguments, (a: any,b: any) => a  <= b); },
	gte : function(){ return Simplex.HandlebarHelper.reduceOp(arguments, (a: any,b: any) => a  >= b); },
	and : function(){ return Simplex.HandlebarHelper.reduceOp(arguments, (a: any,b: any) => a  && b); },
	or  : function(){ return Simplex.HandlebarHelper.reduceOp(arguments, (a: any,b: any) => a  || b); },
	contains  : function(){ return Simplex.HandlebarHelper.reduceOp(arguments, (a: Array<any>,b: any) => a.indexOf(b) !== -1); },
});
