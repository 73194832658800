/// <reference path="../Declarations/Handlebars.d.ts" />

namespace Simplex.HandlebarHelper {

	import SafeString = hbs.SafeString;
	export const nl2br: Handlebars.HelperDelegate = function(text:string|null): SafeString  {
		if(text === null || text === '') {
			return new Handlebars.SafeString('');
		}
		text = Handlebars.Utils.escapeExpression(text);
		text = text.replace(/(\r\n|\n|\r)/gm, '<br/>');
		return new Handlebars.SafeString(text);
	}
}

Handlebars.registerHelper("nl2br", Simplex.HandlebarHelper.nl2br);
