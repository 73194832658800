namespace Simplex.WebComponents.LayoutComponents {
    import WebComponent = Simplex.Decorators.WebComponent;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;

    @WebComponent('ui-header-tab')
    export class HeaderTab extends ABWebComponent {

        private readonly _title: string = "";
        private _active: boolean = false;
        private readonly _link: string = "";
        private readonly _icon: string = "";
        private readonly _dropdown: boolean = false;
        private readonly _submenu: boolean = false;
        private readonly _dropdownMenu?: DropdownMenu;
        private _rendered: boolean = false;
        
        set active(state:boolean) {
            this._active = state;
            const isActive = this.firstElementChild!.classList.contains('is--active');
            if (!state && isActive) {
                this.firstElementChild!.classList.remove('is--active')
            }
            if (state && !isActive) {
                this.firstElementChild!.classList.add('is--active')
            }
        }
        
        get active() {
            return this._active;
        }

        public constructor() {
            super();
            this._title = Messages(this.getAttribute('title') ?? "");
            this._link = this.getAttribute('link') ?? "";
            this._active = this.getAttribute("active") === "";
            this._icon = this.getAttribute('icon') ?? "";
            this._dropdown = this.getAttribute('dropdown') !== null;
            this._submenu = this.getAttribute('submenu') === '';
            if(this._dropdown) {
                this._dropdownMenu = this.querySelector(`ui-dropdown-menu.${this.getAttribute('dropdown')}`) as DropdownMenu;
            }
        }
        
        private getIcon = (): string => {
            if(!this._icon) {
                return '';
            }
            return `<span class='icon'>${this._icon}</span>`;
        };
        
        private onDropdownMenuAction = async (_: Event): Promise<void> => {
            this.classList.add('is--active');
        }

        private bindElementEventListeners(): void {
            if (this._dropdownMenu) {
                this._dropdownMenu.addEventListener("navigated", this.onDropdownMenuAction);
            }
        }

        private removeElementEventListeners(): void {
            if (this._dropdownMenu) {
                this._dropdownMenu.removeEventListener("navigated", this.onDropdownMenuAction);
            }
        }
        
        render() {
            let submenu = '';
            if(this._submenu) {
                submenu = this.innerHTML;
            }
            if(!this._dropdown) {
                this.innerHTML = `<li class="tabs__item ${this.active ? "is--active": ""} "><a href="${this._link}">${this._title}${this.getIcon()}</a>${this._dropdownMenu ? this._dropdownMenu.outerHTML : ''}${submenu}</li>`;
            }
            
            this._rendered = true;
        }

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if(!this._rendered) {
                this.render();
                this.bindElementEventListeners();
            }
        }

        disconnectedCallback() {
            this.removeElementEventListeners();
        }
    }
}
