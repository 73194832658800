/// <reference path="../Declarations/Handlebars.d.ts" />

namespace Simplex.HandlebarHelper {
    let formattingHelper:Simplex.Components.FormattingHelper|null = null;
    
    export const formatCurrency: Handlebars.HelperDelegate = function (currency: string | null): string | null {
        if(!formattingHelper) {
            formattingHelper = Ambrero.AB.Application.Instance!.getSystemComponent('FormattingHelper') as Simplex.Components.FormattingHelper
        }
        return formattingHelper.convertCurrency(currency);
    }
}

Handlebars.registerHelper("formatCurrency", Simplex.HandlebarHelper.formatCurrency);
