namespace Simplex.Models.Authentication {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class Login extends ABModel {

        public username: string;
        public password: string;
        public code: string;
        
        constructor(username: string = "", password: string = "", code: string = "") {
            super();
            this.username = username;
            this.password = password;
            this.code = code;
        }
        
    }
}