namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class NewUserValidateResult extends ABModel {

        public valid: boolean;

        constructor(valid: boolean = false) {
            super();
            this.valid = valid;
        }


    }
}