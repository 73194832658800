namespace Simplex.Models.Authentication {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class AuthResult extends ABModel {

        public authenticated: boolean;
        public tenantSelect: boolean;
        
        constructor(authenticated: boolean = false, tenantSelect: boolean = false) {
            super();
            this.authenticated = authenticated;
            this.tenantSelect = tenantSelect;
        }
        
    }
}