namespace Simplex.WebComponents {
  import WebComponent = Simplex.Decorators.WebComponent;
  import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
  import ABWebComponent = Simplex.Components.ABWebComponent;
  import DropdownMenu = Simplex.WebComponents.LayoutComponents.DropdownMenu;
  import InputRangeSlider = Simplex.WebComponents.FormElements.InputRangeSlider;
  import SnapshotSummary = Simplex.Models.Project.SnapshotSummary;
  import Scope = Simplex.Models.Project.Scope;

  @WebComponent("ui-planning-filter")
  export class PlanningFilter extends ABWebComponent {
    private readonly _projectId: string;
    private readonly _contentTemplate;
    private _rendered: boolean = false;
    private _closeElement?: HTMLElement;
    private readonly _filterModelSessionStorageKey: string;
    private _filterModel: Simplex.WebComponents.Project.Planning.Models.FilterModel =
      new Simplex.WebComponents.Project.Planning.Models.FilterModel();
    private _snapshotsFilterDropdownMenu?: DropdownMenu;
    private _periodsFilterDropdownMenu?: DropdownMenu;
    private _scopeLevelSlider?: InputRangeSlider;
    private snapshots: SnapshotSummary[] = [];
    private _intervalDropdownMenu?: DropdownMenu;
    private _interval: string;

    public constructor(
      projectId: string,
      snapshots: SnapshotSummary[],
      scopes: Scope[],
      interval: string
    ) {
      super();
      this._projectId = projectId;
      this._filterModelSessionStorageKey =
        Simplex.Utils.getPlanningFilterModelCacheKey(this._projectId);
      this.snapshots = snapshots;
      this._interval = interval;
      this._contentTemplate = this.app.getTemplate(
        "WebComponents/Project/Planning/PlanningFilter",
        "PlanningFilter"
      ) as TemplateCallback;
    }

    public set compareWithSnapshotId(value: string | null) {
      this._filterModel.compareWithSnapshotId = value;
    }

    private setIntervalFilterTitle = (): void => {
      if (
        this._filterModel.compareWithSnapshotName &&
        this._filterModel.compareWithSnapshotName !== ""
      ) {
        this._snapshotsFilterDropdownMenu?.setFilterTitle(
          Messages(
            "project.details.selected_snapshot.label",
            this._filterModel.compareWithSnapshotName
          )
        );
      } else {
        this._snapshotsFilterDropdownMenu?.setFilterTitle(
          Messages("project.details.selected_snapshot.none.label")
        );
      }
      this._intervalDropdownMenu?.setFilterTitle(
        Messages(`project.details.interval.label.${this._interval}`)
      );
    };

    private onDropdownMenuAction = async (event: Event): Promise<void> => {
      const dropdownMenuEvent = event as CustomEvent;
      const compareWithSnapshotId = dropdownMenuEvent.detail.details;
      this._filterModel.compareWithSnapshotId = compareWithSnapshotId ?? "";
      const selectedSnapshot = this.snapshots.filter(
        (s) => s.id === compareWithSnapshotId
      );
      if (selectedSnapshot.length > 0) {
        this._filterModel.compareWithSnapshotName = selectedSnapshot[0].name;
      } else {
        this._filterModel.compareWithSnapshotName = "";
      }
      this.setIntervalFilterTitle();
    };

    private onIntervalDropdownMenuAction = async (
      event: Event
    ): Promise<void> => {
      const dropdownMenuEvent = event as CustomEvent;
      const interval = dropdownMenuEvent.detail.details;
      if (interval !== this._interval) {
        this._interval = interval;
      }
      this.setIntervalFilterTitle();
      localStorage.setItem("planningInterval" + this._projectId, interval);
    };

    render() {
      this.innerHTML = this._contentTemplate({
        filterModel: this._filterModel,
        snapshots: this.snapshots,
        snapshotsFilter: this.snapshots.length > 0,
        interval: this._interval,
      });
      this._intervalDropdownMenu = this.querySelector(
        "ui-dropdown-menu.interval__dropdown"
      ) as DropdownMenu;
      this._scopeLevelSlider = this.querySelector(
        'ui-input-range-slider[name="scopeLevel"]'
      ) as InputRangeSlider;
      this._snapshotsFilterDropdownMenu = this.querySelector(
        "ui-dropdown-menu.snapshots-filter"
      ) as DropdownMenu;
      this.setIntervalFilterTitle();

      const milestonesSwitch = this.querySelector(
        'input[name="milestones"]'
      ) as HTMLInputElement;
      if (milestonesSwitch) {
        milestonesSwitch.addEventListener("change", () => {
          this.dispatchEvent(
            new CustomEvent("filterChanged", {
              detail: { name: "milestones", value: milestonesSwitch.checked },
              bubbles: true,
            })
          );
        });
      }
      const criticalPathSwitch = this.querySelector(
        'input[name="criticalpath"]'
      ) as HTMLInputElement;
      if (criticalPathSwitch) {
        criticalPathSwitch.addEventListener("change", () => {
          this.dispatchEvent(
            new CustomEvent("filterChanged", {
              detail: {
                name: "criticalpath",
                value: criticalPathSwitch.checked,
              },
              bubbles: true,
            })
          );
        });
      }
      const snapshotsChangesOnlySwitch = this.querySelector(
        'input[name="snapshotsChangesOnly"]'
      ) as HTMLInputElement;
      if (snapshotsChangesOnlySwitch) {
        snapshotsChangesOnlySwitch.addEventListener("change", () => {
          this.dispatchEvent(
            new CustomEvent("filterChanged", {
              detail: {
                name: "snapshots",
                value: snapshotsChangesOnlySwitch.checked,
              },
              bubbles: true,
            })
          );
        });
      }
      const relationsSwitch = this.querySelector(
        'input[name="relations"]'
      ) as HTMLInputElement;
      if (relationsSwitch) {
        relationsSwitch.addEventListener("change", () => {
          this.dispatchEvent(
            new CustomEvent("filterChanged", {
              detail: { name: "relations", value: relationsSwitch.checked },
              bubbles: true,
            })
          );
        });
      }
      const budgetSwitch = this.querySelector(
        'input[name="budgets"]'
      ) as HTMLInputElement;
      if (budgetSwitch) {
        budgetSwitch.addEventListener("change", () => {
          this.dispatchEvent(
            new CustomEvent("filterChanged", {
              detail: { name: "budgets", value: budgetSwitch.checked },
              bubbles: true,
            })
          );
        });
      }
      const selectedSnapshot = this.snapshots.filter(
        (s) => s.id === this._filterModel.compareWithSnapshotId
      );
      this.setDropdownFilterTitle(
        this._snapshotsFilterDropdownMenu!,
        selectedSnapshot.length > 0 ? selectedSnapshot[0].name : "",
        "project.details.selected_snapshot.label",
        "project.details.selected_snapshot.none.label"
      );
      this.setDropdownFilterTitle(
        this._periodsFilterDropdownMenu!,
        "project.budget.periods." +
          Simplex.WebComponents.Project.Planning.Models.PlanningPeriodType[
            this._filterModel.periodType
          ],
        null,
        ""
      );

      this._closeElement = this.querySelector(".close") as HTMLElement;
      this._closeElement.addEventListener("click", this.close);

      this._rendered = true;
    }

    public onFilterChanged = () => {
      this.loadFilterModelFromSessionStorage();
      this.render();
      this.bindElementEventListeners();
    };

    private onSnapshotDropdownMenuAction = async (
      event: Event
    ): Promise<void> => {
      const dropdownMenuEvent = event as CustomEvent;
      const compareWithSnapshotId = dropdownMenuEvent.detail.details;
      this._filterModel.compareWithSnapshotId = compareWithSnapshotId ?? "";
      const selectedSnapshot = this.snapshots.filter(
        (s) => s.id === compareWithSnapshotId
      );
      this.setDropdownFilterTitle(
        this._snapshotsFilterDropdownMenu!,
        selectedSnapshot.length > 0 ? selectedSnapshot[0].name : "",
        "project.details.selected_snapshot.label",
        "project.details.selected_snapshot.none.label"
      );
      this.dispatchEvent(
        new CustomEvent("filterChanged", {
          detail: {
            name: "compareWithSnapshotId",
            value: this._filterModel.compareWithSnapshotId,
          },
          bubbles: true,
        })
      );
    };

    private onPeriodDropdownMenuAction = async (
      event: Event
    ): Promise<void> => {
      const dropdownMenuEvent = event as CustomEvent;
      this._filterModel.periodType = dropdownMenuEvent.detail
        .details as Simplex.WebComponents.Project.Planning.Models.PlanningPeriodType;
      localStorage.setItem(
        "planningInterval" + this._projectId,
        this._filterModel.periodType
      );
      this.setDropdownFilterTitle(
        this._intervalDropdownMenu!,
        "project.budget.periods." +
          Simplex.WebComponents.Project.Planning.Models.PlanningPeriodType[
            this._filterModel.periodType
          ],
        null,
        ""
      );
      this.dispatchEvent(
        new CustomEvent("filterChanged", {
          detail: { name: "periodType", value: this._filterModel.periodType },
          bubbles: true,
        })
      );
    };

    private onScopeLevelChange = (event: Event) => {
      const customEvent = event as CustomEvent;
      this.dispatchEvent(
        new CustomEvent("filterChanged", {
          detail: { name: "scopeLevel", value: customEvent.detail.level },
          bubbles: true,
        })
      );
    };

    private setDropdownFilterTitle = (
      dropdownMenu: DropdownMenu,
      filterName: string,
      filterPrefix: string | null,
      emptyName: string
    ): void => {
      if (filterName !== "") {
        dropdownMenu?.setFilterTitle(
          filterPrefix
            ? Messages(filterPrefix, filterName)
            : Messages(filterName)
        );
      } else {
        dropdownMenu?.setFilterTitle(Messages(emptyName));
      }
    };

    private async bindElementEventListeners(): Promise<void> {
      if (this._snapshotsFilterDropdownMenu) {
        this._snapshotsFilterDropdownMenu.addEventListener(
          "action",
          this.onSnapshotDropdownMenuAction
        );
      }
      if (this._intervalDropdownMenu) {
        this._intervalDropdownMenu.addEventListener(
          "action",
          this.onPeriodDropdownMenuAction
        );
      }

      this._scopeLevelSlider?.addEventListener(
        "levelChange",
        this.onScopeLevelChange
      );
    }

    private removeElementEventListeners(): void {
      if (this._snapshotsFilterDropdownMenu) {
        this._snapshotsFilterDropdownMenu.removeEventListener(
          "action",
          this.onSnapshotDropdownMenuAction
        );
      }
      if (this._intervalDropdownMenu) {
        this._intervalDropdownMenu.removeEventListener(
          "action",
          this.onPeriodDropdownMenuAction
        );
      }
      this._scopeLevelSlider?.removeEventListener(
        "levelChange",
        this.onScopeLevelChange
      );
    }

    private loadFilterModelFromSessionStorage = () => {
      const filterModelFromSessionStorage = localStorage.getItem(
        this._filterModelSessionStorageKey
      );
      if (filterModelFromSessionStorage) {
        const filterModelFromSessionStorageJson = JSON.parse(
          filterModelFromSessionStorage
        ) as Simplex.WebComponents.Project.Planning.Models.FilterModel;
        this._filterModel.compareWithSnapshotId =
          filterModelFromSessionStorageJson.compareWithSnapshotId;
        this._filterModel.compareWithSnapshotName =
          filterModelFromSessionStorageJson.compareWithSnapshotId;
        this._filterModel.scopeId =
          filterModelFromSessionStorageJson.compareWithSnapshotId;
        this._filterModel.periodType =
          filterModelFromSessionStorageJson.periodType;
        this._filterModel.scopeLevel =
          filterModelFromSessionStorageJson.scopeLevel ?? 3;
        this._filterModel.snapshots =
          filterModelFromSessionStorageJson.snapshots;

        this._filterModel.budgets = filterModelFromSessionStorageJson.budgets;
        this._filterModel.relations =
          filterModelFromSessionStorageJson.relations;
        this._filterModel.milestones =
          filterModelFromSessionStorageJson.milestones;
        this._filterModel.criticalpath =
          filterModelFromSessionStorageJson.criticalpath;
      } else {
        this._filterModel.scopeLevel = 3;
      }
    };

    private saveFilterModelToSessionStorage = () => {
      localStorage.setItem(
        this._filterModelSessionStorageKey,
        JSON.stringify(this._filterModel)
      );
    };

    public close = (): void => {
      this.classList.remove("is--open");
      this.dispatchEvent(new CustomEvent("planningFilterClose", {}));
    };

    private open = (): void => {
      this.classList.add("is--open");
    };
    public toggle = (): void => {
      this.classList.contains("is--open") ? this.close() : this.open();
    };

    async connectedCallback() {
      if (!this.isConnected) {
        return;
      }
      if (!this._rendered) {
        this.loadFilterModelFromSessionStorage();
        this.render();
        await this.bindElementEventListeners();
      }
    }
  }
}
