namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class CostCategoryCreated extends ABModel {

        public parentCostCategoryId: string|null;

        constructor(parentCostCategoryId: string|null = "") {
            super();
            this.parentCostCategoryId = parentCostCategoryId;
        }
    }
}