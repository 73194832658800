namespace Simplex.WebComponents.LayoutComponents {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import DropdownMenu = Simplex.WebComponents.LayoutComponents.DropdownMenu;
    import APIResult = Simplex.Utils.APIResult;
    import ConfirmDialog = Simplex.Components.ConfirmDialog;
    
    @WebComponent("ui-project-header")
    export class ProjectHeader extends ABWebComponent {
        private readonly contentTemplate;
        private readonly _projectId: string | null;
        private readonly _projectNumber: string | null;
        private readonly _projectName: string | null;
        private _dropdownMenu?: DropdownMenu;
        private _rendered: boolean = false;
        private _notice:any;
        
        public constructor() {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/ProjectHeader', 'ProjectHeader') as TemplateCallback;
            this._projectId = this.getAttribute("projectId");
            this._projectNumber = this.getAttribute("projectNumber");
            this._projectName = this.getAttribute("projectName");
            this._notice = this.app.getComponent('AmbreroComponents.Notice');    
        }

        private render(): void {
            this.innerHTML = this.contentTemplate({
                activeTab: this.getAttribute("activeTab") ?? "overview",
                backLink: this.getAttribute("backLink") ?? `/project/${this._projectId}`,
                projectId: this._projectId,
                projectNumber: this._projectNumber,
                projectName: this._projectName,
            });

            this._dropdownMenu = this.querySelector('ui-dropdown-menu.jsSettings') as DropdownMenu;
            this._rendered = true;
        }
        
        private onCopyProjectConfirmed = async (projectId: string): Promise<void> => {
            const result = await this.request.post<APIResult<void>>(`/api/project/${projectId}/copy`, null);
            if (result.isSuccess) {
                this.app.navigateTo(`/project/${result.data.data}`);
            } else {
                this._notice.addMessage(Messages('project.overview.option.copy.failed'), 'warning');
            }
        }

        private onDropdownMenuAction = (event:Event): void => {
            if(!this._projectId) {
                return;
            }
            const customEvent = event as CustomEvent;
            if(customEvent.detail.action === 'export') {
                this.app.navigateTo(`/api/project/${this._projectId}/export`, {download: true});
            }
            else if(customEvent.detail.action === 'copy') {
                const confirmDialog = this.app.getComponent('ConfirmDialog',
                    Messages('project.overview.copy.confirm.title'),
                    Messages('project.overview.copy.confirm.text')) as ConfirmDialog;
                confirmDialog.show();
                confirmDialog.on('yes', async () => await this.onCopyProjectConfirmed(this._projectId as string));
            }
        }

        private bindElementEventListeners(): void {
            if (this._dropdownMenu) {
                this._dropdownMenu.addEventListener("action", this.onDropdownMenuAction);
            }
        }

        private removeElementEventListeners(): void {
            if (this._dropdownMenu) {
                this._dropdownMenu.removeEventListener("action", this.onDropdownMenuAction);
            }
        }
        
        async connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if(!this._rendered) {
                this.render();
                this.bindElementEventListeners();
            }
        }

        disconnectedCallback() {
            this.removeElementEventListeners();
        }
    }
}
