namespace Simplex.WebComponents {
  import WebComponent = Simplex.Decorators.WebComponent;
  import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
  import ABWebComponent = Simplex.Components.ABWebComponent;
  import Auth = Simplex.Components.Auth;
  import DropdownMenu = Simplex.WebComponents.LayoutComponents.DropdownMenu;

  @WebComponent("ui-user")
  export class User extends ABWebComponent {
    private readonly contentTemplate;

    private readonly auth!: Auth;
    private _dropdownMenu?: DropdownMenu;
    private _rendered: boolean = false;

    public constructor() {
      super();
      this.contentTemplate = this.app.getTemplate(
        "WebComponents/User",
        "User"
      ) as TemplateCallback;
      this.auth = this.app.getSystemComponent("Auth") as Auth;
      this.auth.on("userLoggedIn", this.onUserLoggedIn);
      this.auth.on("sessionRefreshed", this.onUserLoggedIn);
    }

    private onUserLoggedIn = (): void => {
      this.render();
    };

    render() {
      //@ts-ignore
      this.innerHTML = this.contentTemplate({ userId: userContext?.user?.id });
      this._dropdownMenu = this.querySelector(
        "ui-dropdown-menu.JSuser"
      ) as DropdownMenu;
      if (this._dropdownMenu) {
        this._dropdownMenu.setHeaderBlock(
          `${this.auth.userFirstName()} ${this.auth.userLastName()}`
        );
      }
      this._rendered = true;
    }

    private onDropdownMenuAction = (event: Event): void => {
      const customEvent = event as CustomEvent;
      if (customEvent.detail.action === "profile") {
        this.app.navigateTo("/profile");
      } else if (customEvent.detail.action === "logout") {
        //this.app.navigateTo('/logout');
        var ev = new CustomEvent("on-logout", {
          bubbles: true,
          composed: true,
        });
        document.dispatchEvent(ev);
      }
    };
    private bindElementEventListeners(): void {
      if (this._dropdownMenu) {
        this._dropdownMenu.addEventListener(
          "action",
          this.onDropdownMenuAction
        );
      }
    }

    private removeElementEventListeners(): void {
      if (this._dropdownMenu) {
        this._dropdownMenu.removeEventListener(
          "action",
          this.onDropdownMenuAction
        );
      }
    }

    async connectedCallback() {
      if (!this.isConnected) {
        return;
      }
      if (!this._rendered) {
        this.render();
        this.bindElementEventListeners();
      }
    }

    disconnectedCallback() {
      this.removeElementEventListeners();
    }
  }
}
