/// <reference path="../Decorators/Decorators.d.ts" />

namespace Simplex.Components {
  import Application = Ambrero.AB.Application;
  import EventBus = Ambrero.AB.Components.EventBus;
  import FetchErrorHandler = Simplex.Components.FetchErrorHandler;

  @Simplex.Decorators.Singleton()
  export class FetchRequestHandler extends ABComponent {
    private app: Ambrero.AB.Application;
    private readonly fetchErrorHandler: FetchErrorHandler;
    private readonly baseHeaders: [[string, string]] = [
      ["content-type", "application/json"],
    ];
    private readonly requestInit: RequestInit = { credentials: "same-origin" };
    private readonly _errorField: string;
    private readonly _dataField?: string | undefined;
    private readonly requestQueue: QueueHandler;

    constructor(app: Application, errorField: string, dataField?: string) {
      super();
      this.app = app;
      this.requestQueue = new QueueHandler();
      this.fetchErrorHandler = this.app.getComponent(
        "FetchErrorHandler"
      ) as FetchErrorHandler;
      this._errorField = errorField;
      this._dataField = dataField;
    }

    public async get<T>(
      path: string,
      args: RequestInit = { method: "get" }
    ): Promise<HttpResponse<T>> {
      args = await this.createRequestInit(args);
      let response: HttpResponse<T> = {} as HttpResponse<T>;

      let task = () =>
        new Promise((resolve) => {
          resolve(this.request<T>(new Request(path, args)));
        });
      await this.requestQueue
        .enqueue(task)
        .then(async (requestResponse: any) => {
          response = requestResponse;
        });

      return response;
    }

    public async put<T>(
      path: string,
      body: any,
      args: RequestInit = { body: JSON.stringify(body), method: "put" }
    ): Promise<HttpResponse<T>> {
      args = await this.createRequestInit(args);
      let response: HttpResponse<T> = {} as HttpResponse<T>;
      let task = () =>
        new Promise((resolve) => {
          resolve(this.request<T>(new Request(path, args)));
        });
      await this.requestQueue
        .enqueue(task)
        .then(async (requestResponse: any) => {
          response = requestResponse;
        });

      return response;
    }

    public async post<T>(
      path: string,
      body?: any,
      args: RequestInit = { body: JSON.stringify(body), method: "post" }
    ): Promise<HttpResponse<T>> {
      args = await this.createRequestInit(args);
      let response: HttpResponse<T> = {} as HttpResponse<T>;
      let task = () =>
        new Promise((resolve) => {
          resolve(this.request<T>(new Request(path, args)));
        });
      await this.requestQueue
        .enqueue(task)
        .then(async (requestResponse: any) => {
          response = requestResponse;
        });

      return response;
    }

    public async delete<T>(
      path: string,
      args: RequestInit = { method: "delete" }
    ): Promise<HttpResponse<T>> {
      args = await this.createRequestInit(args);
      let response: HttpResponse<T> = {} as HttpResponse<T>;

      let task = () =>
        new Promise((resolve) => {
          resolve(this.request<T>(new Request(path, args)));
        });
      await this.requestQueue
        .enqueue(task)
        .then(async (requestResponse: any) => {
          response = requestResponse;
        });

      return response;
    }

    public async postModel<T>(
      model: Ambrero.AB.Models.Model,
      path: string,
      args: RequestInit = {
        body: JSON.stringify(model.getData()),
        method: "post",
      }
    ): Promise<HttpResponse<T>> {
      args = await this.createRequestInit(args);
      let response: HttpResponse<T> = {} as HttpResponse<T>;
      let task = () =>
        new Promise((resolve) => {
          resolve(this.request<T>(new Request(path, args)));
        });
      await this.requestQueue
        .enqueue(task)
        .then(async (requestResponse: any) => {
          response = requestResponse;
        });

      return response;
    }

    public async putModel<T>(
      model: Ambrero.AB.Models.Model,
      path: string,
      args: RequestInit = {
        body: JSON.stringify(model.getData()),
        method: "put",
      }
    ): Promise<HttpResponse<T>> {
      args = await this.createRequestInit(args);
      let response: HttpResponse<T> = {} as HttpResponse<T>;
      let task = () =>
        new Promise((resolve) => {
          resolve(this.request<T>(new Request(path, args)));
        });
      await this.requestQueue
        .enqueue(task)
        .then(async (requestResponse: any) => {
          response = requestResponse;
        });

      return response;
    }

    private async createRequestInit(args: RequestInit) {
      const newArgs: RequestInit = args;
      const csrf_token = $("meta[name='_csrf']").attr("content");
      const csrf_header = $("meta[name='_csrf_header']").attr("content");
      if (csrf_header != undefined && csrf_token != undefined) {
        const existingCsrfHeader = this.baseHeaders.findIndex(
          (h) => h[0] === csrf_header
        );
        if (existingCsrfHeader > -1) {
          this.baseHeaders[existingCsrfHeader] = [csrf_header, csrf_token];
        } else {
          this.baseHeaders.push([csrf_header, csrf_token]);
        }
      }

      newArgs.headers = new Headers(this.baseHeaders);

      newArgs.credentials = this.requestInit.credentials;
      return newArgs;
    }

    private async request<T>(request: RequestInfo): Promise<HttpResponse<T>> {
      let response: HttpResponse<T> = {} as HttpResponse<T>;
      await fetch(request)
        .then(async (rawResponse) => {
          const isJson = rawResponse.headers
            .get("content-type")
            ?.includes("json");
          const body = isJson
            ? await rawResponse.json()
            : await rawResponse.text();

          if (this._dataField != undefined) {
            (<any>response)[this._dataField] = body;
          } else {
            response.data = body;
          }

          response.status = rawResponse.status;
          response.isSuccess = rawResponse.ok;

          if (!response.isSuccess) {
            await this.requestError<T>(response);
          }
        })
        .catch(async (error) => {
          this.app.getLogger().error("Request failed", error);
          response.isSuccess = false;
          const isJson = error.headers.get("content-type")?.includes("json");
          response.errors = isJson ? await error.json() : await error.text();
          await this.requestError<T>(response);
        });

      return response;
    }

    private async requestError<T>(response: HttpResponse<T>) {
      const eventArgs = {
        data: (response as { [key: string]: any })[this._errorField] as string,
        messages: [""],
      };

      switch (response.status) {
        case 404:
          eventArgs.messages = ["error.notFound"];
          // this.app.getEventBus().publish('requestFetchNotFound', EventBus.createEventArgs(eventArgs, this));
          break;
        case 401:
          eventArgs.messages = [""];
          this.app
            .getEventBus()
            .publish(
              "requestAuthenticationRequired",
              EventBus.createEventArgs(eventArgs, this)
            );
          break;
        default:
          eventArgs.messages = [
            ((response as { [key: string]: any })[
              this._errorField
            ] as string) ?? Messages("error.loaddata"),
          ];
          //this.app.getEventBus().publish('requestFetchError',  EventBus.createEventArgs(eventArgs, this));
          break;
      }
    }
  }
}
