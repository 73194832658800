/// <reference path="../Declarations/Handlebars.d.ts" />

namespace Simplex.HandlebarHelper {
	let formattingHelper:Simplex.Components.FormattingHelper|null = null;
	let dateFormat:string = 'DD-MM-YYYY';
	let timeFormat:string = 'HH:mm';
	
	const resolveInputFormat = function(specifiedFormat: string): string {
		switch (specifiedFormat) {
			case 'localDateTime':
				return "YYYY-MM-DD[T]HH:mm:ss";
			default:
				return specifiedFormat;
		}
	}

	const resolveOutputFormat = function(specifiedFormat: string): string {
		switch (specifiedFormat) {
			case 'dateTime':
				return `${dateFormat} ${timeFormat}`;
			case 'time':
				return timeFormat;
			case 'date':
				return dateFormat;
			default:
				return specifiedFormat;
		}
	}

	export const formatDate: Handlebars.HelperDelegate = function(value:string|null, inputFormat:string, outputFormat:string): string|null  {
		if(value === null || value === '') {
			return value;
		}		
		if(!formattingHelper) {
			formattingHelper = Ambrero.AB.Application.Instance!.getSystemComponent('FormattingHelper') as Simplex.Components.FormattingHelper
		}
		dateFormat = formattingHelper.dateFormat;
		timeFormat = formattingHelper.timeFormat;
		const valueAsDate = moment(value, resolveInputFormat(inputFormat));
		if (valueAsDate.isValid()) {
			return valueAsDate.format(resolveOutputFormat(outputFormat));
		} else {
			return "";
		}
	}
}

Handlebars.registerHelper("formatDate", Simplex.HandlebarHelper.formatDate);
