namespace Simplex.WebComponents {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;


    @WebComponent("ui-budget-filter-tag")
    export class FilterTag extends ABWebComponent {
        private readonly contentTemplate;
        private _rendered: boolean = false;
        private key: string;
        private label: string;

        public constructor(key: string, label: string) {
            super();
            this.key = key;
            this.label = label;
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/Budget/BudgetFilter', 'FilterTag') as TemplateCallback;
        }

        public updateLabel = (label: string) => {
            this.label = label;
            this.render();
        }
        
        private onRemoveTagClick = (event: Event) => {
            this.dispatchEvent(new CustomEvent('removeFilterOption', {detail: {key: this.key}}));
            this.dispatchEvent(new CustomEvent('refreshFilter', {detail: {key: this.key}}));
        }
        
        public onRemove = () => {
            this.remove();
        }
        
        render() {
            this.innerHTML = this.contentTemplate({
                label: this.label
            });
            const close = this.querySelector('.JSclose') as HTMLElement;
            close.addEventListener('click', this.onRemoveTagClick);
            this._rendered = true;
        }

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if(!this._rendered) {
                this.render();
            }
        }
    }
}