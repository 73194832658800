/// <reference path="../Declarations/Index.d.ts" />

/* COPYRIGHT (C) Ambrero Software B.V. - All rights reserved
 * This file is part of the Ambrero Base Framework and supplied under license.
 * Unauthorized copying of this file is strictly prohibited.
 * It is permitted to make changes to this file, as long as it is only used in this project.
 */

namespace Simplex.Components {
    import EventArgs = Ambrero.AB.Components.EventArgs;
    export class FormFields {
        private readonly formElement:string = '.input';
        private readonly warningElement:string = '.input__warning';
        private readonly errorClass:string = 'has--warning';


        public setFieldError = (eventArgs: EventArgs):void => {
            const element = eventArgs.data.element;
            const formGroup = $(element).closest(this.formElement);
            formGroup.addClass(this.errorClass);
            if (eventArgs.data.error && eventArgs.data.error !== "") {
                formGroup.find(this.warningElement).text(Messages(eventArgs.data.error));
            }
        }
        
        public setElementError = (selector:string, message:string):void => {
            const formGroup = $(selector).closest(this.formElement);
            formGroup.addClass(this.errorClass);
            
            formGroup.find(this.warningElement).text(Messages(message));
        }        

        public clearFieldError = (eventArgs: EventArgs): void => {
            const element = $(eventArgs.data.element);
            element.closest(this.formElement).removeClass(this.errorClass);
        }

        public addModel = (model: any):void => {
            model.on('PropertyValidated', this.clearFieldError);
            model.on('PropertyError', this.setFieldError);
            model.on('PropertyChanged', this.clearFieldError);
        }
        
        public setFormError = (container: any, error: string):void => {
            const $container = $(container);
            $container.find(".formview").addClass('has--warning');
            $container.find(".formview__error__message").text(error);
        }
        
        public removeFormError = (container: any):void => {
            $(container).find(".formview").removeClass('has--warning');
        }
        private bottomWithFooter = function () {
            let compensation = 0;
            $('footer').each(function(i,el) {
                if($(el).css('position') === "fixed") {
                    compensation = $(el).outerHeight() as number;
                }
            })

            return (window.innerHeight || document.documentElement.clientHeight) - compensation;
        };
        public focusOnFirstError = (scope: any): boolean => {
            if(scope === undefined) {
                scope = $(document.body);
            }
            const errors = scope.find(this.formElement+"."+this.errorClass);
            if(errors.length === 0) {
                return false;
            }
            const rect = errors[0].getBoundingClientRect();
            // 4 px for topline
            if(rect.bottom <= 4 || rect.top >= this.bottomWithFooter()) {
                const warning = errors.find('.warning');
                if(warning.length > 0) {
                    warning[0].scrollIntoView(true);
                } else {
                    errors[0].scrollIntoView(true);
                }
            }
            return true;
        };

    }
}