namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class Data extends ABModel {

        public costCategoryId: string;
        public costCategoryName: string;
        public amount: number;
        public snapshotAmount: number | null;
        public scopeIds: string[];

    
        constructor(
            costCategoryId: string,
            costCategoryName: string,
            amount: number,
            snapshotAmount: number | null,
            scopeIds: string[]) {
            super();
            this.costCategoryId = costCategoryId;
            this.costCategoryName = costCategoryName;
            this.amount = amount;
            this.snapshotAmount = snapshotAmount;
            this.scopeIds = scopeIds;
        }
    }
}