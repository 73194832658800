namespace Simplex.WebComponents.LayoutComponents {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;

    @WebComponent("ui-hint")
    export class UIHint extends ABWebComponent {
        private readonly contentTemplate;
        public constructor() {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/LayoutComponents/UIHint', 'UIHint') as TemplateCallback;
        }

        private render(): void {
            const titleMessageKey = this.getAttribute("title-message-key");
            const textMessageKey = this.getAttribute("text-message-key");
            const buttonMessageKey = this.getAttribute("button-message-key");
            const icon = this.getAttribute("icon");

            this.innerHTML = this.contentTemplate({
                titleMessageKey : titleMessageKey,
                textMessageKey : textMessageKey,
                buttonMessageKey : buttonMessageKey,
                icon : icon
            });
            if(buttonMessageKey) {
                this.bindElementEventListeners();
            }
        }

        private readonly onButtonClicked = (event: Event): void => {
            this.dispatchEvent(new Event("click"));
        }

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            this.render();
        }

        disconnectedCallback() {
            this.removeElementEventListeners();
        }

        private bindElementEventListeners(): void {
            const button = this.querySelector('ui-button');
            if (button) {
                button.addEventListener('click', this.onButtonClicked);
            }
        }

        private removeElementEventListeners(): void {
            const button = this.querySelector('ui-button');
            if (button) {
                button.removeEventListener('click', this.onButtonClicked);
            }
        }
    }
}
