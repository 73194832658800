namespace Simplex.Components {
    import Application = Ambrero.AB.Application;

    export class ConfirmDialog extends ABComponent {

        private _app: Application;
        private readonly _title: string;
        private readonly _content: string;
        private readonly _yesButton: string;
        private readonly _noButton: string;
        private readonly _templatePath: string;
        private readonly _templateName: string;
        private readonly _type: string = "";
        private _dialog: HTMLDialogElement | null = null;

        constructor(app: Application, title: string, content: string, yesButton?: string, noButton?: string, type?:string) {
            super();
            this._app = app;
            this._title = title;
            this._content = content;
            this._yesButton = yesButton ?? Messages('shared.confirmDialog.yes');
            this._noButton = noButton ?? Messages('shared.confirmDialog.no');
            this._type = type ?? "";
            const parts = 'Shared/confirmDialog'.split('/');
            const templateName = parts.pop();

            this._templatePath = parts.join('/');
            this._templateName = templateName || "";
        }

        public show = (): void => {
            this._dialog = this._app.applyTemplate(this._templatePath,
                this._templateName,
                {
                    Title: this._title,
                    Content: this._content,
                    ButtonNo: this._noButton,
                    ButtonYes: this._yesButton
                },
                $("body"),
                "prepend")[0] as HTMLDialogElement;
            const yesButton = this._dialog.querySelector('.yes') as HTMLElement;
            yesButton?.addEventListener("click", this.yesClicked);
            this._dialog.querySelector('.no')?.addEventListener("click", this.noClicked);
            this._dialog.querySelector('.close')?.addEventListener("click", this.hide);
            this._dialog.addEventListener('close', (event) => {
                if(this._dialog) {
                    this._dialog.remove();
                }
            });

            if(this._type) {
                this._dialog.setAttribute(this._type,'');
            }
            this._dialog.showModal();
        }

        private hide = (event?:Event) => {
            event?.preventDefault();
            event?.stopPropagation();
            if(this._dialog) {
                this._dialog.close();
            }
        }

        private noClicked = (event:Event) => {
            event.preventDefault();
            event.stopPropagation();
            
            this.emit("no", Simplex.Utils.createEventArgs({},this));
            this.hide();
        }

        private yesClicked = (event:Event) => {
            event.preventDefault();
            event.stopPropagation();
            
            this.emit("yes", Simplex.Utils.createEventArgs({},this));
            this.hide();
        }
    }
}