namespace Simplex.WebComponents.FormElements {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    
    @WebComponent("ui-input-range-slider")
    export class InputRangeSlider extends ABWebComponent {
        private readonly contentTemplate;
        private input: HTMLInputElement|null = null;

        public constructor() {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/FormElements/InputRangeSlider', 'InputRangeSlider') as TemplateCallback;
        }
        
        render() {
            const ticks = [];
            let min = this.getAttribute("min");
            let max = this.getAttribute("max");
            if(min && max) {
                for(let i = parseInt(min); i <= parseInt(max); i++) {
                    ticks.push(i);
                }
            }
            
            this.innerHTML = this.contentTemplate({
                name: this.getAttribute("name"),
                value: this.getAttribute("value"),
                label: this.getAttribute("label"),
                help: this.getAttribute("help"),
                class: this.getAttribute("class"),
                tabindex: this.getAttribute("tabindex"),
                reverse: this.getAttribute("reverse") === "",
                min: this.getAttribute("min"),
                max: this.getAttribute("max"),
                disabled: this.getAttribute("disabled") === "",
                hidden: this.getAttribute("hidden") === "",
                ticks: ticks
            });

            this.input = this.querySelector("input");
            if(this.input)
            {
                this.input.addEventListener('change', this.change);
            }
        }
        
        private change = (): void => {
            if(this.input) {
                this.dispatchEvent(new CustomEvent("levelChange", {bubbles: true, detail: {level: parseInt(this.input.value)}}))
            }
        }
        
        public setValue = (value: any): void => {
            if(!this.input) {
                return;
            }
            this.input.value = value;
            this.change();
        }
        
        connectedCallback() {
            if (!this.isConnected) {
                return;
            }

            this.render();
        }
    }
}
