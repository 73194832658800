namespace Simplex.WebComponents.Project.ProjectDashboardTasks {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import APIResult = Simplex.Utils.APIResult;
    import Emitter = Ambrero.AB.Components.Emitter;

    @WebComponent("ui-dashboard-project-tasks-widget")
    export class ProjectDashboardTasksWidget extends ABWebComponent {
        private readonly _contentTemplate;
        private readonly _projectId: string;

        public constructor() {
            super();
            this._projectId = this.getAttribute("project-id") || "";
            this._contentTemplate = this.app.getTemplate('WebComponents/Project/Dashboard/ProjectDashboardTasks', 'ProjectDashboardTasks') as TemplateCallback;
            this.addEventListener("taskDeleted", this.taskDeleted);
        }
        
        public refresh = async (): Promise<void> => {
            await this.render();
        }        

        private getProjectTasks = async (): Promise<Simplex.Project.Models.ProjectTask[] | undefined> => {

            const result = await this.request.get<APIResult<Simplex.Project.Models.ProjectTask[]>>(`/api/project/${this._projectId}/tasks`);
            if (!result.isSuccess) {
                return undefined;
            }
            return result.data.data ?? undefined;
        }

        public async taskDeleted(event:Event) {
            event.preventDefault();
            event.stopPropagation();
            await this.render();
        }

        public async render() {
            const tasks = await this.getProjectTasks();
            if (!tasks) {
                return;
            }
            tasks.forEach(t => {
                const priorityByTypeIndex = Simplex.Project.Models.ProjectTask.getPriorityByType(t.type);
                t.priority = Simplex.Project.Models.ProjectTaskPriorityType[priorityByTypeIndex];
            });
            this.innerHTML = this._contentTemplate(
                {
                    data: {
                        tasksTodo: tasks.filter(t => t.completedDate === null).length
                    }
                });
            const tasksContainer = this.querySelector('.tasks');
            if(!tasksContainer) {
                return;
            }

            tasksContainer.innerHTML = "";
            if(tasks.length == 0) {
                tasksContainer.innerHTML = `<span class='i18n'>project.dashboard.tasks.noResults</span>`;
            } else {
                tasks.forEach(t=> {
                    tasksContainer.append(new ProjectDashboardTask(this._projectId, t));
                });   
            }
        }

        public async connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            await this.render();
        }
    }
}