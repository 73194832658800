namespace Simplex.WebComponents.LayoutComponents {
    import WebComponent = Simplex.Decorators.WebComponent;
    import ABWebComponent = Simplex.Components.ABWebComponent;


    @WebComponent("ui-header-tabs")
    export class HeaderTabs extends ABWebComponent {

        private readonly _tabs: HeaderTab[];
        private _activeTab: HeaderTab|undefined;

        public constructor() {
            super();
            this._tabs = [...this.querySelectorAll('ui-header-tab').values()] as HeaderTab[];
        }

        render() {
            this.innerHTML = `<nav class="tabs"><ul class="tabs__items"></ul></nav>`;
            const targetList = this.querySelector('ul');
            if (targetList) {
                this._tabs.forEach(tab=>{
                    if (tab.active) {
                        this._activeTab = tab;
                    }
                    targetList.appendChild(tab);    
                });
            }
        }

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            this.render();
        }
    }
}
