namespace Simplex.Components {
    import Application = Ambrero.AB.Application;
    import APIResult = Simplex.Utils.APIResult;
    import ListViewFilter = Simplex.WebComponents.LayoutComponents.ListViewFilter;
    import IListView = Simplex.WebComponents.LayoutComponents.IListView;
    import ModelBinder = Ambrero.AB.Components.ModelBinder;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import EventArgs = Ambrero.AB.Components.EventArgs;

    import WebComponent = Simplex.Decorators.WebComponent;
    import Inject = Simplex.Decorators.Inject;
    import IModel = Ambrero.AB.Models.IModel;

    @WebComponent("ui-project-finance-list-filter")
    export class FinanceListFilter extends ABWebComponent {
        private readonly contentTemplate;
        private readonly listIdentifier?;
        private readonly _binder?: ModelBinder;
        @Inject("ContentNotifier")
        private readonly _contentNotifier!: ContentNotifier;
        private listView: IListView | null = null;
        private _filterModel: IModel | null = null;
        private rendered: boolean = false;
        private _dialog?: HTMLDialogElement;
        
        public constructor(app: Application) {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/Finance/FinanceListFilter', 'FinanceListFilter') as TemplateCallback;
            
            this.listIdentifier = this.getAttribute("list");
            this._binder = this.app.getComponentType<ModelBinder>("ModelBinder")!;
            this._filterModel = new Simplex.Models.Project.FinanceEntryFilter();
        }

        public setListView(listView: IListView): void {
            if (this.listView !== listView) {
                this.listView = listView;
                this.listView.setFilter(this);
                this.render();
            }
        }

        protected getIdentifier(): string | null {
            return this.listIdentifier ?? null;
        }

        private render(): void {
            if (this.listView && !this.rendered) {
                this._filterModel = this.listView.getFilter();
                this.innerHTML = this.contentTemplate!({
                    Model: this._filterModel,
                });
                this.bindElementEventListeners();
                this.rendered = true;

                this._contentNotifier.notifyDraw(this);
                this.querySelectorAll("[data-action='filter']").forEach(item => {
                    item.removeEventListener("click", this.showDialog);
                    item.addEventListener("click", this.showDialog);
                });

                this._dialog = this.querySelector('dialog') as HTMLDialogElement;


                this._dialog.querySelectorAll(".close").forEach(item => {
                    item.removeEventListener("click", this.closeDialog)
                    item.addEventListener("click", this.closeDialog);
                });
                this._dialog.querySelectorAll(".JSButtons ui-button").forEach(item => {
                    item.removeEventListener("click", this.buttonClick.bind(this, item as HTMLElement));
                    item.addEventListener("click", this.buttonClick.bind(this, item as HTMLElement));
                });
            }
        }

        private onListViewInitialized = (event: InitializedComponentEvent) => {
            if (event.identifier === this.listIdentifier) {
                this.setListView(event.component as IListView);
                this.render();
            }
        }

        private readonly triggerInitializedEvent = (): void => {
            this.app.getEventBus().emit(`ListViewFilter.Initialized`, {
                identifier: this.getIdentifier(),
                component: this
            } as InitializedComponentEvent);
        }

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            this.bindApplicationEventListeners();
            this.triggerInitializedEvent();
        }

        disconnectedCallback() {
            this.removeApplicationEventListeners();
        }

        private propertyChanged = async (eventArgs: EventArgs): Promise<void> => {
            switch (eventArgs.data.field) {
                case "searchTerm":
                case "includeProcessed":
                    await this.listView?.setPageNumber(0);
                    break;
            }
        }

        private bindElementEventListeners = (): void => {
            if (this._filterModel) {
                this._binder?.bind(this._filterModel, this._contentNotifier)
                    .on("PropertyChanged", this.propertyChanged);
            }
        }

        private bindApplicationEventListeners(): void {
            this.app.getEventBus().addEventListener('ListView.Initialized', this.onListViewInitialized);
        }

        private removeApplicationEventListeners(): void {
            this.app.getEventBus().removeEventListener('ListView.Initialized', this.onListViewInitialized);
        }

        
        private showDialog = (event: Event) => {
            event.preventDefault();
            this._dialog?.showModal();
        }
        
        private closeDialog = () => { 
            this._dialog?.close();
        }

        private buttonClick = async (button: HTMLElement): Promise<void> => {
            const action = button.dataset.action;
            switch(action) {
                case "apply":
                    await this.listView?.setPageNumber(0);
                    this.closeDialog();
                    break;
                case "cancel":
                    this.closeDialog();
                    break;
            }

        }
        


    }
}