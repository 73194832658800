namespace Simplex.WebComponents.Project.DashboardProjectBudget.Models {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class ChangeProjectBudgetRequest extends ABModel {

        public budget: number|null;

        constructor(budget: number|null = null) {
            super();
            this.budget = budget;
        }

    }
}