/// <reference path="../Decorators/Decorators.d.ts" />

namespace Simplex.Components {
    import Application = Ambrero.AB.Application;
    import SystemInject = Simplex.Decorators.SystemInject;

    interface LayoutContext {
        State: LayoutState
    }

    export enum LayoutState {
        Idle,
        Loading,
        Transitioning
    }

    @Simplex.Decorators.Singleton()
    export class Layout {
        private readonly app: Application;
        private static readonly SELECTOR_CONTENTBLOCK = "#app";

        private layout: string = "default";

        @SystemInject("Auth")
        private readonly auth!: Auth;

        private readonly contentLayouts: Map<string, Content<any>> = new Map<string, Content<any>>();

        private readonly context: LayoutContext;

        public constructor(app: Application) {
            this.app = app;

            this.getLayoutContent(this.layout);
            this.context = {
                State: LayoutState.Idle
            } as LayoutContext;
        }

        public ensureCreated = (): void => {
            this.getLayoutContent(this.layout).draw();
        }

        public setLayout = (layout: string): void => {
            if (this.layout === layout) {
                return;
            }
            this.layout = layout;
            this.getLayoutContent(layout).draw();
        }

        private getLayoutContent = (name: string): Content<LayoutContext> => {

            let content = this.contentLayouts.get(name);
            if (content) {
                return content;
            }

            content = this.app.getComponent("Content", `Shared/${name}`) as Content<LayoutContext>;
            content.setTargetContainer(Layout.SELECTOR_CONTENTBLOCK);
            content.setContext(this.context);
            this.contentLayouts.set(name, content);
            return content;
        }

        public getContentRenderTarget = (): HTMLElement => {
            let renderTarget = this.getLayoutContent(this.layout).selectJqueryElement('#contentContainer');
            if (renderTarget.length === 0) {
                this.ensureCreated()
            } else {
                return renderTarget.get(0);
            }
            return this.getLayoutContent(this.layout).selectJqueryElement('#contentContainer').get(0);
        };

        private removeCurrentState = (): void => {
            switch (this.context.State) {
                case Simplex.Components.LayoutState.Idle:

                    break;
                case Simplex.Components.LayoutState.Loading:
                    $(document.body).removeClass("is--pageloading");
                    break;
                case Simplex.Components.LayoutState.Transitioning:
                    $(document.body).removeClass("is--pagetransitioning");
                    break;

            }
        };

        public setState = (state: LayoutState): void => {
            if (state === this.context.State) {
                return;
            }

            this.removeCurrentState();

            switch (state) {
                case Simplex.Components.LayoutState.Idle:

                    break;
                case Simplex.Components.LayoutState.Loading:
                    $(document.body).addClass("is--pageloading");
                    break;
                case Simplex.Components.LayoutState.Transitioning:
                    $(document.body).addClass("is--pagetransitioning");
                    break;

            }

            this.context.State = state;
        }
    }
}