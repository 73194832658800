namespace Simplex.WebComponents.Project.Report {
  import WebComponent = Simplex.Decorators.WebComponent;
  import ListView = Simplex.WebComponents.LayoutComponents.ListView;
  import ListViewOptions = Simplex.WebComponents.LayoutComponents.ListViewOptions;
  import ProjectReport = Simplex.WebComponents.Project.Report.Models.ProjectReport;

  @WebComponent("ui-project-reports-list-view")
  export class ReportListView extends ListView<ProjectReport> {
    private readonly tenantId: string;

    public constructor() {
      super();
      this.tenantId = this.getAttribute("project-id") || "";
    }

    protected onDataLoadedEvent = (): void => {
      this.getContext().customData.projectId = this.tenantId;
      const reports = this.getContext().items as ProjectReport[];
      if (reports) {
        if (this.getContext().items.length === 0) {
          this.dispatchEvent(new Event("noReports", { bubbles: true }));
        } else if (
          this.getContext().items.filter((s) => s.baseline).length === 0
        ) {
          this.dispatchEvent(new Event("noBaseline", { bubbles: true }));
        }
      }
    };

    protected getListViewSettings(): ListViewOptions {
      return new ListViewOptions(
        "jsTableContainer",
        false,
        Messages("project.report.listView.noResults"),
        null,
        {
          property: "created",
          direction: "DESC",
        },
        {
          listview: "overview__listview",
        },
        {
          path: "WebComponents/Project/Report/ReportList/ListView",
          file: "itemRow",
        },
        {
          path: "WebComponents/Project/Report/ReportList/ListView",
          file: "headerTemplate",
        }
      );
    }
  }
}
