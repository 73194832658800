namespace Simplex.WebComponents.LayoutComponents {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;


    @WebComponent("ui-progress-bar")
    export class RadialProgressBar extends ABWebComponent {

        private readonly _contentTemplate;

        public constructor() {
            super();
            this._contentTemplate = this.app.getTemplate('WebComponents/LayoutComponents/RadialProgressBar', 'RadialProgressBar') as TemplateCallback;
        }

        render() {
            this.innerHTML = this._contentTemplate({
                title: this.getAttribute("title"),
                steps: this.getAttribute("steps"),
                current: this.getAttribute("current"),
                progress: this.getAttribute("progress")
            });
        }

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            this.render();
        }
    }
}
