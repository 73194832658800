namespace Simplex.WebComponents {

    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import APIResult = Simplex.Utils.APIResult;
    import ProjectTask = Simplex.Project.Models.ProjectTask;
    import ProjectTaskListItem = Simplex.WebComponents.Project.ProjectDashboardTasks.ProjectTaskListItem;

    @WebComponent("ui-project-tasks")
    export class ProjectTasks extends ABWebComponent {
        private readonly _notice: any;
        private readonly _projectId: string;
        private readonly _contentTemplate;
        private _rendered: boolean = false;
        private _closeElement?: HTMLElement;
        private _tabElement?: HTMLElement;
        private _tasksList?: HTMLElement;
        private _menuCounter?: HTMLElement;
        private _countElements: Element[] = [];
        private _listItems:ProjectTaskListItem[] = [];
        
        public constructor() {
            super();
            this._notice = this.app.getComponent('AmbreroComponents.Notice');
            this._projectId = this.getAttribute("project-id") || "";
            this._contentTemplate = this.app.getTemplate('WebComponents/Project/ProjectTasks', 'ProjectTasks') as TemplateCallback;
        }
        render() {
            this.innerHTML = this._contentTemplate();
            this._tabElement = this.querySelector('.verticaltab') as HTMLElement;
            this._tabElement.addEventListener('click', this.onTabClick);
            
            this._closeElement = this.querySelector('.close') as HTMLElement;            
            this._closeElement.addEventListener('click', this.onTabClick);
            
            this._tasksList = this.querySelector('.tasks-list') as HTMLElement;
            this._menuCounter = this.querySelector('.counter') as HTMLElement;
            this._countElements = [...this.querySelectorAll('.count').values()] as HTMLElement[];
            
            this._rendered = true;
        }
        
        private onTabClick = (): void => {
            this.classList.contains('is--open') ? 
                this.close() : 
                this.open();
        }

        private close = (): void => {
            this.classList.remove('is--open');
            this.dispatchEvent(new CustomEvent("projectTaskListClose", {}));
        }

        private open = (): void => {
            this.classList.add('is--open');
        }

        public hide = (): void => {
            this.classList.add('hide');
        }

        public shown = (): boolean => {
            return !this.classList.contains('hide');
        }

        public show = (): void => {
            this.classList.remove('hide');
        }
        
        public showTasks = async (projectId: string, type: ProjectTasksType): Promise<void> => {
            this._listItems = [];
            if(!this._tasksList){
                return;
            }
            const taskResult = await this.request.get<APIResult<ProjectTask[]>>(`/api/project/${projectId}/tasks?type=${type}`)
            if(taskResult.isSuccess && taskResult.data.data)
            {
                if(taskResult.data.data.length === 0) {
                    if(this.shown()) {
                        this._notice.addMessage(Messages('project.tasks.menu.all_fixed'));
                    }
                    this.hide();
                    return;
                }
                if(this._menuCounter) {
                    this._menuCounter.innerHTML = taskResult.data.data.length.toString();
                }
                for (const countElement of this._countElements) {
                    countElement.innerHTML = taskResult.data.data.length.toString();
                }
                this._tasksList.innerHTML = '';
                for (const task of taskResult.data.data) {
                    const priorityByType = Simplex.Project.Models.ProjectTask.getPriorityByType(task.type) as Simplex.Project.Models.ProjectTaskPriorityType;
                    task.priority = priorityByType;
                    task.priorityName = Simplex.Project.Models.ProjectTaskPriorityType[priorityByType];
                    const listItem = new ProjectTaskListItem(this._projectId, task);
                    this._listItems.push(listItem);
                    this._tasksList.append(listItem);

                }
                this.show();
            }
        }

        public removeSelected = () => {
            for (let listItem of this._listItems) {
                listItem.unselect();
            }
        }

        public setSelected = (taskId: string) => {
            for (let listItem of this._listItems) {
                if(listItem.taskId === taskId) {
                    listItem.select();
                }
            }
        }
        
        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if(!this._rendered) {
                this.render();
            }
        }
    }

    export enum ProjectTasksType {
        Planning = 'Planning',
        Budget = 'Budget',
        Snapshots = 'Snapshots',
        ProjectSettings = 'ProjectSettings',
        CostCategory = 'CostCategory',
    }
}
