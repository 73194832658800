namespace Simplex.WebComponents {
  import WebComponent = Simplex.Decorators.WebComponent;
  import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
  import ABWebComponent = Simplex.Components.ABWebComponent;
  import APIResult = Simplex.Utils.APIResult;

  import CostCategorySummary = Simplex.Models.Project.CostCategorySummary;
  import ScopesData = Simplex.Models.Project.ScopesData;
  import Scope = Simplex.Models.Project.Scope;
  import BudgetCellType = Simplex.Models.Project.BudgetCellType;

  @WebComponent("ui-budget-table")
  export class BudgetTable extends ABWebComponent {
    private _scopes?: Scope[];
    private readonly _projectId: string;
    private readonly contentTemplate;
    private readonly tableTemplate;
    private readonly budgetRow;
    private readonly costsRow;
    private readonly noContentTemplate;
    private _budgetTableContainer?: HTMLElement;
    private readonly verticalCollapseClass = "is--collapsed";
    private verticalExpandToggle?: HTMLElement;
    private _rendered: boolean = false;
    private _loaded: boolean = false;
    private _filter?: Simplex.WebComponents.Project.Budget.Models.FilterModel;
    private _costCategories?: CostCategorySummary[];
    private readonly scopeLevelPrefix = "max-scope-depth--";
    private readonly costCategoryLevelPrefix = "max-costs-depth--";
    private readonly _filterModelSessionStorageKey: string;
    private _scopeLevel: number = 1;
    private _costCategoryLevel: number = 0;

    public constructor() {
      super();
      this._projectId = this.getAttribute("project-id") || "";
      this._filterModelSessionStorageKey =
        Simplex.Utils.getBudgetFilterModelCacheKey(this._projectId);
      this.contentTemplate = this.app.getTemplate(
        "WebComponents/Project/Budget/BudgetTable",
        "BudgetTable"
      ) as TemplateCallback;
      this.noContentTemplate = this.app.getTemplate(
        "WebComponents/Project/Budget/BudgetTable",
        "NoData"
      ) as TemplateCallback;
      this.tableTemplate = this.app.getTemplate(
        "WebComponents/Project/Budget/BudgetTable",
        "Table"
      ) as TemplateCallback;
      this.budgetRow = this.app.getTemplate(
        "WebComponents/Project/Budget/BudgetTable",
        "BudgetRow"
      ) as TemplateCallback;
      this.costsRow = this.app.getTemplate(
        "WebComponents/Project/Budget/BudgetTable",
        "CostsRow"
      ) as TemplateCallback;
      const filterModelFromSessionStorage = localStorage.getItem(
        this._filterModelSessionStorageKey
      );
      if (filterModelFromSessionStorage) {
        const filterModelFromSessionStorageJson = JSON.parse(
          filterModelFromSessionStorage
        ) as Simplex.WebComponents.Project.Budget.Models.FilterModel;
        this._scopeLevel = filterModelFromSessionStorageJson.scopeLevel ?? 1;
        this._costCategoryLevel =
          filterModelFromSessionStorageJson.costCategoryLevel ?? 0;
      }
    }

    set costCategories(costCategories: CostCategorySummary[]) {
      this._costCategories = costCategories;
    }

    set scopeLevel(level: number) {
      this.removeClassesByPrefix(this.scopeLevelPrefix);
      this.classList.add(`${this.scopeLevelPrefix}${level}`);
    }

    get scopeLevel(): number {
      const regx = new RegExp(this.scopeLevelPrefix + "\\d", "g");
      return parseInt(
        this.className.match(regx)![0].replace(this.scopeLevelPrefix, "")
      );
    }

    private removeClassesByPrefix(prefix: string): void {
      const regx = new RegExp(prefix + "\\d", "g");
      this.className = this.className.replace(regx, "");
    }

    set costCategoryLevel(level: number) {
      this.removeClassesByPrefix(this.costCategoryLevelPrefix);
      this.classList.add(`${this.costCategoryLevelPrefix}${level}`);
    }

    onScopeHighlight = (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      if (this._budgetTableContainer && event.target) {
        const target = event.target as HTMLElement;
        const scopeHovers = this._budgetTableContainer.querySelectorAll(
          `[data-scope-id="${target.dataset.scopeId}"]`
        );
        scopeHovers.forEach((cell) => {
          cell.classList.add("is--highlighted");
        });
      }
    };
    onScopeUnHighlight = (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      if (this._budgetTableContainer && event.target) {
        const target = event.target as HTMLElement;
        const scopeHovers = this._budgetTableContainer.querySelectorAll(
          `[data-scope-id="${target.dataset.scopeId}"]`
        );
        scopeHovers.forEach((cell) => {
          cell.classList.remove("is--highlighted");
        });
      }
    };

    public removeAllHighlights = () => {
      if (!this._budgetTableContainer) {
        return;
      }
      const headersAndColumnsAndFooters =
        this._budgetTableContainer.querySelectorAll(
          ".header, .budgetrow .col, .budgetfooter"
        ); // data-scope-id
      headersAndColumnsAndFooters.forEach((cell) => {
        cell.classList.remove("is--highlighted");
      });
    };

    public highlightScopes = async (scopeIds: string[]) => {
      if (!this._budgetTableContainer) {
        return;
      }
      const scoperows =
        this._budgetTableContainer.querySelectorAll(".budgettable__row"); // data-scope-id
      scoperows.forEach((row) => {
        row.classList.remove("is--highlighted");
      });

      let scrollPositionSet = false;
      for (let row of scoperows) {
        const scopeId = (row as HTMLElement).dataset["scopeId"] as string;
        if (scopeIds.includes(scopeId)) {
          row.classList.add("is--highlighted");
          if (!scrollPositionSet) {
            row.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
            scrollPositionSet = true;
          }
        } else {
          row.classList.remove("is--highlighted");
        }
      }
    };

    onCostToggleClicked = (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      const target = event.currentTarget as HTMLElement;

      if (target.classList.contains("for-budget")) {
        const budgetrow = target.closest(".budgettable__row") as HTMLElement;
        budgetrow.classList.toggle("budgets--closed");
      } else {
        const parent = target.closest(".parent") as HTMLElement;
        parent.classList.toggle("is--closed");
      }
    };

    onScopeToggleClicked = (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      const target = event.target as HTMLElement;
      const parent = target.closest(".parent") as HTMLElement;
      if (!parent) {
        return;
      }
      parent.classList.toggle("is--closed");
    };

    private filterChanged(
      filter: Simplex.WebComponents.Project.Budget.Models.FilterModel
    ): boolean {
      return (
        filter.scopeId !== this._filter?.scopeId ||
        filter.compareWithSnapshotId !== this._filter?.compareWithSnapshotId
      );
    }

    private createElementFromHTMLString = (htmlString: string): HTMLElement => {
      let div = document.createElement("div");
      div.innerHTML = htmlString.trim();
      return div.firstChild as HTMLElement;
    };

    public clearData() {
      this._loaded = false;
    }

    public loadData = async (
      filter: Simplex.WebComponents.Project.Budget.Models.FilterModel
    ): Promise<void> => {
      this.removeElementEventListeners();
      if (!this._loaded || this.filterChanged(filter)) {
        const scopesResult = await this.request.get<APIResult<ScopesData>>(
          `/api/project/${this._projectId}/scopes?${
            filter?.scopeId !== null ? `scopeId=${filter?.scopeId}` : ""
          }&compareWithSnapshotId=${
            filter?.compareWithSnapshotId !== null
              ? `${filter.compareWithSnapshotId}`
              : ""
          }`
        );
        //HERE
        this._scopes =
          scopesResult.isSuccess && scopesResult.data?.data
            ? scopesResult.data.data.scopes
            : [];
        if (scopesResult.isSuccess && scopesResult.data?.data) {
          this._budgetTableContainer = this.querySelector(
            ".budgettable"
          ) as HTMLElement;
          if (this._scopes.length === 0) {
            this._budgetTableContainer.innerHTML = this.noContentTemplate({
              noScopes: this._scopes.length === 0,
            });
            this._filter = JSON.parse(JSON.stringify(filter));
          } else {
            const scopesResultData = scopesResult.data?.data;
            this._budgetTableContainer.innerHTML = this.tableTemplate({
              totals: [
                {
                  total: scopesResultData.totalBudget,
                  totalSnapshot: scopesResultData.totalBudgetSnapshot,
                  totalChanged: scopesResultData.totalBudgetChanged,
                },
                {
                  total: scopesResultData.totalPrognose,
                  totalSnapshot: scopesResultData.totalPrognoseSnapshot,
                  totalChanged: scopesResultData.totalPrognoseChanged,
                },
                {
                  total: scopesResultData.totalActual,
                  totalSnapshot: scopesResultData.totalActualSnapshot,
                  totalChanged: scopesResultData.totalActualChanged,
                },
                {
                  total: scopesResultData.totalExpense,
                  totalSnapshot: scopesResultData.totalExpenseSnapshot,
                  totalChanged: scopesResultData.totalExpenseChanged,
                },
                {
                  total: scopesResultData.totalExpectedCosts,
                  totalSnapshot: scopesResultData.totalExpectedCostsSnapshot,
                  totalChanged: scopesResultData.totalExpectedCostsChanged,
                },
                {
                  total: scopesResultData.totalResult,
                  totalSnapshot: scopesResultData.totalResultSnapshot,
                  totalChanged: scopesResultData.totalResultChanged,
                },
              ],
              title: "",
            });
            const budgetItemsElement = this._budgetTableContainer.querySelector(
              "#budgetitems"
            ) as HTMLElement;

            this._scopes.forEach((scope) => {
              this.addScopeToTable(scope, budgetItemsElement);
            });

            const toggleScopes = this.querySelectorAll(".toggle");
            const toggleCosts = this.querySelectorAll(".coststoggle");

            toggleScopes.forEach((toggle) => {
              toggle.addEventListener("click", (event: Event) =>
                this.onScopeToggleClicked(event)
              );
            });
            toggleCosts.forEach((toggle) => {
              toggle.addEventListener("click", (event: Event) =>
                this.onCostToggleClicked(event)
              );
            });
            this._filter = JSON.parse(JSON.stringify(filter));
            this._loaded = true;
            this.verticalExpandToggle = this.querySelector(
              ".jsVerticalExpand"
            ) as HTMLElement;
            this.toggleVerticalCollapse(
              this._filter?.verticalCollapsed ?? true
            );
            this.bindElementEventListeners();
          }
        }

        const hadOpenRows = localStorage.getItem("openBudgetRowsBeforeSave");

        if (hadOpenRows) {
          const {
            costRowIds,
            budgetRowIds,
            budgetCostRowIds,
            openCostRowIds,
            openBudgetRowIds,
            openBudgetCostRowIds,
          } = JSON.parse(hadOpenRows);

          if (budgetRowIds) {
            budgetRowIds.forEach((id: string) => {
              const row = document.querySelector(
                `.budgettable__row[data-scope-id="${id}"]`
              ) as HTMLElement;
              row?.classList.add("is--closed");
            });
          }
          if (budgetCostRowIds) {
            budgetCostRowIds.forEach((id: string) => {
              const row = document.querySelector(
                `.budgettable__row[data-scope-id="${id}"]`
              ) as HTMLElement;
              row?.classList.add("budgets--closed");
            });
          }

          if (costRowIds) {
            costRowIds.forEach((id: string) => {
              const row = document.querySelector(
                `.costs__row[data-id="${id}"]`
              ) as HTMLElement;
              row?.classList.add("is--closed");
            });
          }

          if (openCostRowIds) {
            openCostRowIds.forEach((id: string) => {
              const row = document.querySelector(
                `.costs__row[data-id="${id}"]`
              ) as HTMLElement;
              row?.classList.remove("is--closed");
            });
          }
          if (openBudgetRowIds) {
            openBudgetRowIds.forEach((id: string) => {
              const row = document.querySelector(
                `.budgettable__row[data-scope-id="${id}"]`
              ) as HTMLElement;
              row?.classList.remove("is--closed");
            });
          }

          if (openBudgetCostRowIds) {
            openBudgetCostRowIds.forEach((id: string) => {
              const row = document.querySelector(
                `.budgettable__row[data-scope-id="${id}"]`
              ) as HTMLElement;
              row?.classList.remove("budgets--closed");
            });
          }

          localStorage.removeItem("openBudgetRowsBeforeSave");
        }

        this.showActiveScope();
      }
    };

    private showActiveScope = () => {
      const activeScopeId = sessionStorage.getItem("budget_active_scopeId");
      if (activeScopeId && this._budgetTableContainer) {
        const activeViewScope = this._budgetTableContainer.querySelector(
          `.budgettable__row[data-scope-id='${activeScopeId}']`
        );
        if (activeViewScope) {
          const depth = parseInt(
            (activeViewScope as HTMLElement).dataset["depth"]!
          );
          if (this._filter && this._filter.scopeLevel <= depth) {
            this._filter.scopeLevel = depth + 1;
            this.dispatchEvent(
              new CustomEvent("scopeLevelChanged", {
                detail: { scopeLevel: this._filter.scopeLevel },
              })
            );
          }
          window.setTimeout(() => {
            activeViewScope.scrollIntoView({ behavior: "smooth" });
          }, 500);
        }
      }
      sessionStorage.removeItem("budget_active_scopeId");
    };

    private addScopeToTable(
      scope: Scope,
      budgetItemsElement: HTMLElement,
      depth: number = 0
    ) {
      scope.maxScopeDepth = this._scopeLevel;
      scope.depth = depth;
      scope.maxCostsDepth = this._costCategoryLevel;
      const budgetRow = this.createElementFromHTMLString(
        this.budgetRow(scope)
      ) as HTMLElement;
      if (scope?.id) {
        budgetRow.dataset.scopeId = scope.id;
      }
      const budgetRowWrapper = budgetRow.querySelector(
        ".rowwrapper"
      ) as HTMLElement;
      const childrenElement = document.createElement("div");
      childrenElement.classList.add("children");

      const budgetCell = new Simplex.WebComponents.BudgetCell(
        this._projectId,
        null,
        scope,
        BudgetCellType.Budget
      );
      const prognoseCell = new Simplex.WebComponents.BudgetCell(
        this._projectId,
        null,
        scope,
        BudgetCellType.Prognose
      );
      const actualCostsCell = new Simplex.WebComponents.BudgetCell(
        this._projectId,
        null,
        scope,
        BudgetCellType.ActualCosts
      );
      const expenseCell = new Simplex.WebComponents.BudgetCell(
        this._projectId,
        null,
        scope,
        BudgetCellType.Expense
      );
      const expectedCell = new Simplex.WebComponents.BudgetCell(
        this._projectId,
        null,
        scope,
        BudgetCellType.ExpectedCosts
      );
      const resultCell = new Simplex.WebComponents.BudgetCell(
        this._projectId,
        null,
        scope,
        BudgetCellType.Result
      );
      budgetRowWrapper.append(
        budgetCell,
        prognoseCell,
        actualCostsCell,
        expenseCell,
        expectedCell,
        resultCell,
        childrenElement
      );

      this._costCategories?.forEach((costCategory) => {
        this.addCostCategoryToTable(scope, costCategory, childrenElement, 0);
      });
      scope.children.forEach((childScope) => {
        this.addScopeToTable(childScope, childrenElement, depth + 1);
      });
      budgetItemsElement.append(budgetRow);
    }

    private addCostCategoryToTable(
      scope: Scope,
      costCategory: CostCategorySummary,
      childrenElement: HTMLElement,
      depth: number
    ) {
      costCategory.depth = depth;
      costCategory.maxDepth = this._costCategoryLevel;
      costCategory.scopeId = scope.id;
      const costsRow = this.createElementFromHTMLString(
        this.costsRow(costCategory)
      ) as HTMLElement;
      const costsRowWrapper = costsRow.querySelector(
        ".rowwrapper"
      ) as HTMLElement;
      const costsChildrenElement = document.createElement("div");
      costsChildrenElement.classList.add("children");

      const budgetCell = new Simplex.WebComponents.BudgetCell(
        this._projectId,
        costCategory,
        scope,
        BudgetCellType.Budget
      );
      const prognoseCell = new Simplex.WebComponents.BudgetCell(
        this._projectId,
        costCategory,
        scope,
        BudgetCellType.Prognose
      );
      const actualCostsCell = new Simplex.WebComponents.BudgetCell(
        this._projectId,
        costCategory,
        scope,
        BudgetCellType.ActualCosts
      );
      const expenseCell = new Simplex.WebComponents.BudgetCell(
        this._projectId,
        costCategory,
        scope,
        BudgetCellType.Expense
      );
      const expectedCell = new Simplex.WebComponents.BudgetCell(
        this._projectId,
        costCategory,
        scope,
        BudgetCellType.ExpectedCosts
      );
      const resultCell = new Simplex.WebComponents.BudgetCell(
        this._projectId,
        costCategory,
        scope,
        BudgetCellType.Result
      );
      costsRowWrapper.append(
        budgetCell,
        prognoseCell,
        actualCostsCell,
        expenseCell,
        expectedCell,
        resultCell,
        costsChildrenElement
      );
      if (
        budgetCell.getBudget().isUnspecified ||
        prognoseCell.getBudget().isUnspecified ||
        actualCostsCell.getBudget().isUnspecified ||
        expenseCell.getBudget().isUnspecified ||
        expectedCell.getBudget().isUnspecified ||
        resultCell.getBudget().isUnspecified
      ) {
        costsRow.classList.add("is--unspecified");
        const meta = costsRow.querySelector(".costitem__meta") as HTMLElement;
        const unspecified = document.createElement("span");
        unspecified.innerText = Messages("project.budget.unspecified");
        unspecified.classList.add("unspecified-data");
        meta.append(unspecified);
      }
      childrenElement.append(costsRow);

      // loop children
      costCategory.children.forEach((childCostCategory) => {
        this.addCostCategoryToTable(
          scope,
          childCostCategory,
          costsChildrenElement,
          depth + 1
        );
      });
    }

    public show() {
      this.classList.remove("hidden");
    }

    public hide() {
      this.classList.add("hidden");
    }

    async render() {
      this.innerHTML = this.contentTemplate({
        projectId: this._projectId,
      });
      this._rendered = true;
    }

    private readonly verticalExpandToggled = async (
      _: Event
    ): Promise<void> => {
      if (!this.verticalExpandToggle) {
        return;
      }
      this.toggleVerticalCollapse(
        this.verticalExpandToggle.classList.contains("collapse")
      );
    };

    private toggleVerticalCollapse = (collapse: boolean): void => {
      if (!this.verticalExpandToggle) {
        return;
      }
      if (collapse) {
        this.verticalExpandToggle.classList.remove("collapse");
        this.verticalExpandToggle.classList.add("expand");
        this.classList.add(this.verticalCollapseClass);
        this.dispatchEvent(
          new CustomEvent("tableVerticalCollapseChange", {
            bubbles: true,
            detail: { collapsed: true },
          })
        );
      } else {
        this.verticalExpandToggle.classList.add("collapse");
        this.verticalExpandToggle.classList.remove("expand");
        this.classList.remove(this.verticalCollapseClass);
        this.dispatchEvent(
          new CustomEvent("tableVerticalCollapseChange", {
            bubbles: true,
            detail: { collapsed: false },
          })
        );
      }
    };

    disconnectedCallback() {
      this.removeElementEventListeners();
    }

    private bindElementEventListeners(): void {
      this.verticalExpandToggle?.addEventListener(
        "click",
        this.verticalExpandToggled
      );
    }

    private removeElementEventListeners(): void {
      this.verticalExpandToggle?.removeEventListener(
        "click",
        this.verticalExpandToggled
      );
    }

    async connectedCallback() {
      if (!this.isConnected) {
        return;
      }
      if (!this._rendered) {
        await this.render();
      }
    }
  }
}
