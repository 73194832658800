namespace Simplex.WebComponents.Project.DashboardProjectSnapshot {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import APIResult = Simplex.Utils.APIResult;
    import SnapshotData = Simplex.WebComponents.Project.DashboardProjectSnapshot.Models.SnapshotData;

    @WebComponent("ui-dashboard-project-last-snapshot-widget")
    export class ProjectDashboardLastSnapshotWidget extends ABWebComponent {
        private readonly contentTemplate;
        private readonly _projectId: string;

        public constructor() {
            super();
            this._projectId = this.getAttribute("project-id") || "";
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/Dashboard/ProjectDashboardLastSnapshot', 'ProjectDashboardLastSnapshot') as TemplateCallback;
        }

        private getSnapshotData = async (): Promise<SnapshotData | undefined> => {

            const result = await this.request.get<APIResult<SnapshotData>>(`/api/project/${this._projectId}/snapshots/latest`);
            if (!result.isSuccess) {
                return undefined;
            }
            return result.data.data;
        }

        public async render() {
            const snapshotData = await this.getSnapshotData();
            if (!snapshotData) {
                return;
            }
            snapshotData.projectId = this._projectId;
            this.innerHTML = this.contentTemplate(snapshotData);
        }

        private onClickedWidget(): void {
            this.app.navigateTo(`/project/${this._projectId}/snapshots`);
        }

        private bindElementEventListeners(): void {
            this.addEventListener('click', this.onClickedWidget)
        }

        private removeElementEventListeners(): void {
            this.removeEventListener('click', this.onClickedWidget)
        }

        public async connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            await this.render();
            this.bindElementEventListeners();
        }

        disconnectedCallback() {
            this.removeElementEventListeners();
        }
    }
}
