namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class ScopeBudget extends ABModel {

        public costCategoryId: string|null;
        public costCategoryName: string;
        public costCategoryCode: string|null;
        public amount: number|null;
        public snapshotAmount: number|null;
        public amountSum: number|null;
        public amountSumSnapshot: number|null;
        public childAmountSum: number|null;
        public childAmountSumSnapshot: number|null;
        public budgetChanged: boolean;
        public budgetChildSumChanged: boolean;
        public hasSpecifiedChildSum: boolean;
        public isUnspecified: boolean;
        
        constructor(costCategoryId: string|null = null,
                    costCategoryName: string = '',
                    costCategoryCode: string|null = null,
                    amount: number|null = null,
                    snapshotAmount: number|null = null,
                    amountSum: number|null = null,
                    amountSumSnapshot: number|null = null,
                    childAmountSum: number|null = null,
                    childAmountSumSnapshot: number|null = null,
                    budgetChanged: boolean = false,
                    budgetChildSumChanged: boolean = false,
                    hasSpecifiedChildSum: boolean = false,
                    isUnspecified: boolean = false
                    ) {
            super();
            this.costCategoryId = costCategoryId;
            this.amount = amount;
            this.snapshotAmount = snapshotAmount;
            this.amountSum = amountSum;
            this.amountSumSnapshot = amountSumSnapshot;
            this.childAmountSum = childAmountSum;
            this.childAmountSumSnapshot = childAmountSumSnapshot;
            this.costCategoryName = costCategoryName;
            this.costCategoryCode = costCategoryCode;
            this.budgetChanged = budgetChanged;
            this.budgetChildSumChanged = budgetChildSumChanged;
            this.hasSpecifiedChildSum = hasSpecifiedChildSum;
            this.isUnspecified = isUnspecified;
        }

        public convertField = (fieldName: string, direction: "up" | "down", value: any): any => {
            switch (fieldName) {
                case "amount":
                case "snapshotAmount":
                    if (direction === "down") {
                        return value === "" ? null : value;
                    }
            }
        }
        
    }
}