/// <reference path="../Declarations/Index.d.ts" />
/// <reference path="../Decorators/Decorators.d.ts" />

/* COPYRIGHT (C) Ambrero Software B.V. - All rights reserved
 * This file is part of the Ambrero Base Framework and supplied under license.
 * Unauthorized copying of this file is strictly prohibited.
 * It is permitted to make changes to this file, as long as it is only used in this project.
 */
namespace Simplex.Components {
  import Application = Ambrero.AB.Application;

  @Simplex.Decorators.Singleton()
  export class ProjectTasksManager extends ABComponent {
    private readonly _app: Application;
    private readonly _projectTasks: Simplex.WebComponents.ProjectTasks;
    private _activeHighLightTask: Simplex.Project.Models.ProjectTask | null =
      null;

    public constructor(app: Application) {
      super();
      this._app = app;
      const foundProjectTasks = $("body").find("ui-project-tasks");
      this._projectTasks =
        foundProjectTasks.length > 0
          ? (foundProjectTasks[0] as Simplex.WebComponents.ProjectTasks)
          : new Simplex.WebComponents.ProjectTasks();
      $("body").append(this._projectTasks);

      if (foundProjectTasks.length == 0)
        //only open when rendered first time
        //@ts-ignore
        this._projectTasks.open();

      app.getEventBus().on("reset", this.subscribeEventBus);
      this.subscribeEventBus();
      this._projectTasks.addEventListener(
        "projectTaskListItemClicked",
        this.onProjectTaskListItemClicked
      );
      this._projectTasks.addEventListener(
        "projectTaskListClose",
        this.onProjectTaskListClosed
      );
    }

    private onProjectTaskListItemClicked = async (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      const task = (event as CustomEvent)
        .detail as Simplex.Project.Models.ProjectTask;
      this._projectTasks.removeSelected();

      if (
        this._activeHighLightTask &&
        this._activeHighLightTask.type === task.type
      ) {
        this.emit(
          "removeAllHighLights",
          Simplex.Utils.createEventArgs({}, this)
        );
        this._activeHighLightTask = null;
      } else {
        this._activeHighLightTask = task;
        this.emit(
          "projectTaskListItemClicked",
          Simplex.Utils.createEventArgs(task, this)
        );
        this._projectTasks.setSelected(task.id);
      }
    };

    private onProjectTaskListClosed = async (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      this.emit("removeAllHighLights", Simplex.Utils.createEventArgs({}, this));
    };

    private readonly subscribeEventBus = (): void => {
      this._projectTasks.hide();
    };

    public showTasks = async (
      projectId: string,
      type: Simplex.WebComponents.ProjectTasksType
    ): Promise<void> => {
      await this._projectTasks.showTasks(projectId, type);
    };
  }
}
