namespace Simplex.WebComponents.FormElements {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;


    @WebComponent("ui-select")
    export class InputSelect extends ABWebComponent {

        private readonly _contentTemplate;
        private _select?:HTMLSelectElement;
        private readonly _options: string | null = null;
        private feedbackTarget: HTMLElement|null = null;
        private optionParent: string;
        static get observedAttributes() {
            return ['disabled'];
        }
        public constructor(options?: string) {
            super();
            this.optionParent = '';
            this._contentTemplate = this.app.getTemplate('WebComponents/FormElements/InputSelect', 'InputSelect') as TemplateCallback;
            if(options) {
                this._options = options;
            } else {
                this._options = this.innerHTML;
            }
        }
        
        private updateParentLabel = () => {
            const selectedOption = this._select!.options[this._select?.selectedIndex!];

            let parentName = selectedOption.getAttribute('data-first-parent');
            const parentLabel = this.querySelector('.jsparentname') as HTMLElement;
            const ownLabel = this.querySelector('.jsownname') as HTMLElement;
            if(!parentLabel) {
                return;
            }
            parentLabel.parentElement!.classList.remove('no-parent','color--1','color--2','color--3','color--4','color--5','color--6','color--7','color--8','color--9','color--10','color--11','color--12','color--13','color--14');
            if(!parentName) {
                parentLabel.innerText = '';
                ownLabel.innerText = '';
                if(selectedOption.getAttribute('data-color')) {
                    parentLabel.parentElement!.classList.add(`color--${selectedOption.getAttribute('data-color')}`,'no-parent');
                }
                return;
            }
            if(selectedOption.getAttribute('data-last-parent')) {
                parentName = `${parentName} > ..${selectedOption.getAttribute('data-last-parent')}`;
            }
            if(selectedOption.getAttribute('data-color')) {
                parentLabel.parentElement!.classList.add(`color--${selectedOption.getAttribute('data-color')}`)
            }
            const ownName = selectedOption.innerText.replace(/\u2014+\s/g, '');
            parentLabel.innerText = parentName;
            ownLabel.innerText = ownName;
        };
        
        render() {
            this.innerHTML = this._contentTemplate({
                name: this.getAttribute("name"),
                label: this.getAttribute("label"),
                help: this.getAttribute("help"),
                class: this.getAttribute("class"),
                tabindex: this.getAttribute("tabindex"),
                disabled: this.getAttribute("disabled") === "",
                required: this.getAttribute("required") === "",
                hasParentLabel: this.getAttribute('has-parent-label') === '',
                options: this._options
            });
            this._select = this.querySelector('select') as HTMLSelectElement;
            this.feedbackTarget = this.querySelector(".input__feedback");

            if (this._select) {
                this._select.addEventListener("Valid", this.inputValidated);
                this._select.addEventListener("Invalid", this.inputInvalid);
                if(this.getAttribute('has-parent-label') === '') {
                    this._select.addEventListener("change", this.updateParentLabel);
                }
                this.updateParentLabel();
            }
        }

        public setOptions = (options: string):void => {
            if(this._select) {
                this._select.innerHTML = options;
            }
        }
        
        public enable = ():void => {
            if(this._select) {
                this.classList.remove("disabled");
            }
        }
        
        public disable = ():void => {
            if(this._select) {
                this.classList.add("disabled");
            }
        }

        inputValidated = (_:Event):void => {
            if (this.feedbackTarget) {
                this.feedbackTarget.innerHTML = "";
            }
        }

        inputInvalid = (_:Event):void => {
            if (this.feedbackTarget && this._select) {
                this.feedbackTarget.innerHTML = Messages(this._select.dataset.validationMessage ?? "");
            }
        }

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            this.render();
        }

        attributeChangedCallback(name:string, oldValue:string, newValue:string) {
            if (name === 'disabled' && (newValue === '' || newValue === 'disabled')) {
                this.classList.add('disabled');
            } else {
                this.classList.remove('disabled');
            }
        }
    }
}
