namespace Simplex.WebComponents.FormElements {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import EventArgs = Ambrero.AB.Components.EventArgs;
    
    @WebComponent("ui-button")
    export class Button extends ABWebComponent {
        private readonly contentTemplate;
        private _loading: boolean = false;
        private _loadingEvent: string|undefined;
        private button?: HTMLButtonElement|null;

        static get observedAttributes() { return ['value', 'disabled', 'loading', 'loading-state-trigger']; }
        
        public constructor() {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/FormElements/Button', 'Button') as TemplateCallback;
            const loadingStateTrigger = this.getAttribute("loading-state-trigger");
            if (loadingStateTrigger !== null) {
                this.setLoadingEvent(loadingStateTrigger);
            }
        }

        get loading(): boolean  {
            return this._loading;
        }
        
        set loading(value: boolean) {
            this._loading = value;
            if (this.button) {
                if (this._loading) {
                    this.button.classList.add("is--loading");
                } else {
                    this.button.classList.remove("is--loading");
                }
            }
        }
        
        render() {
            this.innerHTML = this.contentTemplate({
                value: this.getAttribute("value"),
                bordered: this.getAttribute("bordered") === "",
                disabled: this.getAttribute("disabled") === "",
                loading: this._loading,
                icon: this.getAttribute("icon"),
                icononly: this.getAttribute("icononly") === "",
            });
            this.button = this.querySelector('button');
            if (this.getAttribute("href")) {
                this.addEventListener("click", () => {
                    const href = this.getAttribute("href");
                    if (href) {
                        this.app.navigateTo(href);
                    }
                });
            }
            else if (this.getAttribute("back-button") === '') {
                this.addEventListener("click", () => {
                    history.back();
                });
        }
        }

        attributeChangedCallback(name: string, oldValue: string, newValue: string):void  {
            if (this.button) {
                switch (name) {
                    case "value":
                        const buttonText = this.button.querySelector('span.button__text');
                        if (buttonText) {
                            buttonText.innerHTML = Messages(newValue);
                        }
                        break;
                    case "loading":
                        this.loading = (newValue === "");
                        break;
                    case "loading-state-trigger":
                        this.setLoadingEvent(newValue);
                        break;
                    case "disabled":
                        if (newValue !== null) {
                            this.button.setAttribute('disabled','');
                            this.button.classList.add('is--disabled');
                        } else {
                            this.button.removeAttribute('disabled');
                            this.button.classList.remove('is--disabled');
                        }
                        break;
                }
            }

        }
        
        connectedCallback() {
            if (!this.isConnected) {
                return;
            }

            this.render();
        }

        private setLoadingEvent(eventName: string | null) {
            if (this._loadingEvent !== undefined) {
                this.app.getEventBus().unsubscribe(this._loadingEvent, this.onLoadTrigger)
            }
            if (typeof(eventName) === "string" && eventName !== "") {
                this._loadingEvent = eventName;
                this.app.getEventBus().subscribe(this._loadingEvent, this.onLoadTrigger)
            }
        }

        private onLoadTrigger = (source:any, eventArgs:EventArgs):void => {
            this.loading = eventArgs.data.state;
        }
    }
}
