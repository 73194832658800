namespace Simplex.WebComponents.Project {
  import WebComponent = Simplex.Decorators.WebComponent;
  import ListView = Simplex.WebComponents.LayoutComponents.ListView;
  import ListViewOptions = Simplex.WebComponents.LayoutComponents.ListViewOptions;
  import SnapshotSummary = Simplex.Models.Project.SnapshotSummary;

  @WebComponent("ui-project-snapshots-list-view")
  export class SnapshotListView extends ListView<SnapshotSummary> {
    private readonly tenantId: string;

    public constructor() {
      super();
      this.tenantId = this.getAttribute("project-id") || "";
    }

    protected onDataLoadedEvent = (): void => {
      this.getContext().customData.projectId = this.tenantId;
      const snapshots = this.getContext().items as SnapshotSummary[];
      if (snapshots) {
        if (this.getContext().items.length === 0) {
          this.dispatchEvent(new Event("noSnapshots", { bubbles: true }));
        } else if (
          this.getContext().items.filter((s) => s.baseline).length === 0
        ) {
          this.dispatchEvent(new Event("noBaseline", { bubbles: true }));
        }
      }
    };

    protected getListViewSettings(): ListViewOptions {
      return new ListViewOptions(
        "jsTableContainer",
        false,
        Messages("project.snapshot.listView.noResults"),
        null,
        {
          property: "created",
          direction: "DESC",
        },
        {
          listview: "overview__listview",
        },
        {
          path: "WebComponents/Project/Snapshot/SnapshotList/ListView",
          file: "itemRow",
        },
        {
          path: "WebComponents/Project/Snapshot/SnapshotList/ListView",
          file: "headerTemplate",
        }
      );
    }
  }
}
