namespace Simplex.Models.Authentication {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class NewPasswordRequest extends ABModel {

        public email: string;
        public code: string;
        public password: string;
        public passwordRepeated: string;
        public reasonRepeated: string;

        constructor(email: string = "", code: string = "", password: string = "", passwordRepeated: string = "", reasonRepeated: string = "") {
            super();
            this.email = email;
            this.code = code;
            this.password = password;
            this.passwordRepeated = passwordRepeated;
            this.reasonRepeated = reasonRepeated;
        }

        public validateField = (fieldName: string, element: HTMLElement, value: string): string | undefined => {
            if (fieldName === "password") {
                if (value.length < 8) {
                    return Messages("user.password.minimumLength", 8);
                }
                if (!/[0-9]/.test(value)) {
                    return Messages("user.password.requiresDigit");
                }
                if (!/[A-Z]/.test(value)) {
                    return Messages("user.password.requiresUppercase");
                }
                if (!/[a-z]/.test(value)) {
                    return Messages("user.password.requiresLowercase");
                }
                if (!/[^a-zA-Z0-9]+/.test(value)) {
                    return Messages("user.password.requiresNonAlphanumeric");
                }
            }

            if (fieldName === "passwordRepeated") {
                if (this.password !== value) {
                    return Messages("user.passwordRepeated.incorrect");
                }
            }
        };
    }
}