namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class ProjectUser extends ABModel {

        public id: string|null;
        public email: string;
        public firstName: string|null;
        public lastName: string|null;
        public projectManager: boolean;
        public responsibleManager: boolean;
        public currentUser: boolean;

        constructor(id: string|null = null, email: string = "", firstName: string|null = null, lastName: string|null = null, projectManager: boolean = false, responsibleManager: boolean = false, currentUser: boolean = false) {
            super();
            this.id = id;
            this.email = email;
            this.firstName = firstName;
            this.lastName = lastName;
            this.projectManager = projectManager;
            this.responsibleManager = responsibleManager;
            this.currentUser = currentUser;
        }
    }
}