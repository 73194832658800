namespace Simplex.WebComponents.Project.DashboardProjectSnapshot {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;

    @WebComponent("ui-dashboard-manage")
    export class ProjectDashboardManage extends ABWebComponent {
        private readonly contentTemplate;
        private readonly _projectId: string;
        private _rendered: boolean = false;

        public constructor() {
            super();
            this._projectId = this.getAttribute("project-id") || "";
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/Dashboard/ProjectDashboardManage', "ProjectDashboardManage") as TemplateCallback;
        }

        public async render() {
            this.innerHTML = this.contentTemplate({projectId: this._projectId});
            this._rendered = true;
        }

        public async connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if (!this._rendered) {
                await this.render();
                this.bindElementEventListeners();
            }
        }

        private getItemElement = (element:HTMLElement) =>{
            while (!element.classList.contains('action_item')) {
                element = element.parentElement as HTMLElement;
            }
            return element;
        }
        
        private onItemClicked = (event:Event): void => {
            event.preventDefault();
            event.stopPropagation()
            const targetElement = this.getItemElement(event.target as HTMLElement);
            const action = targetElement.dataset['action']
            switch (action){
                case 'cost-category_create': {
                    this.app.navigateTo(`/project/${this._projectId}/costCategories`);
                    break
                }
                case 'snapshot_create': {
                    this.app.navigateTo(`/project/${this._projectId}/snapshots/create`);
                    break
                }
                case 'project-export': {
                    this.app.navigateTo(`/api/project/${this._projectId}/export`, {download: true});
                    break
                }
            }
        }
        
        private bindElementEventListeners(): void {
            const items = this.querySelectorAll('.action_item');
            if (items) {
                for (const item of items) {
                    item.addEventListener('click', this.onItemClicked);
                }
            }
        }

        disconnectedCallback() {
            this.removeElementEventListeners();
        }

        private removeElementEventListeners(): void {
            const items = this.querySelectorAll('.action_item');
            if (items) {
                for (const item of items) {
                    item.removeEventListener('click', this.onItemClicked);
                }
            }
        }
    }
}
