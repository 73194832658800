/// <reference path="../Declarations/Index.d.ts" />
/// <reference path="../Decorators/Decorators.d.ts" />

/* COPYRIGHT (C) Ambrero Software B.V. - All rights reserved
 * This file is part of the Ambrero Base Framework and supplied under license.
 * Unauthorized copying of this file is strictly prohibited.
 * It is permitted to make changes to this file, as long as it is only used in this project.
 */
namespace Simplex.Components {
  import EventArgs = Ambrero.AB.Components.EventArgs;
  import Application = Ambrero.AB.Application;
  import APIResult = Simplex.Utils.APIResult;
  import FetchRequestHandler = Simplex.Components.FetchRequestHandler;

  interface UserDetails {
    id: string;
    firstName: string;
    lastName: string;
    language: string;
    numberFormat: string;
    dateFormat: string;
    timeFormat: string;
    authenticated: boolean;
    tenantSelect: boolean;
    roles: string[];
  }

  interface TenantDetails {
    admin: boolean;
    name: string;
  }

  interface Session {
    user: UserDetails;
    tenant: TenantDetails;
  }

  @Simplex.Decorators.Singleton(0)
  export class Auth extends ABComponent {
    private readonly app: Application;
    private profileData?: Session;
    private authenticated: boolean;
    private tenantSelect: boolean;
    // private redirectLoginUrl?: string;
    // private request: Simplex.Components.FetchRequestHandler;
    //private monitorSessionTimer?: number;

    public constructor(app: Application) {
      super();
      this.app = app;
      this.authenticated = true;
      this.tenantSelect = true;
      //@ts-ignore
      this.profileData = window.userContext;
      // this.request = this.app.getComponentType<FetchRequestHandler>(
      //   "FetchRequestHandler"
      // )!;

      //  app.getEventBus().on("reset", this.subscribeEventBus);
      //  this.subscribeEventBus();
    }

    public userId(): string | null | undefined {
      return this.profileData?.user.id;
    }

    public userFirstName(): string | undefined {
      return this.profileData?.user.firstName;
    }
    public userLastName(): string | undefined {
      return this.profileData?.user.lastName;
    }
    public get language(): string {
      return this.profileData?.user.language ?? "NL";
    }
    public get numberFormat(): string {
      return this.profileData?.user.numberFormat ?? "GroupDotAndDecimalComma";
    }
    public get dateFormat(): string {
      return this.profileData?.user.dateFormat ?? "DD-MM-YYYY";
    }
    public get timeFormat(): string {
      return this.profileData?.user.timeFormat ?? "Time24h";
    }

    public isAuthenticated = (cb: CallableFunction): void => {
      cb(
        new Simplex.Models.Authentication.AuthResult(
          true,
          this.profileData?.user.tenantSelect
        )
      );
    };

    public get isTenantSelect(): boolean {
      return this.profileData?.user.tenantSelect ?? false;
    }

    public get isAdminTenant(): boolean {
      return (
        (this.profileData?.tenant.admin ?? false) &&
        this.profileData?.tenant.name === "Simjo"
      );
    }
    public isSessionActive = (): boolean => {
      return true;
      //return this.profileData?.user.authenticated ?? false;
    };

    public refreshSession = (cb: CallableFunction): void => {
      //this.loadProfile(cb);
    };

    public readonly refreshSessionAsync =
      async (): Promise<Simplex.Models.Authentication.AuthResult> => {
        //return await this.loadProfileAsync();
        return Promise.resolve({ authenticated: true, tenantSelect: true });
      };

    public setAuthenticated = (state: boolean): void => {
      this.authenticated = state;
    };

    public storeUsername = (username: string): void => {
      sessionStorage.setItem("userid", username);
    };

    public getStoredUsername = (): string | null => {
      return sessionStorage.getItem("userid");
    };

    public hasRole = (role: string): boolean => {
      if (
        this.profileData === undefined ||
        this.profileData.user === null ||
        this.profileData.user.roles === null
      ) {
        return false;
      }
      return this.profileData.user.roles.indexOf(role) !== -1;
    };

    // public setLoginRedirect = (url?: string): void => {
    //   this.redirectLoginUrl = url;
    // };

    // public getLoginRedirect = (): string | undefined => {
    //   return this.redirectLoginUrl;
    // };

    // private readonly subscribeEventBus = (): void => {
    //   this.app
    //     .getEventBus()
    //     .subscribe(
    //       "requestCSRFTokenInvalid",
    //       this.handleInvalidSCRFToken,
    //       this
    //     );
    //   this.app
    //     .getEventBus()
    //     .subscribe("loginSuccess", () => this.loadProfile, this);
    //   this.app
    //     .getEventBus()
    //     .subscribe("logoutSuccess", () => this.loadProfile, this);
    // };

    // private readonly handleInvalidSCRFToken = (eventArgs: EventArgs): void => {
    //   console.error("PLACEHOLDER - INVALID CSRF TOKEN, RELOAD PAGE ");
    //   document.location.reload();
    // };

    // private readonly loadProfileAsync =
    //   async (): Promise<Simplex.Models.Authentication.AuthResult> => {
    //     const currentAuthenticationState: boolean =
    //       this.profileData?.user.authenticated ?? false;

    //     const session = await this.request.get<APIResult<Session>>(
    //       "/api/session"
    //     );

    //     if (!session.isSuccess) {
    //       return new Simplex.Models.Authentication.AuthResult();
    //     }

    //     const languageChanged =
    //       this.profileData?.user.language !== session.data.data?.user.language;
    //     if (languageChanged) {
    //       const language = this.app.getSystemComponent("Language") as Language;
    //       await language.languageChanged();
    //     }
    //     this.profileData = session.data.data;
    //     if (
    //       currentAuthenticationState != this.profileData?.user.authenticated
    //     ) {
    //       if (this.profileData?.user.authenticated === true) {
    //         this.emit("userLoggedIn", Simplex.Utils.createEventArgs({}, this));
    //         this.startMonitorSession();
    //       } else {
    //         this.emit("userLoggedOut", Simplex.Utils.createEventArgs({}, this));
    //         if (this.monitorSessionTimer) {
    //           clearTimeout(this.monitorSessionTimer);
    //           this.monitorSessionTimer = undefined;
    //         }
    //       }
    //     } else {
    //       this.emit(
    //         "sessionRefreshed",
    //         Simplex.Utils.createEventArgs({}, this)
    //       );
    //     }

    //     return new Simplex.Models.Authentication.AuthResult(
    //       this.profileData?.user.authenticated ?? false,
    //       this.profileData?.user.tenantSelect ?? false
    //     );
    //   };

    // private monitorSession = async (): Promise<void> => {
    //   if (!this.isSessionActive()) {
    //     return;
    //   }
    //   const session = await this.request.get<APIResult<Session>>(
    //     "/api/session"
    //   );
    //   if (!session.isSuccess || session.data.data) {
    //     if (session.data.data) {
    //       if (!session.data.data.user.authenticated) {
    //         this.app
    //           .getEventBus()
    //           .publish(
    //             "requestAuthenticationRequired",
    //             Simplex.Utils.createEventArgs({}, this)
    //           );
    //         return;
    //       }
    //     }
    //   }

    //   //  this.startMonitorSession();
    // };
    // private startMonitorSession = (): void => {
    //   setTimeout(this.monitorSession, 1000 * 60);
    // };

    // private readonly loadProfile = (cb?: CallableFunction): void => {
    //   const currentAuthenticationState: boolean =
    //     this.profileData?.user.authenticated ?? false;
    //   this.app.getRequestHandler().get("/api/session", {
    //     async: false,
    //     successHandler: (result: any): void => {
    //       this.profileData = result.data as Session;
    //       if (
    //         currentAuthenticationState != this.profileData?.user.authenticated
    //       ) {
    //         if (this.profileData?.user.authenticated === true) {
    //           this.emit(
    //             "userLoggedIn",
    //             Simplex.Utils.createEventArgs({}, this)
    //           );
    //           moment.locale(
    //             this.profileData?.user?.language?.toString().toLowerCase() ??
    //               "nl"
    //           );
    //           this.monitorSession();
    //         } else {
    //           this.emit(
    //             "userLoggedOut",
    //             Simplex.Utils.createEventArgs({}, this)
    //           );
    //           if (this.monitorSessionTimer) {
    //             clearTimeout(this.monitorSessionTimer);
    //             this.monitorSessionTimer = undefined;
    //           }
    //         }
    //       }

    //       if (cb) {
    //         cb(
    //           new Simplex.Models.Authentication.AuthResult(
    //             true,
    //             //this.profileData.user.authenticated,
    //             this.profileData.user.tenantSelect
    //           )
    //         );
    //       }
    //     },
    //     errorHandler: (): void => {
    //       this.profileData = undefined;
    //       if (currentAuthenticationState !== false) {
    //         this.emit("userLoggedOut", Simplex.Utils.createEventArgs({}, this));
    //       }
    //       if (cb) {
    //         cb(false);
    //       }
    //     },
    //   });
    // };
  }
}
