namespace Simplex.WebComponents.Project {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import APIResult = Simplex.Utils.APIResult;
    
    interface ScopeReference {
        id: string,
        index: number,
        direction: string,
        type: string,
        startScopeId: string,
        endScopeId: string,
        distance: number,
        dateDiff: number,
        multiStartCount: number,
        critical: boolean
    }
   
    @WebComponent("ui-scope-relation")
    export class ScopeRelation extends ABWebComponent {
        private readonly contentTemplate;
        private readonly connectorTemplate;
        private readonly multiTemplate;
        private readonly popupTemplate;
        private _rendered: boolean = false;
        private readonly _scopeReference: ScopeReference;
        private _referenceStart: boolean = true;
        private _referenceLength!: number;
        private _referenceLength2: number = 1;
        private _referenceDirection!: string;
        private _referencePosition!: string;
        private _referencePosition2!: string;
        private _projectId: string;
        private _tickWidth: number;
        private _multiStartCount: number;
        private _critical: boolean = false;
        
        public constructor(projectId: string, scopeReference: ScopeReference, tickWidth: number) {
            super();
            this._projectId = projectId;
            this._tickWidth = tickWidth;
            this._multiStartCount = scopeReference.multiStartCount;
            this._scopeReference = scopeReference;
            this._critical = scopeReference.critical;
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/ScopeRelation', 'ScopeRelation') as TemplateCallback;
            this.connectorTemplate = this.app.getTemplate('WebComponents/Project/ScopeRelation', 'ScopeRelationConnector') as TemplateCallback;
            this.multiTemplate = this.app.getTemplate('WebComponents/Project/ScopeRelation', 'ScopeRelationMulti') as TemplateCallback;
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/ScopeRelation', 'ScopeRelation') as TemplateCallback;
            this.popupTemplate = this.app.getTemplate('WebComponents/Project/ScopeRelation', 'ScopeRelationPopup') as TemplateCallback;
            this.parseReference();
            if(scopeReference.critical) {
                this.classList.add('is--critical');
            }
        }

        public generateConnector = ():string => {
            return this.connectorTemplate({critical: this._critical});
        };

        public generateMultiCount = (multiStartCount: number):string => {
            return this.multiTemplate({multiStartCount: multiStartCount});
        };
        private parseReference = () => {
            this._referenceLength = this._scopeReference.distance;
            this._referenceDirection = this._scopeReference.direction;
            switch(this._scopeReference.type) {
                case "EqualStart":
                    this._referencePosition = "left";
                    break;
                case "EqualEnd":
                    this._referencePosition = "right";
                    break;
                case "StartAfterEnd":
                    this._referenceLength = this._scopeReference.distance;
                    this._referenceLength2 = this._scopeReference.distance;
     
                    if(this._referenceDirection === "up") {
                        if(this._scopeReference.distance % 2 === 0) {
                            this._referenceLength = Math.max((this._scopeReference.distance / 2) * 2 -1, 1);
                            this._referenceLength2 = this._scopeReference.distance * 2 - this._referenceLength;
                        } 
                        this._referencePosition = "right";
                        this._referencePosition2 = "left";
                    } else {
                        
                        if(this._scopeReference.distance % 2 === 0) {
                            this._referenceLength2= Math.max((this._scopeReference.distance / 2) * 2 -1 , 1);
                            this._referenceLength = this._scopeReference.distance * 2 - this._referenceLength2;
                        }
                        this._referencePosition = "left";
                        this._referencePosition2= "right";
                    }
                    
                    break;
            }    
        };
        
        private onRelationClick = (event: Event) => {
            event.preventDefault();
            event.stopPropagation();
            let multi = false;
            const grid =  document.querySelector('ui-calender-grid') as HTMLElement;
            if(!event.target || !grid) {
                return;
            }
            const target = event.target as HTMLElement;
            if(target.classList.contains('multistart')) {
                multi = true;
            }
            
            const gridRelation = document.querySelector('.JSRelation') as HTMLElement;
            gridRelation.innerHTML = this.popupTemplate({multi: multi});
            const gridPosition = grid.getBoundingClientRect();
            const position = target.getBoundingClientRect();

            let leftOffsetRelationPopup = 0;
            if(gridPosition.x + gridPosition.width < position.x + 180) {
                leftOffsetRelationPopup = 200;
            }
            gridRelation.style.left = `${position.x - gridPosition.x + grid.scrollLeft + 25 - leftOffsetRelationPopup}px`;
            gridRelation.style.top = `${(position.y - gridPosition.y) - 36}px`;
            gridRelation.style.display = `block`;

            const editOption = gridRelation.querySelector('.JSedit') as HTMLElement;
            const deleteOption = gridRelation.querySelector('.JSdelete') as HTMLElement;
            if(editOption) {
                editOption.addEventListener('click', this.onEditRelation);
            }
            if(deleteOption) {
                deleteOption.addEventListener('click', this.onDeleteRelation);
            }
            document.addEventListener('click', this.onDocumentClick);
        }

        private isClickInPopup = (node: HTMLElement): boolean => {
            const gridRelation = document.querySelector('.JSRelation') as HTMLElement;
            while (node !== document.body && node.parentElement !== null) {
                if (node == gridRelation) {
                    return true;
                }
                node = node.parentElement;
            }
            return false;
        }

        private onDocumentClick = (event: MouseEvent): void => {
            if (!this.isClickInPopup(event.target as HTMLElement)) {
                const gridRelation = document.querySelector('.JSRelation') as HTMLElement;
                gridRelation.innerHTML = '';
                gridRelation.style.display = `none`;
            }
        };

        private closeRelationContext = () => {
            const gridRelation = document.querySelector('.JSRelation') as HTMLElement;
            gridRelation.innerHTML = '';
            gridRelation.style.display = `none`;
        };
        
        private onEditRelation = () => {
            this.closeRelationContext();
            this.dispatchEvent(new CustomEvent("relationEdit", {bubbles: true, detail: { id: this._scopeReference.startScopeId, editing: true}} ) );
        }
        private onDeleteRelation = async () => {
            this.closeRelationContext();
            const createResult = await this.request.delete<APIResult<void>>(`/api/project/${this._projectId}/scopes/${this._scopeReference.startScopeId}/reference/${this._scopeReference.id}`);
            if (createResult.isSuccess) {
                this.dispatchEvent(new CustomEvent("relationDeleted", {bubbles: true} ) );
            }
        };
        
        render() {
            this.innerHTML = this.contentTemplate({
                start: this._referenceStart,
                length: this._referenceLength,
                length2: this._referenceLength2,
                direction: this._referenceDirection,
                position: this._referencePosition,
                position2: this._referencePosition2,
                reference: this._scopeReference,
                tickwidth: this._tickWidth,
                multiStartCount: this._multiStartCount,
                critical: this._critical
            });

            this.style.setProperty('--tick-width',`${this._tickWidth}px`);
            const triangle = this.querySelector('.JSRelationEdit') as HTMLElement;
            if(triangle) {
                triangle.addEventListener('click', this.onRelationClick);
            }
            this._rendered = true;
        }
        
        public addMultiRelationEventHandler = () => {
            const circle = this.querySelector('.JSRelationEdit') as HTMLElement;
            if(circle) {
                circle.addEventListener('click', this.onRelationClick);
            }
        }

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if(!this._rendered) {
                this.render();

            }
        }
    }
}