namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class ScopeSummary extends ABModel {

        public id: string|null;
        public name: string;
 
        constructor(id: string|null = null,
                    name: string = "") {
            super();
            this.id = id;
            this.name = name;
        }
    }
}