namespace Simplex.WebComponents.Project.DashboardProjectSnapshot.Models {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class SnapshotBaselineUpdate extends ABModel {

        public projectId: string;
        public setAsBaseline: boolean;

        constructor(projectId: string, setAsBaseline: boolean
        ) {
            super();
            this.projectId = projectId;
            this.setAsBaseline = setAsBaseline;
        }
    }
}