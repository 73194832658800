namespace Simplex.WebComponents {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import ScrollEvent = JQuery.ScrollEvent;
    import EditScopeForm = Simplex.WebComponents.Project.EditScopeForm;
    import AddScope = Simplex.WebComponents.Project.AddScope;
    
    @WebComponent("ui-date-grid")
    export class DateGrid extends ABWebComponent {
        private readonly _interval: [];
        private readonly contentTemplate;
        private _rendered: boolean;
        private _planningHeader?: HTMLElement;
        private _currentYear?: HTMLElement;
        private _today?: HTMLElement;
        private _daterangeStart?: HTMLElement;
        private _daterangeEnd?: HTMLElement;
        private _fader?: HTMLElement;
        private _calender?: HTMLElement;
        private _planning?: Planning;
        private _isFixed: boolean;
        private _originalTop: number;
        private _scrollingElement?: Document;
        private _yearly: boolean;
        
        public constructor(interval: [], yearly: boolean) {
            super();
            this._interval = interval;
            this._rendered = false;
            this._isFixed = false;
            this._originalTop = 0;
            this._yearly = yearly;
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/CalenderGrid', 'DateGrid') as TemplateCallback;
            this.setEventListeners();
        }

        public isFixed = ():boolean => {
            return this._isFixed;
        } 
        
        render() {
            this.innerHTML = this.contentTemplate({
                interval: this._interval,
                yearly: this._yearly
            });
            this._planningHeader = document.querySelector('.planning__title') as HTMLElement;
            if(this._planningHeader.dataset.originalTop) {
                this._originalTop = parseFloat(this._planningHeader.dataset.originalTop);
            } else {
                this._originalTop = this._planningHeader.getBoundingClientRect().top - 40;
            }
            this._currentYear = document.querySelector('.currentyear') as HTMLElement;
            this._today = document.querySelector('.today') as HTMLElement;
            this._fader = document.querySelector('.fader') as HTMLElement;
            this._daterangeStart  = document.querySelector('.JSdaterangeStart') as HTMLElement;
            this._daterangeEnd  = document.querySelector('.JSdaterangeEnd') as HTMLElement;
            this._calender = document.querySelector('ui-calender-grid') as HTMLElement;
            this._planning = document.querySelector('ui-planning') as Planning;
            this._rendered = true;
        }
        
        private closeOpenFormInRange = () => {
            const editForm  = document.querySelector('ui-edit-scope-form') as EditScopeForm;
            if(editForm && this._isFixed) {
                const editFormPosition = editForm.getBoundingClientRect();
                if(editFormPosition.top < 120) {
                    editForm.cancelEdit();
                    return;
                }
            }
        }
        
        private closeAddFormInRange = () => {
            const addForm  = document.querySelector('ui-add-scope') as AddScope;
            if(addForm && this._isFixed) {
                const addFormPosition = addForm.getBoundingClientRect();
                if(addFormPosition.top < 120) {
                    addForm.cancelAdd();
                    return;
                }
            }
        }
        
        private onScroll = (evt:Event): void => {
            if (!this.isConnected || !this._planningHeader) {
                return;
            }
            this._scrollingElement = evt.target as Document;
        //   this.closeOpenFormInRange();
        //   this.closeAddFormInRange();

       
                
                const scrollTop = this._scrollingElement.scrollingElement?.scrollTop || 0;

                if (scrollTop > this._originalTop) {
                    if (this._isFixed) {
                        this._planningHeader.dataset.originalTop = `${this._originalTop}`;
                        this.dragAlong(this._calender!.scrollLeft);
                        return;
                    }
                    this._planningHeader.classList.add('is--fixed');
                    this._currentYear?.classList.add('is--fixed');
                    
                    
                    this._fader?.classList.add('is--fixed');
                    this.classList.add('is--fixed');
                    this.style.left = `-${this._calender!.scrollLeft}px`;
                    let newMarginLeft = this._today!.getBoundingClientRect().left + this._calender!.scrollLeft;
                    this._today!.style.marginLeft = `${newMarginLeft}px`;
                    this._today?.classList.add('is--fixed');
                    this._today!.style.left = `-${this._calender!.scrollLeft}px`;

                    if(this._planning!.hasSelectedScope()) {
                        let newMarginLeftDateStart = this._daterangeStart!.getBoundingClientRect().left + this._calender!.scrollLeft;
                        this._daterangeStart!.style.marginLeft = `${newMarginLeftDateStart}px`;
                        this._daterangeStart?.classList.add('is--fixed');
                        this._daterangeStart!.style.left = `-${this._calender!.scrollLeft}px`;

                        let newMarginLeftDateEnd = this._daterangeEnd!.getBoundingClientRect().left + this._calender!.scrollLeft;
                        this._daterangeEnd!.style.marginLeft = `${newMarginLeftDateEnd}px`;
                        this._daterangeEnd?.classList.add('is--fixed');
                        this._daterangeEnd!.style.left = `-${this._calender!.scrollLeft}px`;
                        this.dragAlong(this._calender!.scrollLeft);
                    }
                    this._isFixed = true;
                } else {
                    if (!this._isFixed) {
                        return;
                    }
                    this._planningHeader.classList.remove('is--fixed');
                    this._currentYear?.classList.remove('is--fixed');
                    
                    this._fader?.classList.remove('is--fixed');
                    this.classList.remove('is--fixed');
                    this.style.left = `0`;
                    
                    const todayStyle = window.getComputedStyle(this._today!);
                    let marginLeft = parseFloat(todayStyle.marginLeft) - 500;
                    this._today!.style.left = `${marginLeft}px`;
                    this._today!.style.marginLeft = `0`;
                    this._today?.classList.remove('is--fixed');
                    if(this._planning!.hasSelectedScope()) {
                        const startStyle = window.getComputedStyle(this._daterangeStart!);
                        let marginLeftStart = parseFloat(startStyle.marginLeft) - 500;
                        this._daterangeStart!.style.left = `${marginLeftStart}px`;
                        this._daterangeStart!.style.marginLeft = `0`;
                        this._daterangeStart?.classList.remove('is--fixed');

                        const endStyle = window.getComputedStyle(this._daterangeEnd!);
                        let marginLeftEnd = parseFloat(endStyle.marginLeft) - 500;
                        this._daterangeEnd!.style.left = `${marginLeftEnd}px`;
                        this._daterangeEnd!.style.marginLeft = `0`;
                        this._daterangeEnd?.classList.remove('is--fixed');
                    }
                    
                    this._isFixed = false;
                }
            
           
        }
        
        public dragAlong = (offsetLeft: number): void => {
            if(!this._isFixed) {
                this._today!.classList.remove('out-of-bounds');
                this._daterangeStart!.classList.remove('out-of-bounds');
                this._daterangeEnd!.classList.remove('out-of-bounds');
                return;
            }
            this.style.left = `-${offsetLeft}px`;
            if(this._today) {
                this._today.style.left = `-${offsetLeft}px`;
                if(this._today.getBoundingClientRect().left < 492) {
                    this._today.classList.add('out-of-bounds');
                } else {
                    this._today.classList.remove('out-of-bounds');
                }
            }
            if(this._planning!.hasSelectedScope() && this._daterangeStart && this._daterangeEnd) {
                this._daterangeStart.style.left = `-${offsetLeft}px`;
                this._daterangeEnd.style.left = `-${offsetLeft}px`;
                if(this._daterangeStart.getBoundingClientRect().left < 460) {
                    this._daterangeStart.classList.add('out-of-bounds');
                } else {
                    this._daterangeStart.classList.remove('out-of-bounds');
                } 
                if(this._daterangeEnd.getBoundingClientRect().left < 492) {
                    this._daterangeEnd.classList.add('out-of-bounds');
                } else {
                    this._daterangeEnd.classList.remove('out-of-bounds');
                }
            }
            
        };
        
        private removeEventListeners = () => {
            document.removeEventListener("scroll", this.onScroll);
        };
        private setEventListeners = () => {
            document.addEventListener("scroll", this.onScroll);
        };

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if(!this._rendered) {
                this.render();
            }
        }
        disconnectedCallback() {
            this.removeEventListeners();
        }
    }    
    
}