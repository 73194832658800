namespace Simplex.WebComponents.Project {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import ModelBinder = Ambrero.AB.Components.ModelBinder;
    import ContentNotifier = Simplex.Components.ContentNotifier;
    import Inject = Simplex.Decorators.Inject;
    import APIResult = Simplex.Utils.APIResult;
    import Button = Simplex.WebComponents.FormElements.Button;
    import EventArgs = Ambrero.AB.Components.EventArgs;
    import ConfirmDialog = Simplex.Components.ConfirmDialog;
    import ScopeSummary = Simplex.Models.Project.ScopeSummary;
    
    @WebComponent("ui-edit-cost-category-form")
    export class EditCostCategoryForm extends ABWebComponent {
        private readonly contentTemplate;
        private readonly _createModel: Simplex.Models.Project.CreateCostCategoryRequest;
        private readonly _costCategory?: Simplex.Models.Project.CostCategory;
        private readonly _binder: ModelBinder;
        @Inject("ContentNotifier")
        private readonly _contentNotifier!: ContentNotifier;
        private readonly _projectId: string;
        private _delete: HTMLElement | null = null;
        private submit?: Button;
        private cancel?: Button;
        private readonly _multipleYears: boolean = false;

        public constructor(projectId?: string, costCategory?: Simplex.Models.Project.CostCategory, multipleYears?:boolean) {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/EditCostCategories/EditCostCategoryForm', 'EditCostCategoryForm') as TemplateCallback;
            this._binder = this.app.getComponentType<ModelBinder>("ModelBinder")!;
            if (projectId === undefined) {
                this._projectId = this.getAttribute("project-id") ?? "";
            } else {
                this._projectId =  projectId;
            }
            this._multipleYears = multipleYears ?? false;
            this._costCategory = costCategory;
            this._createModel = new Simplex.Models.Project.CreateCostCategoryRequest(costCategory);
            this._binder.bind(this._createModel, this._contentNotifier);
        }

        get costCategory() {
            return this._createModel;
        }

        async render() {
            this.innerHTML = this.contentTemplate({
                    model: this._createModel,
                    costCategory: this._costCategory
                }
            );
            this.cancel = this.querySelector(".cancelButton") as Button;
            this.submit = this.querySelector(".submitButton") as Button;
            this._delete = this.querySelector(".deleteButton") as Button;
            if (this.submit) {
                this.submit.addEventListener("click", this.onSubmit);
            }
            if (this.cancel) {
                this.cancel.addEventListener("click", this.onCancel);
            }
            if (this._delete) {
                this._delete.addEventListener("click", this.onDeleteCostCategory);
            }
            this._contentNotifier.notifyDraw(this);
        }

        reset = ():void => {
            this._binder.reset(this._createModel);
        }

        onSubmit = async (event:MouseEvent):Promise<void> => {
            event.preventDefault();
            event.stopPropagation();

            if (!this._binder.validate(this._createModel)) {
                return;
            }
            if (this.submit) {
                this.submit.loading = true;
            }
            if(this._costCategory) {
                const updateResult = await this.request.put<APIResult<void>>(`/api/project/${this._projectId}/costCategories/${this._costCategory.id}`, this._createModel);
                if (updateResult.isSuccess) {
                    this.dispatchEvent(new Event("costCategoryChanged",{bubbles:true}));
                } else {
                    // alert("Fout?");
                }
            } else {
                const createResult = await this.request.post<APIResult<void>>(`/api/project/${this._projectId}/costCategories`, this._createModel);
                if (createResult.isSuccess) {
                    this.dispatchEvent(new CustomEvent("costCategoryCreated", {bubbles: true, detail: new Simplex.Models.Project.CostCategoryCreated(this._createModel.parentCostCategoryId)} ) );
                } else {
                    // alert("Fout?");
                }
            }
            if (this.submit) {
                this.submit.loading = false;
            }
        }
        onDeleteCostCategoryConfirmed = async (event:EventArgs) => {
            event.handled = true;

            if(!this._costCategory) {
                return;
            }
            const deleteResult = await this.request.delete<APIResult<void>>(`/api/project/${this._projectId}/costCategories/${this._costCategory.id}`);
            if (deleteResult.isSuccess) {
                this.dispatchEvent(new Event("costCategoryChanged", {bubbles:true}));
            }
        }

        onDeleteCostCategory = async (event:MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();

            if(!this._costCategory) {
                return;
            }

            const result = await this.request.get<APIResult<ScopeSummary[]>>(`/api/project/${this._projectId}/costCategories/${this._costCategory.id}/scopes`);
            if (!result.isSuccess) {
                return null;
            }

            let deleteMessage = '';
            if(result.data.data && result.data.data.length > 0) {
                deleteMessage = 'project.cost_categories_with_scopes.delete.confirm.text';
            }
            else if(this._costCategory.children.length > 0) {
                deleteMessage = 'project.cost_categories_with_children.delete.confirm.text';
            } else {
                deleteMessage = 'project.cost_categories.delete.confirm.text';
            }

            const confirmDialog = this.app.getComponent('ConfirmDialog',
                Messages('project.cost_categories.delete.confirm.title'),
                Messages(deleteMessage, this._costCategory.name, (result.data.data && result.data.data.length > 0 ? result.data.data[0].name : '')),
                null, null, 'warning'
            ) as ConfirmDialog;
            confirmDialog.show();
            confirmDialog.on('yes', this.onDeleteCostCategoryConfirmed);
        }
        
        onCancel = async (event:MouseEvent):Promise<void> => {
            event.preventDefault();
            event.stopPropagation();

            this.removeAttribute("open");
            this.dispatchEvent(new Event("cancel", {bubbles: true}));
        }

        async connectedCallback() {
            if (!this.isConnected) {
                return;
            }

            await this.render();
        }
    }
}
