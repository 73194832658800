namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class ScopeBudgetUpdate extends ABModel {
        public budget: number|null;
        constructor(budget: number|null = null) {
            super();
            this.budget = budget;
        }        
    }
}