namespace Simplex.WebComponents.FormElements {
    import WebComponent = Simplex.Decorators.WebComponent;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import IModel = Ambrero.AB.Models.IModel;
    
    @WebComponent("ui-autocomplete-list")
    export class AutocompleteList<T extends IModel> extends ABWebComponent implements Node {
        private readonly _items: T[];
        private readonly _autocompleteItems: AutocompleteItem<T>[];
        private readonly _templatePath: string;
        private readonly _templateName: string;
        
        public constructor(templatePath:string, templateName:string) {
            super();
            this._templatePath = templatePath;
            this._templateName = templateName;
            this._items = [];
            this._autocompleteItems = [];
        }

        render() {
            this.updateList();
        }

        public validate = ():boolean => {
            for (const autocompleteItem of this._autocompleteItems) {
                if(!autocompleteItem.valid()) {
                    return false;
                }
            }
            return true;
        }
        
        public setItems = (items:T[]):void => {
            this._items.length = 0;
            for (let i = 0; i < items.length; i++) {
                this._items.push(items[i]);
            }
            this.updateList();
        }
        
        private updateList = ():void => {
            if (!this.isConnected) {
                return;
            }
            this.innerHTML = '';
            this._autocompleteItems.length = 0;
            for (let i = 0; i < this._items.length; i++) {
                const autocompleteItem = new AutocompleteItem(this._items[i], this._templatePath, this._templateName);
                this._autocompleteItems.push(autocompleteItem);
                this.appendChild(autocompleteItem);
            }
            if(this._autocompleteItems.length === 0) {
                this.style.display = "none";
            } else {
                this.style.display = "block";
            }
        }

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }

            this.render();
        }
    }
}
