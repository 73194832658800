namespace Simplex.HandlebarHelper {
    export const run: Handlebars.HelperDelegate = function(): string {
        let object = arguments[0];
        let method = arguments[1];
        if (object === undefined || object === null) {
            return "_method " + method + " not found_";
        }
        if (method in object) {
            return object[method].apply(object);
        }
        throw new Error("_method " + method + " not found_");
    }
}

Handlebars.registerHelper('run', Simplex.HandlebarHelper.run);