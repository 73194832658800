namespace Simplex.Components {
    export class RequestState {
        
        private readonly _state: Map<string, any>;
        
        constructor() {
            this._state = new Map<string, any>();
        }
        
        public setState = (key: string, value: any): void => {
            this._state.set(key, value);
        }
        
        public getState = (key:string):any|null => {
            return this._state.get(key);
        }
    }
}