namespace Simplex.Project.Models {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class ProjectTask extends ABModel {

        public id: string;
        public completedDate: string | null;
        public type: ProjectTaskType;
        public priority?: string;
        public priorityName?: string;
        public actionUrl?: string;
        public scopeIds: string[] = [];

        constructor(id: string, type: ProjectTaskType, scopeIds: string[], completedDate: string | null
        ) {
            super();
            this.id = id;
            this.type = type;
            this.scopeIds = scopeIds;
            this.completedDate = completedDate;
        }

        static getPriorityByType(type: Simplex.Project.Models.ProjectTaskType): ProjectTaskPriorityType {
            switch (type) {
                case ProjectTaskType.ProjectEndDateBaselineOverride:
                case ProjectTaskType.TotalScopesBudgetBaselineOverride:
                case ProjectTaskType.ProjectScopePeriodMissing:
                case ProjectTaskType.ProjectScopeBudgetMissing:
                    return ProjectTaskPriorityType.Error;
                case ProjectTaskType.MissingProjectInformation:
                case ProjectTaskType.MissingScopes:
                case ProjectTaskType.MissingPlanning:
                case ProjectTaskType.MissingScopeBudget:
                case ProjectTaskType.MissingSnapshot:
                case ProjectTaskType.MissingBaseline:
                case ProjectTaskType.MissingCostCategories:
                case ProjectTaskType.ProjectScopeEndDateBaselineOverride:
                case ProjectTaskType.CriticalScopeEndDateBaselineOverride:
                case ProjectTaskType.ProjectScopeBudgetBaselineOverride:
                case ProjectTaskType.ProjectBudgetChangedMakeSnapshot:
                case ProjectTaskType.ProjectPeriodChangedMakeSnapshot:
                case ProjectTaskType.ProjectScopeAddedOrRemovedMakeSnapshot:
                    return ProjectTaskPriorityType.Warning;
            }
            return ProjectTaskPriorityType.Info;
        }

        static getActionUrl(type: Simplex.Project.Models.ProjectTaskType, projectId: string): string | undefined {
            switch (type) {
                case ProjectTaskType.MissingSnapshot:
                    return `/project/${projectId}/snapshots/create`;
                case ProjectTaskType.MissingBaseline:
                case ProjectTaskType.ProjectBudgetChangedMakeSnapshot:
                case ProjectTaskType.ProjectPeriodChangedMakeSnapshot:
                case ProjectTaskType.ProjectScopeAddedOrRemovedMakeSnapshot:
                    return `/project/${projectId}/snapshots`;
                case ProjectTaskType.MissingCostCategories:
                    return `/project/${projectId}/costCategories`;
                case ProjectTaskType.MissingScopeBudget:
                case ProjectTaskType.ProjectScopeBudgetBaselineOverride:
                case ProjectTaskType.TotalScopesBudgetBaselineOverride:
                case ProjectTaskType.ProjectScopeBudgetMissing:
                    return `/project/${projectId}/budget`;
                case ProjectTaskType.MissingProjectInformation:
                    return `/project/${projectId}/edit`;
                case ProjectTaskType.ProjectScopeEndDateBaselineOverride:
                case ProjectTaskType.ProjectScopePeriodMissing:
                case ProjectTaskType.CriticalScopeEndDateBaselineOverride:
                case ProjectTaskType.MissingScopes:
                case ProjectTaskType.MissingPlanning:
                case ProjectTaskType.ProjectEndDateBaselineOverride:
                    return `/project/${projectId}/planning`;                    
            }
            return undefined;
        }
    }

    export enum ProjectTaskType {
        MissingProjectInformation = "MissingProjectInformation",
        MissingScopes = "MissingScopes",
        MissingPlanning = "MissingPlanning",
        MissingScopeBudget = "MissingScopeBudget",
        MissingSnapshot = "MissingSnapshot",
        MissingBaseline = "MissingBaseline",
        MissingCostCategories = "MissingCostCategories",
        ProjectScopeEndDateBaselineOverride = "ProjectScopeEndDateBaselineOverride",
        CriticalScopeEndDateBaselineOverride = "CriticalScopeEndDateBaselineOverride",
        ProjectEndDateBaselineOverride = "ProjectEndDateBaselineOverride",
        TotalScopesBudgetBaselineOverride = "TotalScopesBudgetBaselineOverride",
        ProjectScopeBudgetBaselineOverride = "ProjectScopeBudgetBaselineOverride",
        ProjectBudgetChangedMakeSnapshot = "ProjectBudgetChangedMakeSnapshot",
        ProjectPeriodChangedMakeSnapshot = "ProjectPeriodChangedMakeSnapshot",
        ProjectScopeAddedOrRemovedMakeSnapshot = "ProjectScopeAddedOrRemovedMakeSnapshot",
        ProjectScopePeriodMissing = "ProjectScopePeriodMissing",
        ProjectScopeBudgetMissing = "ProjectScopeBudgetMissing"
    }

    export enum ProjectTaskPriorityType {
        Error = "Error",
        Warning = "Warning",
        Info = "Info"
    }
}