namespace Simplex.Controllers {

    import Content = Simplex.Components.Content;
    import Layout = Simplex.Components.Layout;
    import Inject = Simplex.Decorators.Inject;
    import Get = Simplex.Decorators.Get;
    import FetchRequestHandler = Simplex.Components.FetchRequestHandler;
    import Auth = Simplex.Components.Auth;
    import APIResult = Simplex.Utils.APIResult;
    import ModelBinder = Ambrero.AB.Components.ModelBinder;
    import Profile = Simplex.Models.Authentication.Profile;

    interface IndexContext {
    }
    interface ProfileContext {
        model: Simplex.Models.Authentication.Profile
    }

    @Simplex.Decorators.Authenticated()
    @Simplex.Decorators.Controller("/")
    export class Home extends Ambrero.AB.Controllers.Controller {

        private static readonly CLASS_LOADING_INIT = "is--loading";

        @Inject("Layout")
        private layout!: Layout

        @Inject("FetchRequestHandler")
        private request!: FetchRequestHandler

        @Inject("Auth")
        private auth!: Auth
        
        @Get()
        public Index(): void {
            if(this.auth.isAdminTenant){
                this.app.navigateTo('/admin/tenant');
                return;
            }
            const content = this.app.getComponent('Content', 'Home/index') as Content<IndexContext>;
            content.setTargetContainerElement(this.layout.getContentRenderTarget());
            content.setContext({} as IndexContext);
            content.draw();
        }
        @Get("/profile")
        public async Profile(): Promise<void> {
            const modelBinder = this.app.getComponentType<ModelBinder>('ModelBinder')!;
            const notice = this.app.getComponent('AmbreroComponents.Notice');
            const content = this.app.getComponent('Content', 'Home/profile') as Content<ProfileContext>;
            content.setTargetContainerElement(this.layout.getContentRenderTarget());
            content.setContext({} as ProfileContext);
            
            const onSave = async ():Promise<void> => {
                if (!modelBinder.validate(content.getContext().model)) {
                    return;
                }

                this.eventBus.publish('profileSaving', Simplex.Utils.createEventArgs({state: true}, this));

                const xx = content.getContext().model;
                const saveResponse = await this.request.put<APIResult<void>>('/api/profile',xx);

                this.eventBus.publish('profileSaving', Simplex.Utils.createEventArgs({state: false}, this));
                
                if (!saveResponse.isSuccess) {
                    notice.addMessage("Fout bij het opslaan van het profiel", 'warning');
                    return;
                }
                moment.locale(xx?.language?.toString().toLowerCase() ?? 'nl');
                //@ts-ignore
                if(window.userContext?.user != undefined){
                    //@ts-ignore
                    window.userContext?.user= {...window.userContext?.user,...xx };
                }
                content.draw();
                // notice.addMessage("Profiel opgeslagen", 'success');
                // await this.auth.refreshSessionAsync();
                return;
            }
            
            const profileResponse = await this.request.get<APIResult<Profile>>("/api/profile");
            if (!profileResponse.isSuccess || !profileResponse.data.data) {
                this.app.navigateTo("/");
                return;
            }
            
            content.getContext().model = profileResponse.data.data;
            modelBinder.bind<Content<IndexContext>>(content.getContext().model, content);

            content.on("submit", onSave);
            
            content.draw();
        }
        
    }

}