namespace Simplex.Components {
    import WebComponent = Simplex.Decorators.WebComponent;
    import APIResult = Simplex.Utils.APIResult;
    import ScopesData = Simplex.Models.Project.ScopesData;
    import Scope = Simplex.Models.Project.Scope;
    import InputSelect = Simplex.WebComponents.FormElements.InputSelect;
    import AutocompleteList = Simplex.WebComponents.FormElements.AutocompleteList;
    import Autocomplete = Simplex.WebComponents.FormElements.Autocomplete;
    import CostCategorySummary = Simplex.Models.Project.CostCategorySummary;
    import AutocompleteItemMutationEvent = Simplex.WebComponents.FormElements.AutocompleteItemMutationEvent;
    import ModelBinder = Ambrero.AB.Components.ModelBinder;
    import ContentNotifier = Simplex.Components.ContentNotifier;
    import Inject = Simplex.Decorators.Inject;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ScopeBudget = Simplex.Models.Project.ScopeBudget;
    import EventArgs = Ambrero.AB.Components.EventArgs;

    @WebComponent("ui-add-cost-category-modal-form")
    export class AddCostCategoryModalForm extends ABWebComponent {
        private readonly contentTemplate;
        private readonly _binder: ModelBinder;
        @Inject("ContentNotifier")
        private readonly _contentNotifier!: ContentNotifier;
        private _rendered: boolean = false;
        private _scopes?: Scope[];
        private _projectScopeBudgetAutocomplete?: Autocomplete<CostCategorySummary>;
        private _projectScopeBudgetAutocompleteList: AutocompleteList<ScopeBudget>|null = null;
        private readonly _createModel: Simplex.Models.Project.CreateScopeBudgetItem;
        
        public constructor() {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/Budget/BudgetTable/AddCostCategoryModal', 'AddCostCategoryModalForm') as TemplateCallback;
            this._binder = this.app.getComponentType<ModelBinder>("ModelBinder")!;
            this._createModel = new Simplex.Models.Project.CreateScopeBudgetItem();
            this._binder.bind(this._createModel, this._contentNotifier)
                .on("PropertyChanged", this.propertyChanged);;
        }

        private propertyChanged = (eventArgs: EventArgs) : void => {
            switch (eventArgs.data.field) {
                case "scopeId":
                    if(this.createModel) {
                        const scope = this._scopes?.find(s=>s.id == eventArgs.data.element.value);
                        if(scope) {
                            this.createModel.scopeHasChildren = scope.hasChildScopesWithBudgetDefined;
                        }
                    }
                    break;
            }
        }

        private budgetsValidate =():boolean =>{
            const budgetsValid = this._createModel.validateBudget();
            if (!budgetsValid) {
                this._projectScopeBudgetAutocomplete?.inputInvalid("planning.form.field.budget_breakdown.required");
                return false;
            }
            this._projectScopeBudgetAutocomplete?.inputValid();
            return budgetsValid;
        }
        
        get createModelValid():boolean {
            const budgetsValid = this.budgetsValidate() && this._projectScopeBudgetAutocompleteList !== null && this._projectScopeBudgetAutocompleteList.validate();            
            return this._binder.validate(this._createModel) && budgetsValid;
        }
        get createModel(): Simplex.Models.Project.CreateScopeBudgetItem {
            return this._createModel;
        }
        
        public render = async (): Promise<void> => {
            const projectId = this.getAttribute('project-id');
            this.innerHTML = this.contentTemplate({
                    projectId: projectId
                }
            );
            const scopesResult = await this.request.get<APIResult<ScopesData>>(`/api/project/${projectId}/scopes`);
            this._scopes = scopesResult.isSuccess && scopesResult.data.data ? scopesResult.data.data.scopes : [];

            if(this._scopes) {
                const mainScopeInputSelect = this.querySelector('ui-select[name="scopeId"]') as InputSelect;
                let inputSelectScopeOptions = `<option value="" class="i18n">project.budget.table.addNew.modal.scope_item.select</option>`;
                inputSelectScopeOptions += this.getScopesToSelectOptionsForScopes(this._scopes);
                mainScopeInputSelect.addEventListener('change', (event: Event) => this.onScopeChanged(event));
                mainScopeInputSelect.setOptions(inputSelectScopeOptions);
            }

            const costTypeList = this.querySelector('.costtypelist');
            if(costTypeList) {
                this._projectScopeBudgetAutocomplete = new Autocomplete<CostCategorySummary>(`/api/project/${projectId}/costCategories/find`, "findCostCategories", "planning.form.field.budget_breakdown.placeholder", this.getSummaryText, "planning.form.field.budget_breakdown.no_result", true);
                this._projectScopeBudgetAutocompleteList = new AutocompleteList<ScopeBudget>("WebComponents/Project/Budget/BudgetTable/AddCostCategoryModal", "ProjectScopeBudgetListItem");
                costTypeList.appendChild(this._projectScopeBudgetAutocomplete);
                costTypeList.appendChild(this._projectScopeBudgetAutocompleteList);
                if (this._projectScopeBudgetAutocompleteList) {
                    this._projectScopeBudgetAutocomplete.addEventListener("selected", this.onCostCategorySelected);
                    this._projectScopeBudgetAutocompleteList.addEventListener('AutocompleteItemChange', this.onProjectScopeBudgetListChange);
                }
            }
            this._binder.bind(this._createModel, this._contentNotifier);
            this._rendered = true;
            this._contentNotifier.notifyDraw(this);
        }

        private onProjectScopeBudgetListChange = (event: Event): void => {
            event.stopPropagation();
            if(!this._createModel) {
                return;
            }
            const mutationEvent = event as CustomEvent<AutocompleteItemMutationEvent<ScopeBudget>>;
            const costCategoryIndex = this._createModel.budgets.findIndex(u => u.costCategoryId === mutationEvent.detail.item.costCategoryId);
            if (costCategoryIndex === -1) {
                return;
            }
            
            switch (mutationEvent.detail.action) {
                case "remove":
                    this._createModel.budgets.splice(costCategoryIndex,1);
                    break;

            }
            if (this._projectScopeBudgetAutocompleteList) {
                this._projectScopeBudgetAutocompleteList.setItems(this._createModel.budgets);
            }
            this.budgetsValidate();
        }
        
        private onCostCategorySelected =  (event:Event):void => {
            if (!this._createModel) {
                return;
            }
            const customEvent = event as CustomEvent<CostCategorySummary>;
            const costCategorySummary = customEvent.detail;
            const existing = this._createModel.budgets.findIndex(u => u.costCategoryId === costCategorySummary.id);
            if (existing !== -1) {
                return;
            }
            this._createModel.budgets.push(new ScopeBudget(costCategorySummary.id, costCategorySummary.name, costCategorySummary.code));
            if (this._projectScopeBudgetAutocompleteList && this._createModel) {
                this._projectScopeBudgetAutocompleteList.setItems(this._createModel.budgets);
            }
            this.budgetsValidate();
        }

        private getSummaryText(summary: CostCategorySummary): string {
            return `${summary.name}${summary.code !== null && summary.code !== '' ? ` (${summary.code})` : ''}`;
        }
        
        private getScopesToSelectOptionsForScopes = (scopes:Scope[], addChildren:boolean = false):string => {
            let inputSelectScopeOptions = '';
            scopes.forEach(scope => {
                inputSelectScopeOptions += `<option value="${scope.id}">${scope.name}</option>`;
                if(addChildren && scope.children.length > 0){
                    inputSelectScopeOptions += `<optgroup>` + this.getScopesToSelectOptionsForScopes(scope.children, addChildren) + `</optgroup>`;
                }
            });
            return inputSelectScopeOptions;
        }
        
        private onScopeChanged = async (event: Event): Promise<void> => {
            event.preventDefault();
            event.stopPropagation();
            const select = event.target as HTMLSelectElement;
            if(this._scopes) {
                const filteredMainScopes = this._scopes.filter(s=>s.id === select.value);
                if(filteredMainScopes.length === 0) {
                    return;
                }
                const mainScope = filteredMainScopes[0];
                const subScopeInputSelect = this.querySelector('ui-select[name="subScopeId"]') as InputSelect;
                if(!mainScope.hasChildren) {
                    subScopeInputSelect.setOptions('');
                    subScopeInputSelect?.disable();
                    return;
                }
                subScopeInputSelect?.enable();
                let inputSelectScopeOptions = `<option value="" class="i18n">project.budget.table.addNew.modal.sub_scope_item.select</option>`;
                inputSelectScopeOptions += this.getScopesToSelectOptionsForScopes(mainScope.children, true);                
                if (subScopeInputSelect) {
                    subScopeInputSelect.setOptions(inputSelectScopeOptions);
                }
            }
        }
        
        async connectedCallback() {
            if (!this.isConnected) {
                return;
            }

            if (!this._rendered) {
                await this.render();
            }
        }
    }
}