namespace Simplex.WebComponents.FormElements.SubdomainInputText.Models {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class SubdomainAvailableRequest extends ABModel {

        public subDomain?: string;
        public tenantId?: string|null;

        constructor(tenantId: string|null
        ) {
            super();
            this.tenantId = tenantId;
        }
    }
}