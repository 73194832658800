namespace Simplex.WebComponents.Project.ProjectDashboardTasks {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import APIResult = Simplex.Utils.APIResult;
    import ConfirmDialog = Simplex.Components.ConfirmDialog;

    @WebComponent("ui-dashboard-project-task")
    export class ProjectDashboardTask extends ABWebComponent {
        private readonly _contentTemplate;
        private readonly _projectId: string;
        private readonly _task: Simplex.Project.Models.ProjectTask;
        private _navigateToButton?: HTMLElement;
        private _deleteButton?: HTMLElement;

        public constructor(projectId: string, task: Simplex.Project.Models.ProjectTask) {
            super();
            this._projectId = projectId;
            this._task = task;
            this._contentTemplate = this.app.getTemplate('WebComponents/Project/Dashboard/ProjectDashboardTasks', 'ProjectDashboardTask') as TemplateCallback;
        }

        public async render() {
            const priorityByType = Simplex.Project.Models.ProjectTask.getPriorityByType(this._task.type) as Simplex.Project.Models.ProjectTaskPriorityType;
            this._task.priority = priorityByType;
            this._task.priorityName = Simplex.Project.Models.ProjectTaskPriorityType[priorityByType];
            this._task.actionUrl = Simplex.Project.Models.ProjectTask.getActionUrl(this._task.type, this._projectId);
            this.innerHTML = this._contentTemplate(this._task);

            if (this._task.actionUrl) {
                this._navigateToButton = this.querySelector(".navigate-to-url") as HTMLElement;
                this._navigateToButton?.addEventListener('click', (_: Event) => this.app.navigateTo(this._task.actionUrl ?? ""));
            }
            if (this._task.completedDate) {
                this._deleteButton = this.querySelector(".task__completed") as HTMLElement;
                this._deleteButton?.addEventListener('click', this.onDeleteClick);
            }
        }

        private onDeleteConfirmed = async (): Promise<void> => {
            const deleteResult = await this.request.delete<APIResult<void>>(`/api/project/${this._projectId}/tasks/${this._task.id}`);
            if (deleteResult.isSuccess) {
                this.dispatchEvent(new Event('taskDeleted', {bubbles: true}));
            } else {
                // notice!
                alert("Fout?!");
            }
        }
        private onDeleteClick = (_: Event): void => {

            const confirmDialog = this.app.getComponent('ConfirmDialog',
                Messages('project.dashboard.tasks.delete.confirm.title'),
                Messages('project.dashboard.tasks.delete.confirm.text'),
                Messages('project.dashboard.tasks.delete.confirm.yes'),
                Messages('project.dashboard.tasks.delete.confirm.no')) as ConfirmDialog;
            confirmDialog.show();
            confirmDialog.on('yes', () => this.onDeleteConfirmed());
        }

        disconnectedCallback() {
            this._navigateToButton?.removeEventListener('click', (_: Event) => this.app.navigateTo(this._task.actionUrl ?? ""));
            this._deleteButton?.removeEventListener('click', this.onDeleteClick);
        }
        
        public async connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            await this.render();
        }
    }
}