namespace Simplex.WebComponents.FormElements {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;


    type KeyPressHandler = (event: KeyboardEvent) => void
    
    @WebComponent("ui-input-text")
    export class InputText extends ABWebComponent {
        private readonly contentTemplate;
        private input: HTMLInputElement|null = null;
        private feedbackTarget: HTMLElement|null = null;

        public constructor() {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/FormElements/InputText', 'InputText') as TemplateCallback;
        }

        focus = ():void => {
            if (this.input) {
                this.input.focus();
            }
        }
        removeDisabled = ():void => {
            if (this.input) {
                this.input.removeAttribute('disabled');
            }
        }
        
        clear = ():void => {
            if (this.input) {
                this.input.value = '';
            }
        }
        
        onKeyPress( listener: KeyPressHandler ) {
            if (this.input) {
                this.input.addEventListener("keypress", listener);
            }
        }
        
        render() {
            
            this.innerHTML = this.contentTemplate({
                name: this.getAttribute("name"),
                value: this.getAttribute("value"),
                label: this.getAttribute("label"),
                placeholder: this.getAttribute("placeholder"),
                help: this.getAttribute("help"),
                class: this.getAttribute("class"),
                tabindex: this.getAttribute("tabindex"),
                max: this.getAttribute("max"),
                email: this.getAttribute("email") === "",
                disabled: this.getAttribute("disabled") === "",
                hidden: this.getAttribute("hidden") === "",
                required: this.getAttribute("required") === ""
            });
            
            this.input = this.querySelector("input");
            this.feedbackTarget = this.querySelector(".input__feedback");
            
            if (this.input) {
                this.input.addEventListener("Valid", this.inputValidated);
                this.input.addEventListener("Invalid", this.inputInvalid);
            }
        }
        
        inputValidated = (event:Event):void => {
            if (this.feedbackTarget) {
                this.feedbackTarget.innerHTML = "";
            }
        }
        
        inputInvalid = (event:Event):void => {
            if (this.feedbackTarget && this.input) {
                this.feedbackTarget.innerHTML = Messages(this.input.dataset.validationMessage ?? "");
            }
        }   

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }

            this.render();
        }
    }
}
