namespace Simplex.WebComponents.Project.Models {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class UpdateScopeStartDateRequest extends ABModel {

        public startDate?: string;

        constructor(startDate: string) {
            super();
            this.startDate = startDate;
        }
    }
}