namespace Simplex.WebComponents {
  import WebComponent = Simplex.Decorators.WebComponent;
  import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
  import ABWebComponent = Simplex.Components.ABWebComponent;
  import APIResult = Simplex.Utils.APIResult;
  import PagedResult = Simplex.Models.PagedResult;
  import SnapshotSummary = Simplex.Models.Project.SnapshotSummary;
  import CostCategorySummary = Simplex.Models.Project.CostCategorySummary;
  import ScopesData = Simplex.Models.Project.ScopesData;
  import Scope = Simplex.Models.Project.Scope;
  import ProjectTasksManager = Simplex.Components.ProjectTasksManager;
  import EventArgs = Ambrero.AB.Components.EventArgs;
  import FinanceEntrySlidein = Simplex.Components.FinanceEntrySlidein;
  import Inject = Simplex.Decorators.Inject;
  import NewCostCategoryPopup = Simplex.Components.NewCostCategoryPopup;
  import BudgetPeriodType = Simplex.WebComponents.Project.Budget.Models.BudgetPeriodType;
  @WebComponent("ui-budget")
  export class Budget extends ABWebComponent {
    private readonly _filterModelSessionStorageKey: string;
    private readonly _projectId: string;
    private _project?: Simplex.Models.Project.Project;
    private readonly contentTemplate;
    private readonly _projectTasksManager: ProjectTasksManager;
    private _budgetFilter?: BudgetFilter;
    private _rendered: boolean = false;
    private _filterModel: Simplex.WebComponents.Project.Budget.Models.FilterModel =
      new Simplex.WebComponents.Project.Budget.Models.FilterModel();
    private _filterTags?: FilterTags;
    private _toggleFilter?: HTMLElement;
    private _openAllScopesAnchor?: HTMLAnchorElement;
    private _closeAllScopesAnchor?: HTMLAnchorElement;
    private _tableViewAnchor?: HTMLAnchorElement;
    private _graphViewAnchor?: HTMLAnchorElement;
    private _addCostCategoryButton?: HTMLElement;
    private readonly createCostCategoryPopup: NewCostCategoryPopup;
    private _graphCumulativeViewAnchor?: HTMLAnchorElement;

    private _budgetTable?: BudgetTable;
    private _budgetGraph?: BudgetGraph;
    private _actualCostsGraph?: ActualCostsGraph;
    private snapshots: SnapshotSummary[] = [];

    private _scopes?: Scope[];
    private _costCategories?: CostCategorySummary[];
    private _mainCostCategories?: CostCategorySummary[];
    private readonly MAXLEVEL = 7;

    @Inject("FinanceEntrySlidein")
    private readonly _financeEntrySlidein!: FinanceEntrySlidein;

    public constructor() {
      super();
      this._projectId = this.getAttribute("project-id") || "";
      this._filterModelSessionStorageKey =
        Simplex.Utils.getBudgetFilterModelCacheKey(this._projectId);
      this.contentTemplate = this.app.getTemplate(
        "WebComponents/Project/Budget",
        "Budget"
      ) as TemplateCallback;
      this.createCostCategoryPopup =
        this.app.getComponentType<NewCostCategoryPopup>(
          "NewCostCategoryPopup",
          "below"
        )!;

      this._projectTasksManager = this.app.getComponent(
        "ProjectTasksManager"
      ) as ProjectTasksManager;
      if (this._projectId) {
        this._filterModel.projectId = this._projectId;
      }
      this.loadFilterModelFromSessionStorage();
    }

    private readonly addCostCategoryButtonClicked = async (
      event: Event
    ): Promise<void> => {
      event.preventDefault();
      event.stopPropagation();
      this.createCostCategoryPopup.show(
        <HTMLElement>event.target,
        this._projectId
      );
    };

    private readonly costCategoryCreated = async (): Promise<void> => {
      await this.onScopeCostCategoryChange();
    };
    onCellDoubleClicked = async (event: Event): Promise<void> => {
      event.preventDefault();
      event.stopPropagation();
      const customEvent = event as CustomEvent;
      const eventArgs = Simplex.Utils.createEventArgs({}, this);
      eventArgs.elementData!.projectId = this._projectId;
      if (this._budgetFilter) {
        this._budgetFilter.close();
      }
      this._financeEntrySlidein.showNewFinanceEntry(customEvent, eventArgs);
    };
    onFinanceEntrySlideinClose = async (event: Event): Promise<void> => {
      this._budgetTable?.clearData();
      this._budgetGraph?.clearData();
      this._actualCostsGraph?.clearData();

      Budget.saveOpenAndCloseState();
      await this.loadData();
    };

    public static saveOpenAndCloseState() {
      //get state
      const closedCostRows = document.querySelectorAll(
        ".costs__row.parent.is--closed"
      );
      const closedBudgetRows = document.querySelectorAll(
        ".budgettable__row.is--closed"
      );
      const closedBudgetCost = document.querySelectorAll(
        ".budgettable__row.budgets--closed"
      );
      const openCostRows = document.querySelectorAll(
        ".costs__row.parent:not(.is--closed)"
      );
      const openBudgetRows = document.querySelectorAll(
        ".budgettable__row:not(.is--closed)"
      );
      const openBudgetCost = document.querySelectorAll(
        ".budgettable__row:not(.budgets--closed)"
      );
      const closedCostRowIds: string[] = [];
      const closedBudgetRowIds: string[] = [];
      const closedBudgetCosts: string[] = [];
      closedCostRows.forEach((row) => {
        closedCostRowIds.push(
          (row as HTMLElement).getAttribute("data-id") as string
        );
      });
      closedBudgetRows.forEach((row) => {
        closedBudgetRowIds.push(
          (row as HTMLElement).getAttribute("data-scope-id") as string
        );
      });
      closedBudgetCost.forEach((row) => {
        closedBudgetCosts.push(
          (row as HTMLElement).getAttribute("data-scope-id") as string
        );
      });
      const openCostRowIds: string[] = [];
      const openBudgetRowIds: string[] = [];
      const openBudgetCosts: string[] = [];
      openCostRows.forEach((row) => {
        const id = (row as HTMLElement).getAttribute("data-id") as string;
        if (id) {
          openCostRowIds.push(id);
        }
      });
      openBudgetRows.forEach((row) => {
        const id = (row as HTMLElement).getAttribute("data-scope-id") as string;
        if (id) {
          openBudgetRowIds.push(id);
        }
      });
      openBudgetCost.forEach((row) => {
        const id = (row as HTMLElement).getAttribute("data-scope-id") as string;
        if (id) {
          openBudgetCosts.push(id);
        }
      });
      localStorage.setItem(
        "openBudgetRowsBeforeSave",
        JSON.stringify({
          costRowIds: closedCostRowIds,
          budgetRowIds: closedBudgetRowIds,
          budgetCostRowIds: closedBudgetCosts,
          openCostRowIds: openCostRowIds,
          openBudgetRowIds: openBudgetRowIds,
          openBudgetCostRowIds: openBudgetCosts,
        })
      );
    }

    onScopeChanged = async (value: string): Promise<void> => {
      this._filterModel.scopeId = value;
      await this.loadData();
    };

    compareWithSnapshotIdChanged = async (value: string): Promise<void> => {
      this._filterModel.compareWithSnapshotId = value;
      await this.loadData();
    };

    onCostCategoryChanged = async (value: string): Promise<void> => {
      this._filterModel.costCategoryId = value;
      await this.loadData();
    };
    onPeriodChanged = async (value: string): Promise<void> => {
      this._filterModel.periodType = value as BudgetPeriodType;
      await this.loadData();
    };

    async render() {
      if (this._filterModel.compareWithSnapshotId === null) {
        const selectedSnapshot = this.snapshots.filter((s) => s.baseline);
        if (selectedSnapshot.length > 0) {
          this._filterModel.compareWithSnapshotId = selectedSnapshot[0].id;
        }
        this.saveFilterModelToSessionStorage();
      }
      this.innerHTML = this.contentTemplate({
        filterModel: this._filterModel,
        snapshots: this.snapshots,
        snapshotsFilter: this.snapshots.length > 0,
        project: this._project,
      });
      this._openAllScopesAnchor = this.querySelector(
        "a.open"
      ) as HTMLAnchorElement;
      this._closeAllScopesAnchor = this.querySelector(
        "a.close"
      ) as HTMLAnchorElement;
      this._toggleFilter = this.querySelector(".JSFilterBudget") as HTMLElement;
      this._tableViewAnchor = this.querySelector(
        "a.table-view"
      ) as HTMLAnchorElement;
      this._graphViewAnchor = this.querySelector(
        "a.graph-view"
      ) as HTMLAnchorElement;
      this._graphCumulativeViewAnchor = this.querySelector(
        "a.graph-actual_costs-view"
      ) as HTMLAnchorElement;
      this._addCostCategoryButton = this.querySelector(
        ".JSAddCostCategory"
      ) as HTMLElement;

      this._budgetTable = this.querySelector("ui-budget-table") as BudgetTable;
      this._budgetGraph = this.querySelector("ui-budget-graph") as BudgetGraph;
      this._actualCostsGraph = this.querySelector(
        "ui-actual_costs-graph"
      ) as ActualCostsGraph;
      this._filterTags = this.querySelector(
        "ui-budget-filter-tags"
      ) as FilterTags;

      if (this._filterTags) {
        this._filterTags?.addEventListener(
          "filterChanged",
          this.onFilterChanged
        );
        this._filterTags.setSnapshots(this.snapshots);
        if (this._scopes) {
          this._filterTags.setScopes(this._scopes);
        }
        if (this._mainCostCategories) {
          this._filterTags.setMainCostCategories(this._mainCostCategories);
        }
        for (const key in this._filterModel) {
          this._filterTags.addTag(key, this._filterModel[key]);
        }
      }

      this._toggleFilter.addEventListener("click", this.onToggleFilter);
      this._rendered = true;
    }

    private onToggleFilter = (event: Event) => {
      if (!this._budgetFilter) {
        this._budgetFilter = new BudgetFilter(
          this._projectId,
          this.snapshots,
          this._scopes!,
          this._mainCostCategories!
        );
        //this._budgetFilter?.addEventListener('levelChange', (event: Event) => this.levelChanged(event));
        this._budgetFilter?.addEventListener(
          "filterChanged",
          this.onFilterChanged
        );
        this._toggleFilter?.after(this._budgetFilter);
      }
      this._budgetFilter.toggle();
    };

    private removeFromFilter = async (filterItemName: string) => {
      switch (filterItemName) {
        case "costCategoryId":
          await this.onCostCategoryChanged("");
          break;
        case "scopeId":
          await this.onScopeChanged("");
          break;
        case "scopeLevel":
          this.scopeLevelChanged(1);
          break;
        case "costCategoryLevel":
          this.costCategoryLevelChanged(0);
          break;
        case "compareWithSnapshotId":
          this._filterModel.compareWithSnapshotId = "";
          break;
        case "periodType":
          this._filterModel.periodType =
            Simplex.WebComponents.Project.Budget.Models.BudgetPeriodType.Month;
          break;
      }
      if (this._budgetFilter) {
        this.saveFilterModelToSessionStorage();
        this._budgetFilter.onFilterChanged();
      }
    };

    private onFilterChanged = async (event: Event) => {
      if (!this._filterTags) {
        return;
      }

      const { name, value } = (event as CustomEvent).detail;
      switch (name) {
        case "periodType":
          await this.onPeriodChanged(value);
          break;
        case "compareWithSnapshotId":
          await this.compareWithSnapshotIdChanged(value);
          break;
        case "costCategoryId":
          await this.onCostCategoryChanged(value);
          break;
        case "scopeId":
          await this.onScopeChanged(value);
          break;
        case "scopeLevel":
          this.scopeLevelChanged(value);
          break;
        case "costCategoryLevel":
          this.costCategoryLevelChanged(value);
          break;
        case "tagRemoved":
          await this.removeFromFilter(value);
      }
      this._filterTags.checkTag(name, value);
      this.saveFilterModelToSessionStorage();
    };

    private onOpenAllClicked = (event: Event): void => {
      event.preventDefault();
      event.stopPropagation();
      this.costCategoryLevelChanged(this.MAXLEVEL, true);
      this.scopeLevelChanged(this.MAXLEVEL, true);
    };
    private onCloseAllClicked = (event: Event): void => {
      event.preventDefault();
      event.stopPropagation();
      this.costCategoryLevelChanged(0, true);
      this.scopeLevelChanged(1, true);
    };

    private scopeLevelChanged(
      level: number,
      dontUpdateFilter: boolean = false
    ): void {
      if (this._budgetTable) {
        const pattern = "depth--";
        const regx = new RegExp(pattern + "\\d", "g");
        const scopeElements = this._budgetTable!.querySelectorAll(
          ".budgettable__row.parent"
        );

        scopeElements.forEach((el) => {
          const row = el as HTMLElement;

          const depth = parseInt(
            row.className.match(regx)![0].replace(pattern, "")
          );
          depth < level - 1
            ? row.classList.remove("is--closed")
            : row.classList.add("is--closed");
        });
        if (!dontUpdateFilter) {
          this._filterModel.scopeLevel = level;
          this.saveFilterModelToSessionStorage();
          this._budgetTable.scopeLevel = level;
        }
      }
    }

    private costCategoryLevelChanged(
      level: number,
      dontUpdateFilter: boolean = false
    ): void {
      if (this._budgetTable) {
        const pattern = "depth--";
        const regx = new RegExp(pattern + "\\d", "g");
        const childrenElements =
          this._budgetTable!.querySelectorAll(".costs__row.parent");

        const scopeElements =
          this._budgetTable!.querySelectorAll(".budgettable__row");
        if (level === 0) {
          scopeElements.forEach((el) => {
            const row = el as HTMLElement;
            if (
              !row.classList.contains("fixed") &&
              !row.classList.contains("budgetfooter")
            ) {
              row.classList.add("budgets--closed");
            }
          });
        } else {
          scopeElements.forEach((el) => {
            const row = el as HTMLElement;
            if (
              !row.classList.contains("fixed") &&
              !row.classList.contains("budgetfooter")
            ) {
              row.classList.remove("budgets--closed");
            }
          });
        }
        childrenElements.forEach((el) => {
          const row = el as HTMLElement;

          const depth = parseInt(
            row.className.match(regx)![0].replace(pattern, "")
          );
          depth < level - 1
            ? row.classList.remove("is--closed")
            : row.classList.add("is--closed");
        });
        if (!dontUpdateFilter) {
          this._filterModel.costCategoryLevel = level;
          this.saveFilterModelToSessionStorage();
          this._budgetTable.costCategoryLevel = level;
        }
      }
    }

    private onProjectTaskManagerRemoveHighLights = (event: EventArgs) => {
      event.handled = true;
      this._budgetTable?.removeAllHighlights();
    };

    private onProjectTaskListItemClicked = async (event: EventArgs) => {
      event.handled = true;
      const task = event.data as Simplex.Project.Models.ProjectTask;
      this._budgetTable?.highlightScopes(task.scopeIds);
    };

    private setGraphViewType(
      viewType: Simplex.WebComponents.Project.Budget.Models.BudgetViewType
    ): void {
      if (this._filterModel.graphViewType === viewType) {
        if (this._filterModel.showTable) {
          this._filterModel.graphViewType = null;
        }
      } else {
        this._filterModel.graphViewType = viewType;
      }
    }
    private async toggleShowTable(event: Event): Promise<void> {
      event.preventDefault();
      event.stopPropagation();

      if (!this._filterModel.graphViewType && this._filterModel.showTable) {
        // Cannot hide table if no graph is not shown
        return;
      }
      this._filterModel.showTable = !this._filterModel.showTable;
      if (this._filterModel.showTable) {
        this._tableViewAnchor?.classList.remove("inactive");
        this._budgetTable?.show();
      } else {
        this._tableViewAnchor?.classList.add("inactive");
        this._budgetTable?.hide();
      }
      await this.loadData();
      this.saveFilterModelToSessionStorage();
    }

    private async graphViewTypeChanged(
      event: Event,
      viewType: Simplex.WebComponents.Project.Budget.Models.BudgetViewType
    ): Promise<void> {
      event.preventDefault();
      event.stopPropagation();

      this.setGraphViewType(viewType);
      if (this._filterModel.graphViewType) {
        if (
          this._filterModel.graphViewType ===
          Simplex.WebComponents.Project.Budget.Models.BudgetViewType.GraphBudget
        ) {
          this._graphViewAnchor?.classList.remove("inactive");
          this._graphCumulativeViewAnchor?.classList.add("inactive");

          this._budgetGraph?.show();
          this._actualCostsGraph?.hide();
        } else {
          this._graphViewAnchor?.classList.add("inactive");
          this._graphCumulativeViewAnchor?.classList.remove("inactive");

          this._budgetGraph?.hide();
          this._actualCostsGraph?.show();
        }
      } else {
        this._graphCumulativeViewAnchor?.classList.add("inactive");
        this._graphViewAnchor?.classList.add("inactive");
        this._budgetGraph?.hide();
        this._actualCostsGraph?.hide();
      }
      await this.loadData();
      this.saveFilterModelToSessionStorage();
    }

    private async bindElementEventListeners(): Promise<void> {
      this._openAllScopesAnchor?.addEventListener(
        "click",
        this.onOpenAllClicked
      );
      this._closeAllScopesAnchor?.addEventListener(
        "click",
        this.onCloseAllClicked
      );

      this._addCostCategoryButton?.addEventListener(
        "click",
        this.addCostCategoryButtonClicked
      );
      this._tableViewAnchor?.addEventListener("click", (event: Event) =>
        this.toggleShowTable(event)
      );
      this._graphViewAnchor?.addEventListener("click", (event: Event) =>
        this.graphViewTypeChanged(
          event,
          Simplex.WebComponents.Project.Budget.Models.BudgetViewType.GraphBudget
        )
      );
      this._graphCumulativeViewAnchor?.addEventListener(
        "click",
        (event: Event) =>
          this.graphViewTypeChanged(
            event,
            Simplex.WebComponents.Project.Budget.Models.BudgetViewType
              .GraphActualCosts
          )
      );

      this._budgetTable?.addEventListener(
        "scopeCostCategoryChange",
        this.onScopeCostCategoryChange
      );
      this._budgetTable?.addEventListener(
        "tableVerticalCollapseChange",
        this.onTableVerticalCollapseChange
      );
      this._budgetTable?.addEventListener(
        "cellDoubleClicked",
        this.onCellDoubleClicked
      );
      this._financeEntrySlidein.on("close", this.onFinanceEntrySlideinClose);

      this._projectTasksManager.on(
        "removeAllHighLights",
        this.onProjectTaskManagerRemoveHighLights
      );
      this._projectTasksManager.on(
        "projectTaskListItemClicked",
        this.onProjectTaskListItemClicked
      );
    }

    private onScopeCostCategoryChange = async (): Promise<void> => {
      await this.getCostCategoriesFilterData();

      this._budgetTable?.clearData();
      this._budgetGraph?.clearData();
      this._actualCostsGraph?.clearData();
      await this.loadData();
    };

    private onTableVerticalCollapseChange = async (
      event: Event
    ): Promise<void> => {
      const customEvent = event as CustomEvent;
      this._filterModel.verticalCollapsed = customEvent.detail.collapsed;
      this.saveFilterModelToSessionStorage();
    };

    private removeElementEventListeners(): void {
      this._openAllScopesAnchor?.removeEventListener(
        "click",
        this.onOpenAllClicked
      );
      this._closeAllScopesAnchor?.removeEventListener(
        "click",
        this.onCloseAllClicked
      );
      this._addCostCategoryButton?.removeEventListener(
        "click",
        this.addCostCategoryButtonClicked
      );
      this._projectTasksManager.off(
        "removeAllHighLights",
        this.onProjectTaskManagerRemoveHighLights
      );
      this._projectTasksManager.off(
        "projectTaskListItemClicked",
        this.onProjectTaskListItemClicked
      );
    }

    private async getCostCategoriesFilterData(): Promise<void> {
      const costCategoriesResult = await this.request.get<
        APIResult<CostCategorySummary[]>
      >(`/api/project/${this._projectId}/costCategories`);
      this._costCategories =
        costCategoriesResult.isSuccess && costCategoriesResult.data.data
          ? costCategoriesResult.data.data
          : [];
      this._mainCostCategories = this._costCategories?.filter(
        (c) => c.parentCostCategoryId === Simplex.Utils.GUID_EMPTY
      );
    }

    private async getFilterData(): Promise<void> {
      const projectResult = await this.request.get<
        APIResult<Simplex.Models.Project.Project>
      >(`/api/project/${this._projectId}`);
      if (!projectResult.isSuccess) {
        return undefined;
      }
      this._project = projectResult.data.data;

      const snapshotsResult = await this.request.post<
        APIResult<PagedResult<SnapshotSummary>>
      >(`/api/project/${this._projectId}/snapshots/search`);
      this.snapshots =
        snapshotsResult.isSuccess && snapshotsResult.data.data
          ? snapshotsResult.data.data.items
          : [];

      const scopesResult = await this.request.get<APIResult<ScopesData>>(
        `/api/project/${this._projectId}/scopes`
      );
      this._scopes =
        scopesResult.isSuccess && scopesResult.data.data
          ? scopesResult.data.data.scopes
          : [];
      await this.getCostCategoriesFilterData();
    }

    private async loadData(): Promise<void> {
      const ev = new CustomEvent("budget-reload");
      window.dispatchEvent(ev);

      if (this._budgetTable && this._costCategories) {
        this._budgetTable.costCategories = this._costCategories;
      }
      if (this._filterModel.showTable) {
        this._tableViewAnchor?.classList.remove("inactive");
        await this._budgetTable?.loadData(this._filterModel);
        this._budgetTable?.show();
      }
      if (
        this._filterModel.graphViewType ===
        Simplex.WebComponents.Project.Budget.Models.BudgetViewType.GraphBudget
      ) {
        this._graphViewAnchor?.classList.remove("inactive");
        await this._budgetGraph?.loadData(this._filterModel);
        this._budgetGraph?.show();
      } else if (
        this._filterModel.graphViewType ===
        Simplex.WebComponents.Project.Budget.Models.BudgetViewType
          .GraphActualCosts
      ) {
        this._graphCumulativeViewAnchor?.classList.remove("inactive");
        await this._actualCostsGraph?.loadData(this._filterModel);
        this._actualCostsGraph?.show();
      }
      this.saveFilterModelToSessionStorage();
      await this._projectTasksManager.showTasks(
        this._projectId,
        Simplex.WebComponents.ProjectTasksType.Budget
      );
    }

    private loadFilterModelFromSessionStorage = () => {
      const filterModelFromSessionStorage = localStorage.getItem(
        this._filterModelSessionStorageKey
      );
      if (filterModelFromSessionStorage) {
        const filterModelFromSessionStorageJson = JSON.parse(
          filterModelFromSessionStorage
        ) as Simplex.WebComponents.Project.Budget.Models.FilterModel;
        this._filterModel.verticalCollapsed =
          filterModelFromSessionStorageJson.verticalCollapsed;
        this._filterModel.graphViewType =
          filterModelFromSessionStorageJson.graphViewType;
        this._filterModel.showTable =
          filterModelFromSessionStorageJson.showTable;
        this._filterModel.periodType =
          filterModelFromSessionStorageJson.periodType;
        this._filterModel.scopeLevel =
          filterModelFromSessionStorageJson.scopeLevel ?? 3;
        this._filterModel.costCategoryLevel =
          filterModelFromSessionStorageJson.costCategoryLevel ?? 3;

        if (filterModelFromSessionStorageJson.projectId === this._projectId) {
          this._filterModel.compareWithSnapshotId =
            filterModelFromSessionStorageJson.compareWithSnapshotId;
          this._filterModel.scopeId = filterModelFromSessionStorageJson.scopeId;
          this._filterModel.costCategoryId =
            filterModelFromSessionStorageJson.costCategoryId;
        }
        this.onSnapshotsChangesOnlyToggled(this._filterModel.snapshots);
      } else {
        this._filterModel.scopeLevel = 3;
        this._filterModel.costCategoryLevel = 3;
      }
    };

    private onSnapshotsChangesOnlyToggled = (state: boolean) => {
      if (state) {
        this.classList.add("snapshot-changes-only");
      } else {
        this.classList.remove("snapshot-changes-only");
      }
    };

    private saveFilterModelToSessionStorage = () => {
      localStorage.setItem(
        this._filterModelSessionStorageKey,
        JSON.stringify(this._filterModel)
      );
    };

    async connectedCallback() {
      if (!this.isConnected) {
        return;
      }
      if (!this._rendered) {
        await this.getFilterData();
        await this.render();
        await this.bindElementEventListeners();
        await this.loadData();
      }
    }

    disconnectedCallback() {
      this.removeElementEventListeners();
    }
  }
}
