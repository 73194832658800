namespace Simplex.Components {
    export abstract class ABComponent {
        private readonly emitter = new Simplex.Components.Emitter();

        public on(event: string|string[], fn: CallableFunction) {
            if (Array.isArray(event)) {
                for (let k in event) {
                    this.emitter.on(event[k], fn);
                }
                return this.emitter;
            } else {
                return this.emitter.on(event, fn);
            }
        }

        public addEventListener(event: string, fn: CallableFunction): void {
            this.on(event, fn);
        }

        public once(event: string, fn: CallableFunction): void {
            this.emitter.once(event, fn);
        }

        public off(event: string, fn?: CallableFunction): void {
            this.emitter.off(event, fn);
        }

        public removeListener(event: string, fn: CallableFunction): void {
            this.off(event, fn);
        }

        public removeAllListeners(event: string, fn: CallableFunction): void {
            this.off(event, fn);
        }

        public removeEventListener(event: string, fn: CallableFunction): void {
            this.off(event, fn);
        }

        public emit(event: string, ...args: any[]): void {
            this.emitter.emit(event, ...args);
        }

        public listeners(event: string): CallableFunction[] {
            return this.emitter.listeners(event);
        }

        public hasListeners(event: string): boolean {
            return this.emitter.hasListeners(event);
        }
    }
}