namespace Simplex.WebComponents.Project.ProjectMilestonesList.Models {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class Milestone extends ABModel {

        public projectId: string;
        public name: string;
        public date: string;

        constructor(projectId: string, name: string, date: string
        ) {
            super();
            this.projectId = projectId;
            this.name = name;
            this.date = date;
        }
    }
}