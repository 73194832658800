namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;
    import IBoundModel = Ambrero.AB.Models.IBoundModel;

    export class CreateScopeRequest extends ABModel {
        private static REFERENCES_REGEX = /^references\.(\d+)\.(\w+)$/;

        public parentScopeId: string | null = Simplex.Utils.GUID_EMPTY;
        public name: string = '';
        public index: number = -1;
        public color: string | null = null;
        public startDate: string | null = null;
        public endDate: string | null = null;
        public durationInDays: number | null = null;
        public startDateMilestone: boolean | null = null;
        public startDateMilestoneName: string | null = null;
        public endDateMilestone: boolean | null = null;
        public endDateMilestoneName: string | null = null;
        public critical: boolean = false;
        public budgetBreakdown: boolean = false;
        public budget: number | null = null;
        public budgets: ScopeBudget[] = [];
        public references: ScopeReference[] = [];

        constructor(scope?: Simplex.Models.Project.Scope) {
            super();
            if (scope) {
                this.parentScopeId = scope.parentScopeId;
                this.name = scope.name;
                this.color = scope.color;
                this.index = scope.index;
                this.startDate = scope.startDate;
                this.endDate = scope.endDate;
                this.durationInDays = scope.durationInDays;
                this.startDateMilestoneName = scope.startDateMilestoneName;
                this.startDateMilestone = scope.startDateMilestoneName !== null && scope.startDateMilestoneName !== '';
                this.endDateMilestoneName = scope.endDateMilestoneName;
                this.endDateMilestone = scope.endDateMilestoneName !== null && scope.endDateMilestoneName !== '';
                this.critical = scope.critical;
                this.budgetBreakdown = scope.budgetBreakdown;
                this.budget = scope.budget;
                this.budgets = scope.budgets;
                this.references = scope.references ?? [];
            }
            if(this.references.filter(r=> r.startScopeId === null && r.endScopeId === null).length === 0) {
                this.references.push(new ScopeReference());
            }
        }
        public GetFieldModel = (fieldName:string) => {
            switch (fieldName) {
                case "budgets":
                    return new ScopeBudget();
            }
            return null;
        }        
        
        public convertField = (fieldName: string, direction: "up" | "down", value: any): any => {
            const formattingHelper = Ambrero.AB.Application.Instance!.getSystemComponent('FormattingHelper') as Simplex.Components.FormattingHelper;
            switch (fieldName) {
                case "startDate":
                case "endDate":
                    switch (direction) {
                        case "up": //get
                            if (value !== null && value !== "") {
                                value = moment(value, 'YYYY-MM-DD').format(formattingHelper.dateFormat)
                            }
                            break;
                        case "down": //set
                            
                            if (value === "" || value === null) {
                                value = null;
                            } else {
                                value = moment(value, formattingHelper.dateFormat).format('YYYY-MM-DD');
                            }
                            break;
                    }
                    return value;
                case "budget":
                    if (direction === "down") {
                        value = value !== null && value !== "" ? parseFloat(value) : null;
                    }
                    break;
                case "budgetBreakdown":
                    if (direction === "down") {
                        value = value !== null && value !== "" && value === 'true';
                    }
            }
            return value;
        }

        public validateDates = ():[boolean, string, string] => {
            if (this.endDate === null && this.startDate !== null) {
                return [false, "endDate","error.required"];
            }
            if (this.startDate === null && this.endDate !== null) {
                return [false, "startDate","error.required"];
            }
            const formattingHelper = Ambrero.AB.Application.Instance!.getSystemComponent('FormattingHelper') as Simplex.Components.FormattingHelper;
            const tmpEnd = moment(this.endDate, formattingHelper.dateFormat);
            const tmpStart = moment(this.startDate, formattingHelper.dateFormat);
            if (tmpEnd.isBefore(tmpStart)) {
                return [false, "startDate","error.date.before.start"];
            }
            
            return [true, "",""];
        }

        public validateBudget = ():boolean => {
            return !this.budgetBreakdown || this.budgets.length > 0;
        }   

        public validateReferences = (scopeId: string):ReferenceValidationError[] => {
            let errorReferenceIndexes: ReferenceValidationError[] = [];
            for (let i = 0; i < this.references.length; i++) {
                const reference = this.references[i];
                if (reference.startScopeId && reference.endScopeId && reference.startScopeId !== scopeId && reference.endScopeId !== scopeId) {
                    errorReferenceIndexes.push(new ReferenceValidationError(i, ReferenceValidationErrorType.CurrentScopeNotSelected));
                }
                else if (reference.startScopeId && reference.endScopeId && reference.startScopeId === reference.endScopeId) {
                    errorReferenceIndexes.push(new ReferenceValidationError(i, ReferenceValidationErrorType.SelfReference));
                }
            } 
            
            return errorReferenceIndexes;
        }
        
        public validateField = (fieldName: string, element: HTMLElement, value: string, boundModel: IBoundModel): string | undefined => {

            if (fieldName === "startDateMilestoneName") {
                if (this.startDateMilestone === true && value === "") {
                    return Messages("error.required");
                }
            } else if (fieldName === "endDateMilestoneName") {
                if (this.endDateMilestone === true && value === "") {
                    return Messages("error.required");
                }
            } else if (fieldName.indexOf('references') > -1) {
                const matches = fieldName.match(CreateScopeRequest.REFERENCES_REGEX);
                if(!matches) {
                    return;
                }
                const index = parseInt(matches[1]);
                const field = matches[2];
                
                this.toJSON(); // TODO : Als dit er niet staat, zijn de velden startScopeId en endScopeId niet gevuld
                if(field === 'startScopeId' && !value && value !== '' && this.references[index].endScopeId) {
                    return Messages("error.required");
                } else if(field === 'endScopeId' && !value && value !== '' && this.references[index].startScopeId) {
                    return Messages("error.required");
                }
            }
        }
        
        public isBudgetDifferentFromParent = (parentBudget:number|null) : boolean => {
            let budget = this.budget;
            if(this.budgetBreakdown) {
                budget = this.budgets.reduce<number>((accumulator, scopeBudget) => {
                    return accumulator + (scopeBudget.amount ?? 0);
                }, 0);
            }
            if(!budget) {
                return false;
            }
            if(!parentBudget) {
                return false;
            }
            return this.budget != parentBudget;
        }
        
        public isStartDateBeforeParentStartDate = (parentStartDateString:string|null) : boolean => {
            if(!this.startDate) {
                return false;
            }
            if(!parentStartDateString) {
                return false;
            }
            const formattingHelper = Ambrero.AB.Application.Instance!.getSystemComponent('FormattingHelper') as Simplex.Components.FormattingHelper;
            const startDateMoment = moment(this.startDate, formattingHelper.dateFormat);
            const parentStartDateMoment = moment(parentStartDateString, formattingHelper.dateFormat);
            return startDateMoment.isBefore(parentStartDateMoment);
        }
        
        public isEndDateAfterParentStartDate = (parentEndDateString:string|null) : boolean => {
            if(!this.endDate) {
                return false;
            }
            if(!parentEndDateString) {
                return false;
            }
            const formattingHelper = Ambrero.AB.Application.Instance!.getSystemComponent('FormattingHelper') as Simplex.Components.FormattingHelper;
            const endDateMoment = moment(this.endDate, formattingHelper.dateFormat);
            const parentEndDateMoment = moment(parentEndDateString, formattingHelper.dateFormat);
            return endDateMoment.isAfter(parentEndDateMoment);
        }
    }
}