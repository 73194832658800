///<reference path="../../node_modules/autonumeric/index.d.ts"/>

namespace Simplex.Utils {
  import EventArgs = Ambrero.AB.Components.EventArgs;
  import FormFields = Simplex.Components.FormFields;

  export var GUID_EMPTY: string = "00000000-0000-0000-0000-000000000000";
  const STORAGE_FILTER_BUDGET_BASE = "Budget-filterModel-";
  const STORAGE_FILTER_PLANNING_BASE = "Planning-filterModel-";
  export function getBudgetFilterModelCacheKey(projectId: string): string {
    return STORAGE_FILTER_BUDGET_BASE + projectId;
  }
  export function getPlanningFilterModelCacheKey(projectId: string): string {
    return STORAGE_FILTER_PLANNING_BASE + projectId;
  }
  export function isGuid(guid: string): boolean {
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
      guid
    );
  }

  export interface Message {
    key: string;
    parameters: Array<string | number>;
  }

  export interface APIResult<T> {
    data?: T;
    success: boolean;
    messages: Array<Message>;
    fields: Map<string, string>;
    redirect: string;
  }

  export function coerceToBase64Url(thing: any, name?: any) {
    // Array or ArrayBuffer to Uint8Array
    if (Array.isArray(thing)) {
      thing = Uint8Array.from(thing);
    }

    if (thing instanceof ArrayBuffer) {
      thing = new Uint8Array(thing);
    }

    // Uint8Array to base64
    if (thing instanceof Uint8Array) {
      var str = "";
      var len = thing.byteLength;

      for (var i = 0; i < len; i++) {
        str += String.fromCharCode(thing[i]);
      }
      thing = window.btoa(str);
    }

    if (typeof thing !== "string") {
      throw new Error("could not coerce '" + name + "' to string");
    }

    // base64 to base64url
    // NOTE: "=" at the end of challenge is optional, strip it off here
    thing = thing.replace(/\+/g, "-").replace(/\//g, "_").replace(/=*$/g, "");

    return thing;
  }
  export function coerceToArrayBuffer(thing: any, name?: any) {
    if (typeof thing === "string") {
      // base64url to base64
      thing = thing.replace(/-/g, "+").replace(/_/g, "/");

      // base64 to Uint8Array
      var str = window.atob(thing);
      var bytes = new Uint8Array(str.length);
      for (var i = 0; i < str.length; i++) {
        bytes[i] = str.charCodeAt(i);
      }
      thing = bytes;
    }

    // Array to Uint8Array
    if (Array.isArray(thing)) {
      thing = new Uint8Array(thing);
    }

    // Uint8Array to ArrayBuffer
    if (thing instanceof Uint8Array) {
      thing = thing.buffer;
    }

    // error if none of the above worked
    if (!(thing instanceof ArrayBuffer)) {
      throw new TypeError("could not coerce '" + name + "' to ArrayBuffer");
    }

    return thing;
  }
  export function onlyUnique(value: any, index: any, self: any): boolean {
    return self.indexOf(value) === index;
  }

  export function createEventArgs(data: object | null, source: any): EventArgs {
    return {
      handled: false,
      data: data,
      global: false,
      source: source,
      elementData: {},
    };
  }
  export function createErrorMessage(error: any): string {
    let message: string;
    if (error instanceof Error) {
      message = error.message;
    } else {
      message = String(error);
    }
    return message;
  }

  export function bindAutoNumericField(
    element: HTMLInputElement,
    options: any
  ): void {
    // @ts-ignore
    let autoNumericElement = AutoNumeric.getAutoNumericElement(element);
    if (autoNumericElement !== null) {
      return;
    }
    // @ts-ignore
    new AutoNumeric(element, options);
  }

  export function getNumericString(
    element: HTMLInputElement
  ): string | undefined {
    // @ts-ignore
    let autoNumericElement = AutoNumeric.getAutoNumericElement(element);
    if (autoNumericElement !== null) {
      const val = $(element).val();

      if (typeof val === "string") {
        return val.replace(",", ".");
      }
      if (typeof val === "number") {
        return val.toString();
      }
      return val?.toString();
    }

    return undefined;
  }
  export function getHeaderValue(
    headerName: string,
    defaultValue: string | number | null | undefined
  ) {
    const header = <HTMLMetaElement>(
      document.head.querySelector(`meta[name="${headerName}"]`)
    );
    if (header === null) {
      return defaultValue;
    }
    return header.content;
  }
  export function getNumericSettings(): any {
    const headerValue = getHeaderValue(
      "Simplex_NumberFormat",
      "GroupDotAndDecimalComma"
    );
    let numberGroupSeparator;
    let numberDecimalSeparator;
    switch (headerValue) {
      case "GroupCommaAndDecimalDot":
        numberGroupSeparator = ",";
        numberDecimalSeparator = ".";
        break;
      case "GroupApostropheAndDecimalDot":
        numberGroupSeparator = "'";
        numberDecimalSeparator = ".";
        break;
      case "GroupSpaceAndDecimalDot":
        numberGroupSeparator = " ";
        numberDecimalSeparator = ".";
        break;
      default:
        numberGroupSeparator = ".";
        numberDecimalSeparator = ",";
        break;
    }
    return {
      numberGroupSeparator: numberGroupSeparator,
      numberDecimalSeparator: numberDecimalSeparator,
    };
  }

  export function getAutoNumericSettings(): any {
    const numericSettings = Simplex.Utils.getNumericSettings();

    return {
      integerFourDecimalsNoDigitSeparator: {
        decimalPlaces: 4,
        decimalCharacter: numericSettings.numberDecimalSeparator,
        digitGroupSeparator: "",
        wheelStep: "0.01",
        allowDecimalPadding: false,
      },
      integerTwoDecimalsNoDigitSeparator: {
        decimalPlaces: 2,
        decimalCharacter: numericSettings.numberDecimalSeparator,
        digitGroupSeparator: "",
        wheelStep: "0.01",
        allowDecimalPadding: false,
      },
      integerTenDecimalsNoDigitSeparator: {
        decimalPlaces: 10,
        decimalCharacter: numericSettings.numberDecimalSeparator,
        digitGroupSeparator: "",
        wheelStep: "0.01",
        allowDecimalPadding: false,
      },
      integerOneDecimalsNoDigitSeparator: {
        decimalPlaces: 1,
        decimalCharacter: numericSettings.numberDecimalSeparator,
        digitGroupSeparator: "",
        wheelStep: "1",
        allowDecimalPadding: false,
      },
      integerPositiveNoDigitSeparator: {
        minimumValue: 0,
        decimalPlaces: 0,
        decimalCharacter: numericSettings.numberDecimalSeparator,
        digitGroupSeparator: "",
      },
      postcalCodeNumber: {
        minimumValue: 1,
        maximumValue: 9999,
        decimalPlaces: 0,
        decimalCharacter: numericSettings.numberDecimalSeparator,
        digitGroupSeparator: "",
      },
      percentage: {
        minimumValue: 0,
        maximumValue: 100,
        decimalPlaces: 0,
        decimalCharacter: numericSettings.numberDecimalSeparator,
        digitGroupSeparator: "",
      },
      decimalNoDigitSeparator: {
        decimalCharacter: numericSettings.numberDecimalSeparator,
        decimalPlaces: 10,
        digitGroupSeparator: "",
        wheelStep: "0.01",
        allowDecimalPadding: false,
      },
    };
  }

  export function elementPartOfCurrentDOM(element: HTMLElement) {
    return $(element).closest("body").length > 0;
  }

  export function whichAnimationEvent(): string {
    let t;
    let el: HTMLElement = document.createElement("fakeelement");

    let animations = {
      animation: "animationend",
      OAnimation: "oAnimationEnd",
      MozAnimation: "animationend",
      WebkitAnimation: "webkitAnimationEnd",
    };

    for (t in animations) {
      // @ts-ignore
      if (el.style[t] !== undefined) {
        // @ts-ignore
        return animations[t];
      }
    }
    return "";
  }

  export function disableElement(
    element: HTMLElement | JQuery,
    additionalClasses?: string
  ): CallableFunction {
    const disableClass = `disabled ${additionalClasses}`;

    $(element).addClass(disableClass).prop("disabled", true);

    return function () {
      $(element).prop("disabled", false).removeClass(disableClass);
    };
  }

  export function SetIsDisabledElement(
    element: HTMLElement | JQuery,
    additionalClasses?: string
  ): CallableFunction {
    const disableClass = `is--disabled ${additionalClasses}`;

    $(element).addClass(disableClass).prop("disabled", true);

    return function () {
      $(element).prop("disabled", false).removeClass(disableClass);
    };
  }

  export function getActiveControllerName(): string {
    return $("body").data("controller");
  }

  export function resolveMessageFromAjaxResponse(
    xhr: XMLHttpRequest,
    handledFieldError: boolean
  ): string | null {
    const resolveCombinedMessageFromResponse =
      function resolveCombinedMessageFromResponse(
        responseType: XMLHttpRequestResponseType,
        responseText: string
      ): string | null {
        let result: string | null = null;
        if (responseType === "json") {
          try {
            const apiResult: APIResult<void> = JSON.parse(responseText);
            if (apiResult.messages && apiResult.messages.length > 0) {
              result = "";
              for (let i = 0; i < apiResult.messages.length; i++) {
                const message = apiResult.messages[i];
                if (message.parameters !== null) {
                  result += Messages(message.key, ...message.parameters);
                } else {
                  result += Messages(message.key);
                }
                if (i < apiResult.messages.length - 1) {
                  result += ", ";
                }
              }
            }
          } catch (e: Error | unknown) {
            if (e instanceof Error) {
              result = e.message;
            } else {
              result = "uknonwn";
            }
          }
        }

        return result;
      };

    const resolveMessageFromResponseStatus =
      function resolveMessageFromResponseStatus(status: number): string | null {
        let result: string | null = null;

        const statusMessageKey = `requestError.status.${status}`;
        const statusMessage = Messages(statusMessageKey);

        if (statusMessage !== statusMessageKey) {
          result = statusMessage;
        }

        return result;
      };

    let resolvedMessage = resolveCombinedMessageFromResponse(
      xhr.responseType || "json",
      xhr.responseText
    );
    if (resolvedMessage == null && !handledFieldError) {
      resolvedMessage = resolveMessageFromResponseStatus(xhr.status);
    }
    if (resolvedMessage == null && !handledFieldError) {
      resolvedMessage = Messages("requestError.default", xhr.status);
    }
    return resolvedMessage;
  }
}
