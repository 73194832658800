namespace Simplex.WebComponents.FormElements {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;


    type KeyPressHandler = (event: KeyboardEvent) => void
    
    @WebComponent("ui-input-date")
    export class InputDate extends ABWebComponent {
        private readonly contentTemplate;
        private input: HTMLInputElement|null = null;
        private feedbackTarget: HTMLElement|null = null;
        private formattingHelper: Simplex.Components.FormattingHelper|null = null;

        public constructor() {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/FormElements/InputDate', 'InputDate') as TemplateCallback;
            this.formattingHelper = this.app.getComponent("FormattingHelper") as Simplex.Components.FormattingHelper;
        }

        focus = ():void => {
            if (this.input) {
                this.input.focus();
            }
        }
        
        onKeyPress( listener: KeyPressHandler ) {
            if (this.input) {
                this.input.addEventListener("keypress", listener);
            }
        }
        
        set value(value: moment.Moment | null) {
            if (this.input) {
                this.input.value = value?.format(this.formattingHelper!.dateFormat) ?? '';
                this.input.dispatchEvent(new Event('change'));
            }
        }
        
        render() {
            this.innerHTML = this.contentTemplate({
                name: this.getAttribute("name"),
                format: this.getAttribute("format") ?? this.formattingHelper!.dateFormat,
                value: this.getAttribute("value"),
                label: this.getAttribute("label"),
                placeholder: this.getAttribute("placeholder"),
                help: this.getAttribute("help"),
                class: this.getAttribute("class"),
                tabindex: this.getAttribute("tabindex"),
                disabled: this.getAttribute("disabled") === "",
                required: this.getAttribute("required") === ""
            });
            
            this.input = this.querySelector("input");
            this.feedbackTarget = this.querySelector(".input__feedback");
            
            if (this.input) {
                this.input.addEventListener("Valid", this.inputValidated);
                this.input.addEventListener("Invalid", this.inputInvalid);
                this.input.addEventListener("change", this.inputChanged);
            }
        }

        inputChanged = (event:Event):void => {
            let value = this.input!.value;
            if (value !== null && value !== "" && value.match(/\d{4}-\d{2}-\d{2}/)) {
                value = moment(value, 'YYYY-MM-DD').format(this.formattingHelper!.dateFormat)
                this.input!.value = value;
            }
        }
        inputValidated = (event:Event):void => {
            if (this.feedbackTarget) {
                this.feedbackTarget.innerHTML = "";
            }
        }
        
        inputInvalid = (event:Event):void => {
            if (this.feedbackTarget && this.input) {
                this.feedbackTarget.innerHTML = Messages(this.input.dataset.validationMessage ?? "");
            }
        }   

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }

            this.render();
        }
    }
}
