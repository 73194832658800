namespace Simplex.WebComponents.FormElements {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import SystemInject = Simplex.Decorators.SystemInject
    
    @WebComponent("ui-input-currency")
    export class InputCurrency extends ABWebComponent {
        private readonly contentTemplate;
        private input: HTMLInputElement|null = null;
        private feedbackTarget: HTMLElement|null = null;
        // @ts-ignore
        private autoNumeric: AutoNumeric|null;
        @SystemInject("FormattingHelper")
        private readonly formattingHelper!: Simplex.Components.FormattingHelper;

        private defaultNumberGroupSeparator = '.';
        private defaultNumberDecimalSeparator = ',';

        public constructor() {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/FormElements/InputCurrency', 'InputCurrency') as TemplateCallback;
        }

        get value(): number | null {
            return this.autoNumeric?.rawValue ? parseInt(this.autoNumeric.rawValue) : null;
        }
        
        inputFieldMutation = (mutationList:MutationRecord[]) => {
            mutationList.forEach((mutation: MutationRecord) => {
                switch (mutation.type) {
                    case "attributes":
                        if(this.input !== null && this.autoNumeric.domElement !== null) {
                            const classAttribute = this.input.getAttribute("class");
                            if(classAttribute) 
                            {
                                this.autoNumeric.domElement.setAttribute("class", classAttribute.toString());
                            }
                        }
                        break;
                }
            });
        }

        render() {
            const fieldName = this.getAttribute("name");
            this.innerHTML = this.contentTemplate({
                name: this.getAttribute("name"),
                readonly: this.getAttribute("readonly") === "",
                value: this.getAttribute("value"),
                currency: this.getAttribute("currency") ?? 'euro',
                label: this.getAttribute("label"),
                placeholder: this.getAttribute("placeholder"),
                help: this.getAttribute("help"),
                class: this.getAttribute("class"),
                tabindex: this.getAttribute("tabindex"),
                max: this.getAttribute("max"),
                disabled: this.getAttribute("disabled") === "",
                required: this.getAttribute("required") === ""
            });
            
            const inputAutonumeric = this.querySelector(`input[name='${fieldName}_autonumeric']`);
            this.input = this.querySelector(`input[name='${fieldName}']`);
            if(this.input) {
                this.input.addEventListener("change", this.inputChanged);
                const observer = new MutationObserver(this.inputFieldMutation);
                observer.observe(this.input, {
                    attributeFilter: ["class"],
                    attributeOldValue: true,
                    subtree: true
                });
                this.input.addEventListener("Invalid", this.inputInvalid);
                this.input.addEventListener("Valid", this.inputValidated);
            }
            this.feedbackTarget = this.querySelector(".input__feedback");
            let numberGroupSeparator;
            let numberDecimalSeparator;
            const numberFormat = this.formattingHelper.numberFormat;
            switch (numberFormat) {
                case 'GroupCommaAndDecimalDot':
                    numberGroupSeparator = ',';
                    numberDecimalSeparator = '.';
                    break;
                case 'GroupApostropheAndDecimalDot':
                    numberGroupSeparator = '\'';
                    numberDecimalSeparator = '.';
                    break;
                case 'GroupSpaceAndDecimalDot':
                    numberGroupSeparator = ' ';
                    numberDecimalSeparator = '.';
                    break;
                case 'GroupDotAndDecimalComma':
                default:
                    numberGroupSeparator = this.defaultNumberGroupSeparator;
                    numberDecimalSeparator = this.defaultNumberDecimalSeparator;
                    break;
            }
            if (inputAutonumeric) {
                // @ts-ignore
                this.autoNumeric = new AutoNumeric(inputAutonumeric, {
                    decimalPlaces: 0,
                    digitGroupSeparator: numberGroupSeparator,
                    decimalCharacter: numberDecimalSeparator,
                    wheelStep: 1
                });
                this.autoNumeric.domElement.addEventListener("Valid", this.autonumericValidated);
            }
        }

        inputChanged = (_:Event):void => {
            this.autoNumeric.setValue(this.input?.value);
        }
        
        autonumericValidated = (_:Event):void => {
            if(this.input !== null && this.autoNumeric !== null) {
                this.input.value = this.autoNumeric.rawValue;
                this.input.dispatchEvent(new Event('change'));
            }
        }
        
        inputValidated = (_:Event):void => {
            if (this.feedbackTarget) {
                this.feedbackTarget.innerHTML = "";
            }
        }
        
        inputInvalid = (_:Event):void => {
            if (this.feedbackTarget && this.input) {
                this.feedbackTarget.innerHTML = Messages(this.input.dataset.validationMessage ?? "");
            }
        }   

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }

            this.render();
        }
    }
}
