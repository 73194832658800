namespace Simplex.WebComponents {
  import WebComponent = Simplex.Decorators.WebComponent;
  import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
  import ABWebComponent = Simplex.Components.ABWebComponent;
  import DropdownMenu = Simplex.WebComponents.LayoutComponents.DropdownMenu;
  import InputSelect = Simplex.WebComponents.FormElements.InputSelect;
  import InputRangeSlider = Simplex.WebComponents.FormElements.InputRangeSlider;
  import SnapshotSummary = Simplex.Models.Project.SnapshotSummary;
  import CostCategorySummary = Simplex.Models.Project.CostCategorySummary;
  import Scope = Simplex.Models.Project.Scope;
  import CostCategory = Simplex.Models.Project.CostCategory;

  @WebComponent("ui-budget-filter")
  export class BudgetFilter extends ABWebComponent {
    private readonly _projectId: string;
    private readonly _contentTemplate;
    private _rendered: boolean = false;
    private _closeElement?: HTMLElement;
    private readonly _filterModelSessionStorageKey: string;
    private _filterModel: Simplex.WebComponents.Project.Budget.Models.FilterModel =
      new Simplex.WebComponents.Project.Budget.Models.FilterModel();
    private _snapshotsFilterDropdownMenu?: DropdownMenu;
    private _periodsFilterDropdownMenu?: DropdownMenu;
    private _scopeLevelSlider?: InputRangeSlider;
    private _costCategoryLevelSlider?: InputRangeSlider;
    private _costCategoryList?: InputSelect;
    private _scopeList?: InputSelect;
    private snapshots: SnapshotSummary[] = [];
    private _scopeDropdown?: HTMLElement;
    private _scopes?: Scope[];
    private _mainCostCategories?: CostCategorySummary[];

    public constructor(
      projectId: string,
      snapshots: SnapshotSummary[],
      scopes: Scope[],
      mainCostCategories: CostCategorySummary[]
    ) {
      super();
      this._projectId = projectId;
      this._filterModelSessionStorageKey =
        Simplex.Utils.getBudgetFilterModelCacheKey(this._projectId);
      this.snapshots = snapshots;
      this._scopes = scopes;
      this._mainCostCategories = mainCostCategories;
      this._contentTemplate = this.app.getTemplate(
        "WebComponents/Project/Budget/BudgetFilter",
        "BudgetFilter"
      ) as TemplateCallback;
    }

    render() {
      this.innerHTML = this._contentTemplate({
        filterModel: this._filterModel,
        snapshots: this.snapshots,
        snapshotsFilter: this.snapshots.length > 0,
      });
      this._scopeLevelSlider = this.querySelector(
        'ui-input-range-slider[name="scopeLevel"]'
      ) as InputRangeSlider;
      this._costCategoryLevelSlider = this.querySelector(
        'ui-input-range-slider[name="costCategoryLevel"]'
      ) as InputRangeSlider;
      this._snapshotsFilterDropdownMenu = this.querySelector(
        "ui-dropdown-menu.snapshots-filter"
      ) as DropdownMenu;
      this._periodsFilterDropdownMenu = this.querySelector(
        "ui-dropdown-menu.period-filter"
      ) as DropdownMenu;

      const selectedSnapshot = this.snapshots.filter(
        (s) => s.id === this._filterModel.compareWithSnapshotId
      );
      this.setDropdownFilterTitle(
        this._snapshotsFilterDropdownMenu!,
        selectedSnapshot.length > 0 ? selectedSnapshot[0].name : "",
        "project.details.selected_snapshot.label",
        "project.details.selected_snapshot.none.label"
      );
      this.setDropdownFilterTitle(
        this._periodsFilterDropdownMenu!,
        "project.budget.periods." +
          Simplex.WebComponents.Project.Budget.Models.BudgetPeriodType[
            this._filterModel.periodType
          ],
        null,
        ""
      );

      this._closeElement = this.querySelector(".close") as HTMLElement;
      this._closeElement.addEventListener("click", this.close);

      this.fillCostCategoriesList();
      this.fillScopesList();
      this._rendered = true;
    }

    public onFilterChanged = () => {
      this.loadFilterModelFromSessionStorage();
      this.render();
      this.bindElementEventListeners();
    };

    private onSnapshotDropdownMenuAction = async (
      event: Event
    ): Promise<void> => {
      const dropdownMenuEvent = event as CustomEvent;
      const compareWithSnapshotId = dropdownMenuEvent.detail.details;
      this._filterModel.compareWithSnapshotId = compareWithSnapshotId ?? "";
      const selectedSnapshot = this.snapshots.filter(
        (s) => s.id === compareWithSnapshotId
      );
      this.setDropdownFilterTitle(
        this._snapshotsFilterDropdownMenu!,
        selectedSnapshot.length > 0 ? selectedSnapshot[0].name : "",
        "project.details.selected_snapshot.label",
        "project.details.selected_snapshot.none.label"
      );
      this.dispatchEvent(
        new CustomEvent("filterChanged", {
          detail: {
            name: "compareWithSnapshotId",
            value: this._filterModel.compareWithSnapshotId,
          },
          bubbles: true,
        })
      );
    };

    private onPeriodDropdownMenuAction = async (
      event: Event
    ): Promise<void> => {
      const dropdownMenuEvent = event as CustomEvent;
      this._filterModel.periodType = dropdownMenuEvent.detail
        .details as Simplex.WebComponents.Project.Budget.Models.BudgetPeriodType;
      this.setDropdownFilterTitle(
        this._periodsFilterDropdownMenu!,
        "project.budget.periods." +
          Simplex.WebComponents.Project.Budget.Models.BudgetPeriodType[
            this._filterModel.periodType
          ],
        null,
        ""
      );
      this.dispatchEvent(
        new CustomEvent("filterChanged", {
          detail: { name: "periodType", value: this._filterModel.periodType },
          bubbles: true,
        })
      );
    };

    private fillCostCategoriesList = (): void => {
      if (this._mainCostCategories) {
        let inputSelectCostCategoryOptions = `<option value="" class="i18n">project.budget.all_costCategories</option>`;
        this._mainCostCategories.forEach((costCategory) => {
          inputSelectCostCategoryOptions += `<option value="${
            costCategory.id
          }" ${
            this._filterModel.costCategoryId === costCategory.id
              ? 'selected="selected"'
              : ""
          }>${costCategory.name}</option>`;
        });
        this._costCategoryList = new InputSelect(
          inputSelectCostCategoryOptions
        );
        this._costCategoryList.addEventListener(
          "change",
          this.onCostCategoryChanged
        );
        const costCategoryDropdown = this.querySelector(
          ".costCategoryDropdown"
        ) as HTMLElement;
        if (costCategoryDropdown) {
          costCategoryDropdown.innerHTML = "";
          costCategoryDropdown.append(this._costCategoryList);
        }
      }
    };

    private onCostCategoryChanged = (event: Event) => {
      const select = event.target as HTMLSelectElement;
      this.dispatchEvent(
        new CustomEvent("filterChanged", {
          detail: { name: "costCategoryId", value: select.value },
          bubbles: true,
        })
      );
    };
    private onScopeChanged = (event: Event) => {
      const select = event.target as HTMLSelectElement;
      this.dispatchEvent(
        new CustomEvent("filterChanged", {
          detail: { name: "scopeId", value: select.value },
          bubbles: true,
        })
      );
    };

    private onScopeLevelChange = (event: Event) => {
      const customEvent = event as CustomEvent;
      this.dispatchEvent(
        new CustomEvent("filterChanged", {
          detail: { name: "scopeLevel", value: customEvent.detail.level },
          bubbles: true,
        })
      );
    };

    private onCostCategoryLevelChange = (event: Event) => {
      const customEvent = event as CustomEvent;
      this.dispatchEvent(
        new CustomEvent("filterChanged", {
          detail: {
            name: "costCategoryLevel",
            value: customEvent.detail.level,
          },
          bubbles: true,
        })
      );
    };

    private fillScopesList = (): void => {
      if (this._scopes) {
        this._scopeDropdown = this.querySelector(
          ".scopeDropdown"
        ) as HTMLElement;
        let inputSelectScopeOptions = `<option value="" class="i18n">project.details.planning.allScopes</option>`;
        let selectedScopeFound = false;
        const addScopesToDropDownResult = this.addScopesToDropDown(
          this._scopes,
          selectedScopeFound,
          inputSelectScopeOptions
        );
        selectedScopeFound = addScopesToDropDownResult.selectedScopeFound;
        inputSelectScopeOptions =
          addScopesToDropDownResult.inputSelectScopeOptions;
        if (!selectedScopeFound) {
          this._filterModel.scopeId = null;
        }
        this._scopeList = new Simplex.WebComponents.FormElements.InputSelect(
          inputSelectScopeOptions
        );
        this._scopeList.addEventListener("change", this.onScopeChanged);
        if (this._scopeDropdown) {
          this._scopeDropdown.innerHTML = "";
          this._scopeDropdown.append(this._scopeList);
        }
      }
    };

    private addScopesToDropDown = (
      scopes: Scope[],
      selectedScopeFound: boolean,
      inputSelectScopeOptions: string,
      depth: number = 0
    ) => {
      scopes.forEach((scope) => {
        if (
          scope.parentScopeId === Simplex.Utils.GUID_EMPTY ||
          this._filterModel.graphViewType ===
            Simplex.WebComponents.Project.Budget.Models.BudgetViewType
              .GraphBudget ||
          this._filterModel.graphViewType ===
            Simplex.WebComponents.Project.Budget.Models.BudgetViewType
              .GraphActualCosts
        ) {
          const scopeSelected = this._filterModel.scopeId === scope.id;
          selectedScopeFound = selectedScopeFound || scopeSelected;
          inputSelectScopeOptions += `<option value="${scope.id}" ${
            scopeSelected ? 'selected="selected"' : ""
          }>${" ".padStart(depth + 1, "➣") + scope.name}</option>`;
          if (
            (this._filterModel.graphViewType ===
              Simplex.WebComponents.Project.Budget.Models.BudgetViewType
                .GraphBudget ||
              this._filterModel.graphViewType ===
                Simplex.WebComponents.Project.Budget.Models.BudgetViewType
                  .GraphActualCosts) &&
            scope.children &&
            scope.children.length > 0
          ) {
            const addScopesToDropDownResult = this.addScopesToDropDown(
              scope.children,
              selectedScopeFound,
              "",
              depth + 1
            );
            selectedScopeFound =
              selectedScopeFound ||
              addScopesToDropDownResult.selectedScopeFound;
            inputSelectScopeOptions +=
              addScopesToDropDownResult.inputSelectScopeOptions;
          }
        }
      });
      return {
        selectedScopeFound,
        inputSelectScopeOptions,
      };
    };

    private setDropdownFilterTitle = (
      dropdownMenu: DropdownMenu,
      filterName: string,
      filterPrefix: string | null,
      emptyName: string
    ): void => {
      if (filterName !== "") {
        dropdownMenu?.setFilterTitle(
          filterPrefix
            ? Messages(filterPrefix, filterName)
            : Messages(filterName)
        );
      } else {
        dropdownMenu?.setFilterTitle(Messages(emptyName));
      }
    };

    private bindElementEventListeners() {
      if (this._snapshotsFilterDropdownMenu) {
        this._snapshotsFilterDropdownMenu.addEventListener(
          "action",
          this.onSnapshotDropdownMenuAction
        );
      }
      if (this._periodsFilterDropdownMenu) {
        this._periodsFilterDropdownMenu.addEventListener(
          "action",
          this.onPeriodDropdownMenuAction
        );
      }

      this._scopeLevelSlider?.addEventListener(
        "levelChange",
        this.onScopeLevelChange
      );
      this._costCategoryLevelSlider?.addEventListener(
        "levelChange",
        this.onCostCategoryLevelChange
      );
    }

    private removeElementEventListeners(): void {
      if (this._snapshotsFilterDropdownMenu) {
        this._snapshotsFilterDropdownMenu.removeEventListener(
          "action",
          this.onSnapshotDropdownMenuAction
        );
      }
      if (this._periodsFilterDropdownMenu) {
        this._periodsFilterDropdownMenu.removeEventListener(
          "action",
          this.onPeriodDropdownMenuAction
        );
      }
      this._scopeLevelSlider?.removeEventListener(
        "levelChange",
        this.onScopeLevelChange
      );
      this._costCategoryLevelSlider?.removeEventListener(
        "levelChange",
        this.onCostCategoryLevelChange
      );
    }

    private loadFilterModelFromSessionStorage = () => {
      const filterModelFromSessionStorage = localStorage.getItem(
        this._filterModelSessionStorageKey
      );
      if (filterModelFromSessionStorage) {
        const filterModelFromSessionStorageJson = JSON.parse(
          filterModelFromSessionStorage
        ) as Simplex.WebComponents.Project.Budget.Models.FilterModel;

        this._filterModel.periodType =
          filterModelFromSessionStorageJson.periodType;
        this._filterModel.scopeLevel =
          filterModelFromSessionStorageJson.scopeLevel ?? 3;
        this._filterModel.costCategoryLevel =
          filterModelFromSessionStorageJson.costCategoryLevel ?? 3;

        if (filterModelFromSessionStorageJson.projectId === this._projectId) {
          this._filterModel.compareWithSnapshotId =
            filterModelFromSessionStorageJson.compareWithSnapshotId;
          this._filterModel.scopeId = filterModelFromSessionStorageJson.scopeId;
          this._filterModel.costCategoryId =
            filterModelFromSessionStorageJson.costCategoryId;
        }
      } else {
        this._filterModel.scopeLevel = 3;
        this._filterModel.costCategoryLevel = 3;
      }
    };

    private saveFilterModelToSessionStorage = () => {
      localStorage.setItem(
        this._filterModelSessionStorageKey,
        JSON.stringify(this._filterModel)
      );
    };

    public close = (): void => {
      this.classList.remove("is--open");
      this.dispatchEvent(new CustomEvent("budgetFilterClose", {}));
    };

    private open = (): void => {
      this.classList.add("is--open");
    };
    public toggle = (): void => {
      this.classList.contains("is--open") ? this.close() : this.open();
    };

    async connectedCallback() {
      if (!this.isConnected) {
        return;
      }
      if (!this._rendered) {
        this.loadFilterModelFromSessionStorage();
        this.render();
        this.bindElementEventListeners();
      }
    }
  }
}
