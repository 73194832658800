namespace Simplex.WebComponents.FormElements {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import SystemInject = Simplex.Decorators.SystemInject
    
    @WebComponent("ui-input-number")
    export class InputNumber extends ABWebComponent {
        @SystemInject("FormattingHelper")
        private readonly formattingHelper!: Simplex.Components.FormattingHelper;
        
        private readonly contentTemplate;
        private input: HTMLInputElement|null = null;
        private feedbackTarget: HTMLElement|null = null;
        // @ts-ignore
        private autoNumeric: AutoNumeric|null;
        private minValue: number = 0;
        private maxValue: number = Number.MAX_VALUE

        private defaultNumberGroupSeparator = '.';
        private defaultNumberDecimalSeparator = ',';
        
        public constructor() {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/FormElements/InputNumber', 'InputNumber') as TemplateCallback;
        }

        get value(): number | null {
            return this.autoNumeric?.rawValue ? parseInt(this.autoNumeric.rawValue) : null;
        }

        set value(value: number | null) {
            if(this.input) {
                if(value === null) {
                    value = 0;
                }
                if(value < this.minValue) {
                    value = this.minValue;                    
                } else if (value > this.maxValue) {
                    value = this.maxValue;
                }
                if(this.input.value !== value.toString()) {
                    this.input.value = value.toString();
                    this.input.dispatchEvent(new Event('change'));
                }
            }
        }

        inputFieldMutation = (mutationList:MutationRecord[]) => {
            mutationList.forEach((mutation: MutationRecord) => {
                switch (mutation.type) {
                    case "attributes":
                        if(this.input !== null && this.autoNumeric.domElement !== null) {
                            const classAttribute = this.input.getAttribute("class");
                            if(classAttribute)
                            {
                                this.autoNumeric.domElement.setAttribute("class", classAttribute.toString());
                            }
                        }
                        break;
                }
            });
        }

        render() {
            const fieldName = this.getAttribute("name");
            const minValue = this.getAttribute("min");
            if(minValue) {
                this.minValue = parseInt(minValue);
            }
            const maxValue = this.getAttribute("max");
            if(maxValue) {
                this.maxValue = parseInt(maxValue);
            }
            this.innerHTML = this.contentTemplate({
                name: this.getAttribute("name"),
                readonly: this.getAttribute("readonly") === "",
                value: this.getAttribute("value"),
                label: this.getAttribute("label"),
                placeholder: this.getAttribute("placeholder"),
                affix: this.getAttribute("affix"),
                help: this.getAttribute("help"),
                class: this.getAttribute("class"),
                tabindex: this.getAttribute("tabindex"),
                min: this.minValue,
                max: this.maxValue,
                disabled: this.getAttribute("disabled") === "",
                required: this.getAttribute("required") === "",
                showButtons: this.getAttribute("showButtons") === ""
            });

            const inputAutonumeric = this.querySelector(`input[name='${fieldName}_autonumeric']`);
            this.input = this.querySelector(`input[name='${fieldName}']`);
            if(this.input) {
                this.input.addEventListener("change", this.inputChanged);
                const observer = new MutationObserver(this.inputFieldMutation);
                observer.observe(this.input, {
                    attributeFilter: ["class"],
                    attributeOldValue: true,
                    subtree: true
                });
                this.input.addEventListener("Invalid", this.inputInvalid);
                this.input.addEventListener("Valid", this.inputValidated);
            }
            this.feedbackTarget = this.querySelector(".input__feedback");
            let numberGroupSeparator;
            let numberDecimalSeparator;
            const numberFormat = this.formattingHelper.numberFormat;
            switch (numberFormat) {
                case 'GroupCommaAndDecimalDot':
                    numberGroupSeparator = ',';
                    numberDecimalSeparator = '.';
                    break;
                case 'GroupApostropheAndDecimalDot':
                    numberGroupSeparator = '\'';
                    numberDecimalSeparator = '.';
                    break;
                case 'GroupSpaceAndDecimalDot':
                    numberGroupSeparator = ' ';
                    numberDecimalSeparator = '.';
                    break;
                case 'GroupDotAndDecimalComma':
                default:
                    numberGroupSeparator = this.defaultNumberGroupSeparator;
                    numberDecimalSeparator = this.defaultNumberDecimalSeparator;
                    break;
            }
            if (inputAutonumeric) {
                // @ts-ignore
                this.autoNumeric = new AutoNumeric(inputAutonumeric, {
                    decimalPlaces: 0,
                    digitGroupSeparator: numberGroupSeparator,
                    decimalCharacter: numberDecimalSeparator,
                    wheelStep: 1,
                    minimumValue: this.getAttribute("min") ? this.getAttribute("min") : '-10000000000000',
                    maximumValue: this.getAttribute("max") ? this.getAttribute("max") : '10000000000000',
                    overrideMinMaxLimits: this.getAttribute("overrideMinMaxLimits") ? this.getAttribute("overrideMinMaxLimits") : 'ceiling'
                });
                this.autoNumeric.domElement.addEventListener("Valid", this.autonumericValidated);
            }
            const minButton = this.querySelector(".JsMinNumber");
            const plusButton = this.querySelector(".JsPlusNumber");
            minButton?.addEventListener("click", this.minButtonClicked);
            plusButton?.addEventListener("click", this.plusButtonClicked);
        }
        
        private minButtonClicked = (event:Event) : void => {
            event.preventDefault();
            event.stopPropagation();

            if(this.input !== null && this.autoNumeric !== null) {
                const value = this.autoNumeric.rawValue ? parseInt(this.autoNumeric.rawValue) : 0;
                const newValue = value - 1;
                this.input.value = ((newValue >= this.minValue ? newValue : this.minValue).toString());
                this.input.dispatchEvent(new Event('change'));
            }
        }
        
        private plusButtonClicked = (event:Event) : void => {
            event.preventDefault();
            event.stopPropagation();

            if(this.input !== null && this.autoNumeric !== null) {
                const value = this.autoNumeric.rawValue ? parseInt(this.autoNumeric.rawValue) : 0;
                const newValue = value + 1;
                this.input.value = ((newValue <= this.maxValue ? newValue : this.maxValue).toString());
                this.input.dispatchEvent(new Event('change'));
            }
        }

        private inputChanged = (_:Event):void => {
            this.autoNumeric.setValue(this.input?.value);
        }

        private autonumericValidated = (_:Event):void => {
            if(this.input !== null && this.autoNumeric !== null) {
                this.input.value = this.autoNumeric.rawValue;
                this.input.dispatchEvent(new Event('change'));
            }
        }

        private inputValidated = (_:Event):void => {
            if (this.feedbackTarget) {
                this.feedbackTarget.innerHTML = "";
            }
        }

        private inputInvalid = (_:Event):void => {
            if (this.feedbackTarget && this.input) {
                this.feedbackTarget.innerHTML = Messages(this.input.dataset.validationMessage ?? "");
            }
        }

        private connectedCallback() {
            if (!this.isConnected) {
                return;
            }

            this.render();
        }
    }
}