namespace Simplex.Components {
    import Application = Ambrero.AB.Application;

    @Simplex.Decorators.Singleton()
    export class State {

        private _state?:RequestState;
        private readonly app: Application;

        constructor(app: Application) {
            this.app = app;
            this.app.getEventBus().on('reset', this.subscribeEventBus);
            this.subscribeEventBus();

        }
        
        public get = ():RequestState => {
            if(this._state) {
                return this._state;
            }
            console.log("Created state");
            this._state = new RequestState();
            return this._state;
        }
        
        private readonly applicationLoaded = (): void => {
            this._state = undefined;
        }

        private readonly subscribeEventBus = (): void => {
            this.app.getEventBus().subscribe('applicationLoaded', this.applicationLoaded, self);
        }
        
    }
}