namespace Simplex.Models.Project {
  import ABModel = Ambrero.AB.Models.ABModel;

  export class CreateProjectRequest extends ABModel {
    public name: string;
    public number: string;
    public templateId: string;

    constructor(
      name: string = "",
      number: string = "",
      templateId: string = ""
    ) {
      super();
      this.name = name;
      this.number = number;
      this.templateId = templateId;
    }
  }
}
