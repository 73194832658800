namespace Simplex.Components {
    import Application = Ambrero.AB.Application;

    export interface InitializedComponentEvent {
        identifier: string | null,
        component: any
    }
    
    export abstract class ABWebComponent extends HTMLElement {
        protected readonly app: Application;
        protected readonly request: FetchRequestHandler;
        protected constructor() {
            super();
            this.app = Ambrero.AB.Application.Instance!;
            this.request = this.app.getComponentType<FetchRequestHandler>("FetchRequestHandler")!;

        }
    }
}