namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;
    import IBoundModel = Ambrero.AB.Models.IBoundModel;

    export class CreateScopeBudgetItem extends ABModel {

        public scopeId: string|null = null;
        public scopeHasChildren: boolean = false;
        public subScopeId: string|null = null;
        public budgets: ScopeBudget[] = [];
    
        constructor() {
            super();
        }

        public validateField = (fieldName: string, element: HTMLElement, value: string, boundModel: IBoundModel): string | undefined => {
            switch(fieldName) {
                case "subScopeId":
                    if (this.scopeHasChildren && (value === null || value === '')) {
                        return Messages("error.required");
                    }
            }
        }
        
        public validateBudget = ():boolean => {
            return this.budgets.length > 0;
        }

        public GetFieldModel = (fieldName:string) => {
            switch (fieldName) {
                case "budgets":
                    return new ScopeBudget();
            }
            return null;
        }
    }
}