/// <reference path="../Declarations/Handlebars.d.ts" />

namespace Template.HandlebarHelper {
	export const debug: Handlebars.HelperDelegate = function() {
		const args = arguments;
		if(args.length > 1) {
			console.log(args[0], args[1])
		}
		else if(args.length > 0) {
			console.log(args[0])
		}
		debugger;
		return args ? "" : '';
	}
}

Handlebars.registerHelper('debug', Template.HandlebarHelper.debug);
