namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class ProjectReportData extends ABModel {

        public id: string;
        public name: string;
        public number: string;

        public startDate: string;
        public endDate: string;
        
        public baselineName: string;
        public baselineDate: string;
        
        public snapshotName: string;
        public snapshotDate: string;

        public parentScopes: Scope[];

        constructor(id: string = "", 
                    name: string = "",
                    number: string = "",
                    startDate: string = "",
                    endDate: string = "",
                    baselineName: string,
                    baselineDate: string,
                    snapshotName: string,
                    snapshotDate: string,
                    parentScopes: Scope[]
        ) {
            super();
            this.id = id;
            this.name = name;
            this.number = number;
            this.startDate = startDate;
            this.startDate = startDate;
            this.endDate = endDate;
            this.baselineName = baselineName;
            this.baselineDate = baselineDate;
            this.snapshotName = snapshotName;
            this.snapshotDate = snapshotDate;
            this.parentScopes = parentScopes;
        }
    }
}