namespace Simplex.Models.Project {

    export class ChangeCostCategorySortingRequest {
        public newParentCostCategoryId: string;
        public newIndex: number;

        constructor(newParentCostCategoryId: string = Simplex.Utils.GUID_EMPTY, newIndex: number = 0) {
            this.newParentCostCategoryId = newParentCostCategoryId;
            this.newIndex = newIndex;
        }
    }
}