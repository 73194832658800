namespace Simplex.WebComponents.FormElements {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import Emitter = Ambrero.AB.Components.Emitter;

    @WebComponent("ui-input-radio")
    export class InputRadio extends ABWebComponent {

        private readonly contentTemplate;
        private input: HTMLInputElement | null = null;
        private readonly _emitter: Emitter;

        public constructor() {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/FormElements/InputRadio', 'InputRadio') as TemplateCallback;
            this._emitter = new Emitter();
        }


        private connectedCallback() {
            if (!this.isConnected) {
                return;
            }

            this.render();
        }
        render() {
            this.innerHTML = this.contentTemplate({
                name: this.getAttribute("name"),
                checked: this.getAttribute("checked") === "",
                radioValue: this.getAttribute("radioValue"),
                label: this.getAttribute("label"),
                class: this.getAttribute("class"),
                tabindex: this.getAttribute("tabindex"),
                disabled: this.getAttribute("disabled") === "",
                icon: this.getAttribute("icon"),
            });
            this.input = this.querySelector("input");
            if (this.input) {
                this.input.addEventListener('change', this.toggle);
            }
        }

        private readonly toggle = (): void => {
            this.dispatchEvent(new CustomEvent("toggle", {detail: this.input?.checked}))
        }
    }
}
