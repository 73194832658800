namespace Simplex.WebComponents.FormElements {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import APIResult = Simplex.Utils.APIResult;
    import PagedResult = Simplex.Models.PagedResult;

    
    @WebComponent("ui-autocomplete")
    export class Autocomplete<TSummary> extends ABWebComponent implements Node {
        private readonly contentTemplate;
        private _input: HTMLInputElement | null = null;
        private resultsElement: HTMLElement | null = null;
        private resultsListElement: HTMLUListElement | null = null;
        private closeTimer?: number;
        private _searching: boolean = false;
        private _triggerSearchAgain: boolean = false;
        private readonly _results: TSummary[] = [];
        private readonly _noResultsItem: HTMLLIElement;
        private _visible: boolean = false;
        private readonly _name: string;
        private readonly _doSearchOnFocus: boolean;
        private readonly _updateUrl: string;
        private readonly _placeholder: string;
        private readonly _getInnerHtmlOfSummary: (summary:TSummary) =>string;
        private _feedbackTarget: HTMLElement|null = null;
        private timeout:number = 0;

        public constructor(updateUrl: string, name: string, placeholder: string, getInnerHtmlOfSummary: (summary:TSummary) => string, emptyMessage?: string, doSearchOnFocus: boolean = false) {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/FormElements/Autocomplete', 'Autocomplete') as TemplateCallback;
            this._noResultsItem = document.createElement("LI") as HTMLLIElement;
            this._noResultsItem.classList.add("noresults");
            this._noResultsItem.innerHTML = Messages(emptyMessage ?? '');
            this._updateUrl = updateUrl;
            this._doSearchOnFocus = doSearchOnFocus;
            this._name = name;
            this._placeholder = placeholder;
            this._getInnerHtmlOfSummary = getInnerHtmlOfSummary;
        }

        focus = (): void => {
            if (this._input) {
                this._input.focus();
            }
        }

        async render() {
            this.innerHTML = this.contentTemplate({
                name: this._name,
                placeholder: this._placeholder
            });

            this._input = this.querySelector("input");
            this._feedbackTarget = this.querySelector(".input__feedback");

            this.resultsElement = this.querySelector(".input__results");
            this.resultsListElement = this.querySelector("ul");

            if (this._input) {
                this._input.addEventListener("blur", this.onBlur);
                this._input.addEventListener("focus", this.onFocus);
                this._input.addEventListener("keyup", this.onKeyPress);
            }
        }        

        updateResults = (newResults: TSummary[]) => {
            this._results.length = 0;
            for (let k in newResults) {
                this._results.push(newResults[k]);
            }
            const currentResultElements = this.resultsListElement!.querySelectorAll('li');
            currentResultElements.forEach(element => element.remove());

            if (this._results.length === 0) {
                if (this._input!.value.length > 0) {
                    this.resultsListElement!.appendChild(this._noResultsItem)
                }
            } else {
                if (this._noResultsItem.parentElement) {
                    this._noResultsItem.remove();
                }
                for (let i = 0; i < this._results.length; i++) {
                    const element = document.createElement("LI") as HTMLLIElement;
                    element.innerHTML = this._getInnerHtmlOfSummary(this._results[i]);
                    element.addEventListener("click", this.onSelectItem.bind(this, this._results[i]));
                    this.resultsListElement!.appendChild(element)

                }
            }
        }

        onSelectItem = (result: TSummary, _: MouseEvent): void => {
            if (this._input) {
                this._input.value = "";
            }
            this._results.splice(0,this._results.length);
            this.dispatchEvent(new CustomEvent<TSummary>("selected", {detail: result}));
            this.closeResults();
        }

        doSearch = async (): Promise<void> => {
            if (this._searching) {
                this._triggerSearchAgain = true;
                return;
            }
            const value = this._input!.value;
            const searchResult = await this.request.post<APIResult<PagedResult<TSummary>>>(this._updateUrl, { searchTerm: value });
            if (this.resultsElement) {
                this.resultsElement.classList.add("is--search_active");
            }
            if (searchResult.isSuccess && searchResult.data.data) {
                this.updateResults(searchResult.data.data.items);
            } else {
                this.updateResults([]);
            }

            this._searching = false;
            if (this._triggerSearchAgain) {
                this._triggerSearchAgain = false;
                await this.search();
            }
        }

        search = async (): Promise<void> => {
            clearTimeout(this.timeout);

            this.timeout = setTimeout(() =>{
                this.doSearch();
            }, 200);
        }

        onKeyPress = async (_: KeyboardEvent) => {
            await this.search();
        }

        onBlur = (_: Event): void => {
            if (this.closeTimer) {
                clearTimeout(this.closeTimer);
            }
            this.closeTimer = setTimeout(this.closeResults, 100);
        }

        closeResults = (): void => {
            if (this.closeTimer) {
                clearTimeout(this.closeTimer);
            }
            if (this.resultsElement) {
                this.resultsElement.classList.remove("is--open");
                this.resultsElement.classList.remove("is--search_active");
            }
            this._visible = false;
            this._triggerSearchAgain = false;
            document.removeEventListener('click', this.onDocumentClick);

        }

        public inputValid = ():void => {
            if (this._feedbackTarget) {
                this.classList.remove('is--invalid');
                this.classList.add('is--valid');
                this._feedbackTarget.innerHTML = "";
            }
        }

        public inputInvalid = (message:string):void => {
            if (this._feedbackTarget && this._input) {
                this._feedbackTarget.style.display = '-webkit-box';
                this._feedbackTarget.innerHTML = Messages(message);
                this.classList.remove('is--valid');
                this.classList.add('is--invalid');
            }
        }

        onFocus = async (_: Event): Promise<void> => {
            if (this.closeTimer) {
                clearTimeout(this.closeTimer);
            }
            if (this.resultsElement) {
                this.resultsElement.classList.add("is--open");
                if(this._results && this._results.length > 0) {
                    this.resultsElement.classList.add("is--search_active");
                } else {
                    await this.doSearch();
                }                
            }
            this._visible = true;
            document.addEventListener('click', this.onDocumentClick);
        }

        private isClickInPopup = (node: HTMLElement): boolean => {
            while (node !== document.body && node.parentElement !== null) {
                if (node == this) {
                    return true;
                }
                node = node.parentElement;
            }
            return false;
        }

        private onDocumentClick = (event: MouseEvent): void => {
            if (!this.isClickInPopup(event.target as HTMLElement)) {
                this.closeResults();
            }
        }


        async connectedCallback() {
            if (!this.isConnected) {
                return;
            }

            await this.render();
        }
    }
}
