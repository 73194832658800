
/// <reference path="../Models/Project/ProjectUser.ts" />

namespace Simplex.Controllers {

    import Content = Simplex.Components.Content;
    import UserAutocomplete = Simplex.WebComponents.Project.UserAutocomplete;
    import UserMutationEvent = Simplex.WebComponents.Project.UserMutationEvent;
    import EventArgs = Ambrero.AB.Components.EventArgs;
    import FetchRequestHandler = Simplex.Components.FetchRequestHandler;
    import Layout = Simplex.Components.Layout;
    import Inject = Simplex.Decorators.Inject;
    import Path = Simplex.Decorators.Path;
    import Get = Simplex.Decorators.Get;
    import Authenticated = Simplex.Decorators.Authenticated;
    import Controller = Simplex.Decorators.Controller;
    import APIResult = Simplex.Utils.APIResult;
    import ModelBinder = Ambrero.AB.Components.ModelBinder;
    import TenantUser = Simplex.Models.Users.TenantUser;
    import ProjectUser = Simplex.Models.Project.ProjectUser;
    import UserList = Simplex.WebComponents.Project.UserList;
    import Scope = Simplex.Models.Project.Scope;
    import Planning = Simplex.WebComponents.Planning;
    import ProjectDashboardSetupHelper = Simplex.WebComponents.Project.ProjectDashboardSetupHelper.ProjectDashboardSetupHelperWidget;
    import ProjectDashboardTasksWidget = Simplex.WebComponents.Project.ProjectDashboardTasks.ProjectDashboardTasksWidget;
    import ProjectTasksManager = Simplex.Components.ProjectTasksManager;
    
    interface DetailContext {
        ProjectId: string,
        Project: Simplex.Models.Project.Project
    }
    interface PlanningContext {
        ProjectId: string,
        Project: Simplex.Models.Project.Project
    }

    interface PlanningEditContext {
        ProjectId: string,
        Project: Simplex.Models.Project.Project,
        Scopes: Scope[]
    }

    interface SnapshotEditContext {
        ProjectId: string,
        Project: Simplex.Models.Project.Project,
        Snapshot: Simplex.Models.Project.SnapshotSummary|null,
        Scopes: Scope[]
    }
    interface ReportCreateContext {
        ProjectId: string,
        Project: Simplex.Models.Project.Project
    }
    interface FinanceContext {
        ProjectId: string,
        Project: Simplex.Models.Project.Project
    }

    @Authenticated()
    @Controller("/project")
    export class Project extends Ambrero.AB.Controllers.Controller {

        @Inject("Layout")
        private layout!: Layout

        @Inject("FetchRequestHandler")
        private request!: FetchRequestHandler
        
        @Get("{id:guid}")
        public async Detail(@Path("id") id: string): Promise<void> {
            const content = this.app.getComponent('Content', 'Project/detail') as Content<DetailContext>;
            content.setTargetContainerElement(this.layout.getContentRenderTarget());
            
            const project = await this.LoadProject(id);
            if (project === undefined) {
                throw "Project not found";
            }
            content.on('draw', (eventArgs:EventArgs):void => {
                const container = eventArgs.data.container.get(0) as HTMLElement;
                const projectDashboardRoadmap = container.querySelector("ui-dashboard-project-setuphelper-widget") as ProjectDashboardSetupHelper;
                projectDashboardRoadmap.addEventListener("roadmapClosed", (event:Event):void => {
                    event.stopPropagation();
                    const projectDashboardTasksWidget = content.selectElement("ui-dashboard-project-tasks-widget") as ProjectDashboardTasksWidget;
                    projectDashboardTasksWidget.refresh();

                });
            });
            content.setContext({
                ProjectId: id,
                Project: project
            } as DetailContext);
            content.draw();
        } 
        
        @Get("{id:guid}/budget")
        public async Budget(@Path("id") id: string): Promise<void> {
            const content = this.app.getComponent('Content', 'Project/budget') as Content<DetailContext>;
            content.setTargetContainerElement(this.layout.getContentRenderTarget());
            
            const project = await this.LoadProject(id);
            if (project === undefined) {
                throw "Project not found";
            }
            
            content.setContext({
                ProjectId: id,
                Project: project
            } as DetailContext);
            content.draw();
        } 
        
        @Get("{id:guid}/planning")
        public async Planning(@Path("id") id: string): Promise<void> {
            const content = this.app.getComponent('Content', 'Project/planning') as Content<PlanningContext>;
            content.setTargetContainerElement(this.layout.getContentRenderTarget());
            const project = await this.LoadProject(id);
            if (project === undefined) {
                throw "Project not found";
            }

            this.layout.getContentRenderTarget()
            
            content.setContext({
                ProjectId: id,
                Project: project
            } as PlanningContext);

            content.on('draw', (eventArgs:EventArgs):void => {
                const container = eventArgs.data.container.get(0) as HTMLElement;
                const planning = container.querySelector("ui-planning") as Planning;
            });
            
            content.draw();
        }        
        
        @Get("{id:guid}/edit")
        public async Edit(@Path("id") id: string): Promise<void> {
            const content = this.app.getComponent('Content', 'Project/edit') as Content<PlanningContext>;
            const modelBinder = this.app.getComponentType<ModelBinder>('ModelBinder')!;
            const projectTasksManager = this.app.getComponent('ProjectTasksManager') as ProjectTasksManager;
            let userList: UserList|null = null;
            content.setTargetContainerElement(this.layout.getContentRenderTarget());
            const notice = this.app.getComponent('AmbreroComponents.Notice');
            
            const project = await this.LoadProject(id);
            if (project === undefined) {
                throw "Project not found";
            }
            
            const projectUsers = await this.LoadProjectUsers(id);
            
            
            const model = new Simplex.Models.Project.ProjectEdit(project.name, project.startDate, project.endDate, project.number, project.currency, project.budget, projectUsers);
            modelBinder.bind(model, content);

            const onSubmit = async (event:EventArgs):Promise<boolean> => {
                event.handled = true;
                
                if (!modelBinder.validate(model)) {
                    return false;
                }
                const datesValid = model.validateDates();
                if (!datesValid) {
                    modelBinder.setFieldError(model,'endDate',"error.date.start_and_end.required");
                    return false;
                }
                this.eventBus.publish('savingProject', Simplex.Utils.createEventArgs({state: true}, this));
                
                const saveResult = await this.request.put<APIResult<void>>(`/api/project/${id}`, model);

                this.eventBus.publish('savingProject', Simplex.Utils.createEventArgs({state: false}, this));
                
                if (!saveResult.isSuccess) {
                    notice.addMessage("Fout bij het opslaan van het project", 'warning');
                }  else {
                    notice.addMessage("Project opgeslagen", 'success');
                    this.app.navigateTo(`/project/${id}`);    
                }
                return false;
            };
            
            const onUserSelected =  async (event: Event): Promise<void> => {
                const customEvent = event as CustomEvent<TenantUser>;
                const tenantUser = customEvent.detail;
                const existing = model.users.findIndex(u => u.email.toLowerCase() === tenantUser.email.toLowerCase());
                if (existing !== -1) {
                    return;
                }
                const validateResult = await this.request.post<APIResult<boolean>>(`/api/project/${id}/users/validate`, new Simplex.Models.Project.NewUserValidateRequest(tenantUser.email));
                if (!validateResult.data.data) {
                    notice.addMessage(Messages(validateResult.data.messages[0].key), 'warning');
                    return;
                }

                model.users.push(new ProjectUser(tenantUser.id, tenantUser.email, tenantUser.firstName, tenantUser.lastName, tenantUser.projectManager, tenantUser.responsibleManager));
                if (userList) {
                    userList.setUsers(model.users);
                }

            };
            
            const onUserListChange = (event: Event): void => {
                const mutationEvent = event as CustomEvent<UserMutationEvent>;
                const userIndex = model.users.findIndex(u=>u.email === mutationEvent.detail.user.email);
                if (userIndex === -1) {
                    return;
                }
                switch (mutationEvent.detail.action) {
                    case "remove":
                        model.users.splice(userIndex,1);
                        break;
                    case "removeProjectManager":
                        model.users[userIndex].projectManager = false;
                        break;
                    case "addProjectManager":
                        model.users[userIndex].projectManager = true;
                        break;
                    case "removeResponsibleManager":
                        model.users[userIndex].responsibleManager = false;
                        break;
                    case "addResponsibleManager":
                        model.users[userIndex].responsibleManager = true;
                        break;
                        
                }
                if (userList) {
                    userList.setUsers(model.users);
                }
            }
            const onProjectTaskManagerRemoveHighLights = (event: EventArgs) => {
                event.handled = true;
                const highlightedElements = content.getTargetContainer()[0].querySelectorAll('.is--highlighted');
                highlightedElements.forEach(e=>e.classList.remove('is--highlighted'));
            }

            const onProjectTaskListItemClicked = async (event: EventArgs) => {
                event.handled = true;
                const targetContainer = content.getTargetContainer()[0];
                if(!content.getContext().Project.startDate) {
                    targetContainer.querySelector('input[name="startDate"]')?.classList.add('is--highlighted');   
                }
                if(!content.getContext().Project.endDate) {
                    targetContainer.querySelector('input[name="endDate"]')?.classList.add('is--highlighted');   
                }
                if(!content.getContext().Project.budget) {
                    targetContainer.querySelector('input[name="budget"]')?.classList.add('is--highlighted');   
                }
            }
            
            const onDrawContent = async (eventArgs: EventArgs): Promise<void> => {
                const element = eventArgs.data.container[0];

                const userFind = element.querySelector("ui-user-autocomplete") as UserAutocomplete;
                if (userFind) {
                    userFind.addEventListener("selected", onUserSelected);
                }
                userList = element.querySelector("ui-user-list") as UserList;
                if (userList) {
                    userList.setUsers(model.users);
                    userList.addEventListener("change", onUserListChange)
                }
                projectTasksManager.on('removeAllHighLights', onProjectTaskManagerRemoveHighLights);
                projectTasksManager.on('projectTaskListItemClicked', onProjectTaskListItemClicked);
                await projectTasksManager.showTasks(id, Simplex.WebComponents.ProjectTasksType.ProjectSettings);
            };
            
            content.on('submit', onSubmit);
            content.on(['draw','drawSection'], onDrawContent);
            
            content.setContext({
                ProjectId: id,
                Project: project
            } as PlanningContext);
            content.draw();
        }
        
        @Get("{id:guid}/planning/edit")
        public async PlanningEdit(@Path("id") id: string): Promise<void> {
            const content = this.app.getComponent('Content', 'Project/planningedit') as Content<PlanningEditContext>;
            content.setTargetContainerElement(this.layout.getContentRenderTarget());

            const project = await this.LoadProject(id);
            if (project === undefined) {
                throw "Project not found";
            }
            
            content.setContext({
                ProjectId: id,
                Project: project,
                Scopes: []
            } as PlanningEditContext);

            content.draw();
        }

        @Get("{id:guid}/snapshots")
        public async Snapshots(@Path("id") id: string): Promise<void> {
            const content = this.app.getComponent('Content', 'Project/snapshots') as Content<PlanningEditContext>;
            content.setTargetContainerElement(this.layout.getContentRenderTarget());

            const project = await this.LoadProject(id);
            if (project === undefined) {
                throw "Project not found";
            }

            content.setContext({
                ProjectId: id,
                Project: project
            } as PlanningEditContext);
            content.draw();
        }

        @Get("{id:guid}/costCategories")
        public async CostCategories(@Path("id") id: string): Promise<void> {
            const content = this.app.getComponent('Content', 'Project/costCategories') as Content<PlanningEditContext>;
            content.setTargetContainerElement(this.layout.getContentRenderTarget());

            const project = await this.LoadProject(id);
            if (project === undefined) {
                throw "Project not found";
            }

            content.setContext({
                ProjectId: id,
                Project: project
            } as PlanningEditContext);
            content.draw();
        }
        
        @Get("{projectId:guid}/snapshots/create")
        public async SnapshotCreate(@Path("projectId") projectId: string): Promise<void> {
            const content = this.app.getComponent('Content', 'Project/snapshotedit') as Content<SnapshotEditContext>;
            content.setTargetContainerElement(this.layout.getContentRenderTarget());

            const project = await this.LoadProject(projectId);
            if (project === undefined) {
                throw "Project not found";
            }
            
            content.setContext({
                ProjectId: projectId,
                Project: project,
                Snapshot: null
            } as SnapshotEditContext);

            content.draw();
        }
        
        @Get("{projectId:guid}/snapshots/{id:guid}/edit")
        public async SnapshotEdit(@Path("projectId") projectId: string, @Path("id") id: string): Promise<void> {
            const content = this.app.getComponent('Content', 'Project/snapshotedit') as Content<SnapshotEditContext>;
            content.setTargetContainerElement(this.layout.getContentRenderTarget());

            const project = await this.LoadProject(projectId);
            if (project === undefined) {
                throw "Project not found";
            }
            
            const snapshot = await this.LoadSnapshot(projectId, id);
            if (snapshot === undefined) {
                throw "Snapshot not found";
            }

            content.setContext({
                ProjectId: projectId,
                Project: project,
                Snapshot: snapshot
            } as SnapshotEditContext);

            content.draw();
        }
        
        @Get("{id:guid}/milestones")
        public Milestones(@Path("id") id: string): void {
        }

        private LoadSnapshot = async (projectId: string, id: string): Promise<Simplex.Models.Project.SnapshotSummary|undefined> => {

            const result = await this.request.get<APIResult<Simplex.Models.Project.SnapshotSummary>>(`/api/project/${projectId}/snapshots/${id}`);
            if (!result.isSuccess) {
                return undefined;
            }
            return result.data.data;
        }
        
        @Get("{id:guid}/members")
        public Members(@Path("id") id: string): void {
        }
        
        private LoadProject = async (projectId: string): Promise<Simplex.Models.Project.Project|undefined> => {
        
            const result = await this.request.get<APIResult<Simplex.Models.Project.Project>>(`/api/project/${projectId}`);
            if (!result.isSuccess) {
                return undefined;
            }
            return result.data.data;
        }
        private LoadProjectUsers = async (projectId: string): Promise<Simplex.Models.Project.ProjectUser[]|undefined> => {
        
            const result = await this.request.get<APIResult<Simplex.Models.Project.ProjectUser[]>>(`/api/project/${projectId}/users`);
            if (!result.isSuccess) {
                return undefined;
            }
            return result.data.data;
        }

        @Get("{id:guid}/reports")
        public async Report(@Path("id") id: string): Promise<void> {
            const content = this.app.getComponent('Content', 'Project/reports') as Content<PlanningEditContext>;
            content.setTargetContainerElement(this.layout.getContentRenderTarget());

            const project = await this.LoadProject(id);
            if (project === undefined) {
                throw "Project not found";
            }

            content.setContext({
                ProjectId: id,
                Project: project
            } as PlanningEditContext);
            content.draw();
        }

        @Get("{projectId:guid}/reports/create")
        public async ReportCreate(@Path("projectId") projectId: string): Promise<void> {
            const content = this.app.getComponent('Content', 'Project/reportcreate') as Content<ReportCreateContext>;
            content.setTargetContainerElement(this.layout.getContentRenderTarget());

            const project = await this.LoadProject(projectId);
            if (project === undefined) {
                throw "Project not found";
            }

            content.setContext({
                ProjectId: projectId,
                Project: project,
            } as ReportCreateContext);

            content.draw();
        }
        
        @Get("{projectId:guid}/finance")
        public async Finance(@Path("projectId") projectId: string): Promise<void> {
            const content = this.app.getComponent('Content', 'Project/finance') as Content<FinanceContext>;
            content.setTargetContainerElement(this.layout.getContentRenderTarget());

            const project = await this.LoadProject(projectId);
            if (project === undefined) {
                throw "Project not found";
            }

            content.setContext({
                ProjectId: projectId,
                Project: project
            } as FinanceContext);

            this.app.getEventBus().subscribe("newFinanceEntryCreated", () => {
                const list = content.getTargetElement().querySelector("ui-project-finance-list") as Simplex.WebComponents.Project.Report.FinanceList;
                if (list) {
                    list.setPageNumber(0);
                }
            });
            
            content.on(["draw","drawSection"], () => {
               
                content.getTargetElement().querySelector("ui-project-finance-import")?.addEventListener("imported", async (e: Event) => {
                    const list = content.getTargetElement().querySelector("ui-project-finance-list") as Simplex.WebComponents.Project.Report.FinanceList;
                    if (list) {
                        list.reload();
                    }
                    
                });
                
            });
            
            content.draw();
        }
    }
}