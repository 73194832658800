namespace Simplex.WebComponents.Project {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    
    @WebComponent("ui-view-scope-sortable")
    export class ViewScopeSortable extends ABWebComponent {

        private readonly contentTemplate;
        private readonly _multipleYears: boolean;
        private readonly _depth: number;
        private readonly _projectId: string;
        private readonly _projectCurrency: string;
        private _rendered: boolean = false;
        private readonly _scope: Simplex.Models.Project.Scope;
        private _childrenListSortable?: Simplex.WebComponents.ScopeListSortable;
        private _openState: boolean = false;
        
        get scopeId(): string {
            return this._scope.id ?? "";
        }
        get parentScopeId(): string {
            return this._scope.parentScopeId ?? "";
        }
        
        get scopeIndex(): number {
            return this._scope.index;
        }
        get snapshotChange(): string|null {
            return this._scope.snapshotChange;
        }
        
        public constructor(projectId: string, projectCurrency: string, scope:Simplex.Models.Project.Scope, depth: number, multipleYears:boolean) {
            super();
            this._scope = scope;
            this._depth = depth;
            this._projectId = projectId;
            this._projectCurrency = projectCurrency;
            this._multipleYears = multipleYears;
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/ScopeListSortable/ViewScopeSortable', 'ViewScopeSortable') as TemplateCallback;
        }

        private render = () => {
            this.setAttribute("depth",`${this._depth}`);
            this.innerHTML = this.contentTemplate({
                scope: this._scope,
                projectCurrency: this._projectCurrency,
                dateFormat: this._multipleYears ? "date" : "D MMM"
            });
            if(this._scope.snapshotChange !== 'Deleted') {
                this._childrenListSortable = new Simplex.WebComponents.ScopeListSortable(this._depth + 1, this._scope.children, this._projectId, this._projectCurrency, this._scope.id!, this._multipleYears);
                this.appendChild(this._childrenListSortable);
            }
            this._rendered = true;
        }
        
        public setState = (state:boolean): void => {
            this._openState = state;
            if(this._openState) {
                this.classList.add('is--open');
            } else {
                this.classList.remove('is--open');
            }
        }

        public getDepth() :number {
            return this._depth;
        }
        
        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if(!this._rendered) {
                this.render();
            }
        }
    }
}
