namespace Simplex.Interfaces {

    type ScopeProxyCallback = (event: string, ...args: any[]) => boolean;

    export class ScopeProxy {
        private readonly callback: ScopeProxyCallback;

        constructor(callback: ScopeProxyCallback) {
            this.callback = callback;
        }

        /**
         * Event call in HTML even:
         * <div onCLick="return $scope.event('name', ...params);" />
         * @param eventName
         * @param args
         */
        public event(eventName: string, ...args: any[]): boolean {
            return this.callback(eventName, args);
        }
    }

    export interface Scoped {
        readonly scopeProxy: ScopeProxy;
        createScopeProxy(): ScopeProxy;
    }
}