namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class Budget extends ABModel {

        public budgetCellType: BudgetCellType;
        public editable: boolean;

        public budgetBreakdown: boolean|null;

        public amount: number | null;
        public snapshotAmount: number| null;
        public budgetChanged: boolean;

        public amountSum: number | null;
        public snapshotAmountSum: number| null;

        public isUnspecified: boolean;
        public hasSpecifiedChildSum: boolean;
        public hasAmount: boolean = false;
    
        constructor(
            budgetCellType: BudgetCellType,
            editable: boolean = false,
            budgetBreakdown: boolean|null = null,
            amount: number | null = null,
            snapshotAmount: number | null = null,
            budgetChanged: boolean = false,

            amountSum: number | null = null,
            snapshotAmountSum: number | null = null,

            isUnspecified: boolean = false,
            hasSpecifiedChildSum: boolean = false) {
            super();
            this.budgetCellType = budgetCellType;
            this.editable = editable;

            this.budgetBreakdown = budgetBreakdown;
            
            this.amount = amount;
            this.snapshotAmount = snapshotAmount;
            this.budgetChanged = budgetChanged;

            this.amountSum = amountSum;
            this.snapshotAmountSum = snapshotAmountSum;
            
            this.isUnspecified = isUnspecified;
            this.hasSpecifiedChildSum = hasSpecifiedChildSum;
        }
    }

    export enum BudgetCellType {
        Budget = "Budget",
        Prognose = "Prognose",
        ActualCosts = "ActualCosts",
        Expense = "Expense",
        ExpectedCosts = "ExpectedCosts",
        Result = "Result"
    }
}