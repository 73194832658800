namespace Simplex.Models.Users {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class TenantUser extends ABModel {

        public id: string|null;
        public firstName: string|null;
        public lastName: string|null;
        public email: string;
        public projectManager: boolean;
        public responsibleManager: boolean;

        constructor(id: string|null = "", firstName: string|null = null, lastName: string|null = null, email: string = "", projectManager: boolean = false, responsibleManager: boolean = false) {
            super();
            this.id = id;
            this.firstName = firstName;
            this.lastName = lastName;
            this.email = email;
            this.projectManager = projectManager;
            this.responsibleManager = responsibleManager;
        }
    }
}