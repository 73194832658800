namespace Simplex.WebComponents.Project.Report.Models {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class ProjectReport extends ABModel {

        public id: string;
        public projectId: string;
        public name: string;
        public createdBy: string;
        public createdByName: string;
        public created: string|null;

        constructor(
            id: string,
            projectId: string,
            name: string,
            createdBy: string,
            createdByName: string,
            created: string|null
        ) {
            super();
            this.id = id;
            this.projectId = projectId;
            this.name = name;
            this.createdBy = createdBy;
            this.createdByName = createdByName;
            this.created = created;
        }
    }
}