namespace Simplex.WebComponents.Project {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import ModelBinder = Ambrero.AB.Components.ModelBinder;
    import ContentNotifier = Simplex.Components.ContentNotifier;
    import Inject = Simplex.Decorators.Inject;
    import ScopeReference = Simplex.Models.Project.ScopeReference;

    @WebComponent("ui-scope-reference-edit-row")
    export class ScopeReferenceEditRow extends ABWebComponent implements Node {
        private readonly _contentTemplate;
        private _rendered: boolean = false;
        private readonly _binder: ModelBinder;
        private readonly _item: ScopeReference;
        private readonly _index: number;
        private readonly _allScopes: Simplex.Models.Project.Scope[];
        private readonly _canDelete: boolean;
        @Inject("ContentNotifier")
        private readonly _contentNotifier!: ContentNotifier;

        public constructor(item: Simplex.Models.Project.ScopeReference, index: number, canDelete: boolean, allScopes: Simplex.Models.Project.Scope[]) {
            super();
            this._binder = this.app.getComponentType<ModelBinder>("ModelBinder")!;
            this._contentTemplate = this.app.getTemplate('WebComponents/Project/EditScopeForm', 'ScopeReferenceEditRow') as TemplateCallback;
            this._item = item;
            this._allScopes = allScopes;
            this._index = index;
            this._canDelete = canDelete;
            this._binder.bind(this._item, this._contentNotifier);
        }

        render() {
            this.innerHTML = this._contentTemplate({
                item: this._item,
                index: this._index,
                canDelete: this._canDelete,
                allScopes: this._allScopes
            });
            this._rendered = true;
            this._contentNotifier.notifyDraw(this);
        }

        onItemClick = (event:Event):void => {
            event.preventDefault();
            event.stopPropagation();
            const target = event.currentTarget as HTMLElement;
            const action = target.dataset['action']!;
            this.dispatchEvent(new CustomEvent(action, { detail:{ item: this._item, index: this._index }, bubbles: true }))
        }
        
        public valid = ():boolean => {
            return this._binder.validate(this._item);
        }
        
        connectedCallback() {
            if (!this.isConnected) {
                return;
            }

            if(!this._rendered) {
                this.render();

                this.querySelectorAll('ui-button').forEach(button => button.addEventListener("click", this.onItemClick));                
            }
        }
    }
}
