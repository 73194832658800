namespace Simplex.WebComponents.Project.DashboardProjectSnapshot {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import ConfirmDialog = Simplex.Components.ConfirmDialog;
    import APIResult = Simplex.Utils.APIResult;
    import UIHint = Simplex.WebComponents.LayoutComponents.UIHint;
    import ProjectTasksManager = Simplex.Components.ProjectTasksManager;
    import EventArgs = Ambrero.AB.Components.EventArgs;
    import ProjectTaskType = Simplex.Project.Models.ProjectTaskType;
    
    @WebComponent("ui-project-snapshots-list")
    export class SnapshotList extends ABWebComponent {
        private readonly _projectTasksManager : ProjectTasksManager;
        private readonly contentTemplate;
        private readonly _projectId: string;
        private _rendered: boolean = false;
        
        public constructor() {
            super();
            this._projectTasksManager = this.app.getComponent('ProjectTasksManager') as ProjectTasksManager;
            this._projectId = this.getAttribute("project-id") || "";
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/Snapshot/SnapshotList', 'SnapshotList') as TemplateCallback;
            this.addEventListener("action", this.onContextMenuClick);
        }
        
        private onDeleteConfirmed = async (snapshotId: string): Promise<void> => {
            const deleteResult = await this.request.delete<APIResult<void>>(`/api/project/${this._projectId}/snapshots/${snapshotId}`);
            if (deleteResult.isSuccess) {
                await this.render();
            } else {
                // notice!
                alert("Fout?!");
            }
        }
        
        private doSnapshotBaselineUpdate = async (snapshotId: string, setAsBaseline:boolean): Promise<void> => {
            const confirmDialog = this.app.getComponent('ConfirmDialog',
                Messages('project.snapshot.baseline_update.confirm.title'),
                Messages(setAsBaseline ? 'project.snapshot.baseline_update.set.confirm.text' : 'project.snapshot.baseline_update.unset.confirm.text'),
                Messages('project.snapshot.baseline_update.confirm.yes'),
                Messages('project.snapshot.baseline_update.confirm.no')) as ConfirmDialog;
            confirmDialog.show();
            confirmDialog.on('yes', () => this.doSnapshotBaselineUpdateConfirmed(snapshotId, setAsBaseline));
        }
        
        private doSnapshotBaselineUpdateConfirmed = async (snapshotId: string, setAsBaseline:boolean): Promise<void> => {
            const snapshotBaselineUpdate = new Simplex.WebComponents.Project.DashboardProjectSnapshot.Models.SnapshotBaselineUpdate(this._projectId, setAsBaseline);
            const updateResponse = await this.request.put<APIResult<void>>(`/api/project/${this._projectId}/snapshots/${snapshotId}/changeBaseline`, snapshotBaselineUpdate);
            if (updateResponse.isSuccess) {
                await this.render();
            } else {
                // notice!
                alert("Fout?!");
            }
        }

        private onContextMenuClick = async (event: Event): Promise<void> => {
            event.preventDefault();
            event.stopPropagation();
            
            const customEvent = event as CustomEvent;
            const target = customEvent.target as HTMLElement;
            if (!target) {
                return;
            }
            const snapshotId = target.getAttribute('snapshot-id');
            if (!snapshotId) {
                return;
            }
            switch (customEvent.detail) {
                case 'delete':
                    const confirmDialog = this.app.getComponent('ConfirmDialog',
                        Messages('project.snapshot.delete.confirm.title'),
                        Messages('project.snapshot.delete.confirm.text'),
                        Messages('project.snapshot.delete.confirm.yes'),
                        Messages('project.snapshot.delete.confirm.no'),
                        'warning') as ConfirmDialog;
                    confirmDialog.show();
                    confirmDialog.on('yes', () => this.onDeleteConfirmed(snapshotId));
                    break;
                case 'edit':
                    this.app.navigateTo(`/project/${this._projectId}/snapshots/${snapshotId}/edit`);
                    break;
                case 'setBaseline':
                    await this.doSnapshotBaselineUpdate(snapshotId, true);
                    break;
                case 'unsetBaseline':
                    await this.doSnapshotBaselineUpdate(snapshotId, false);
                    break;
            }
        }

        private showNoResultHint = ():void => {
            const noResultHint = this.querySelector("ui-hint[name='no_snapshot']") as UIHint;
            if(noResultHint) {
                noResultHint.style.display = '';
            }
        }

        private showNoBaselineHint = ():void => {
            const noBaselineHint = this.querySelector("ui-hint[name='no_baseline']") as UIHint;
            if(noBaselineHint) {
                noBaselineHint.style.display = '';
            }
        }
        
        private removeHighLights = () => {
            const highlightedElements = this.querySelectorAll('.is--highlighted');
            highlightedElements.forEach(e=>e.classList.remove('is--highlighted'));
        }
        private onProjectTaskManagerRemoveHighLights = (event: EventArgs) => {
            event.handled = true;
            this.removeHighLights();
        }

        private onProjectTaskListItemClicked = async (event: EventArgs) => {
            event.handled = true;
            const task = event.data as Simplex.Project.Models.ProjectTask;
            this.removeHighLights();
            
            if(task.type === ProjectTaskType.MissingSnapshot || task.type === ProjectTaskType.ProjectScopeAddedOrRemovedMakeSnapshot) {
                this.querySelector('.JsAddSnapshotButton')?.classList.add('is--highlighted');
            }
            else if(task.type === ProjectTaskType.MissingBaseline) {
                this.querySelector('.JsSnapshotContextMenu')?.classList.add('is--highlighted');
            }
        }
        
        private bindElementEventListeners(): void {
            this.addEventListener('noSnapshots', this.showNoResultHint);
            this.addEventListener('noBaseline', this.showNoBaselineHint);

            this._projectTasksManager.on('removeAllHighLights', this.onProjectTaskManagerRemoveHighLights);
            this._projectTasksManager.on('projectTaskListItemClicked', this.onProjectTaskListItemClicked);
        }
        
        private removeElementEventListeners(): void {
            this.removeEventListener('noSnapshots', this.showNoResultHint);
            this.removeEventListener('noBaseline', this.showNoBaselineHint);
            this._projectTasksManager.off('removeAllHighLights', this.onProjectTaskManagerRemoveHighLights);
            this._projectTasksManager.off('projectTaskListItemClicked', this.onProjectTaskListItemClicked);
        }

        public async render() {
            this.innerHTML = this.contentTemplate({projectId: this._projectId});
            
            await this._projectTasksManager.showTasks(this._projectId, Simplex.WebComponents.ProjectTasksType.Snapshots);
            this._rendered = true;
        }

        public async connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if (!this._rendered) {
                await this.render();
                this.bindElementEventListeners();
            }
        }

        disconnectedCallback() {
            this.removeElementEventListeners();
        }
    }
}
