namespace Simplex.WebComponents.Project {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;

    export interface ToggleEvent {
        id: string,
        open: boolean,
        renderReference: boolean
    }
    
    export interface EditEvent {
        id: string,
        editing: boolean
    }
    
    export interface AddEvent {
        id: string,
        adding: boolean
    }
    
    @WebComponent("ui-view-scope")
    export class ViewScope extends ABWebComponent {

        private readonly contentTemplate;
        private readonly _isOdd: boolean;
        private readonly _projectId: string;
        private readonly _projectCurrency: string;
        private readonly _multipleYears: boolean;
        private readonly _lastChild: boolean;
        private readonly _depth: number;
        private _openState: boolean;
        private _rendered: boolean = false;
        private _container?: HTMLElement;
        private _addScopeForm?: AddScope;
        private _editScopeForm?: EditScopeForm;
        private readonly _scope: Simplex.Models.Project.Scope;
        private readonly _allScopes: Simplex.Models.Project.Scope[];

        set edit(state:boolean) {
            if(state){
                this.classList.add("is--edit");
            } else {
                this.classList.remove("is--edit");
            }
        }
        get edit() {
            return this.classList.contains("is--edit");
        }
        
        set add(state:boolean) {
            if(state){
                this.classList.add("is--add");
            } else {
                this.classList.remove("is--add");
            }
        }
        get add() {
            return this.classList.contains("is--add");
        }

        get scopeId(): string {
            return this._scope.id ?? "";
        }
        get parentScopeId(): string {
            return this._scope.parentScopeId ?? "";
        }
        
        get scopeIndex(): number {
            return this._scope.index;
        }
        get openState(): boolean {
            return this._openState;
        }

        public constructor(scope:Simplex.Models.Project.Scope, isOdd: boolean, projectId: string, projectCurrency: string, allScopes: Simplex.Models.Project.Scope[], depth: number, multipleYears:boolean, lastChild: boolean) {
            super();
            this._scope = scope;
            this._allScopes = allScopes;
            this._isOdd = isOdd;
            this._depth = depth;
            this._openState = false;
            this._projectId = projectId;
            this._projectCurrency = projectCurrency;
            this._multipleYears = multipleYears;
            this._lastChild = lastChild;
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/ViewScope', 'ViewScope') as TemplateCallback;
        }

        private render = () => {
            this.setAttribute("depth",`${this._depth}`);
            if(this._isOdd) {
                this.classList.add('is--odd');
            } else {
                this.classList.add('is--even');
            }
            
            this.innerHTML = this.contentTemplate({
                open: this._openState,
                scope: this._scope,
                projectCurrency: this._projectCurrency,
                lastChild: this._lastChild,
                dateFormat: this._multipleYears ? "date" : "D MMM"
            });
            this._container = document.querySelector('ui-scopelist') as HTMLElement;
            const toggleButton = this.querySelector('.toggle');
            const editHandler = this.querySelector('.scope__details');
            const addButton = this.querySelector('.add');
            if(toggleButton) {
                toggleButton.addEventListener('click', this.onToggleClicked);
            }
            if(editHandler) {
                editHandler.addEventListener('click', this.onEdit);
            } 
            if(addButton) {
                addButton.addEventListener('click', this.onAdd);
            }
            
            this._scope.children.forEach((child: Simplex.Models.Project.Scope, index: number) => {
                if(this._scope.color) {
                    child.color = this._scope.color;
                }
                child.parentScopeBudget = this._scope.budget ?? this._scope.parentScopeBudget;
                child.parentScopeStartDate = this._scope.startDate ?? this._scope.parentScopeStartDate;
                child.parentScopeEndDate = this._scope.endDate ?? this._scope.parentScopeEndDate;
                let lastChild = false;
                if(index === this._scope.children.length -1 ) {
                    lastChild = true;
                }
                const viewScope = new Simplex.WebComponents.Project.ViewScope(child, this._isOdd, this._projectId, this._projectCurrency, this._allScopes,this._depth+1, this._multipleYears, lastChild);
                this._container!.appendChild(viewScope);
            });
            
            this._rendered = true;
        }
        public setState = (open:boolean): void => {
            this._openState = open;
            if(this._openState) {
                this.classList.add('is--open');
            } else {
                this.classList.remove('is--open');
            }
        }

        public getFormHeight = (): number => {
            if (this._editScopeForm) {
                return this._editScopeForm.getFormHeight();
            }
            return 0;
        }
        
        private onAddScopeExpand = ():void => {
            const name = this._addScopeForm!.getValue();
            if (this._editScopeForm === undefined) {
                this._editScopeForm = new Simplex.WebComponents.Project.EditScopeForm(this._projectId, this._allScopes);
                this._editScopeForm.addEventListener("cancel", () => this.resetEditState());
                this._editScopeForm.addEventListener("delete", () => this.resetEditState());
            } else {
                this._editScopeForm.reset();
            }
            this._editScopeForm.scope.name = name ?? "";
            this._editScopeForm.scope.parentScopeId = this._scope.id || Simplex.Utils.GUID_EMPTY;
            this._editScopeForm.scope.index = -1;
            
            if(this._container) {
                this.resetAddState();
                this.after(this._editScopeForm);
                this.edit = true;
                this.dispatchEvent(new CustomEvent<EditEvent>('stateEdit',{bubbles: true, detail: {id: this._scope.id!, editing: this.edit}}));
                let bounding = this._editScopeForm.getBoundingClientRect();
                if (bounding.bottom > window.innerHeight) {
                    this._editScopeForm.scrollIntoView(false);
                } else if(bounding.top < 0) {
                    this._editScopeForm.scrollIntoView();
                }
            }
        }

        public removeHighlight = () => {
            this.classList.remove('is--highlighted');
        }
        public showHighlightAndEnsureParentOpened = (allViewScopes: ViewScope[]) => {
            this.classList.add('is--highlighted');

            this.ensureParentOpened(allViewScopes);            
        }        
        
        public ensureParentOpened = (allViewScopes: Simplex.WebComponents.Project.ViewScope[]) => {
            if(!this._openState) {
                this._openState = true;
                this.setState(this._openState);
                this.dispatchEvent(new CustomEvent<ToggleEvent>('stateToggled',{detail: {id: this._scope.id!, open: this._openState, renderReference: false}}));
            }
            
            if(this._scope.parentScopeId === Simplex.Utils.GUID_EMPTY) {
                this.scrollIntoView({behavior: "smooth", block: "center"});
                return;
            }
            const parentScopeView = allViewScopes.filter((s) => {return s.scopeId === this._scope.parentScopeId})[0];
            parentScopeView.ensureParentOpened(allViewScopes)
        }
        
        public onToggleClicked = (event: Event) => {
            event.preventDefault();
            event.stopPropagation();
            this._openState = !this._openState;
            this.setState(this._openState);
            this.dispatchEvent(new CustomEvent<ToggleEvent>('stateToggled',{detail: {id: this._scope.id!, open: this._openState, renderReference: true}}));
        }
        
        public onToggle = (open?: boolean) => {
            this._openState = open ?? !this._openState;
            this.setState(this._openState);
            this.dispatchEvent(new CustomEvent<ToggleEvent>('stateToggled',{detail: {id: this._scope.id!, open: this._openState, renderReference: false}}));
        }

        private resetEditState = ()  => {
            if(!this.edit) {
                return;
            }
            this.edit = false;
            if(this._container && this._editScopeForm) {
                this._container.removeChild(this._editScopeForm);
                this.dispatchEvent(new CustomEvent<EditEvent>('stateEdit',{bubbles: true, detail: {id: this._scope.id!, editing: this.edit}}));
            }
        }

        private resetAddState = ()  => {
            if(!this.add) {
                return;
            }
            this.add = false;
            if(this._container && this._addScopeForm) {
                this._container.removeChild(this._addScopeForm);
                this.dispatchEvent(new CustomEvent<AddEvent>('stateAdd',{bubbles: true, detail: {id: this._scope.id!, adding: this.add}}));
            }
        }

        public onEdit = (evt: Event) => {
            evt.preventDefault();
            if(this.edit || this._scope.snapshotChange === 'Deleted') {
               return; 
            }
            if(this._container) {
                this.edit = true;
                this._editScopeForm = new Simplex.WebComponents.Project.EditScopeForm(this._projectId, this._allScopes, this._scope);
                this.after(this._editScopeForm);
                this._editScopeForm.addEventListener("cancel", () => this.resetEditState());
                this.dispatchEvent(new CustomEvent<EditEvent>('stateEdit',{bubbles: true, detail: {id: this._scope.id!, editing: this.edit}}));
                let bounding = this._editScopeForm.getBoundingClientRect();
                if (bounding.bottom > window.innerHeight) {
                    this._editScopeForm.scrollIntoView(false);
                } else if(bounding.top < 0) {
                    this._editScopeForm.scrollIntoView();
                }
            }
        }

        private getLastSubScope = (viewscope: ViewScope, depth: number): ViewScope => {
            const nextScope = viewscope.nextElementSibling as ViewScope;
            if(!nextScope) {
                return viewscope;
            }
            if(nextScope.tagName.toLowerCase() !== "ui-view-scope") {
                return viewscope;
            }
            let currentDepth = parseInt(nextScope.getAttribute('depth')!,10);
            if(currentDepth > depth) {
                return this.getLastSubScope(nextScope, depth);
            }
            return viewscope;
        }
        
        private onAdd = (evt: Event) => {
            evt.preventDefault();
            if(this.add) {
                return;
            }
            if(this._container) {
                this.resetEditState();
                this.add = true;
                this.dispatchEvent(new CustomEvent<AddEvent>('stateAdd',{bubbles: true, detail: {id: this._scope.id!, adding: this.add}}));
                this._addScopeForm = new Simplex.WebComponents.Project.AddScope();
                this._addScopeForm.projectId = this._projectId;
                this._addScopeForm.parentId = this._scope.id || Simplex.Utils.GUID_EMPTY;
//                const lastElement = this.getLastSubScope(this, this._depth) as ViewScope;
//                lastElement.after(this._addScopeForm);
                this.after(this._addScopeForm);
                this._addScopeForm.addEventListener("cancel", () => this.resetAddState());
                this._addScopeForm.addEventListener("expand", this.onAddScopeExpand);
                this._addScopeForm?.focus();
            }
        }
        
        public getDepth = (): number => {
            return this._depth;
        }
        
        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if(!this._rendered) {
                this.render();
            }
        }
        

    }
}
