namespace Simplex.WebComponents.Project.ProjectDashboardSetupHelper {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import APIResult = Simplex.Utils.APIResult;
    import Emitter = Ambrero.AB.Components.Emitter;
    import ProjectTask = Simplex.Project.Models.ProjectTask;
    import ConfirmDialog = Simplex.Components.ConfirmDialog;
    
    @WebComponent("ui-dashboard-project-setuphelper-widget")
    export class ProjectDashboardSetupHelperWidget extends ABWebComponent {
        private readonly contentTemplate;
        private readonly _projectId: string;
        private readonly _emitter: Emitter;
        private _taskIndex: number = 0;
        private _roadmapTasks?:ProjectTask[];
        private _currentTaskContainer:HTMLElement|null = null;
        private _notice: any;
        public constructor() {
            super();
            this._emitter = new Emitter();
            this._projectId = this.getAttribute("project-id") || "";
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/Dashboard/ProjectDashboardSetupHelper', 'ProjectDashboardSetupHelper') as TemplateCallback;
            this._notice = this.app.getComponent('AmbreroComponents.Notice');
        }

        private getRoadmapTasks = async (): Promise<ProjectTask[] | undefined> => {
            const result = await this.request.get<APIResult<ProjectTask[]>>(`/api/project/${this._projectId}/tasks/setup`);
            if (!result.isSuccess || !result.data.data || result.data.data.length === 0) {
                this.outerHTML = '';
                return undefined;
            }
            result.data.data.forEach(a=>a.actionUrl = Simplex.Project.Models.ProjectTask.getActionUrl(a.type, this._projectId));
            return result.data.data;
        }

        private fillCurrentTask = ():void => {
            if(!this._roadmapTasks || !this._currentTaskContainer) {
                return;
            }

            const indicators = this.querySelectorAll('.task-indicator');
            if(!indicators || indicators.length === 0) {
                return;
            }
            for (let i = 0; i < indicators.length; i++) {
                const indicator = indicators[i];
                if (i === this._taskIndex) {
                    indicator.classList.add('task__indicator__active');
                } else {
                    indicator.classList.remove('task__indicator__active');
                }
                const task = this._roadmapTasks[i];
                if(task) {
                    if (task.completedDate !== null) {
                        indicator.classList.add('task__indicator__completed');
                    } else {
                        indicator.classList.remove('task__indicator__completed');
                    }
                }
            }

            if(this._roadmapTasks.filter(t=>t.completedDate === null).length === 0) {
                const completeIndicator = this.querySelector('.task-indicator.completed') as HTMLElement;
                if(completeIndicator){
                    completeIndicator.style.display = '';
                }
            }
            
            this._currentTaskContainer.innerHTML = '';
            this._currentTaskContainer.appendChild(
                new ProjectDashboardSetupHelperCurrentTask(
                    this._taskIndex,
                    this._roadmapTasks.length,
                    this._taskIndex !== this._roadmapTasks.length ? this._roadmapTasks[this._taskIndex] : undefined,
                    this._taskIndex !== this._roadmapTasks.length && this._roadmapTasks.length - 1 > this._taskIndex ? this._roadmapTasks[this._taskIndex + 1] : undefined
                ));            
        }

        public async render() {
            this._roadmapTasks = await this.getRoadmapTasks();
            if(!this._roadmapTasks) {
                return;
            }
            this._taskIndex = this._roadmapTasks.findIndex(t=>t.completedDate === null);
            if(this._taskIndex === -1) {
                this._taskIndex = this._roadmapTasks.length;
            }
            this.innerHTML = this.contentTemplate({
                percentageCompleted : this._taskIndex === 0 ? 0 : Math.round( this._taskIndex / this._roadmapTasks.length * 100),
                totalTasks : this._roadmapTasks.length,
                taskIndex : this._taskIndex
            });
            this._currentTaskContainer = this.querySelector('.current-task-container');
            this.fillCurrentTask();
        }

        public async connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            await this.render();
            this.bindElementEventListeners();
        }
        
        private onIndexClicked = (event:Event):void => {
            event.preventDefault();
            event.stopPropagation();
            const indicator = event.target as HTMLElement;
            this._taskIndex = parseInt(indicator.dataset['taskIndex']!);
            this.fillCurrentTask();
        }

        private onCloseRoadmapConfirmed = async (): Promise<void> => {
            const deleteResult = await this.request.delete<APIResult<void>>(`/api/project/${this._projectId}/tasks/setup`);
            if (deleteResult.isSuccess) {
                this.dispatchEvent(new CustomEvent("roadmapClosed"));
                this.outerHTML = '';
            } else {
                this._notice.addMessage(Messages('project.dashboard.tasks.setuphelper.confirm.delete.error'), 'warning');
            }
        }
        
        private onCloseClicked = (event:Event):void => {
            event.preventDefault();
            event.stopPropagation();

            const confirmDialog = this.app.getComponent('ConfirmDialog',
                Messages('project.dashboard.tasks.setuphelper.confirm.delete.title'),
                Messages('project.dashboard.tasks.setuphelper.confirm.delete.text')) as ConfirmDialog;
            confirmDialog.show();
            confirmDialog.on('yes', this.onCloseRoadmapConfirmed);
        }

        private bindElementEventListeners(): void {
            const closeButton = this.querySelector('.close');
            if(closeButton) {
                closeButton.addEventListener('click', this.onCloseClicked);
            }
            this.addEventListener('completeClicked', this.onCloseClicked);

            const indicators = this.querySelectorAll('.task-indicator');
            if(!indicators || indicators.length === 0) {
                return;
            }
            for (const indicator of indicators) {
                indicator.addEventListener('click', this.onIndexClicked);
            }
        }
        
        disconnectedCallback() {
            this.removeElementEventListeners();
        }
        private removeElementEventListeners(): void {
            const closeButton = this.querySelector('.close');
            if(closeButton) {
                closeButton.removeEventListener('click', this.onCloseClicked);
            }
            const indicators = this.querySelectorAll('.task-indicator');
            if(!indicators || indicators.length === 0) {
                return;
            }
            for (const indicator of indicators) {
                indicator.removeEventListener('click', this.onIndexClicked);
            }
        }
    }
}
