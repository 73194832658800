namespace Simplex.Decorators {
    export function Authenticated(...roles: string[]): Function {
        return function (object: Function, methodName?: string) {
            if (methodName === undefined || methodName === null) {
                Simplex.Components.Router.addRouteControllerRestriction(object.prototype.constructor.name,roles);
            } else {
                Simplex.Components.Router.addRouteControllerRestriction(`${object.constructor.name}::${methodName}`,roles);
            }
        };
    }
}
