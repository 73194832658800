/// <reference path="../Declarations/Handlebars.d.ts" />

namespace Simplex.HandlebarHelper {
    export const scopeBudgetLookup: Handlebars.HelperDelegate = function(budgets:[], budgetId:string, block): any|null {
        // @ts-ignore
        const foundBudgets = budgets.find(o => o.costCategoryId === budgetId);
        if(foundBudgets) {
            // @ts-ignore
            return block.fn(budgets.find(o => o.costCategoryId === budgetId));
        } else {
            // @ts-ignore
            return block.inverse(budgets.find(o => o.costCategoryId === budgetId));
        }
    }
}

Handlebars.registerHelper("scopeBudgetLookup", Simplex.HandlebarHelper.scopeBudgetLookup);