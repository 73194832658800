/// <reference path="../../LayoutComponents/ListView/ListView.ts" />

namespace Simplex.WebComponents.Tenant {
    import WebComponent = Simplex.Decorators.WebComponent;
    import Tenant = Simplex.Models.Admin.Tenant;
    import ListView = Simplex.WebComponents.LayoutComponents.ListView;
    import ListViewOptions = Simplex.WebComponents.LayoutComponents.ListViewOptions;
    import ListViewEvents = Simplex.WebComponents.LayoutComponents.ListViewEvents;
    
    @WebComponent("ui-tenant-list")
    export class TenantListView extends ListView<Tenant> {
         
        public constructor() {
            super();
        }
        
        protected onCustomEvent = (eventName: ListViewEvents, data: any):void => {
            switch(eventName) {
                case "ItemSelected":
                    this.app.navigateTo(`/admin/tenant/${data.id}`);
                    break;
            }
        }

        protected getListViewSettings(): ListViewOptions {
            return new ListViewOptions('jsTableContainer', true, Messages('tenant.listView.noResults'), null, {
                    property: 'name',
                    direction: 'ASC'
                }, {
                    listview: 'overview__listview'
                }, {
                    path: 'WebComponents/Admin/TenantListView',
                    file: 'itemRow'
                }, {
                    path: 'WebComponents/Admin/TenantListView',
                    file: 'headerTemplate'
                }
            )
        }
    }
}
