namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class ProjectReport extends ABModel {

        public id: string;
        public fileName: string;
        public createdBy: string;
        public created: string;

        constructor(id: string = "", fileName: string = "", createdBy: string = "", created: string = "") {
            super();
            this.id = id;
            this.fileName = fileName;
            this.createdBy = createdBy;
            this.created = created;
        }

    }
}