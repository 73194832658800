namespace Simplex.Models.Authentication {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class NewUserRequest extends ABModel {

        public firstName: string;
        public lastName: string;
        public token: string;
        public language: string;
        public password: string;

        constructor(firstName: string = "", lastName: string = "", token: string = "", language: string = "", password: string = "") {
            super();
            this.firstName = firstName;
            this.lastName = lastName;
            this.token = token;
            this.language = language;
            this.password = password;
        }

        public validateField = (fieldName: string, element: HTMLElement, value: string): string | undefined => {
            if (fieldName === "password") {
                if (value.length < 8) {
                    return Messages("user.password.minimumLength", 8);
                }
                if (!/[0-9]/.test(value)) {
                    return Messages("user.password.requiresDigit");
                }
                if (!/[A-Z]/.test(value)) {
                    return Messages("user.password.requiresUppercase");
                }
                if (!/[a-z]/.test(value)) {
                    return Messages("user.password.requiresLowercase");
                }
                if (!/[^a-zA-Z0-9]+/.test(value)) {
                    return Messages("user.password.requiresNonAlphanumeric");
                }
            }
        };
    }
}