

namespace Simplex.Decorators {

    export var Singletons:Array<{order:number, name: string, args?: any}> = [];
    
    export function Singleton(order:number = 100, ...args: any[]): Function {
        return function (object: Function) {
            Singletons.push({order: order, name: fixMinimizedControllerName(object.prototype.constructor.name), args: args});
        };
    }

    function fixMinimizedControllerName(controllerName: string) {
        if (controllerName.indexOf('_') !== -1) {
            return controllerName.substring(0, controllerName.indexOf('_'));
        }
        return controllerName;
    }
}
