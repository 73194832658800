/// <reference path="../../../../../node_modules/highcharts/modules/series-label.d.ts" />

namespace Simplex.WebComponents {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import APIResult = Simplex.Utils.APIResult;
    import BudgetGraphData = Simplex.Models.Project.BudgetGraphData;
    import SystemInject = Simplex.Decorators.SystemInject

    @WebComponent("ui-budget-graph")
    export class BudgetGraph extends ABWebComponent {
        private readonly _projectId: string;

        @SystemInject("FormattingHelper")
        private readonly formattingHelper!: Simplex.Components.FormattingHelper;
        private readonly contentTemplate;
        private readonly noContentTemplate;
        private readonly legendTemplate;
        private _rendered: boolean = false;
        private _loaded: boolean = false;
        private _filter?: Simplex.WebComponents.Project.Budget.Models.FilterModel;
        private _availableScrollSpace?: number;
        private _scrollElement?: HTMLElement;
        private _scrollLeftButton?: HTMLElement;
        private _scrollRightButton?: HTMLElement;
        private _legendElement?: HTMLElement;
        
        public constructor() {
            super();
            this._projectId = this.getAttribute("project-id") || "";
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/Budget/BudgetGraph', 'BudgetGraph') as TemplateCallback;
            this.noContentTemplate = this.app.getTemplate('WebComponents/Project/Budget/BudgetGraph', 'NoData') as TemplateCallback;
            this.legendTemplate = this.app.getTemplate('WebComponents/Project/Budget/BudgetGraph', 'Legend') as TemplateCallback;
        }

        private filterChanged(filter: Simplex.WebComponents.Project.Budget.Models.FilterModel): boolean {
            return filter.periodType !== this._filter?.periodType ||
                filter.scopeId !== this._filter?.scopeId ||
                filter.costCategoryId !== this._filter?.costCategoryId ||
                filter.compareWithSnapshotId !== this._filter?.compareWithSnapshotId;
        }
        
        public clearData(){
            this._loaded = false;
        }
        
        public loadData = async (filter: Simplex.WebComponents.Project.Budget.Models.FilterModel): Promise<void> => {
            if (!this._loaded || this.filterChanged(filter)) {
                this.classList.add('loading');
                this._filter = JSON.parse(JSON.stringify(filter));
                const result = await this.request.get<APIResult<BudgetGraphData>>(`/api/project/${this._projectId}/Statistics/CostCategoriesForPeriod?${(filter?.costCategoryId !== null ? `costCategoryId=${filter?.costCategoryId}` : "")}&${(filter?.scopeId !== null ? `scopeId=${filter?.scopeId}` : "")}&${(filter?.compareWithSnapshotId !== null ? `compareWithSnapshotId=${filter.compareWithSnapshotId}` : "")}&${(filter?.periodType !== null ? `periodType=${filter.periodType}` : "")}`);

                if (result.isSuccess && result.data.data && result.data.data.mainCategories.length > 0) {
                    this._scrollLeftButton = document.querySelector('.scrollLeft') as HTMLElement;
                    if(this._scrollLeftButton) {
                        this._scrollLeftButton.classList.remove('is--hidden');
                    }
                    this._scrollRightButton = document.querySelector('.scrollRight') as HTMLElement;
                    if(this._scrollRightButton) {
                        this._scrollRightButton.classList.remove('is--hidden');
                    }
                    this._legendElement = this.querySelector('.graphlegend') as HTMLElement;
                    if(this._legendElement) {
                        this._legendElement.classList.remove('is--hidden');
                    }
                    this.renderGraph(result.data.data);
                    this._scrollElement = this.querySelector('.highcharts-scrolling') as HTMLElement;
                } else {
                    this.renderNoContent();
                }
                this.classList.remove('loading');
                this._loaded = true;
            }
        }

        private scrollGraphLeft = (): void => {
            this._scrollElement!.scrollLeft -= this._availableScrollSpace! + 80; // for scale padding
            if(this._scrollElement!.scrollLeft === 0) {
                this._scrollLeftButton?.classList.add('is--disabled');
            }
            this._scrollRightButton?.classList.remove('is--disabled');
        };
        
        private scrollGraphRight = (): void => {
            if(!this._scrollElement) { 
                return;
            }
            this._scrollElement.scrollLeft += this._availableScrollSpace! - 80;
            const canStillScroll = (this._scrollElement.scrollLeft + this._scrollElement.getBoundingClientRect().width) < this._scrollElement.scrollWidth;
            if(!canStillScroll) {
                this._scrollRightButton?.classList.add('is--disabled');
            }
            this._scrollLeftButton?.classList.remove('is--disabled');
        };

        private renderNoContent = (): void => {
            const graphContainer = this.querySelector('.graph-container') as HTMLElement;
            if (!graphContainer) {
                return;
            }
            graphContainer.innerHTML = this.noContentTemplate();
            this._scrollRightButton?.classList.add('is--hidden');
            this._scrollLeftButton?.classList.add('is--hidden');
            this._legendElement?.classList.add('is--hidden');
            
        }
        private getCategoryName = (startDate: string): string => {
            const momentDate = moment(startDate);
            switch (this._filter?.periodType!) {
                case Simplex.WebComponents.Project.Budget.Models.BudgetPeriodType.Year:
                    return momentDate.format('YYYY');
                case Simplex.WebComponents.Project.Budget.Models.BudgetPeriodType.Quarter:
                    return "q" + momentDate.quarter() + " '" + momentDate.format('YY');
                case Simplex.WebComponents.Project.Budget.Models.BudgetPeriodType.Month:
                    return momentDate.format("MMM [']YY");
                case Simplex.WebComponents.Project.Budget.Models.BudgetPeriodType.Week:
                    return momentDate.isoWeek() + " '" + momentDate.format('YY');
                case Simplex.WebComponents.Project.Budget.Models.BudgetPeriodType.Day:
                    return momentDate.format("D MMM [']YY");
            }
        }
        private getBarAmount = (): number => {
            switch (this._filter?.periodType!) {
                case Simplex.WebComponents.Project.Budget.Models.BudgetPeriodType.Year:
                    return 6;
                case Simplex.WebComponents.Project.Budget.Models.BudgetPeriodType.Quarter:
                    return 12;
                case Simplex.WebComponents.Project.Budget.Models.BudgetPeriodType.Month:
                    return 24;
                case Simplex.WebComponents.Project.Budget.Models.BudgetPeriodType.Week:
                    return 30;
                case Simplex.WebComponents.Project.Budget.Models.BudgetPeriodType.Day:
                    return 35;
            }
        }

        private renderGraph(data: BudgetGraphData) {
            const graphContainer = this.querySelector('.graph-container') as HTMLElement;
            if (!graphContainer) {
                return;
            }
            const currencySymbol = this.formattingHelper.convertCurrency(data.projectCurrency);
            let costCategorySeries: any[] = [];
            let series: any[] = [];
            let categories: any[] = [];
            let cumulative: any =
                {
                    name: Messages('project.budget.graph.cumulative'),
                    type: 'line',
                    marker: {
                        enabled: false
                    },
                    data: []
                };
            series.push(cumulative);
            for (let i = 0; i < data.mainCategories.length; i++) {
                const category = data.mainCategories[i];
                costCategorySeries.push({
                    type: 'column',
                    name: category.name,
                    stack: 1,
                    yAxis: 1,
                    data: []
                });
            }
            let cumulativeSnapshot: any = null
            if (this._filter?.compareWithSnapshotId) {
                cumulativeSnapshot = {
                    name: Messages('project.budget.graph.cumulative_snapshot'),
                    type: 'line',                    
                    marker: {
                        enabled: false
                    },
                    data: []
                };
                series.push(cumulativeSnapshot);
            }
            data.periods.forEach(d => {
                    categories.push(this.getCategoryName(d.period.startDate))

                    for (let i = 0; i < costCategorySeries.length; i++) {
                        const costCategory = data.mainCategories[i];
                        const costCategoryData = d.data.filter(d => d.costCategoryId == costCategory.id);
                       costCategorySeries[i].data.push(
                            {
                                y: (costCategoryData && costCategoryData.length > 0) ? costCategoryData[0].amount : 0,
                                snapshot: (costCategoryData && costCategoryData.length > 0) ? costCategoryData[0].snapshotAmount : 0,
                                scopeIds: (costCategoryData && costCategoryData.length > 0) ? costCategoryData[0].scopeIds : []
                            });
                    }

                    cumulative.data.push(d.totalAmountCumulative);
                    if (cumulativeSnapshot) {
                        cumulativeSnapshot.data.push(d.totalAmountSnapshotCumulative);
                    }
                }
            );
            
            let scrollableArea = {};
            let scrollFactor = data.periods.length/this.getBarAmount();
            if(scrollFactor < 1) {
                scrollFactor = 1;
            }
            const budgetTitle = document.querySelector('.budget__title') as HTMLElement;
            if(budgetTitle) {
                this._availableScrollSpace = budgetTitle.getBoundingClientRect().width - 160;
                scrollableArea = {
                    scrollablePlotArea: {
                        minWidth: this._availableScrollSpace * scrollFactor,
                        scrollPositionX: 0,
                        opacity: 1
                    }
                };
            }
            
            series.push(...costCategorySeries);

            Highcharts.chart(graphContainer, {
                chart: scrollableArea,
                title: {
                    text: ''
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    formatter: function(tooltip) {
                        const currentTooltip = this as TooltipFormatterContextObject;
                        let text = currentTooltip.series.name;
                        if(!currentTooltip.point.scopeIds) {
                            return tooltip.defaultFormatter.call(this, tooltip);
                        }
                        if(currentTooltip.point.scopeIds.length > 0) {
                            text += ' ' + Messages('project.budget.graph.number_of_scopes', currentTooltip.point.scopeIds.length);
                        }
                        text+='</br>';
                        if(currentTooltip.point.snapshot) {
                            text += `<span style="text-decoration: line-through">${currencySymbol} ${currentTooltip.point.snapshot}</span></br>`;
                        }
                        text += `<b>${currencySymbol} ${Simplex.HandlebarHelper.formatNumber(currentTooltip.y)}</b>`
                        return text;
                    }
                },
                plotOptions: {
                    series: {
                        //@ts-ignore
                        pointPadding: -.25
                    },
                    column: {
                        stacking: 'normal'
                    }
                },
                yAxis: [{
                    opposite: true,
                    visible: true,
                    tickPosition: "inside",
                    offset: 0,
                    labels: {
                        formatter: function() { return currencySymbol + Simplex.HandlebarHelper.formatNumber(this.value); },
                    },
                    title: {
                        text: Messages('project.budget.graph.cumulative.y.title'),
                    }
                },
                {
                    visible: true,
                    tickPosition: "inside",
                    offset: 0,
                    labels: {
                        formatter: function() { return currencySymbol + Simplex.HandlebarHelper.formatNumber(this.value); },
                    },
                    title: {
                        text: Messages('project.budget.graph.cost_categories.y.title'),
                    }
                }],
                xAxis: {
                    alternateGridColor: '#f9faf9',
                    categories: categories
                },
                series: series
            }, this.graphRendered);
        }
        
        private graphRendered = (chart: Highcharts.Chart) => {
            //@ts-ignore
            if(chart.options.chart.scrollablePlotArea.minWidth > chart.chartWidth) {
                if(this._scrollLeftButton) {
                    this._scrollLeftButton.addEventListener('click',this.scrollGraphLeft);
                }
                if(this._scrollRightButton) {
                    this._scrollRightButton.classList.remove('is--disabled');
                    this._scrollRightButton.addEventListener('click', this.scrollGraphRight);
                }
            }
            if(this._legendElement && chart.series.length > 0) {
                this._legendElement.innerHTML = this.legendTemplate({data: chart.series});
            }
            const toggle = this.querySelector('.toggleLegend') as HTMLElement;
            if(toggle) {
                toggle.addEventListener('click', this.onToggleLegend);
            }
        };
        
        private onToggleLegend = (evt: Event): void => {
            evt.stopPropagation();
            if(this._legendElement) {
                this._legendElement.classList.toggle('is--active');
            }
        };

        public show() {
            this.classList.remove('hidden');
        }

        public hide() {
            this.classList.add('hidden');
        }

        async render() {
            this.innerHTML = this.contentTemplate();
            this._rendered = true;
        }

        async connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if (!this._rendered) {
                await this.render();
            }
        }
    }

    export interface TooltipFormatterContextObject {
        /**
         * The point's current color.
         */
        color?: string;
        /**
         * The point's current color index, used in styled mode instead of `color`.
         * The color index is inserted in class names used for styling.
         */
        colorIndex: number;
        /**
         * The name of the related point.
         */
        key?: string;
        /**
         * The percentage for related points in a stacked series or pies.
         */
        percentage: number;
        /**
         * The related point. The point name, if defined, is available through
         * `this.point.name`.
         */
        point: any;
        /**
         * The related series. The series name is available through
         * `this.series.name`.
         */
        series: any;
        /**
         * The total of values in either a stack for stacked series, or a pie in a
         * pie series.
         */
        total?: number;
        /**
         * For categorized axes this property holds the category name for the point.
         * For other axes it holds the X value.
         */
        x?: (number|string);
        /**
         * The y value of the point.
         */
        y?: (number|null);
    }
}