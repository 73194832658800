namespace Simplex.WebComponents.Project {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import ProjectUser = Simplex.Models.Project.ProjectUser;
    import ContextMenu = Simplex.WebComponents.LayoutComponents.ContextMenu;
    import Auth = Simplex.Components.Auth;
    
    export interface UserMutationEvent {
        user: UserListUser,
        action: string
    }
    
    @WebComponent("ui-user-list")
    export class UserList extends ABWebComponent {
        private readonly userTemplate;
        private readonly auth: Auth;
        
        // private userListElement: HTMLUListElement|null = null;
        private readonly users: UserListUser[];

        public constructor() {
            super();
            this.auth = this.app.getSystemComponent("Auth") as Auth;
            this.userTemplate = this.app.getTemplate('WebComponents/Project/UserList', 'User') as TemplateCallback;
            this.users = [];
        }

        render() {
            this.updateList();
        }

        public setUsers = (users:ProjectUser[]):void => {
            const currentUserId = this.auth.userId();
            this.users.length = 0;

            for (let i=0;i<users.length;i++) {
                const user = users[i];
                this.users.push(new UserListUser(user.id, user.firstName, user.lastName, user.email, user.projectManager, user.responsibleManager, user.id === currentUserId));
            }
            this.updateList();
        }
        
        private updateList = ():void => {
            if (!this.isConnected) {
                return;
            }
            let outputHtml = "";
            for (let i=0;i<this.users.length;i++) {
                outputHtml += this.userTemplate({
                    user : this.users[i],
                    index: i
                });
            }
            this.innerHTML = outputHtml;
            const links = [...this.querySelectorAll("ui-context-menu")] as ContextMenu[];
            for (let i=0;i<links.length;i++) {
                const link = links[i];
                const index = link.dataset['index'];
                if (index !== undefined) {
                    link.addEventListener("action", this.onUserAction.bind(this, this.users[parseInt(index)]));
                }
            }
            
        }

        onUserAction = (user: UserListUser, event:Event):void => {
            event.preventDefault();
            event.stopPropagation();
            const customEvent = event as CustomEvent;
            const action = customEvent.detail;
            if(action === "addResponsibleManager"){
                for (let userListUser of this.users) {
                    if(userListUser.responsibleManager){
                        userListUser.responsibleManager = false;
                        this.dispatchEvent(new CustomEvent<UserMutationEvent>("change", { detail:{ user: userListUser, action: "removeResponsibleManager" } }))
                    }
                }
            }
            this.dispatchEvent(new CustomEvent<UserMutationEvent>("change", { detail:{ user: user, action: action } }))
        };
        
        connectedCallback() {
            if (!this.isConnected) {
                return;
            }

            this.render();
        }

    }
}
