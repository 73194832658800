namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class ActualCostsGraphData extends ABModel {

        public projectId: string;
        public periods: ActualCostsGraphDataPeriod[] = [];
        
        constructor(
            projectId: string,
            periods: ActualCostsGraphDataPeriod[]) {
                super();
                this.projectId = projectId;
                this.periods = periods;
        }
    }
}