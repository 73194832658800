/// <reference path="../Declarations/Handlebars.d.ts" />

namespace Simplex.HandlebarHelper {

	export const math: Handlebars.HelperDelegate = function (lvalue, operator, rvalue): number  {
		const lvalueAsFloat = parseFloat(lvalue);
		const rvalueAsFloat = parseFloat(rvalue);

		switch (operator) {
			case "+":
				return lvalueAsFloat + rvalueAsFloat;
			case "-":
				return lvalueAsFloat - rvalueAsFloat;
			case "*":
				return lvalueAsFloat * rvalueAsFloat;
			case "/":
				return lvalueAsFloat / rvalueAsFloat;
			case "%":
				return lvalueAsFloat % rvalueAsFloat;
		}

		throw new Error(`Unsupported operator ${operator} in math call`);
	}
}

Handlebars.registerHelper("math", Simplex.HandlebarHelper.math);
