namespace Simplex.WebComponents.LayoutComponents {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import InitializedComponentEvent = Simplex.Components.InitializedComponentEvent;
    import ModelBinder = Ambrero.AB.Components.ModelBinder;
    import EventArgs = Ambrero.AB.Components.EventArgs;
    import ContentNotifier = Simplex.Components.ContentNotifier;
    import Inject = Simplex.Decorators.Inject;
    import IModel = Ambrero.AB.Models.IModel;
    
    export interface IListViewFilter {
        setListView(listView: IListView): void;
    }
    
    @WebComponent("ui-listview-filter")
    export class ListViewFilter extends ABWebComponent {
        private readonly contentTemplate;
        private readonly listIdentifier?;
        private readonly _binder?: ModelBinder;
        @Inject("ContentNotifier")
        private readonly _contentNotifier!: ContentNotifier;
        private listView: IListView | null = null;
        private _filterModel: IModel | null = null;
        private rendered: boolean = false;

        public constructor() {
            super();
            const templatePath = this.getAttribute("template-path");
            const templateName = this.getAttribute("template-name");
            if(!templatePath || !templateName) {
                return;
            }
            this.contentTemplate = this.app.getTemplate(templatePath, templateName) as TemplateCallback;
            this.listIdentifier = this.getAttribute("list");
            this._binder = this.app.getComponentType<ModelBinder>("ModelBinder")!;
        }

        public setListView(listView: IListView): void {
            if (this.listView !== listView) {
                this.listView = listView;
                this.listView.setFilter(this);
                this.render();
            }
        }

        protected getIdentifier(): string | null {
            return this.listIdentifier ?? null;
        }

        private render(): void {
            if (this.listView && !this.rendered) {
                this._filterModel = this.listView.getFilter();
                this.innerHTML = this.contentTemplate!({
                    Model: this._filterModel,
                });
                this.bindElementEventListeners();
                this.rendered = true;
                
                this._contentNotifier.notifyDraw(this);
            }
        }

        private onListViewInitialized = (event: InitializedComponentEvent) => {
            if (event.identifier === this.listIdentifier) {
                this.setListView(event.component as IListView);
                this.render();
            }
        }

        private readonly triggerInitializedEvent = (): void => {
            this.app.getEventBus().emit(`ListViewFilter.Initialized`, {
                identifier: this.getIdentifier(),
                component: this
            } as InitializedComponentEvent);
        }
        
        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            this.bindApplicationEventListeners();
            this.triggerInitializedEvent();
        }

        disconnectedCallback() {
            this.removeApplicationEventListeners();
        }

        private propertyChanged = async (eventArgs: EventArgs): Promise<void> => {
            switch (eventArgs.data.field) {
                case "deactivated":
                    await this.listView?.setPageNumber(0);
                    break;
            }
        }
        
        private bindElementEventListeners = (): void => {
            if (this._filterModel) {
                this._binder?.bind(this._filterModel, this._contentNotifier)
                    .on("PropertyChanged", this.propertyChanged);
            }
        }

        private bindApplicationEventListeners(): void {
            this.app.getEventBus().addEventListener('ListView.Initialized', this.onListViewInitialized);
        }

        private removeApplicationEventListeners(): void {
            this.app.getEventBus().removeEventListener('ListView.Initialized', this.onListViewInitialized);
        }
    }
}
