namespace Simplex.Models {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class PagedResult<T> extends ABModel {

        public page: number = 0;
        public size: number = 0;
        public itemCount: number = 0;
        public totalItemCount: number = 0;
        public pageCount: number = 0;
        public items: T[] = [];

        constructor() {
            super();
        }

    }
}