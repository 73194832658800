namespace Simplex.WebComponents {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import SnapshotSummary = Simplex.Models.Project.SnapshotSummary;
    import Scope = Simplex.Models.Project.Scope;
    import CostCategorySummary = Simplex.Models.Project.CostCategorySummary;

    @WebComponent("ui-budget-filter-tags")
    export class FilterTags extends ABWebComponent {
        private readonly contentTemplate;
        private _rendered: boolean = false;
        private _tags: Map<string, FilterTag>;
        private snapshots: SnapshotSummary[] = [];
        private scopes: Scope[] = [];
        private mainCostCategories: CostCategorySummary[] = [];
        
        public constructor() {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/Budget/BudgetFilter', 'FilterTag') as TemplateCallback;
            this._tags = new Map<string, FilterTag>();
        }

        render() {
            this.innerHTML = '';
            this._rendered = true;
            
        }
        
        public setSnapshots = (snapshots: SnapshotSummary[]) => {
            this.snapshots = snapshots;
        }
        
        public setScopes = (scopes: Scope[]) => {
            this.scopes = scopes;
        }
        
        public setMainCostCategories = (mainCostCategories: CostCategorySummary[]) => {
            this.mainCostCategories = mainCostCategories;
        }
        
        private createTag = (key: string, label: string) => {
            const tag = new FilterTag(key, label);
            tag.addEventListener('removeFilterOption', this.removeTag);
            this.appendChild(tag);
            this._tags.set(key,tag);
        }
        
        public addTag = (tag: string, value: string) => {
            switch(tag) {
                case 'compareWithSnapshotId':
                    if(value) {
                        // lookup snapshot name
                        const selectedSnapshot = this.snapshots.filter(s=>s.id === value);
                        if(selectedSnapshot.length === 0) {
                            break;
                        }
                        const label = Messages('project.budget.filter.compareWith', selectedSnapshot[0].name)
                        this.createTag(tag, label);
                    }
                    break;
                case 'periodType':
                    if(value !== Simplex.WebComponents.Project.Budget.Models.BudgetPeriodType.Month) {
                        // lookup snapshot name
                        const label = Messages(`project.budget.periods.${value}`);
                        this.createTag(tag, label);
                    }
                    break;
                case 'scopeId':
                    if(value) {
                        // lookup scope name
                        const selectedScope = this.scopes.filter(s=>s.id === value);
                        if(selectedScope.length === 0) {
                            break;
                        }
                        const label = Messages('project.budget.filter.scope', selectedScope[0].name)
                        this.createTag(tag, label);
                    }
                    break;
                case 'costCategoryId':
                    if(value) {
                        // lookup cost category name
                        const selectCostCategory = this.mainCostCategories.filter(s=>s.id === value);
                        if(selectCostCategory.length === 0) {
                            break;
                        }
                        const label = Messages('project.budget.filter.costCategory', selectCostCategory[0].name)
                        this.createTag(tag, label);
                    }
                    break;
                case 'scopeLevel':
                    if(parseInt(value) > 1) {
                        const label = Messages('project.budget.filter.scopeLevel', value)
                        this.createTag(tag, label);
                    }
                    break;
                case 'costCategoryLevel':
                    if(parseInt(value) > 0) {
                        const label = Messages('project.budget.filter.costCategoryLevel', value)
                        this.createTag(tag, label);
                    }
                    break;
                case 'viewType':
                case 'projectId':
                case 'verticalCollapsed':
                default:
                    return;
            }
        }
        
        public checkTag = (tag: string, value: string) => {
            const currentTag = this._tags.get(tag) as FilterTag;
            if(!currentTag) {
                this.addTag(tag, value);
                return;
            }
            switch(tag) {
                case 'scopeId':
                    if(value == "") {
                        this.removeTag(new CustomEvent('removeFilterOption', {detail: {key: tag}}))
                    } else {
                        // lookup scope name
                        const selectedScope = this.scopes.filter(s=>s.id === value);
                        if(selectedScope.length === 0) {
                            break;
                        }
                        currentTag.updateLabel(Messages('project.budget.filter.scope', selectedScope[0].name));
                    }
                    break;
                case 'costCategoryId':
                    if(value == "") {
                        this.removeTag(new CustomEvent('removeFilterOption', {detail: {key: tag}}))
                    } else {
                        // lookup cost category name
                        const selectCostCategory = this.mainCostCategories.filter(s=>s.id === value);
                        if(selectCostCategory.length === 0) {
                            break;
                        }
                        currentTag.updateLabel(Messages('project.budget.filter.costCategory', selectCostCategory[0].name));
                    }
                    break;
                case 'compareWithSnapshotId':
                    if(value == "") {
                        this.removeTag(new CustomEvent('removeFilterOption', {detail: {key: tag}}))
                    } else {
                        const selectedSnapshot = this.snapshots.filter(s=>s.id === value);
                        if(selectedSnapshot.length === 0) {
                            break;
                        }
                        currentTag.updateLabel(Messages('project.budget.filter.compareWith', selectedSnapshot[0].name));
                    }
                    break;
                case 'periodType':
                    if(value !== Simplex.WebComponents.Project.Budget.Models.BudgetPeriodType.Month) {
                        this.removeTag(new CustomEvent('removeFilterOption', {detail: {key: tag}}))
                    } else {
                        currentTag.updateLabel(Messages(`project.budget.periods.${value}`));
                    }
                    break;
                case 'scopeLevel':
                    if(parseInt(value) <= 1) {
                        this.removeTag(new CustomEvent('removeFilterOption', {detail: {key: tag}}))
                    } else {
                        currentTag.updateLabel(Messages('project.budget.filter.scopeLevel', value));
                    }
                    break;
                case 'costCategoryLevel':
                    if(parseInt(value) <= 0) {
                        this.removeTag(new CustomEvent('removeFilterOption', {detail: {key: tag}}))
                    } else {
                        currentTag.updateLabel(Messages('project.budget.filter.costCategoryLevel', value));
                    }
                    break;
            }
        }
        
        public removeTag = (event: Event) => {
            const customEvent = event as CustomEvent;
            const tag = this._tags.get(customEvent.detail.key) as FilterTag;
            tag.onRemove();
            this.dispatchEvent(new CustomEvent('filterChanged', { detail:{ name: 'tagRemoved', value: customEvent.detail.key }, bubbles: true }));
            this._tags.delete(customEvent.detail.key);
        };

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if(!this._rendered) {
                this.render();
            }
        }

    }
}