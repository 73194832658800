/// <reference path="../Declarations/Handlebars.d.ts" />

namespace Simplex.HandlebarHelper {

    export const times: Handlebars.HelperDelegate = function(n, block): string  {
        let accum = '';
        for(let i = 0; i < n; ++i) {
            accum += block.fn(i);
        }
        return accum;
    }
}

Handlebars.registerHelper("times", Simplex.HandlebarHelper.times);