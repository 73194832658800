namespace Simplex.WebComponents.Project.Report {
    import WebComponent = Simplex.Decorators.WebComponent;
    import ProjectReport = Simplex.Models.Project.ProjectReport;
    import ListView = Simplex.WebComponents.LayoutComponents.ListView;
    import ListViewOptions = Simplex.WebComponents.LayoutComponents.ListViewOptions;
    import ConfirmDialog = Simplex.Components.ConfirmDialog;
    import APIResult = Simplex.Utils.APIResult;
    
    @WebComponent("ui-project-reports-list")
    export class ReportList extends ListView<ProjectReport> {
        public constructor() {
            super();
            this.addEventListener("action", this.onContextMenuClick);
        }

        onProjectReportConfirmed = async (projectId:string, reportId:string):Promise<void> => {
            const deleteResult = await this.request.delete<APIResult<void>>(`/api/project/${projectId}/reports/${reportId}`);
            if (deleteResult.isSuccess) {
                await this.setPageNumber(0);
            }
        }
        
        private onContextMenuClick = async (event: Event): Promise<void> => {
            event.stopPropagation();
            const customEvent = event as CustomEvent;
            const target = customEvent.target as HTMLElement;
            if (!target) {
                return;
            }
            const projectId = target.getAttribute('project-id');
            if (!projectId) {
                return;
            }
            const reportId = target.getAttribute('report-id');
            if (!reportId) {
                return;
            }
            switch(customEvent.detail) {
                case "download":
                    this.app.navigateTo(`/api/project/${projectId}/reports/${reportId}/download`, {download: true});
                    break;
                case "delete":
                    const confirmDialog = this.app.getComponent('ConfirmDialog',
                        Messages('project.report.delete.confirm.title'),
                        Messages('project.report.delete.confirm.text'),
                        null, null, 'warning'
                    ) as ConfirmDialog;
                    confirmDialog.show();
                    confirmDialog.on('yes', () => this.onProjectReportConfirmed(projectId, reportId));
                    
                    break;
            }
        }

        protected getListViewSettings(): ListViewOptions {
            return new ListViewOptions('jsTableContainer', true, Messages('project.listView.noResults'), null, {
                    property: 'fileName',
                    direction: 'ASC'
                }, {
                    listview: 'overview__listview'
                }, {
                    path: 'WebComponents/Project/Report/ReportList',
                    file: 'ListView/itemRow'
                }, {
                    path: 'WebComponents/Project/Report/ReportList',
                    file: 'ListView/headerTemplate'
                }
            )
        }
    }
}
