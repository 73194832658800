namespace Simplex.Models.Project {
  import ABModel = Ambrero.AB.Models.ABModel;
  export class FinanceEntryFilter extends ABModel {
    public startDate?: string | null = null;
    public endDate?: string | null = null;
    public status?: string | null = null;
    public type?: string | null = null;
    public searchTerm: string | null = null;
    public includeProcessed: boolean = true;

    public hasDateRange = (): boolean => {
      return this.startDate !== null && this.endDate !== null;
    };

    public convertField = (
      fieldName: string,
      direction: "up" | "down",
      value: any
    ): any => {
      switch (fieldName) {
        case "includeProcessed":
          if (direction === "down") {
            value = !(value === "false" || value === false);
          }
          break;
        case "startDate":
        case "endDate":
          const formattingHelper =
            Ambrero.AB.Application.Instance!.getSystemComponent(
              "FormattingHelper"
            ) as Simplex.Components.FormattingHelper;
          switch (direction) {
            case "up": //get
              if (value !== null && value !== "") {
                value = moment(value, "YYYY-MM-DD").format(
                  formattingHelper.dateFormat
                );
              }
              break;
            case "down": //set
              if (value === "" || value === null) {
                value = null;
              } else {
                value = moment(value, formattingHelper.dateFormat).format(
                  "YYYY-MM-DD"
                );
              }
              break;
          }
          return value;
        case "status":
        case "type":
          if (direction === "down") {
            value = value === "" ? null : value;
          }
          break;
      }
      return value;
    };
  }
}
