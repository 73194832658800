namespace Simplex.WebComponents.Project.DashboardProjectBudget.Models {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class BudgetData extends ABModel {

        public budget: number|null;
        public snapshotBudget: number|null;
        public budgetChanged: boolean;

        constructor(budget: number|null = null,
                    snapshotBudget: number|null = null,
                    budgetChanged: boolean = false
        ) {
            super();
            this.budget = budget;
            this.snapshotBudget = snapshotBudget;
            this.budgetChanged = budgetChanged;
        }
    }
}