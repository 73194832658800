namespace Simplex.Controllers {
  import FormFields = Simplex.Components.FormFields;
  import APIResult = Simplex.Utils.APIResult;
  import FetchRequestHandler = Simplex.Components.FetchRequestHandler;
  import Auth = Simplex.Components.Auth;
  import Layout = Simplex.Components.Layout;
  import Content = Simplex.Components.Content;
  import EventArgs = Ambrero.AB.Components.EventArgs;
  import Inject = Simplex.Decorators.Inject;
  import Get = Simplex.Decorators.Get;
  import Path = Simplex.Decorators.Path;
  import IModel = Ambrero.AB.Models.IModel;
  import ModelBinder = Ambrero.AB.Components.ModelBinder;
  import Login = Simplex.Models.Authentication.Login;
  import Button = Simplex.WebComponents.FormElements.Button;
  import InputText = Simplex.WebComponents.FormElements.InputText;
  import InputPassword = Simplex.WebComponents.FormElements.InputPassword;

  interface IndexContext {
    Model: Login;
    LoginFailure: boolean;
    LoginFailureReason: string;
  }

  interface ForgotPasswordContext {
    Model: IModel;
    View: "ValidateCode" | "RequestPassword" | "ResetPassword";
  }
  interface TenantSelectContext {
    Tenants: Simplex.Models.Authentication.UserTenant[];
  }

  interface NewUserContext {
    Model: IModel;
  }

  interface LogoutContext {
    Loading: boolean;
  }

  @Simplex.Decorators.Layout("authentication")
  export class Authentication extends Ambrero.AB.Controllers.Controller {
    private static readonly CLASS_LOADING_INIT = "is--loading";

    @Inject("Layout")
    private layout!: Layout;

    @Inject("Auth")
    private auth!: Auth;

    @Inject("FetchRequestHandler")
    private request!: FetchRequestHandler;

    // @Get("/login")
    // public async Login(): Promise<void> {
    //   const content = this.app.getComponent(
    //     "Content",
    //     "Authentication/login"
    //   ) as Content<IndexContext>;
    //   const modelBinder =
    //     this.app.getComponentType<ModelBinder>("ModelBinder")!;

    //   content.setTargetContainerElement(this.layout.getContentRenderTarget());
    //   content.setContext({
    //     Model: new Simplex.Models.Authentication.Login(),
    //     LoginFailure: false,
    //     LoginFailureReason: "",
    //   } as IndexContext);

    //   modelBinder.bind<Content<IndexContext>>(
    //     content.getContext().Model,
    //     content
    //   );

    //   if (sessionStorage.getItem("login-email")) {
    //     content.getContext().Model.username =
    //       sessionStorage.getItem("login-email")!;
    //     sessionStorage.removeItem("login-email");
    //   }

    //   const onForgotPassword = (_: EventArgs): void => {
    //     sessionStorage.setItem(
    //       "forgotUsername",
    //       content.getContext().Model.username
    //     );
    //   };

    //   // const onLogin = async (event?: EventArgs): Promise<void> => {
    //   //     if (event) {
    //   //         event.handled = true;
    //   //     }
    //   //     content.getContext().LoginFailure = false;
    //   //     content.getContext().LoginFailureReason = "";
    //   //     content.refreshSections('div.input-button');

    //   //     if (!modelBinder.validate(content.getContext().Model)) {
    //   //         return;
    //   //     }

    //   //     this.eventBus.publish('loginSubmitting', Simplex.Utils.createEventArgs({state: true}, this));

    //   //     const buttons = content.selectElements("ui-button") as Button[];
    //   //     buttons.forEach(button => button.loading = true);

    //   //     const loginResponse = await this.request.post<APIResult<Simplex.Models.Authentication.AuthenticationResult>>('/api/auth/login', content.getContext().Model);

    //   //     this.eventBus.publish('loginSubmitting', Simplex.Utils.createEventArgs({state: false}, this));

    //   //     if (loginResponse.isSuccess && loginResponse.data?.data?.success) {
    //   //         this.auth.refreshSession((authResult: Simplex.Models.Authentication.AuthResult): void => {
    //   //             if(loginResponse.data?.data?.redirectUrl) {
    //   //                 this.app.navigateTo(loginResponse.data.data.redirectUrl);
    //   //                 return;
    //   //             }
    //   //             if (authResult.authenticated) {
    //   //                 const loginRedirect = this.auth.getLoginRedirect();
    //   //                 if (loginRedirect) {
    //   //                     this.auth.setLoginRedirect();
    //   //                     this.app.navigateTo(loginRedirect, {alwaysNavigate: true});
    //   //                     return;
    //   //                 }
    //   //                 this.app.navigateTo("/");
    //   //             }

    //   //         });
    //   //     } else {
    //   //         if (loginResponse.data.messages) {
    //   //             const params = loginResponse.data.messages[0].parameters ?? [];
    //   //             const msg = Messages(loginResponse.data.messages[0].key, ...params);
    //   //             content.getContext().LoginFailureReason = msg ? msg : "";
    //   //         }
    //   //         content.getContext().LoginFailure = true;
    //   //         content.refreshSections('.JSloginwarning');
    //   //         content.refreshElementClasses('.loginbox__form__main');
    //   //     }
    //   // };

    //   //   const onDraw = (): void => {
    //   //     setTimeout(() => {
    //   //       if (content.getContext().Model.username !== "") {
    //   //         const inputPassword = content.selectElement(
    //   //           "ui-input-password"
    //   //         ) as InputPassword;
    //   //         if (inputPassword) {
    //   //           inputPassword.focus();
    //   //         }
    //   //       } else {
    //   //         const inputUsername = content.selectElement(
    //   //           "ui-input-text"
    //   //         ) as InputText;
    //   //         if (inputUsername) {
    //   //           inputUsername.focus();
    //   //         }
    //   //       }
    //   //     }, 100);
    //   //     const inputPassword = content.selectElement(
    //   //       "ui-input-password"
    //   //     ) as InputPassword;
    //   //     if (inputPassword) {
    //   //       inputPassword.onKeyPress((event: KeyboardEvent): void => {
    //   //         if (event.code === "Enter") {
    //   //           inputPassword.blur();
    //   //           onLogin();
    //   //         }
    //   //       });
    //   //     }
    //   //   };

    //   if (this.auth.isSessionActive()) {
    //     this.app.navigateTo("/");
    //     return;
    //   }

    //   //   content.on("draw", onDraw);
    //   //   content.on("forgotPassword", onForgotPassword);
    //   //   content.on("login", onLogin);

    //   content.draw();
    // }

    // @Get("/tenantSelect")
    // public async TenantSelect(): Promise<void> {
    //   const content = this.app.getComponent(
    //     "Content",
    //     "Authentication/tenantSelect"
    //   ) as Content<TenantSelectContext>;
    //   const notice = this.app.getComponent("AmbreroComponents.Notice");

    //   const logout = async (): Promise<void> => {
    //     console.log("LOGOUT in TenantSelect!!!");
    //     const tenantSelectResponse = await this.request.get<APIResult<void>>(
    //       `/api/auth/logout`
    //     );
    //     if (tenantSelectResponse.isSuccess) {
    //       this.auth.refreshSession((result: boolean): void => {
    //         this.app.navigateTo("/login", { alwaysNavigate: true });
    //       });
    //     }
    //   };
    //   const tenantSelected = async (tenantId: string): Promise<void> => {
    //     const tenantSelectResponse = await this.request.post<
    //       APIResult<Simplex.Models.Authentication.AuthenticationResult>
    //     >(`/api/auth/selectTenant?tenantId=${tenantId}`);
    //     if (tenantSelectResponse.isSuccess) {
    //       this.auth.refreshSession(
    //         (result: Simplex.Models.Authentication.AuthResult): void => {
    //           if (
    //             !result.authenticated ||
    //             !tenantSelectResponse.data?.data?.redirectUrl
    //           ) {
    //             this.app.navigateTo("/login");
    //             return;
    //           }
    //           this.app.navigateTo(tenantSelectResponse.data.data.redirectUrl);
    //         }
    //       );
    //     } else {
    //       notice.addMessage("Failed to set tenant for user", "warning");
    //       this.app.navigateTo("/");
    //     }
    //   };

    //   //@ts-ignore
    //   const userTenants = window.userContext.Tenants ?? [];
    //   const tenantsResponse: Simplex.Models.Authentication.UserTenant[] =
    //     userTenants;
    //   // = await this.request.get<
    //   //   APIResult<Simplex.Models.Authentication.UserTenant[]>
    //   // >("api/auth/tenants");
    //   // if (!tenantsResponse.isSuccess || !tenantsResponse.data.data) {
    //   //   this.app.navigateTo("/");
    //   //   return;
    //   // }
    //   content.setContext({
    //     Tenants: tenantsResponse,
    //   } as TenantSelectContext);

    //   const onDraw = (): void => {
    //     const logoutButton = content.selectElement(".logoutButton") as Button;
    //     logoutButton.addEventListener("click", () => logout());
    //     const buttons = content.selectElements(
    //       ".tenantSelectButton"
    //     ) as Button[];
    //     for (let button of buttons) {
    //       const tenantId = button.dataset["id"] as string;
    //       button.addEventListener("click", () => tenantSelected(tenantId));
    //     }
    //   };

    //   content.setTargetContainerElement(this.layout.getContentRenderTarget());
    //   content.on("draw", onDraw);

    //   content.draw();
    // }

    // @Simplex.Decorators.Get("/login/forgotpassword")
    // public ForgotPassword(): void {
    //   const content = this.app.getComponent(
    //     "Content",
    //     "Authentication/forgotpassword"
    //   ) as Content<ForgotPasswordContext>;
    //   const formFields = this.app.getComponent("FormFields") as FormFields;
    //   const modelBinder =
    //     this.app.getComponentType<ModelBinder>("ModelBinder")!;

    //   const requestPasswordRequest =
    //     new Simplex.Models.Authentication.ForgotPasswordRequest();
    //   const validateCodeRequest =
    //     new Simplex.Models.Authentication.ValidateCodeRequest();
    //   const newPasswordRequest =
    //     new Simplex.Models.Authentication.NewPasswordRequest();

    //   const boundRequestPasswordRequest = modelBinder.bind<
    //     Content<ForgotPasswordContext>
    //   >(requestPasswordRequest, content);
    //   const boundValidateCodeRequest = modelBinder.bind<
    //     Content<ForgotPasswordContext>
    //   >(validateCodeRequest, content);
    //   const boundNewPasswordRequest = modelBinder.bind<
    //     Content<ForgotPasswordContext>
    //   >(newPasswordRequest, content);

    //   formFields.addModel(boundRequestPasswordRequest);
    //   formFields.addModel(boundValidateCodeRequest);
    //   formFields.addModel(boundNewPasswordRequest);

    //   content.setTargetContainerElement(this.layout.getContentRenderTarget());

    //   content.setContext({
    //     Model: requestPasswordRequest,
    //     View: "RequestPassword",
    //   } as ForgotPasswordContext);

    //   const forgotUsername = sessionStorage.getItem("forgotUsername");
    //   if (forgotUsername !== null) {
    //     requestPasswordRequest.email = forgotUsername;
    //     sessionStorage.removeItem("forgotUsername");
    //   }

    //   content.on(
    //     ["beforedraw", "beforeDrawSection"],
    //     (eventArgs: EventArgs): void => {
    //       switch (content.getContext().View) {
    //         case "ValidateCode":
    //           content.getContext().Model = validateCodeRequest;
    //           break;
    //         case "ResetPassword":
    //           content.getContext().Model = newPasswordRequest;
    //           break;
    //         case "RequestPassword":
    //           content.getContext().Model = requestPasswordRequest;
    //           break;
    //       }
    //     }
    //   );
    //   content.on(["draw", "drawSection"], (eventArgs: EventArgs): void => {
    //     switch (content.getContext().View) {
    //       case "ValidateCode":
    //         setTimeout(() => {
    //           const codeElement = content
    //             .getContext()
    //             .Model.getElementByField("code");
    //           if (codeElement) {
    //             $(codeElement.element).trigger("focus");
    //           }
    //         }, 100);
    //         break;
    //       case "ResetPassword":
    //         setTimeout(() => {
    //           const passwordElement = content
    //             .getContext()
    //             .Model.getElementByField("password");
    //           if (passwordElement) {
    //             $(passwordElement.element).trigger("focus");
    //           }
    //         }, 100);
    //         break;
    //     }
    //   });

    //   const submitSuccess = (): void => {
    //     switch (content.getContext().View) {
    //       case "RequestPassword":
    //         content.getContext().View = "ValidateCode";
    //         validateCodeRequest.email = requestPasswordRequest.email;
    //         break;
    //       case "ValidateCode":
    //         content.getContext().View = "ResetPassword";
    //         newPasswordRequest.email = validateCodeRequest.email;
    //         newPasswordRequest.code = validateCodeRequest.code;
    //         break;
    //       case "ResetPassword":
    //         this.app.navigateTo("/login");
    //         break;
    //     }
    //     content.refreshSections(".JSLoginBox");
    //   };

    //   const onBack = (event: EventArgs): boolean => {
    //     event.handled = true;
    //     switch (content.getContext().View) {
    //       case "ResetPassword":
    //         content.getContext().View = "ValidateCode";
    //         newPasswordRequest.email = validateCodeRequest.email;
    //         newPasswordRequest.code = "";
    //         break;
    //       case "ValidateCode":
    //         content.getContext().View = "RequestPassword";
    //         newPasswordRequest.email = validateCodeRequest.email;
    //         newPasswordRequest.code = validateCodeRequest.code;
    //         break;
    //     }
    //     content.refreshSections(".JSLoginBox");

    //     return false;
    //   };
    //   const onSubmit = (event: EventArgs): void => {
    //     event.handled = true;

    //     const element = content.selectJqueryElement(".JSButtonSubmit");
    //     let url = "";
    //     switch (content.getContext().View) {
    //       case "ValidateCode":
    //         if (!boundValidateCodeRequest.validate()) {
    //           return;
    //         }
    //         url = "/api/auth/forgotpassword/code";
    //         break;
    //       case "ResetPassword":
    //         if (!boundNewPasswordRequest.validate()) {
    //           return;
    //         }
    //         url = "/api/auth/forgotpassword/newpassword";
    //         break;
    //       case "RequestPassword":
    //         if (!boundRequestPasswordRequest.validate()) {
    //           return;
    //         }
    //         url = "/api/auth/forgotpassword";
    //         break;
    //     }

    //     this.app.getRequestHandler().post({
    //       type: "json",
    //       data: JSON.stringify(content.getContext().Model),
    //       url: url,
    //       successHandler: submitSuccess,
    //       completeHandler: Simplex.Utils.disableElement(
    //         element,
    //         Authentication.CLASS_LOADING_INIT
    //       ),
    //     });
    //   };

    //   content.on("submit", onSubmit);
    //   content.on("back", onBack);

    //   content.draw();
    // }

    @Simplex.Decorators.Get("/logout")
    public Logout(): void {
      const content = this.app.getComponent(
        "Content",
        "Authentication/logout"
      ) as Content<LogoutContext>;
      var container =this.layout.getContentRenderTarget();
      content.setTargetContainerElement(container);

      content.setContext({
        Loading: false
      } as LogoutContext);

      // this.app.getRequestHandler().get("/api/auth/logout", (): void => {
      //   this.auth.refreshSession(
      //     (result: Simplex.Models.Authentication.AuthResult): void => {
      //       if (result.authenticated) {
      //         this.app.navigateTo("/");
      //       } else {
      //         content.getContext().Loading = false;
      //         content.draw();
      //       }
      //     }
      //   );
      // });
      content.draw();
    }

    // @Simplex.Decorators.Get("/unlockaccount/{email}")
    // public UnlockAccount(): void {
    //   const content = this.app.getComponent(
    //     "Content",
    //     "Authentication/lockedRemoved"
    //   ) as Content<IndexContext>;
    //   content.setTargetContainerElement(this.layout.getContentRenderTarget());
    //   content.setContext({
    //     // @ts-ignore
    //   } as IndexContext);
    //   content.draw();

    //   setTimeout((): void => {
    //     this.app.navigateTo("/login");
    //   }, 3000);
    // }

    // @Simplex.Decorators.Get("/login/newuser/{id:guid}/{token}")
    // public NewUser(
    //   @Path("token") token: string,
    //   @Path("id") userId: string
    // ): void {
    //   const modelBinder =
    //     this.app.getComponentType<ModelBinder>("ModelBinder")!;
    //   const notice = this.app.getComponent("AmbreroComponents.Notice");

    //   const content = this.app.getComponent(
    //     "Content",
    //     "Authentication/newuser"
    //   ) as Content<NewUserContext>;
    //   content.setTargetContainerElement(this.layout.getContentRenderTarget());

    //   const model = new Simplex.Models.Authentication.NewUserRequest();
    //   if (/^en\b/.test(navigator.language)) {
    //     model.language = "EN";
    //   } else if (/^nl\b/.test(navigator.language)) {
    //     model.language = "NL";
    //   }
    //   content.setContext({
    //     Model: model,
    //   } as NewUserContext);
    //   modelBinder.bind<Content<NewUserContext>>(
    //     content.getContext().Model,
    //     content
    //   );

    //   content.getContext().Model.token = token;

    //   content.on("draw", (eventArgs: EventArgs): void => {});

    //   const onSubmit = async (event?: EventArgs): Promise<void> => {
    //     if (event) {
    //       event.handled = true;
    //     }

    //     if (!modelBinder.validate(content.getContext().Model)) {
    //       return;
    //     }

    //     this.eventBus.publish(
    //       "loginSubmitting",
    //       Simplex.Utils.createEventArgs({ state: true }, this)
    //     );

    //     const buttons = content.selectElements("ui-button") as Button[];
    //     buttons.forEach((button) => (button.loading = true));

    //     const loginResponse = await this.request.post<
    //       APIResult<{ email: string }>
    //     >(`/api/auth/newuser/${userId}`, content.getContext().Model);

    //     this.eventBus.publish(
    //       "loginSubmitting",
    //       Simplex.Utils.createEventArgs({ state: false }, this)
    //     );

    //     if (loginResponse.isSuccess) {
    //       notice.addMessage(
    //         "Registratie gelukt, u kunt nu inloggen.",
    //         "success"
    //       );
    //       sessionStorage.setItem("login-email", loginResponse.data.data!.email);
    //       this.app.navigateTo("/login");
    //     } else {
    //       if (loginResponse.data.messages.length > 0) {
    //         notice.addMessage(loginResponse.data.messages[0].key, "warning");
    //       } else {
    //         notice.addMessage("Fout bij het registreren.", "warning");
    //       }
    //     }
    //   };

    //   content.on("submit", onSubmit);
    //   content.draw();
    // }
  }
}
