/// <reference path="../Declarations/Handlebars.d.ts" />

namespace Simplex.HandlebarHelper {
	const defaultNumberGroupSeparator = '.';
	const defaultNumberDecimalSeparator = ',';
	let formattingHelper:Simplex.Components.FormattingHelper|null = null;
	
	export const formatNumber: Handlebars.HelperDelegate = function(value:number|null, decimalCount:number|null, emptyText: string|null): string|null  {
		if (value === undefined || value === null) {
			return emptyText ?? null;
		}
		let numberGroupSeparator;
		let numberDecimalSeparator;
		if(!formattingHelper) {
			formattingHelper = Ambrero.AB.Application.Instance!.getSystemComponent('FormattingHelper') as Simplex.Components.FormattingHelper
		}
		const numberFormat = formattingHelper.numberFormat;
		switch (numberFormat) {
			case 'GroupCommaAndDecimalDot':
				numberGroupSeparator = ',';
				numberDecimalSeparator = '.';
				break;
			case 'GroupApostropheAndDecimalDot':
				numberGroupSeparator = '\'';
				numberDecimalSeparator = '.';
				break;
			case 'GroupSpaceAndDecimalDot':
				numberGroupSeparator = ' ';
				numberDecimalSeparator = '.';
				break;
			case 'GroupDotAndDecimalComma':
			default:
				numberGroupSeparator = defaultNumberGroupSeparator;
				numberDecimalSeparator = defaultNumberDecimalSeparator;
				break;
		}
		if(decimalCount === null || isNaN(decimalCount)) {
			decimalCount = 0;
		}
		let parts = value.toString().split(".");
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, numberGroupSeparator);
		if (parts.length === 1 && !isNaN(decimalCount) && decimalCount > 0) {
			parts.push('0');
		}

		if (parts.length === 2 && !isNaN(decimalCount)) {
			if (parts[1].length > decimalCount) {
				parts[1] = parts[1].substring(0, decimalCount);
			} else {
				parts[1] = parts[1].padEnd(decimalCount, '0');
			}

			if (parts[1].trim() === "") {
				parts.splice(1, 1);
			}
		}
		return parts.join(numberDecimalSeparator);
	}
}

Handlebars.registerHelper("formatNumber", Simplex.HandlebarHelper.formatNumber);