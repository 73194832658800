namespace Simplex.Components {
	import IObservableContent = Ambrero.AB.Components.IObservableContent;
	import Emitter = Ambrero.AB.Components.Emitter;

	export class ContentNotifier implements IObservableContent {
		private emitter: Emitter = new Emitter();

		off(eventName: "draw" | "drawSection", callback: (event: Ambrero.AB.Components.EventArgs) => void): void {
			this.emitter.off("draw", callback);
			this.emitter.off("drawSection", callback);
		}

		on(eventName: "draw" | "drawSection", callback: (event: Ambrero.AB.Components.EventArgs) => void): void {
			this.emitter.on("draw", callback);
			this.emitter.on("drawSection", callback);
		}

		notifyDraw(element: any) {
			this.emitter.emit("draw", Simplex.Utils.createEventArgs({container: element}, this))
		}
	}
}