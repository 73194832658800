namespace Simplex.Models.Admin {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class TenantCreate extends ABModel {

        public name: string | null;
        public subdomain: string | null;
        public emailDomain: string | null;
        public chamberOfCommerce: string | null;
        public vatNumber: string | null;
        public houseNumber: string | null;
        public zipcode: string | null;
        public street: string | null;
        public city: string | null;
        public firstName: string | null;
        public lastName: string | null;
        public email: string | null;
        public language: string | null;
        public numberFormat: string | null;
        public dateFormat: string | null;
        public timeFormat: string | null;

        constructor(
            name: string | null = null,
            subdomain: string | null = null,
            emailDomain: string | null = null,
            chamberOfCommerce: string | null = null,
            vatNumber: string | null = null,
            houseNumber: string | null = null,
            zipcode: string | null = null,
            street: string | null = null,
            city: string | null = null,
            firstName: string | null = null,
            lastName: string | null = null,
            email: string | null = null,
            language: string | null = null,
            numberFormat: string | null = null,
            dateFormat: string | null = null,
            timeFormat: string | null = null
            
        ) {
            super();
            this.name = name;
            this.subdomain = subdomain;
            this.emailDomain = emailDomain;
            this.chamberOfCommerce = chamberOfCommerce;
            this.vatNumber = vatNumber;
            this.houseNumber = houseNumber;
            this.zipcode = zipcode;
            this.street = street;
            this.city = city;
            this.firstName = firstName;
            this.lastName = lastName;
            this.email = email;
            this.language = language;
            this.numberFormat = numberFormat;
            this.dateFormat = dateFormat;
            this.timeFormat = timeFormat;
        }

        public validateField = (fieldName: string, element: HTMLElement, value: string): string | undefined => {
            if ($(element).attr("name") === "subdomain") {
                if (value.length > 62) {
                    return "error.domain.invalid.max";
                }
                if (! /^[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])$/.test(value)) {
                    return "error.domain.invalid.chars"
                }
            }
        }
    }
} 