namespace Simplex.WebComponents.Project {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import ModelBinder = Ambrero.AB.Components.ModelBinder;
    import APIResult = Simplex.Utils.APIResult;
    import Button = Simplex.WebComponents.FormElements.Button;
    import EventArgs = Ambrero.AB.Components.EventArgs;
    import ContentNotifier = Simplex.Components.ContentNotifier;
    import Inject = Simplex.Decorators.Inject;
    
    @WebComponent("ui-create-project-report-form")
    export class ReportCreateForm extends ABWebComponent {
        private readonly _contentTemplate;
        private readonly _createModel: Simplex.WebComponents.Project.Report.Models.ReportCreate;
        private readonly _binder: ModelBinder;
        private readonly _projectId: string;
        private _rendered: boolean = false;
        private _submit?: Button;
        private readonly _generalExplanationSessionKey: string = 'reportCreateForm_generalExplanation';
        private readonly _planningExplanationSessionKey: string = 'reportCreateForm_planningExplanation';
        private readonly _budgetExplanationSessionKey: string = 'reportCreateForm_budgetExplanation';
        private _explanationBlockPreviewGeneral?: Element;
        private _explanationBlockPreviewPlanning?: Element;
        private _explanationBlockPreviewBudget?: Element;
        private _explanationBlockGeneral?: HTMLInputElement;
        private _explanationBlockPlanning?: HTMLInputElement;
        private _explanationBlockBudget?: HTMLInputElement;
        private _previewContainer?: Element;
        @Inject("ContentNotifier")
        private readonly _contentNotifier!: ContentNotifier;
        
        public constructor() {
            super();
            this._contentTemplate = this.app.getTemplate('WebComponents/Project/Report/ReportCreate', 'ReportCreateForm') as TemplateCallback;
            this._binder = this.app.getComponentType<ModelBinder>("ModelBinder")!;
            this._projectId = this.getAttribute("project-id") ?? "";
            this._createModel = new Simplex.WebComponents.Project.Report.Models.ReportCreate();
            this._createModel.projectId = this._projectId;
            this._createModel.generalExplanation = sessionStorage.getItem(this._generalExplanationSessionKey) ?? undefined;
            this._createModel.planningExplanation = sessionStorage.getItem(this._planningExplanationSessionKey) ?? undefined;
            this._createModel.budgetExplanation = sessionStorage.getItem(this._budgetExplanationSessionKey) ?? undefined;
            this._binder.bind(this._createModel, this._contentNotifier)
                .on("PropertyChanged", this.propertyChanged);
        }

        async render() {
            this.innerHTML = this._contentTemplate(
                {
                    projectId : this._projectId,
                    model : this._createModel
                }
            );

            this._submit = this.querySelector(".submitButton") as Button;
            this._explanationBlockGeneral = this.querySelector("textarea[name='generalExplanation']") as HTMLInputElement;
            this._explanationBlockPlanning = this.querySelector("textarea[name='planningExplanation']") as HTMLInputElement;
            this._explanationBlockBudget = this.querySelector("textarea[name='budgetExplanation']") as HTMLInputElement;
            this._previewContainer = this.querySelector('.preview-container') as Element;
            
            await this.renderPreview();

            this._explanationBlockPreviewGeneral = this._previewContainer.querySelector('.explanation-block-general') as Element;
            this._explanationBlockPreviewPlanning = this._previewContainer.querySelector('.explanation-block-planning') as Element;
            this._explanationBlockPreviewBudget = this._previewContainer.querySelector('.explanation-block-budget') as Element;
            
            this._rendered = true;
        }

        private renderPreview = async (): Promise<void> => {
            const previewResult = await this.request.post<APIResult<string>>(`/api/project/${this._projectId}/reports/Preview`, this._createModel);
            if (!previewResult.isSuccess || !previewResult.data.data) {
                return undefined;
            }
            if(this._previewContainer){
                this._previewContainer.innerHTML = previewResult.data.data;
            }
        }
        
        private explanationBlockUpdate = (element?:Element, text?:string):void =>{
            if(element && text){
                element.innerHTML = Simplex.HandlebarHelper.nl2br(text);
            }
        }
 
        private propertyChanged = (eventArgs: EventArgs) : void => {
            switch (eventArgs.data.field) {
                case "generalExplanation":
                    this.explanationBlockUpdate(this._explanationBlockPreviewGeneral, this._createModel.generalExplanation);
                    break;
                case "planningExplanation":
                    this.explanationBlockUpdate(this._explanationBlockPreviewPlanning, this._createModel.planningExplanation);
                    break;
                case "budgetExplanation":
                    this.explanationBlockUpdate(this._explanationBlockPreviewBudget, this._createModel.budgetExplanation);
                    break;
            }
        }

        onSubmit = async (event: MouseEvent): Promise<void> => {
            event.preventDefault();
            event.stopPropagation();

            if (!this._binder.validate(this._createModel)) {
                return;
            }
            if (this._submit) {
                this._submit.loading = true;
            }
            
            const createResult = await this.request.post<APIResult<void>>(`/api/project/${this._projectId}/reports`, this._createModel);
            if (createResult.isSuccess) {
                sessionStorage.setItem(this._generalExplanationSessionKey, this._createModel?.generalExplanation ?? '')
                sessionStorage.setItem(this._planningExplanationSessionKey, this._createModel?.planningExplanation ?? '')
                sessionStorage.setItem(this._budgetExplanationSessionKey, this._createModel?.budgetExplanation ?? '')

                this.app.navigateTo(`/api/project/${this._projectId}/reports/${createResult.data.data}/download`, {download: true});
                this.app.navigateTo(`/project/${this._projectId}/reports`);
            } else {
                // alert("Fout?");
            }
            if (this._submit) {
                this._submit.loading = false;
            }
        }

        async connectedCallback() {
            if (!this.isConnected) {
                return;
            }

            if (!this._rendered) {
                await this.render();
                this.bindElementEventListeners();
            }
        }

        private onKeyPressExplanation(_explanationBlock?: HTMLInputElement, previewField?:Element) {
            this.explanationBlockUpdate(previewField, _explanationBlock?.value);
        }
        
        private bindElementEventListeners(): void {
            if (this._submit) {
                this._submit.addEventListener("click", this.onSubmit);
            }
            const previewContainer = this.querySelector('.preview-container');
            if(!previewContainer){
                return;
            }
            
            if(this._explanationBlockGeneral) {
                this._explanationBlockGeneral.addEventListener("keyup", () => this.onKeyPressExplanation(this._explanationBlockGeneral, this._explanationBlockPreviewGeneral));
            }
            if(this._explanationBlockPlanning) {
                this._explanationBlockPlanning.addEventListener("keyup", () => this.onKeyPressExplanation(this._explanationBlockPlanning, this._explanationBlockPreviewPlanning));
            }
            if(this._explanationBlockBudget) {
                this._explanationBlockBudget.addEventListener("keyup", () => this.onKeyPressExplanation(this._explanationBlockBudget, this._explanationBlockPreviewBudget));
            }
            this._contentNotifier.notifyDraw(this);
        }

        disconnectedCallback() {
            this.removeElementEventListeners();
        }

        private removeElementEventListeners(): void {
            if (this._submit) {
                this._submit.removeEventListener("click", this.onSubmit);
            }
            if(this._explanationBlockGeneral) {
                
            }
            if(this._explanationBlockGeneral) {
                this._explanationBlockGeneral.removeEventListener("keyup", () => this.onKeyPressExplanation(this._explanationBlockGeneral, this._explanationBlockPreviewGeneral));
            }
            if(this._explanationBlockPlanning) {
                this._explanationBlockPlanning.removeEventListener("keyup", () => this.onKeyPressExplanation(this._explanationBlockPlanning, this._explanationBlockPreviewPlanning));
            }
            if(this._explanationBlockBudget) {
                this._explanationBlockBudget.removeEventListener("keyup", () => this.onKeyPressExplanation(this._explanationBlockBudget, this._explanationBlockPreviewBudget));
            }
        }
    }
}
