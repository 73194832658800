namespace Simplex.WebComponents.Project.Planning.Models {
    import ABModel = Ambrero.AB.Models.ABModel;
    import Scope = Simplex.Models.Project.Scope;

    export class FilterModel extends ABModel {

        public compareWithSnapshotId: string|null = null;
        public compareWithSnapshotName: string|null = null;
        public scopeId: string|null = null;
        public periodType: PlanningPeriodType = PlanningPeriodType.Month;
        public scopeLevel: number;
        public snapshots: boolean;
        public budgets: boolean;
        public relations: boolean;
        public milestones: boolean;
        public criticalpath: boolean;
        
        constructor(compareWithSnapshotId: string|null = null, compareWithSnapshotName: string|null = null, scopeId: string|null = null, 
                    periodType: PlanningPeriodType = PlanningPeriodType.Month,  scopeLevel: number = 1, budgets: boolean = true,
                    snapshots: boolean = false, relations: boolean = true, milestones: boolean = false, criticalpath: boolean = false) {
            super();
            this.compareWithSnapshotId = compareWithSnapshotId;
            this.compareWithSnapshotName = compareWithSnapshotName;
            this.scopeId = scopeId;
            this.periodType = periodType;
            this.scopeLevel = scopeLevel;
            this.budgets = budgets;
            this.snapshots = snapshots;
            this.relations = relations;
            this.milestones = milestones;
            this.criticalpath = criticalpath;
        }
    }

    export enum PlanningPeriodType {
        Year = 'Year',
        Quarter = 'Quarter',
        Month = 'Month',
        Week = 'Week',
        Day = 'Day'
    }
}