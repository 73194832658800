namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class ProjectSummary extends ABModel {

        public id: string;
        public number: string;
        public created: string;
        public responsibleManagerName?: string;

        constructor(id: string = "", number: string = "", created: string = "", responsibleManagerName?: string) {
            super();
            this.id = id;
            this.number = number;
            this.created = created;
            this.responsibleManagerName = responsibleManagerName;
        }

    }
}