namespace Simplex.Models.Project {

    export class ChangeScopeSortingRequest {
        public newParentScopeId: string;
        public newIndex: number;

        constructor(newParentScopeId: string = Simplex.Utils.GUID_EMPTY, newIndex: number = 0) {
            this.newParentScopeId = newParentScopeId;
            this.newIndex = newIndex;
        }
    }
}