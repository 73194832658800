namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class Scope extends ABModel {

        public id: string|null;
        public parentScopeId: string|null;
        public name: string;
        public color: string;
        public index: number;
        public startDate: string|null = null;
        public parentScopeStartDate: string|null = null;
        public endDate: string|null = null;
        public parentScopeEndDate: string|null = null;
        public startDateMilestoneName: string|null;
        public endDateMilestoneName: string|null;
        public durationInDays: number|null;
        public critical: boolean;
        public maxDepth: boolean;
        public budgetBreakdown: boolean;
        public budget: number|null;
        public snapshotBudget: number|null;
        public budgets: ScopeBudget[];
        public expectedCostsBreakdown: boolean;
        public expectedCosts: number|null;
        public snapshotExpectedCosts: number|null;
        public expectedCostsChanged : boolean = false;
        public actual: number|null;
        public snapshotActual: number|null;
        public actualChanged : boolean = false;
        public expense: number|null;
        public snapshotExpense: number|null;
        public expenseChanged : boolean = false;
        public expectedCostsPerCostCategory: ScopeBudget[];
        public actualPerCostCategory: ScopeBudget[];
        public expensePerCostCategory: ScopeBudget[];
        public prognose: number|null;
        public result: number|null;
        public parentScopeBudget: number|null;
        public scopeChanged : boolean = false;
        public budgetChanged : boolean = false;
        public budgetChildSumChanged : boolean = false;
        public startDateChanged : boolean = false;
        public endDateChanged : boolean = false;
        public snapshotStartDate: string|null = null;
        public snapshotEndDate: string|null = null;
        public snapshotChange: string|null = null;
        public hasChildren: boolean;
        public hasChildScopesWithBudgetDefined: boolean;
        public hasChildScopesWithExpectedCostsDefined: boolean;
        public children: Scope[];
        public references: ScopeReference[];

        constructor(id: string|null = null,
                    parentScopeId: string|null = null,
                    name: string = "",
                    color: string = "",
                    index: number = 0,
                    startDate: string|null = null,
                    parentScopeStartDate: string|null = null,
                    endDate: string|null = null,
                    parentScopeEndDate: string|null = null,
                    startDateMilestoneName: string|null = null,
                    endDateMilestoneName: string|null = null,
                    durationInDays: number|null = null,
                    critical: boolean = false,
                    budgetBreakdown: boolean =false,
                    budget: number|null = null,
                    snapshotBudget: number|null = null,
                    maxDepth: boolean = false,
                    budgets: ScopeBudget[] = [],
                    expectedCostsBreakdown: boolean = false,
                    expectedCosts: number|null = null,
                    snapshotExpectedCosts: number|null = null,
                    actual: number|null = null,
                    snapshotActual: number|null = null,
                    actualChanged: boolean = false,
                    expense: number|null = null,
                    snapshotExpense: number|null = null,
                    expenseChanged: boolean = false,
                    expectedCostsPerCostCategory: ScopeBudget[] = [],
                    actualPerCostCategory: ScopeBudget[] = [],
                    expensePerCostCategory: ScopeBudget[] = [],
                    prognose: number|null = null,
                    result: number|null = null,
                    parentScopeBudget: number|null = null,
                    scopeChanged: boolean = false,
                    budgetChanged: boolean = false,
                    budgetChildSumChanged: boolean = false,
                    expectedCostsChanged: boolean = false,
                    startDateChanged: boolean = false,
                    endDateChanged: boolean = false,
                    snapshotStartDate: string|null = null,
                    snapshotEndDate: string|null = null,
                    snapshotChange: string|null = null,
                    hasChildren: boolean,
                    hasChildScopesWithBudgetDefined: boolean,
                    hasChildScopesWithExpectedCostsDefined: boolean,
                    children: Scope[] = [],
                    references: ScopeReference[] = []) {
            super();
            this.id = id;
            this.parentScopeId = parentScopeId;
            this.name = name;
            this.color = color;
            this.index = index;
            this.startDate = startDate;
            this.parentScopeStartDate = parentScopeStartDate;
            this.endDate = endDate;
            this.parentScopeEndDate = parentScopeEndDate;
            this.startDateMilestoneName = startDateMilestoneName;
            this.endDateMilestoneName = endDateMilestoneName;
            this.durationInDays= durationInDays;
            this.critical = critical;
            this.budgetBreakdown = budgetBreakdown;
            this.budget = budget;
            this.snapshotBudget = snapshotBudget;
            this.maxDepth = maxDepth;
            this.parentScopeBudget = parentScopeBudget;
            this.budgets = budgets;
            this.expectedCostsBreakdown = expectedCostsBreakdown;
            this.actual = actual;
            this.snapshotActual = snapshotActual;
            this.actualChanged = actualChanged;
            this.expense = expense;
            this.snapshotExpense = snapshotExpense;
            this.expenseChanged = expenseChanged;
            this.expectedCosts = expectedCosts;
            this.snapshotExpectedCosts = snapshotExpectedCosts;
            this.expectedCostsPerCostCategory = expectedCostsPerCostCategory;            
            this.actualPerCostCategory = actualPerCostCategory;            
            this.expensePerCostCategory = expensePerCostCategory;
            this.prognose = prognose;
            this.result = result;
            this.scopeChanged = scopeChanged;
            this.budgetChanged = budgetChanged;
            this.budgetChildSumChanged = budgetChildSumChanged;
            this.expectedCostsChanged = expectedCostsChanged;
            this.startDateChanged = startDateChanged;
            this.endDateChanged = endDateChanged;
            this.snapshotStartDate = snapshotStartDate;
            this.snapshotEndDate = snapshotEndDate;
            this.snapshotChange = snapshotChange;
            this.hasChildren = hasChildren;
            this.hasChildScopesWithBudgetDefined = hasChildScopesWithBudgetDefined;
            this.hasChildScopesWithExpectedCostsDefined = hasChildScopesWithExpectedCostsDefined;
            this.children = children;
            this.references = references;
        }

        public convertField = (fieldName: string, direction: "up" | "down", value: any): any => {
            switch (fieldName) {
                case "budget":
                   if (direction === "down") {
                       return value === "" ? null : value;
                   }
            }
        }
        
    }
}