namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class ScopeCreated extends ABModel {

        public parentScopeId: string|null;

        constructor(parentScopeId: string|null = "") {
            super();
            this.parentScopeId = parentScopeId;
        }
    }
}