namespace Simplex.WebComponents.Project {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import APIResult = Simplex.Utils.APIResult;
    import UIHint = Simplex.WebComponents.LayoutComponents.UIHint;
    import CostCategory = Simplex.Models.Project.CostCategory;
    import ProjectTasksManager = Simplex.Components.ProjectTasksManager;
    import ProjectTaskType = Simplex.Project.Models.ProjectTaskType;
    import EventArgs = Ambrero.AB.Components.EventArgs;
    
    @WebComponent("ui-edit-cost-category-overview")
    export class EditCostCategoryOverview extends ABWebComponent {
        private _rendered: boolean = false;
        private readonly _contentTemplate;
        private readonly _projectId: string;
        private readonly _costCategoriesContainer = '.costCategoriesContainer';
        private readonly _projectTasksManager : ProjectTasksManager;
        
        public constructor() {
            super();
            this._projectId = this.getAttribute("project-id") || "";
            this._contentTemplate = this.app.getTemplate('WebComponents/Project/EditCostCategories/EditCostCategoryOverview', 'EditCostCategoryOverview') as TemplateCallback;
            this._projectTasksManager = this.app.getComponent('ProjectTasksManager') as ProjectTasksManager;
            
            this.addEventListener("costCategoryCreated", async (event: Event) => {
                const parentCostCategoryId = (event as CustomEvent<Simplex.Models.Project.CostCategoryCreated>).detail.parentCostCategoryId;
                await this.loadAndDrawCostCategories(parentCostCategoryId);
            });
            this.addEventListener("costCategoryChanged", async (_: Event) => await this.loadAndDrawCostCategories());
            this.addEventListener("cancel", () => this.loadAndDrawCostCategories(null));
        }

        async render() {
            this.innerHTML = this._contentTemplate({ProjectId: this._projectId});
            this._rendered = true;
        }

        private loadAndDrawCostCategories = async (selectedParentCostCategoryId?: string | null): Promise<void> => {
            const result = await this.request.get<APIResult<CostCategory[]>>(`/api/project/${this._projectId}/costCategories`);
            const costCategoriesContainer = this.querySelector(this._costCategoriesContainer);
            if (!result.isSuccess || result.data.data === undefined || costCategoriesContainer === null) {
                return;
            }
            const noCostCategoriesHint = this.querySelector('ui-hint') as UIHint;
            noCostCategoriesHint.style.display = (noCostCategoriesHint && result.data.data.length === 0) ? '' : 'none';
            costCategoriesContainer.innerHTML = "";
            const editCostCategory = new Simplex.WebComponents.Project.EditCostCategory(this._projectId, result.data.data, undefined, -1);
            costCategoriesContainer.appendChild(editCostCategory);

            const editCostCategoryElements = this.querySelectorAll('ui-edit-cost-category');
            if (editCostCategoryElements && editCostCategoryElements.length > 0) {
                editCostCategoryElements.forEach(element => {
                    const editCostCategory = element as Simplex.WebComponents.Project.EditCostCategory;
                    if (selectedParentCostCategoryId !== Simplex.Utils.GUID_EMPTY && editCostCategory.costCategoryId == selectedParentCostCategoryId) {
                        editCostCategory.onAddCostCategory();
                        return false;
                    }
                });
            }
            await this._projectTasksManager.showTasks(this._projectId, Simplex.WebComponents.ProjectTasksType.CostCategory);
        }

        private removeAllHighlights = () => {
            const addCostCategoryForm = this.querySelector('ui-edit-cost-category ui-add-cost_category');
            addCostCategoryForm?.classList.remove('is--highlighted');
        }

        private onProjectTaskManagerRemoveHighLights = (event: EventArgs) => {
            event.handled = true;
            this.removeAllHighlights();
        }

        private onProjectTaskListItemClicked = async (event: EventArgs) => {
            event.handled = true;
            const task = event.data as Simplex.Project.Models.ProjectTask;
            this.removeAllHighlights();
            if(task.type === ProjectTaskType.MissingCostCategories) {
                const addCostCategoryForm = this.querySelector('ui-edit-cost-category ui-add-cost_category');
                if(addCostCategoryForm) {
                    addCostCategoryForm.classList.add('is--highlighted');
                    (addCostCategoryForm as AddCostCategory).focus();
                }
            }
        }

        private bindElementEventListeners(): void {
            this._projectTasksManager.on('removeAllHighLights', this.onProjectTaskManagerRemoveHighLights);
            this._projectTasksManager.on('projectTaskListItemClicked', this.onProjectTaskListItemClicked);
        }

        private removeElementEventListeners(): void {
            this._projectTasksManager.off('removeAllHighLights', this.onProjectTaskManagerRemoveHighLights);
            this._projectTasksManager.off('projectTaskListItemClicked', this.onProjectTaskListItemClicked);
        }
        
        async connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if (!this._rendered) {
                await this.render();
                this.bindElementEventListeners();
            }
            await this.loadAndDrawCostCategories();
        }

        disconnectedCallback() {
            this.removeElementEventListeners();
        }
    }
}
