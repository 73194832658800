namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class CostCategorySummary extends ABModel {

        public id: string|null;
        public name: string;
        public code: string;
        public children: CostCategorySummary[];

        constructor(id: string|null = null,
                    name: string = "",
                    code: string = "",
                    index: number = 0,
                    maxDepth: boolean = false,
                    children: CostCategorySummary[] = []) {
            super();
            this.id = id;
            this.name = name;
            this.code = code;
            this.children = children;
        }
    }
}