namespace Simplex.WebComponents.Project.ProjectDashboardTasks {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;

    @WebComponent("ui-project-tasks-task")
    export class ProjectTaskListItem extends ABWebComponent {
        private readonly _contentTemplate;
        private readonly _projectId: string;
        private readonly _task: Simplex.Project.Models.ProjectTask;

        public constructor(projectId: string, task: Simplex.Project.Models.ProjectTask) {
            super();
            this._projectId = projectId;
            this._task = task;
            this._contentTemplate = this.app.getTemplate('WebComponents/Project/ProjectTasks', 'ProjectTaskListItem') as TemplateCallback;
        }

        public async render() {
            const priorityByType = Simplex.Project.Models.ProjectTask.getPriorityByType(this._task.type) as Simplex.Project.Models.ProjectTaskPriorityType;
            this._task.priority = priorityByType;
            this._task.priorityName = Simplex.Project.Models.ProjectTaskPriorityType[priorityByType];
            this._task.actionUrl = Simplex.Project.Models.ProjectTask.getActionUrl(this._task.type, this._projectId);
            this.innerHTML = this._contentTemplate(this._task);
        }

        private clicked = async (evt: Event): Promise<void> => {
            evt.preventDefault();
            evt.stopPropagation();
            this.dispatchEvent(new CustomEvent("projectTaskListItemClicked", {
                bubbles: true,
                detail: this._task
            }));
        }

        get taskId() {
            return this._task.id;
        }
        
        public select = (): void => {
            const listviewItem = this.querySelector('.listview__item');
            listviewItem?.classList.add('selected');
        }
        
        public unselect = (): void => {
            const listviewItem = this.querySelector('.listview__item');
            listviewItem?.classList.remove('selected');
        }
        
        disconnectedCallback() {
            this.removeEventListener('click', this.clicked);
        }

        public async connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            await this.render();
            this.addEventListener('click', this.clicked);
        }
    }
}