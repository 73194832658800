namespace Simplex.WebComponents.Project {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import ModelBinder = Ambrero.AB.Components.ModelBinder;
    import Emitter = Ambrero.AB.Components.Emitter;
    import APIResult = Simplex.Utils.APIResult;
    import ContentNotifier = Simplex.Components.ContentNotifier;
    import Inject = Simplex.Decorators.Inject;
    
    @WebComponent("ui-add-cost_category")
    export class AddCostCategory extends ABWebComponent {

        private readonly contentTemplate;
        private readonly _createModel: Simplex.Models.Project.CreateCostCategoryRequest;
        private readonly _binder: ModelBinder;
        private readonly _emitter: Emitter;
        private _input: HTMLInputElement | null = null;
        private _projectId?: string;
        @Inject("ContentNotifier")
        private readonly _contentNotifier!: ContentNotifier;

        public constructor() {
            super();
            this._emitter = new Emitter();
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/EditCostCategories/AddCostCategory', 'AddCostCategory') as TemplateCallback;
            this._binder = this.app.getComponentType<ModelBinder>("ModelBinder")!;
            this._createModel = new Simplex.Models.Project.CreateCostCategoryRequest();
            this._binder.bind(this._createModel, this._contentNotifier);            
        }

        render() {
            this.innerHTML = this.contentTemplate({ });
            this._input = this.querySelector("input");
            if (this._input) {
                this._input.addEventListener("keydown", this.onKeyDown);
            }
            
            this.querySelector("div.toggle")!.addEventListener("click", (event:Event) => {
                event.preventDefault();
                event.stopPropagation();
                this.dispatchEvent(new Event("expand"));
            });
            
            this._contentNotifier.notifyDraw(this);
        }

        getValue() {
            return this._input?.value;
        }
        
        set parentId(id: string|null) {
            this._createModel.parentCostCategoryId = id;
        }
        get parentId() {
            return this._createModel.parentCostCategoryId;
        }

        set index(index: number) {
            this._createModel.index = index;
        }
        get index() {
            return this._createModel.index;
        }
        
        set projectId(id: string) {
            this._projectId = id; 
        }
        get projectId() {
            return this._projectId || ""; 
        }
        
        public reset = ():void => {
            this._binder.reset(this._createModel);
            if (this._input) {
                this._input.value = "";
            }
        }
        
        public focus = ():void => {
            if (this._input) {
                this._input.focus();
            }
        }
        
        onKeyDown = async (event:KeyboardEvent):Promise<void> => {
            if(event.code === 'Enter' || event.code === 'NumpadEnter') {
                this._input?.blur();
                this._input?.focus();

                await this.onSubmit();
            }
            if (event.code === "Escape") {
                this.app.getEventBus().publish('closeCostCategory', Simplex.Utils.createEventArgs(null,this));

            }
        }
        
        onSubmit = async ():Promise<void> => {
            if (!this._binder.validate(this._createModel)) {
                return;
            }
            this._createModel.active = true;
            const createResult = await this.request.post<APIResult<void>>(`/api/project/${this.projectId}/costCategories`, this._createModel);
            if (createResult.isSuccess) {
                if(this._input) {
                    this._input.value = "";
                }
                this.dispatchEvent(new CustomEvent("costCategoryCreated", {bubbles: true, detail: new Simplex.Models.Project.CostCategoryCreated(this._createModel.parentCostCategoryId)} ) );
            } else {
                alert("Fout?");
            }
        }

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }

            this.render();
        }        
    }
}
