namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class Project extends ABModel {

        public id: string;
        public name: string;
        public number: string;
        public currency: string;
        public created: string;

        public startDate: string;
        public startDateReadonly: boolean;
        public snapshotStartDate: string;
        public startDateChanged: boolean;
        
        public endDate: string;
        public endDateReadonly: boolean;
        public snapshotEndDate: string;
        public endDateChanged: boolean;

        public budget: number;
        public budgetReadonly: boolean;
        public snapshotBudget: number;
        public budgetChanged: boolean;

        constructor(id: string = "", 
                    name: string = "", 
                    number: string = "",
                    currency: string = "", 
                    created: string = "",
                    startDate: string = "",
                    startDateReadonly: boolean = false,
                    snapshotStartDate: string = "",
                    startDateChanged: boolean = false,
                    endDate: string = "",
                    endDateReadonly: boolean = false,
                    snapshotEndDate: string = "",
                    endDateChanged: boolean = false,
                    budget: number = 0,
                    budgetReadonly: boolean = false,
                    snapshotBudget: number = 0,
                    budgetChanged: boolean = false
        ) {
            super();
            this.id = id;
            this.name = name;
            this.number = number;
            this.currency = currency;
            this.created = created;
            this.startDate = startDate;
            this.startDateReadonly = startDateReadonly;
            this.snapshotStartDate = snapshotStartDate;
            this.startDateChanged = startDateChanged;
            this.endDate = endDate;
            this.endDateReadonly = endDateReadonly;
            this.snapshotEndDate = snapshotEndDate;
            this.endDateChanged = endDateChanged;
            this.budget = budget;
            this.budgetReadonly = budgetReadonly;
            this.snapshotBudget = snapshotBudget;
            this.budgetChanged = budgetChanged;
        }
    }
}