namespace Simplex.Decorators {

    export function Inject(componentName: string, ...args: any[]): Function {

        return function(target: Object, propertyKey: string) {
            const getter = function() {
                // @ts-ignore
                if (!('__components' in this)) {
                    // @ts-ignore
                    this.__components = {};
                }
                const key = `${componentName}-${propertyKey}`;

                // @ts-ignore
                if (this.__components[key]) {
                    // @ts-ignore
                    return this.__components[key];
                }

                let component = Ambrero.AB.Application.Instance!.getSystemComponent(componentName);
                if (component === undefined || component === null) {
                    component = Ambrero.AB.Application.Instance!.getComponent(componentName,...args)
                }
                if (component === undefined || component === null) {
                    // @ts-ignore
                    throw new Error(`Required component ${componentName} for ${this.constructor.name}.${propertyKey} not found`);
                }
                // @ts-ignore
                this.__components[key] = component;
                return component;
            };
            Object.defineProperty(target, propertyKey, {
                get: getter,
                set: function(value) { }
            });

        };
    }
}

