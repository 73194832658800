namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class ActualCostsGraphDataPeriod extends ABModel {

        public period: Period;
        public totalAmountCumulative: number|null;
        public totalAmountCumulativeForecast: number|null;
        public totalBudget: number;
        public totalBaselineAmountCumulative: number;
        public totalAmountCumulativeForecastBaseline: number;
    
        constructor(
            period: Period,
            totalAmountCumulative: number|null,
            totalAmountCumulativeForecast: number|null,
            totalBudget: number,
            totalBaselineAmountCumulative: number,
            totalAmountCumulativeForecastBaseline: number,
        ) {
            super();
            this.period = period;
            this.totalAmountCumulative = totalAmountCumulative;
            this.totalAmountCumulativeForecast = totalAmountCumulativeForecast;
            this.totalBudget = totalBudget;
            this.totalBaselineAmountCumulative = totalBaselineAmountCumulative;
            this.totalAmountCumulativeForecastBaseline = totalAmountCumulativeForecastBaseline;
        }
    }
}