namespace Simplex.WebComponents.Project {

        import ABModel = Ambrero.AB.Models.ABModel;

        export class ProjectListViewFilter extends ABModel {

        public deactivated: boolean;

        constructor(deactivated: boolean = false) {
            super();
            this.deactivated = deactivated;
        }
    }
}