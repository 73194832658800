namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class ScopesData extends ABModel {

        public projectId: string;
        public projectCurrency: string;
        public snapshotName: string | null = null;
        public snapshotCreated: string | null = null;
        public startDate: string | null = null;
        public endDate: string | null = null;
        public multipleYears: boolean = false;
        public scopes: Scope[] = [];

        public totalBudget : number | null; 
        public totalBudgetSnapshot : number | null; 
        public totalBudgetChanged : boolean; 
        public totalExpectedCosts : number | null; 
        public totalExpectedCostsSnapshot : number | null; 
        public totalExpectedCostsChanged : boolean; 
        public totalExpense : number | null; 
        public totalExpenseSnapshot : number | null; 
        public totalExpenseChanged : boolean; 
        public totalActual : number | null; 
        public totalActualSnapshot : number | null; 
        public totalActualChanged : boolean; 
        public totalPrognose : number | null; 
        public totalPrognoseSnapshot : number | null; 
        public totalPrognoseChanged : boolean; 
        public totalResult : number | null; 
        public totalResultSnapshot : number | null;
        public totalResultChanged: boolean; 

        constructor(
            projectId: string,
            projectCurrency: string, 
            snapshotName: string | null, 
            snapshotCreated: string | null,
            startDate: string | null,
            endDate: string | null,
            multipleYears: boolean,
            scopes: Scope[],
            totalBudget : number | null,
            totalBudgetSnapshot : number | null,
            totalBudgetChanged : boolean,
            totalExpectedCosts : number | null,
            totalExpectedCostsSnapshot : number | null,
            totalExpectedCostsChanged : boolean,
            totalExpense : number | null,
            totalExpenseSnapshot : number | null,
            totalExpenseChanged : boolean,
            totalActual : number | null,
            totalActualSnapshot : number | null,
            totalActualChanged : boolean,
            totalPrognose : number | null,
            totalPrognoseSnapshot : number | null,
            totalPrognoseChanged : boolean,
            totalResult : number | null,
            totalResultSnapshot : number | null,
            totalResultChanged: boolean) {
            super();
            this.projectId = projectId;
            this.projectCurrency = projectCurrency;
            this.snapshotName = snapshotName;
            this.snapshotCreated = snapshotCreated;
            this.startDate = startDate;
            this.endDate = endDate;
            this.multipleYears = multipleYears;
            this.scopes = scopes;
            this.totalBudget = totalBudget;
            this.totalBudgetSnapshot = totalBudgetSnapshot;
            this.totalBudgetChanged = totalBudgetChanged;
            this.totalExpectedCosts = totalExpectedCosts;
            this.totalExpectedCostsSnapshot = totalExpectedCostsSnapshot;
            this.totalExpectedCostsChanged = totalExpectedCostsChanged;
            this.totalExpense = totalExpense;
            this.totalExpenseSnapshot = totalExpenseSnapshot;
            this.totalExpenseChanged = totalExpenseChanged;
            this.totalActual = totalActual;
            this.totalActualSnapshot = totalActualSnapshot;
            this.totalActualChanged = totalActualChanged;
            this.totalPrognose = totalPrognose;
            this.totalPrognoseSnapshot = totalPrognoseSnapshot;
            this.totalPrognoseChanged = totalPrognoseChanged;
            this.totalResult = totalResult;
            this.totalResultSnapshot = totalResultSnapshot;
            this.totalResultChanged = totalResultChanged;
        }
    }
}