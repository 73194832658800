namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class CreateFinanceEntryScope extends ABModel {
        public scopeId: string | null = null;
        public amountEx: number | null = null;
    }
    
    
    export class CreateFinanceEntry extends ABModel {

        public type: "Actual" | "Expense" = "Actual";
        public reference: string | null = null;
        public poNumber: string | null = null;
        public bookingCode: string | null = null;
        public date: string | null = null;
        public supplier: string | null = null;

        public scopes: CreateFinanceEntryScope[] = [];

        public costDescription: string | null = null;

        public costCategoryId: string | null = null;

        public amountEx: number | null = null;

        public description: string | null = null;
        public log: string | null = null;

     
    }
}