namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class CreateProjectResponse extends ABModel {

        public id: string;

        constructor(id: string = "") {
            super();
            this.id = id;
        }

    }
}