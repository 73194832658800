namespace Simplex.WebComponents.Project.DashboardProjectSnapshot.Models {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class SnapshotData extends ABModel {

        public projectId: string;
        public created: string | null = null;

        constructor(projectId: string, created: string | null
        ) {
            super();
            this.projectId = projectId;
            this.created = created;
        }
    }
}