namespace Simplex.Controllers {

    import Content = Simplex.Components.Content;
    import FormFields = Simplex.Components.FormFields;
    import Auth = Simplex.Components.Auth;
    import Path = Simplex.Decorators.Path;
    import Get = Simplex.Decorators.Get;
    import Query = Simplex.Decorators.Query;
    import Component = Simplex.Decorators.Component;
    import ValueType = Simplex.Components.ValueType;
    import Layout = Simplex.Components.Layout;
    import Inject = Simplex.Decorators.Inject;

    interface IndexContext {
    }

    export class Error extends Ambrero.AB.Controllers.Controller {

        @Inject("Layout")
        private layout!: Layout

        @Get("/error/notfound")
        public NotFound(): void {
            const content = this.app.getComponent('Content', 'Error/notFound') as Content<IndexContext>;
            content.setTargetContainerElement(this.layout.getContentRenderTarget());
            content.setContext({} as IndexContext);
            content.draw();
        }

        @Get("/error/badrequest")
        public BadRequest(): void {
            const content = this.app.getComponent('Content', 'Error/badRequest') as Content<IndexContext>;
            content.setTargetContainerElement(this.layout.getContentRenderTarget());
            content.setContext({} as IndexContext);
            content.draw();
        }
        @Get("/error/unauthorized")
        public NotAuthorized(): void {
            const content = this.app.getComponent('Content', 'Error/unauthorized') as Content<IndexContext>;
            content.setTargetContainerElement(this.layout.getContentRenderTarget());
            content.setContext({} as IndexContext);
            content.draw();
        }
        @Get("/error/unauthenticated")
        public NotAuthenticated(): void {
            const content = this.app.getComponent('Content', 'Error/unauthenticated') as Content<IndexContext>;
            content.setTargetContainerElement(this.layout.getContentRenderTarget());
            content.setContext({} as IndexContext);
            content.draw();
        }


    }
}