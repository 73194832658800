namespace Simplex.WebComponents {
  import WebComponent = Simplex.Decorators.WebComponent;
  import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
  import ABWebComponent = Simplex.Components.ABWebComponent;
  import APIResult = Simplex.Utils.APIResult;
  import InputCurrency = Simplex.WebComponents.FormElements.InputCurrency;
  import BudgetCellType = Simplex.Models.Project.BudgetCellType;

  @WebComponent("ui-budget-cell")
  export class BudgetCell extends ABWebComponent {
    private readonly contentTemplate;
    private _rendered: boolean = false;
    private readonly _projectId: string;
    private _currencyInput?: InputCurrency;
    private readonly _budget: Models.Project.Budget;
    private readonly cellType: BudgetCellType;
    private readonly _scope: Models.Project.Scope;
    private readonly _costCategorySummary: Models.Project.CostCategorySummary | null;
    private readonly _updateModel: Models.Project.ScopeBudgetUpdate;
    private _editState: boolean;
    private _notice: any;

    public constructor(
      projectId: string,
      costCategorySummary: Models.Project.CostCategorySummary | null,
      scope: Models.Project.Scope,
      cellType: BudgetCellType
    ) {
      super();
      this.contentTemplate = this.app.getTemplate(
        "WebComponents/Project/Budget/BudgetTable",
        "BudgetCell"
      ) as TemplateCallback;
      this._notice = this.app.getComponent("AmbreroComponents.Notice");
      this.cellType = cellType;
      this._scope = scope;
      this._projectId = projectId;
      this._costCategorySummary = costCategorySummary;
      this._budget = this.getBudget();
      this._budget.hasAmount =
        this._budget.amount !== null ||
        this._budget.snapshotAmount !== null ||
        this._budget.amountSum !== null ||
        this._budget.snapshotAmountSum !== null;
      this._updateModel = new Simplex.Models.Project.ScopeBudgetUpdate(
        this._budget.amount
      );
      this._editState = false;
    }

    set edit(state: boolean) {
      if (state) {
        this.classList.add("is--editing");
      } else {
        this.classList.remove("is--editing");
      }
      this._editState = state;
    }

    get edit(): boolean {
      return this._editState;
    }

    private closeEdit = () => {
      this.edit = false;
      const input = this.querySelector(
        "ui-input-currency input"
      ) as HTMLInputElement;
      if (input) input.removeEventListener("keyup", this.onKeyUp);
      document.removeEventListener("click", this.onClickOutside);
    };

    private onKeyUp = async (event: KeyboardEvent): Promise<void> => {
      if (event.code === "Escape" || !this._currencyInput) {
        this.closeEdit();
        return;
      }
      if (
        this._currencyInput &&
        (event.code === "Enter" || event.code === "NumpadEnter")
      ) {
        Budget.saveOpenAndCloseState();

        this.classList.add("is--saving");
        this._updateModel.budget = this._currencyInput.value;
        const saveResponse = await this.request.put<APIResult<void>>(
          `/api/project/${this._projectId}/scopes/${this._scope.id}/${
            this._budget.budgetCellType
          }/${this._costCategorySummary?.id ?? Simplex.Utils.GUID_EMPTY}`,
          this._updateModel
        );
        this.classList.remove("is--saving");
        if (!saveResponse.isSuccess) {
          this._notice.addMessage(
            Messages("project.budget.table.update.failed"),
            "warning"
          );
          return;
        }
        this.dispatchEvent(
          new Event("scopeCostCategoryChange", { bubbles: true })
        );
      }
    };

    private onClickOutside = (event: Event) => {
      if (event.target) {
        const targetElement = event.target as HTMLElement;
        const clickedBudgetCell = targetElement.closest(
          "ui-budget-cell"
        ) as BudgetCell;
        if (
          !clickedBudgetCell ||
          (clickedBudgetCell &&
            clickedBudgetCell._budget.costCategoryId !==
              this._budget.costCategoryId)
        ) {
          this.closeEdit();
        }
      }
    };

    private onClick = (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      if (!this.edit && this._budget.editable) {
        this.edit = true;
        if (this._currencyInput) {
          const input = this._currencyInput.querySelector(
            "input"
          ) as HTMLInputElement;
          input.addEventListener("keyup", this.onKeyUp);
          input.focus();
        }
        document.addEventListener("click", this.onClickOutside);
      }
      if (
        this.cellType === BudgetCellType.ActualCosts ||
        this.cellType === BudgetCellType.Expense
      ) {
        this.onDoubleClick(event);
      }
    };
    private onDoubleClick = (event: Event) => {
      event.preventDefault();
      event.stopPropagation();

      const isEmpty = (event.currentTarget as HTMLElement).querySelector(
        ".empty-cell"
      );
      if (isEmpty) {
        localStorage.setItem(
          "presetNewActuals",
          JSON.stringify({
            costCategory: this._costCategorySummary?.id,
            scope: this._scope?.id,
          })
        );
        this.dispatchEvent(
          new CustomEvent("cellDoubleClicked", { bubbles: true })
        );
      }
    };

    render() {
      this.innerHTML = this.contentTemplate({
        type: this.cellType,
        scope: this._scope,
        budget: this._budget,
      });
      if (
        this.cellType === BudgetCellType.ExpectedCosts ||
        this.cellType === BudgetCellType.Expense ||
        this.cellType === BudgetCellType.ActualCosts
      ) {
        this.classList.add("is--collapsible");
      }
      this._currencyInput = this.querySelector(
        "ui-input-currency"
      ) as InputCurrency;
      const budgetElement = this.querySelector(
        ".scopebudget__budget"
      ) as HTMLElement;
      if (budgetElement) {
        this.addEventListener("click", this.onClick);
      }
      if (
        this.cellType === BudgetCellType.ActualCosts ||
        this.cellType === BudgetCellType.Expense
      ) {
        this.addEventListener("dblclick", this.onDoubleClick);
      }

      this._rendered = true;
    }

    connectedCallback() {
      if (!this.isConnected) {
        return;
      }
      if (!this._rendered) {
        this.render();
      }
    }

    public getBudget(): Simplex.Models.Project.Budget {
      switch (this.cellType) {
        case BudgetCellType.Budget:
          return this.getBudgetForBudget(this._scope);
        case BudgetCellType.Prognose:
          return this.getBudgetForPrognose(this._scope);
        case BudgetCellType.ActualCosts:
          return this.getBudgetForActualCosts(this._scope);
        case BudgetCellType.Expense:
          return this.getBudgetForExpense(this._scope);
        case BudgetCellType.ExpectedCosts:
          return this.getBudgetForExpectedCosts(this._scope);
        case BudgetCellType.Result:
          return this.getBudgetForResult(this._scope);
        default:
          throw new Error("Unknown budget cell type");
      }
    }

    private getBudgetForBudget(
      scope: Simplex.Models.Project.Scope
    ): Simplex.Models.Project.Budget {
      const budget = new Simplex.Models.Project.Budget(BudgetCellType.Budget);

      if (this._costCategorySummary === null) {
        budget.amount = scope.budget;
        budget.snapshotAmount = scope.snapshotBudget;
        budget.budgetBreakdown = scope.budgetBreakdown;
        budget.budgetChanged = scope.budgetChanged;
        budget.amountSum = scope.budget;
        budget.snapshotAmountSum = scope.snapshotBudget;
        budget.isUnspecified = true;
        budget.hasSpecifiedChildSum = scope.budgets.length > 0;
        budget.editable =
          !scope.hasChildScopesWithBudgetDefined &&
          scope.budgets.filter(
            (b) => b.costCategoryId !== Simplex.Utils.GUID_EMPTY
          ).length === 0;

        return budget;
      } else {
        const costCategoryId = this._costCategorySummary.id;
        const budgetForCostCategory = scope.budgets.find(
          (b) => b.costCategoryId === costCategoryId
        );
        if (budgetForCostCategory) {
          budget.amount = budgetForCostCategory.amount;
          budget.snapshotAmount = budgetForCostCategory.snapshotAmount;
          budget.budgetBreakdown = true;
          budget.budgetChanged = budgetForCostCategory.budgetChanged;
          budget.amountSum = budgetForCostCategory.amountSum;
          budget.snapshotAmountSum = budgetForCostCategory.amountSumSnapshot;
          budget.isUnspecified = budgetForCostCategory.isUnspecified;
          budget.hasSpecifiedChildSum =
            budgetForCostCategory.hasSpecifiedChildSum;
        }
        budget.editable = !scope.hasChildScopesWithBudgetDefined;
        return budget;
      }
    }

    private getBudgetForPrognose(
      scope: Simplex.Models.Project.Scope
    ): Simplex.Models.Project.Budget {
      const actualBudget = this.getBudgetForActualCosts(scope);
      const expenseBudget = this.getBudgetForExpense(scope);
      const expectedCostsBudget = this.getBudgetForExpectedCosts(scope);
      const budget = new Simplex.Models.Project.Budget(BudgetCellType.Prognose);
      budget.editable = false;
      budget.amount =
        actualBudget.amount !== null ||
        expenseBudget.amount !== null ||
        expectedCostsBudget.amount !== null
          ? (actualBudget.amount ?? 0) +
            (expenseBudget.amount ?? 0) +
            (expectedCostsBudget.amount ?? 0)
          : null;
      budget.budgetBreakdown = true;
      budget.amountSum =
        actualBudget.amountSum !== null ||
        expenseBudget.amountSum !== null ||
        expectedCostsBudget.amountSum !== null
          ? (actualBudget.amountSum ?? 0) +
            (expenseBudget.amountSum ?? 0) +
            (expectedCostsBudget.amountSum ?? 0)
          : null;
      budget.snapshotAmountSum =
        actualBudget.snapshotAmountSum !== null ||
        expenseBudget.snapshotAmountSum !== null ||
        expectedCostsBudget.snapshotAmountSum !== null
          ? (actualBudget.snapshotAmountSum ?? 0) +
            (expenseBudget.snapshotAmountSum ?? 0) +
            (expectedCostsBudget.snapshotAmountSum ?? 0)
          : null;
      budget.isUnspecified =
        actualBudget.isUnspecified ||
        expenseBudget.isUnspecified ||
        expectedCostsBudget.isUnspecified;
      budget.hasSpecifiedChildSum =
        actualBudget.hasSpecifiedChildSum ||
        expenseBudget.hasSpecifiedChildSum ||
        expectedCostsBudget.hasSpecifiedChildSum;
      return budget;
    }

    private getBudgetForActualCosts(
      scope: Simplex.Models.Project.Scope
    ): Simplex.Models.Project.Budget {
      const budget = new Simplex.Models.Project.Budget(
        BudgetCellType.ActualCosts
      );
      budget.editable = false;
      if (this._costCategorySummary === null) {
        const amounts = scope.actualPerCostCategory.filter((a) => a.amount);
        const snapshotAmounts = scope.actualPerCostCategory.filter(
          (a) => a.snapshotAmount
        );
        budget.amount =
          amounts.length > 0
            ? amounts.reduce((a, b) => a + (b.amount ?? 0), 0)
            : null;
        budget.snapshotAmount =
          snapshotAmounts.length > 0
            ? snapshotAmounts.reduce((a, b) => a + (b.snapshotAmount ?? 0), 0)
            : null;
        budget.budgetBreakdown = true;
        budget.budgetChanged = scope.actualChanged;
        budget.amountSum =
          amounts.length > 0
            ? amounts.reduce((a, b) => a + (b.amount ?? 0), 0)
            : null;
        budget.snapshotAmountSum =
          snapshotAmounts.length > 0
            ? snapshotAmounts.reduce((a, b) => a + (b.snapshotAmount ?? 0), 0)
            : null;
        budget.isUnspecified = true;
        budget.hasSpecifiedChildSum = scope.actualPerCostCategory.length > 0;

        return budget;
      } else {
        const costCategoryId = this._costCategorySummary.id;
        const actualPerCostCategory = scope.actualPerCostCategory.find(
          (b) => b.costCategoryId === costCategoryId
        );
        if (actualPerCostCategory) {
          budget.amount = actualPerCostCategory.amount;
          budget.snapshotAmount = actualPerCostCategory.snapshotAmount;
          budget.budgetBreakdown = true;
          budget.budgetChanged = actualPerCostCategory.budgetChanged;
          budget.amountSum = actualPerCostCategory.amountSum;
          budget.snapshotAmountSum = actualPerCostCategory.amountSumSnapshot;
          budget.isUnspecified = actualPerCostCategory.isUnspecified;
          budget.hasSpecifiedChildSum =
            actualPerCostCategory.hasSpecifiedChildSum;
        }
        return budget;
      }
    }

    private getBudgetForExpense(
      scope: Simplex.Models.Project.Scope
    ): Simplex.Models.Project.Budget {
      const budget = new Simplex.Models.Project.Budget(BudgetCellType.Expense);
      budget.editable = false;
      if (this._costCategorySummary === null) {
        const amounts = scope.expensePerCostCategory.filter((a) => a.amount);
        const snapshotAmounts = scope.expensePerCostCategory.filter(
          (a) => a.snapshotAmount
        );
        budget.amount =
          amounts.length > 0
            ? amounts.reduce((a, b) => a + (b.amount ?? 0), 0)
            : null;
        budget.snapshotAmount =
          snapshotAmounts.length > 0
            ? snapshotAmounts.reduce((a, b) => a + (b.snapshotAmount ?? 0), 0)
            : null;
        budget.budgetBreakdown = true;
        budget.budgetChanged = scope.expenseChanged;
        budget.amountSum =
          amounts.length > 0
            ? amounts.reduce((a, b) => a + (b.amount ?? 0), 0)
            : null;
        budget.snapshotAmountSum =
          snapshotAmounts.length > 0
            ? snapshotAmounts.reduce((a, b) => a + (b.snapshotAmount ?? 0), 0)
            : null;
        budget.isUnspecified = true;
        budget.hasSpecifiedChildSum = scope.expensePerCostCategory.length > 0;
        return budget;
      } else {
        const costCategoryId = this._costCategorySummary.id;
        const expensePerCostCategory = scope.expensePerCostCategory.find(
          (b) => b.costCategoryId === costCategoryId
        );
        if (expensePerCostCategory) {
          budget.amount = expensePerCostCategory.amount;
          budget.snapshotAmount = expensePerCostCategory.snapshotAmount;
          budget.budgetBreakdown = true;
          budget.budgetChanged = expensePerCostCategory.budgetChanged;
          budget.amountSum = expensePerCostCategory.amountSum;
          budget.snapshotAmountSum = expensePerCostCategory.amountSumSnapshot;
          budget.isUnspecified = expensePerCostCategory.isUnspecified;
          budget.hasSpecifiedChildSum =
            expensePerCostCategory.hasSpecifiedChildSum;
        }
        return budget;
      }
    }

    private getBudgetForExpectedCosts(
      scope: Simplex.Models.Project.Scope
    ): Simplex.Models.Project.Budget {
      const budget = new Simplex.Models.Project.Budget(
        BudgetCellType.ExpectedCosts
      );

      if (this._costCategorySummary === null) {
        const amounts = scope.expectedCostsPerCostCategory.filter(
          (a) => a.amount
        );
        const snapshotAmounts = scope.expectedCostsPerCostCategory.filter(
          (a) => a.snapshotAmount
        );
        budget.amount =
          amounts.length > 0
            ? amounts.reduce((a, b) => a + (b.amount ?? 0), 0)
            : null;
        budget.snapshotAmount =
          snapshotAmounts.length > 0
            ? snapshotAmounts.reduce((a, b) => a + (b.snapshotAmount ?? 0), 0)
            : null;
        budget.budgetBreakdown = scope.expectedCostsBreakdown;
        budget.budgetChanged = scope.expectedCostsChanged;
        budget.amountSum =
          amounts.length > 0
            ? amounts.reduce((a, b) => a + (b.amount ?? 0), 0)
            : null;
        budget.snapshotAmountSum =
          snapshotAmounts.length > 0
            ? snapshotAmounts.reduce((a, b) => a + (b.snapshotAmount ?? 0), 0)
            : null;
        budget.isUnspecified = true;
        budget.hasSpecifiedChildSum =
          scope.expectedCostsPerCostCategory.length > 0;
        budget.editable =
          !scope.hasChildScopesWithExpectedCostsDefined &&
          scope.expectedCostsPerCostCategory.filter(
            (b) => b.costCategoryId !== Simplex.Utils.GUID_EMPTY
          ).length === 0;

        return budget;
      } else {
        const costCategoryId = this._costCategorySummary.id;
        const expectedCostsPerCostCategory =
          scope.expectedCostsPerCostCategory.find(
            (b) => b.costCategoryId === costCategoryId
          );
        if (expectedCostsPerCostCategory) {
          budget.amount = expectedCostsPerCostCategory.amount;
          budget.snapshotAmount = expectedCostsPerCostCategory.snapshotAmount;
          budget.budgetBreakdown = true;
          budget.budgetChanged = expectedCostsPerCostCategory.budgetChanged;
          budget.amountSum = expectedCostsPerCostCategory.amountSum;
          budget.snapshotAmountSum =
            expectedCostsPerCostCategory.amountSumSnapshot;
          budget.isUnspecified = expectedCostsPerCostCategory.isUnspecified;
          budget.hasSpecifiedChildSum =
            expectedCostsPerCostCategory.hasSpecifiedChildSum;
        }
        budget.editable = !scope.hasChildScopesWithExpectedCostsDefined;

        return budget;
      }
    }

    private getBudgetForResult(
      scope: Simplex.Models.Project.Scope
    ): Simplex.Models.Project.Budget {
      const budgetBudget = this.getBudgetForBudget(scope);
      const prognoseBudget = this.getBudgetForPrognose(scope);
      const budget = new Simplex.Models.Project.Budget(BudgetCellType.Result);
      budget.editable = false;
      budget.amountSum =
        prognoseBudget.amountSum !== null || budgetBudget.amountSum !== null
          ? (budgetBudget.amountSum ?? 0) - (prognoseBudget.amountSum ?? 0)
          : null;
      //HERE!!CHANGE!
      budget.snapshotAmountSum =
        prognoseBudget.snapshotAmountSum !== null ||
        budgetBudget.snapshotAmountSum !== null
          ? (budgetBudget.amountSum ?? 0) -
            (prognoseBudget.snapshotAmountSum ?? 0)
          : null;
      budget.hasSpecifiedChildSum =
        prognoseBudget.hasSpecifiedChildSum ||
        budgetBudget.hasSpecifiedChildSum;
      budget.budgetBreakdown = true;
      budget.isUnspecified =
        prognoseBudget.isUnspecified || budgetBudget.isUnspecified;
      if (!budget.isUnspecified) {
        budget.amount = budget.amountSum;
      }

      return budget;
    }
  }
}
