namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;
    import IBoundModel = Ambrero.AB.Models.IBoundModel;

    export class ProjectEdit extends ABModel {

        public name: string;
        public number: string|null;
        public currency: string|null;
        public startDate: string|null;
        public endDate: string|null;
        public budget: number|null;
        public users: ProjectUser[];

        
        constructor(name: string = "", startDate: string|null = null, endDate: string|null = null, number: string|null = null, currency: string|null = null, budget: number|null = null, users: Simplex.Models.Project.ProjectUser[] = []) {
            super();
            this.name = name;
            this.startDate = startDate;
            this.endDate = endDate;
            this.number = number;
            this.currency = currency;
            this.budget = budget;
            this.users = users;
        }

        public validateDates = ():boolean => {
            if (this.endDate === null && this.startDate !== null) {
                return false;
            }
            return !(this.startDate === null && this.endDate !== null);
            
        }
        
        validateField = (fieldName: string, element: HTMLElement, value: string, boundModel: IBoundModel): string | undefined => {
            
            //@TODO
            switch(fieldName) {
                case "startDate":
                    if (value === "" || value === null) {
                        if (this.endDate === null) {
                            boundModel.clearFieldError("endDate");
                        }
                    } else {
                        if (this.endDate !== null) {
                            const tmpEnd = moment(this.endDate, 'DD-MM-YYYY');
                            const tmpStart = moment(value, 'DD-MM-YYYY');
                            if (tmpEnd.isBefore(tmpStart)) {
                                return "error.date.before.start";
                            }
                        }

                    }
                    break;
                case "endDate":
                    if (value === "" || value === null) {
                        if (this.startDate === null) {
                            boundModel.clearFieldError("startDate");
                        }
                    } else {
                        if (this.startDate !== null) {
                            const tmpEnd = moment(value, 'DD-MM-YYYY');
                            const tmpStart = moment(this.startDate, 'DD-MM-YYYY');
                            if (tmpEnd.isBefore(tmpStart)) {
                                return "error.date.before.end";
                            }
                        }
                    }
                    break;
            }
            
            return;
        }

        public convertField = (fieldName: string, direction: "up" | "down", value: any): any => {
            switch (fieldName) {
                case "startDate":
                case "endDate":
                    const formattingHelper = Ambrero.AB.Application.Instance!.getSystemComponent('FormattingHelper') as Simplex.Components.FormattingHelper;
                    switch (direction) {
                        case "up": //get
                            if (value !== null && value !== "") {
                                value = moment(value, 'YYYY-MM-DD').format(formattingHelper.dateFormat)
                            }
                            break;
                        case "down": //set
                            if (value === "" || value === null) {
                                value = null;
                            } else {
                                value = moment(value, formattingHelper.dateFormat).format('YYYY-MM-DD');
                            }
                            break;
                    }
                    return value;
                case "budget":
                    if (direction === "down") {
                        value = value !== null && value !== "" ? parseFloat(value) : null;
                    }
            }
            return value;
        }
    }
}