/* COPYRIGHT (C) Ambrero Software B.V. - All rights reserved
 * This file is part of the Ambrero Base Framework and supplied under license.
 * Unauthorized copying of this file is strictly prohibited.
 * It is permitted to make changes to this file, as long as it is only used in this project.
 */
/// <reference path="../Decorators/Decorators.d.ts" />

namespace Simplex.Components {
    import Application = Ambrero.AB.Application;
    import RequestHandler = Ambrero.AB.Components.RequestHandler;

    @Simplex.Decorators.Singleton(0)
    export class RequestError {
        private readonly app: Application;
        private readonly notice: any;

        public constructor(app: Application) {
            this.app = app;
            this.notice = this.app.getComponent('AmbreroComponents.Notice');
            this.app.getEventBus().on('reset', this.subscribeEventBus);
            this.subscribeEventBus();
        }

        private readonly handleRequestError = (requestHandler: RequestHandler, eventArgs: any): void => {
            eventArgs.handled = true;
            
            const errorEventArgs = eventArgs as ErrorEventArgs;
            const resolvedMessage = Simplex.Utils.resolveMessageFromAjaxResponse(errorEventArgs.source.xhr, errorEventArgs.source.handledFieldError);
            if (resolvedMessage !== null) {
                this.notice.addMessage(resolvedMessage, 'warning');
            }
        }

        private readonly subscribeEventBus = (): void => {
            this.app.getEventBus().subscribe('requestError', this.handleRequestError.bind(this), this);
        }
    }

    interface ErrorEventArgs {
        source: {
            xhr: XMLHttpRequest,
            handledFieldError: boolean
            message: string
            type: string
        },
        data: any,
        global: boolean,
        handled: boolean,
    }
}