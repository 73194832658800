namespace Simplex.WebComponents.Project {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import Scope = Simplex.Models.Project.Scope;
    import APIResult = Simplex.Utils.APIResult;
    
    @WebComponent("ui-scope-row")
    export class ScopeRow extends ABWebComponent {

        private readonly notice: any;
        private readonly contentTemplate;
        private _rendered: boolean = false;
        private readonly _isOdd: boolean = false;
        private readonly _interval: string;
        private readonly _intervalList: any[];
        private readonly _totalDaySpan: number;
        private _intervalWidth: number = 100;
        private _tickWidth: number = 3;
        private readonly _projectStartDate: string;
        private readonly _projectId?: string;
        private readonly _scope: Simplex.Models.Project.Scope;
        private _mousePosition: any;
        private readonly _linePosition: any;
        private _bar!: HTMLElement;
        private _dateRangeStart: HTMLElement;
        private _dateRangeEnd: HTMLElement;
        private _grid: CalenderGrid;
        private _lineElement: HTMLElement;
        private _leftEdgeElement!: HTMLElement;
        private _rightEdgeElement!: HTMLElement;
        private _relationStartElement!: HTMLElement;
        private _dragDate!: string;
        private _dragFromEnd: boolean;
        private _originalBarWidth!: number;
        private _originalBarLeft!: number;
        private _lastResizeSnap: number = 0;
        private _selectState: boolean = false;
        private _lineTimeout: number = 0;
        private _depth: number = 0;
        private _dategrid!: DateGrid;
        
        get scope():Scope {
            return this._scope;
        }
        set color(color:string) {
            this.classList.add(`color--${color}`);
        };
        
        set edit(state:boolean) {
            if(state){
                this.classList.add("is--edit");
            } else {
                this.classList.remove("is--edit");
            }
        }
        get edit() {
            return this.classList.contains("is--edit");
        }
        
        set add(state:boolean) {
            if(state){
                this.classList.add("is--add");
            } else {
                this.classList.remove("is--add");
            }
        }
        get add() {
            return this.classList.contains("is--add");
        }
        
        set select(state:boolean) {
            if(state){
                this._bar.classList.add("is--selected");
            } else {
                this._bar.classList.remove("is--selected");
            }
            this._selectState = state;
        }
        
        get select():boolean {
            return this._selectState;
        }
        set visibility(state:boolean) {
            if(state){
                this.classList.add("is--open");
            } else {
                this.classList.remove("is--open");
            }
        }
        get visibility() {
            return this.classList.contains("is--open");
        }
        
        public constructor(projectId:string, scope:Simplex.Models.Project.Scope, isOdd: boolean, totalDaySpan: number, projectStartDate: string, interval: string, intervalList: [], maxScopeDepth: number = 1) {
            super();
            this._projectId = projectId;
            this._scope = scope;
            this._depth = scope.depth;
            this._isOdd = isOdd;
            this._interval = interval;
            this._intervalList = intervalList;
            this._totalDaySpan = totalDaySpan;
            this._projectStartDate = projectStartDate;
            this._dragFromEnd = false;
            this._mousePosition = { top: 0, left: 0, x: 0, y: 0 };
            this._linePosition = { startX: 0, startY: 0, endX: 0, endY: 0, height: '1px'};
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/ScopeRow', 'ScopeRow') as TemplateCallback;
            this._dateRangeStart = document.querySelector('.JSdaterangeStart') as HTMLElement;
            this._dateRangeEnd = document.querySelector('.JSdaterangeEnd') as HTMLElement;
            this._grid = document.querySelector('ui-calender-grid') as CalenderGrid;
            this._dategrid = document.querySelector('ui-date-grid') as DateGrid;
            this._lineElement = document.querySelector('.JSline') as HTMLElement;
            this.notice = this.app.getComponent('AmbreroComponents.Notice');
            //this.visibility = scope.depth < maxScopeDepth && scope.hasChildren;
        }
        
        private generateBaseline = (): void => {
            if(this._scope.startDate !== null || this._scope.snapshotStartDate !== null) {
                const baseline = this.querySelector('.baseline') as HTMLElement;
                let start = this._scope.snapshotStartDate;
                if(!start) {
                    start = this._scope.startDate;
                }
                let end = this._scope.snapshotEndDate;
                if(!end) {
                    end = this._scope.endDate;
                }
                if(start && end && baseline) {
                    this.calculateSizeAndPosition(baseline, start, end);
                }
            }
        };
        
        private generateBar = (): void => {
            if(this._bar) {
                if(this._scope.startDate && this._scope.endDate) {
                    this.calculateSizeAndPosition(this._bar, this._scope.startDate, this._scope.endDate);
                } else {
                    this._bar.style.width = `0px`;
                }
            }
        };

        private calculateSizeAndPosition = (el: HTMLElement, startDate: string, endDate: string) => {
            if(!el) {
                return;
            }
            const start = moment(startDate);
            const end = moment(endDate);
            const lengthInDays = end.diff(start, 'days');
            const startDaysOffset = start.diff(moment(this._projectStartDate), 'days');
            const firstDate = this._grid.querySelector('.date.first') as HTMLElement;
            this._intervalWidth = firstDate.getBoundingClientRect().width;
            // subtract the extra month from the total dayspan
            let actualBarWidthPercentage = 0;
            switch(this._interval) {
                case 'Day':
                    actualBarWidthPercentage = (lengthInDays / (this._totalDaySpan - 1) * 100);
                    break;
                case 'Week':
                    actualBarWidthPercentage = (lengthInDays / (this._totalDaySpan - 7) * 100);
                    break;
                case 'Month':
                    actualBarWidthPercentage = (lengthInDays / (this._totalDaySpan - end.daysInMonth()) * 100);
                    break;
                case 'Quarter':
                    let quarterStart = end.clone().startOf('quarter');
                    let quarterEnd = end.clone().endOf('quarter');
                    actualBarWidthPercentage = (lengthInDays / (this._totalDaySpan - quarterEnd.diff(quarterStart,'days')) * 100);
                    break;
                case 'Year':
                    let yearStart = end.clone().startOf('year');
                    let yearEnd = end.clone().endOf('year');
                    actualBarWidthPercentage = (lengthInDays / (this._totalDaySpan - yearEnd.diff(yearStart,'days')) * 100);
                    break;
            }
            
            let adjustedBarWidthPercentage = actualBarWidthPercentage * ((this._grid.scrollWidth - (this._intervalWidth * 2)) / this._grid.scrollWidth);
            el.style.width = `${adjustedBarWidthPercentage}%`;
            el.style.left = `${(this._grid.scrollWidth - this._intervalWidth) * (startDaysOffset / this._totalDaySpan) + (this._intervalWidth / 2)}px`;
        }

        public removeHighlight = () => {
            this.classList.remove('is--highlighted');
        }
        public showHighlight = () => {
            this.classList.add('is--highlighted');
        }
        
        public updateReference = (distance: number, direction: string, isConnectedAtBeginning: boolean, isStart: boolean) => {
            
            if(isConnectedAtBeginning) {
                this._leftEdgeElement.classList.add(`is--connected`,`connectionlength--${distance}`,`direction--${direction}`,isStart ? 'is--startpoint': 'is--endpoint');
            } else {
                this._rightEdgeElement.classList.add(`is--connected`,`connectionlength--${distance}`,`direction--${direction}`,isStart ? 'is--startpoint': 'is--endpoint');
            }
        }
        
        private getIntervalSnapValue = (): string => {
            switch (this._interval) {
                default:
                case 'Day':
                case 'Week':
                case 'Months':
                    return 'days';
                case 'Quarter':
                case 'Year':
                    return 'weeks';
            }
        }
        
        private onBarEdgePointerDown = (event: PointerEvent): void => {
            if(this.select) {
                this.onResizeBar(event);
                return;
            }
            this.onDrawRelation(event);
        }
        
        private onDrawRelation = (event: PointerEvent) => {
            event.stopPropagation();
            event.preventDefault();
            this._grid.classList.add('is--creating-relations');
            const allBars = this._grid.querySelectorAll('.bar, .baseline');
            allBars.forEach(element => {
                let bar = element as HTMLElement;
                bar.classList.remove('is--connecting','is--rejected');
            });
            const gridOffset = this._grid.getBoundingClientRect();
            this._linePosition.startX = event.clientX - gridOffset.left + this._grid.scrollLeft;
            this._linePosition.startY = event.clientY - gridOffset.top;
            this._relationStartElement = event.target as HTMLElement;
            this._grid.addEventListener('pointermove', this.pointerMoveDrawHandler);
            this._grid.addEventListener('pointerup', this.pointerUpDrawHandler, {once: true});
        }
        
        private onRejectRelation = (element: HTMLElement) => {
            const bar = element.closest('.bar') as HTMLElement;
            if(bar) {
                const baseline = bar.nextElementSibling as HTMLElement;
                bar.classList.remove('is--connecting');
                bar.classList.add('is--rejected');
                if(baseline) {
                    baseline.classList.add('is--rejected');
                }
            }
        }
        
        private pointerUpDrawHandler = (event: PointerEvent): void => {
            const endPoint = event.target as HTMLElement;
            this._grid.removeEventListener('pointermove', this.pointerMoveDrawHandler);
            window.clearTimeout(this._lineTimeout);
            this._grid.classList.remove('is--creating-relations');
            this._grid.setAttribute('style', `--relation-line-height: 1px; --relation-line-length: 0px; --relation-line-degree: 0deg; --relation-line-start-y: 0px; --relation-line-start-x: 0px`);
            if(!endPoint.classList.contains('bar__edge')) {
                if(endPoint.classList.contains('bar')) {
                    this.onRejectRelation(endPoint);
                    this.onRejectRelation(this._relationStartElement);
                }
                return;
            }
            const relationType = this.resolveRelationType(this._relationStartElement, endPoint);
            if(relationType === '') {
                this.onRejectRelation(endPoint);
                this.onRejectRelation(this._relationStartElement);
                return;
            }
            this.createRelation(relationType, endPoint);
        };
        
        private createRelation = async (type: string, endPoint: HTMLElement) => {
            const scope = endPoint.closest('ui-scope-row') as ScopeRow;
            let startScopeId = this._scope.id;
            let endScopeId = scope.scope.id;
            if(startScopeId === endScopeId) { 
                return;
            }
            if(type === 'StartAfterEnd' && endPoint.classList.contains('bar__edge--start')) {
                startScopeId = scope.scope.id;
                endScopeId = this._scope.id;
            }
            const createResult = await this.request.post<APIResult<any>>(`/api/project/${this._projectId}/scopes/${startScopeId}/reference`, {
                referenceType: type,
                referenceScopeId: endScopeId
            });
            if (createResult.isSuccess) {
                this.dispatchEvent(new CustomEvent("relationCreated", {bubbles: true} ) );
            } else {
                if(createResult.data?.messages && createResult.data?.messages.length > 0) {
                    this.notice.addMessage(Messages(createResult.data.messages[0].key), 'warning');
                }
                this.onRejectRelation(endPoint);
                this.onRejectRelation(this._relationStartElement);
            }  
        }
        
        private resolveRelationType = (startpoint: HTMLElement, endpoint: HTMLElement): string => {
            if(startpoint.classList.contains('bar__edge--start') && endpoint.classList.contains('bar__edge--start')) {
                return 'EqualStart';
            }
            if((startpoint.classList.contains('bar__edge--end') && endpoint.classList.contains('bar__edge--start')) || (endpoint.classList.contains('bar__edge--end') && startpoint.classList.contains('bar__edge--start'))) {
                return 'StartAfterEnd';
            }
            if(startpoint.classList.contains('bar__edge--end') && endpoint.classList.contains('bar__edge--end')) {
                return 'EqualEnd';
            }
            return '';
        }
        
        private pointerMoveDrawHandler = (event: PointerEvent): void => {
            const gridOffset = this._grid.getBoundingClientRect();
            this._linePosition.endX = event.clientX - gridOffset.left + this._grid.scrollLeft;
            this._linePosition.endY = event.clientY - gridOffset.top;
            if(!this._lineTimeout) {
                this.drawLine();
            }
            this._lineTimeout = window.setTimeout(() => {
                window.clearTimeout(this._lineTimeout);
                this.checkConnections(event);
                this.drawLine();
            },25);
        }
        
        private checkConnections = (event: PointerEvent) => {
            // check if hover is over edge of another bar, if so animate height of bars
            const endPoint = event.target as HTMLElement;
            if(!endPoint) {
                return;
            }
            const endBar = endPoint.parentElement;
            if(endPoint.classList.contains('bar__edge') && endBar !== this._bar) {
                endBar!.classList.add('is--connecting');
                this._bar.classList.add('is--connecting');
            } else {
                // move to grid
                const bars = this._grid.querySelectorAll('.bar');
                if (bars && bars.length > 0) {
                    for (const bar of bars) {
                        const item = bar as HTMLElement;
                        item.classList.remove('is--connecting');
                    }
                }
            }
        }
        
        private drawLine = () => {
            let deltaX = this._linePosition.endX - this._linePosition.startX;
            let deltaY = this._linePosition.endY - this._linePosition.startY;
            let startX = this._linePosition.startX;
            let startY = this._linePosition.startY;
            
            if(deltaX < 0) {
                startX = this._linePosition.endX;
                startY = this._linePosition.endY;
                deltaX = this._linePosition.startX - this._linePosition.endX;
                deltaY = this._linePosition.startY - this._linePosition.endY;
            }
            
            let lineLength = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2));
            let degree;
            if(deltaX === 0) {
                degree = 90;
            } else {
                let m = (this._linePosition.endY - this._linePosition.startY) / (this._linePosition.endX - this._linePosition.startX);
                degree = Math.atan(m) * 180 / Math.PI;
            }
            
            this._grid.setAttribute('style', `--relation-line-length: ${lineLength}px; --relation-line-degree: ${degree}deg; --relation-line-start-y: ${startY}px; --relation-line-start-x: ${startX}px`);
        }
  
        private onResizeBar = (event: PointerEvent) => {
            event.stopPropagation();
            const resizeElement = event.target as HTMLElement;
            resizeElement.classList.add('is--resizing');
            if(resizeElement && resizeElement.classList.contains('bar__edge--end') && this._scope.endDate) {
                this._dragDate = this._scope.endDate;
                this._dragFromEnd = true;
            }
            if(resizeElement && resizeElement.classList.contains('bar__edge--start') && this._scope.startDate) {
                this._dragDate = this._scope.startDate;
                this._dragFromEnd = false;
            }
            this._tickWidth = this._grid.calculateTickWidth(0);
            const barPosition = this._bar.getBoundingClientRect();
            this._originalBarWidth = barPosition.width;
            this._originalBarLeft = parseInt(this._bar.style.left, 10);
            this.setPointerCapture(event.pointerId);
            this.style.cursor = 'col-resize';
            this.style.userSelect = 'none';
            this._mousePosition = {
                left: this.scrollLeft,
                x: event.clientX,
            };
            this.addEventListener('pointermove', this.pointerMoveResizeHandler);
            this.addEventListener('pointerup', this.pointerUpResizeHandler, {once: true});
            this._lastResizeSnap = 0;
        }

        private pointerMoveResizeHandler (evt: PointerEvent): void {
            const dx = evt.clientX - this._mousePosition.x;
            const snapGrace = 0.66;
            let dateOffset;
            
            let restDx = dx + (this._lastResizeSnap * this._tickWidth)/-1;
            
            if(restDx < 0) {
                dateOffset = Math.ceil(restDx / (this._tickWidth * snapGrace)) + this._lastResizeSnap;
            } else {
                dateOffset = Math.floor(restDx / (this._tickWidth * snapGrace)) + this._lastResizeSnap;
            }
    
            let widthModification = dx;
            if(dateOffset !== this._lastResizeSnap) {
                widthModification = dateOffset * this._tickWidth;
            }            
            if(this._dragFromEnd) {
                this._bar.style.width = `${this._originalBarWidth + widthModification}px`;
            } else {
                this._bar.style.left = `${this._originalBarLeft + widthModification}px`;
                this._bar.style.width = `${this._originalBarWidth + (widthModification/-1)}px`;
            }

            if(dateOffset !== this._lastResizeSnap) {
                this._lastResizeSnap = dateOffset;
                this.updateScopeDateRange();
            }
        };
        
        private hasStartDateReference = (): boolean => {
            return this.scope.references.filter(s=> s.startScopeId === this.scope.id && (s.referenceType === 'EqualStart' || s.referenceType === 'StartAfterEnd')).length > 0;
        }
        
        private hasEndDateReference = (): boolean => {
            return this.scope.references.filter(s=> s.startScopeId === this.scope.id && s.referenceType === 'EqualEnd').length > 0;
        }

        private async pointerUpResizeHandler (evt: PointerEvent): Promise<void> {
            this.removeEventListener('pointermove', this.pointerMoveResizeHandler);
            this.style.cursor = 'grab';
            this.style.removeProperty('user-select');
            this.querySelectorAll('is--resizing').forEach(element => {
                const edge = element as HTMLElement;
                edge.classList.remove('is--resizing');
            });
            //@ts-ignore
            const newDate = moment(this._dragDate).clone().add(this._lastResizeSnap, this.getIntervalSnapValue());
            if(this._dragFromEnd) {
                if(this.hasEndDateReference()) {
                    this.notice.addMessage(Messages('planning.form.field.reference.error.end_date_has_reference_value'), 'warning');
                    this.onRejectRelation(this._bar);
                }
                else {
                    const updateEndDate: Simplex.WebComponents.Project.Models.UpdateScopeEndDateRequest = new Simplex.WebComponents.Project.Models.UpdateScopeEndDateRequest(newDate.format('YYYY-MM-DD'));
                    const updateResponse = await this.request.put<APIResult<boolean>>(`/api/project/${this._projectId}/scopes/${this._scope.id}/endDate`, updateEndDate);
                    if (!updateResponse.isSuccess) {
                        if (updateResponse.data?.messages && updateResponse.data?.messages.length > 0) {
                            this.notice.addMessage(Messages(updateResponse.data.messages[0].key), 'warning');
                        }
                        this.onRejectRelation(this._bar);
                    }
                }
                this.dispatchEvent(new Event("scopeChanged",{bubbles:true}));
                return;
            } else {
                if(this.hasStartDateReference()) {
                    this.notice.addMessage(Messages('planning.form.field.reference.error.start_date_has_reference_value'), 'warning');
                    this.onRejectRelation(this._bar);
                } else {
                    const updateStartDate: Simplex.WebComponents.Project.Models.UpdateScopeStartDateRequest = new Simplex.WebComponents.Project.Models.UpdateScopeStartDateRequest(newDate.format('YYYY-MM-DD'));
                    const updateResponse = await this.request.put<APIResult<boolean>>(`/api/project/${this._projectId}/scopes/${this._scope.id}/startDate`, updateStartDate);
                    if (!updateResponse.isSuccess) {
                        if (updateResponse.data?.messages && updateResponse.data?.messages.length > 0) {
                            this.notice.addMessage(Messages(updateResponse.data.messages[0].key), 'warning');
                        }
                        this.onRejectRelation(this._bar);
                    }
                }
                this.dispatchEvent(new Event("scopeChanged",{bubbles:true}));
                return;
            }
            
        };
        
        private createNewDate = (): string => {
            //@ts-ignore
            return moment(this._dragDate).clone().add(this._lastResizeSnap, this.getIntervalSnapValue()).format("D MMM YY");            
        }

        private updateScopeDateRange = () => {

            const barPosition = this._bar.getBoundingClientRect();
            let start = barPosition.left - this._grid.getBoundingClientRect().left  + this._grid.scrollLeft;
            let end = (barPosition.left + barPosition.width) - this._grid.getBoundingClientRect().left  + this._grid.scrollLeft;

            if(this._dategrid.isFixed()) {
                this._dateRangeStart.classList.add('is--fixed');
                this._dateRangeEnd.classList.add('is--fixed');
                this._dateRangeStart.style.marginLeft = `calc((${start}px - (var(--date-indicator-width) / 2) + 500px)`;
                this._dateRangeStart.style.left = `${this._grid.scrollLeft * -1}px`;
                this._dateRangeEnd.style.marginLeft = `calc((${end}px - (var(--date-indicator-width) / 2) + 500px)`;
                this._dateRangeEnd.style.left = `${this._grid.scrollLeft * -1}px`;

                if(start < 65) {
                    this._dateRangeStart.classList.add('out-of-bounds');
                } else {
                    this._dateRangeStart.classList.remove('out-of-bounds');
                }
                if(end < 65) {
                    this._dateRangeEnd.classList.add('out-of-bounds');
                } else {
                    this._dateRangeEnd.classList.remove('out-of-bounds');
                }
                
            } else {
                this._dateRangeStart.classList.remove('is--fixed');
                this._dateRangeEnd.classList.remove('is--fixed');
                this._dateRangeStart.style.left = `calc(${start}px - (var(--date-indicator-width) / 2)`;
                this._dateRangeEnd.style.left = `calc(${end}px - (var(--date-indicator-width) / 2)`;
            }
            
            if(this._lastResizeSnap === 0) {
                this._dateRangeStart.querySelector('.datelabel')!.innerHTML = moment(this._scope.startDate).format("D MMM YY");
                this._dateRangeEnd.querySelector('.datelabel')!.innerHTML = moment(this._scope.endDate).format("D MMM YY");
                return;
            }
            let newDate = this.createNewDate();
            if(this._dragFromEnd) {
                this._dateRangeEnd.querySelector('.datelabel')!.innerHTML = newDate;
            } else {
                this._dateRangeStart.querySelector('.datelabel')!.innerHTML = newDate;
            }

            
        }

        public onDeselectBar = () => {
            this.select = false;
        }
        
        private onSelectBar = (event: Event) => {
            if(event.target === this._leftEdgeElement || event.target === this._rightEdgeElement) {
                return;
            }
            event.stopPropagation();
            this.select = !this.select;

            this._dateRangeStart.classList.remove('out-of-bounds');
            this._dateRangeEnd.classList.remove('out-of-bounds');
            if(this.select) {
                this.updateScopeDateRange();
                this._dateRangeStart.classList.remove('is--hidden');
                this._dateRangeEnd.classList.remove('is--hidden');
                this.dispatchEvent(new CustomEvent("scopeSelected", {bubbles: true, detail: this} ) );
                document.addEventListener('click', this.onDocumentClick);
            } else {
                this.dispatchEvent(new CustomEvent("scopeDeselected", {bubbles: true, detail: this} ) );
                this._dateRangeStart.classList.add('is--hidden');
                this._dateRangeEnd.classList.add('is--hidden');
            }
        }

        private onDocumentClick = (event: MouseEvent): void => {
            if(event.target === this._leftEdgeElement || event.target === this._rightEdgeElement) {
                return;
            }
            this._dateRangeStart.classList.add('is--hidden');
            this._dateRangeEnd.classList.add('is--hidden');
            this._dateRangeStart.classList.remove('out-of-bounds');
            this._dateRangeEnd.classList.remove('out-of-bounds');
            this.select = false;
            document.removeEventListener('click', this.onDocumentClick);

        }
        
        
        render() {
            this.setAttribute("depth",`${this._depth}`);
            if(this._isOdd) {
                this.classList.add('is--odd');
            } else {
                this.classList.add('is--even');
            }
            this.innerHTML = this.contentTemplate({scope: this._scope, interval: this._intervalList});
            this._bar = this.querySelector('.bar') as HTMLElement;
            if(this._scope.critical) {
                this._bar.classList.add('is--critical');
            }
            this._leftEdgeElement = this._bar.querySelector('.bar__edge--start') as HTMLElement;
            this._rightEdgeElement = this._bar.querySelector('.bar__edge--end') as HTMLElement;
            this.generateBar();
            this.generateBaseline();
            this._bar.addEventListener('click', this.onSelectBar);
            this._leftEdgeElement.addEventListener('pointerdown', this.onBarEdgePointerDown);
            this._rightEdgeElement.addEventListener('pointerdown', this.onBarEdgePointerDown);
            this.color = this._scope.color;

            this._rendered = true;
        }

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if(!this._rendered) {
                this.render();
                
            }
        }

    }
}
