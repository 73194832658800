namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class FinanceEntryEdit extends ABModel {

        public type: "Actual"|"Expense" = "Actual";
        public reference: string|null = null;
        public poNumber: string|null = null;
        public bookingCode: string|null = null;
        public date: string|null = null;
        public supplier: string|null = null;
        
        public scopes: FinanceEntryScope[] = [];
        
        public costDescription: string|null = null;
        
        public costCategoryId: string|null = null;
        public costCategory: string|null = null;
        
        public amountEx: number|null = null;
        
        public description: string|null = null;
        public log: string|null = null;
    


        
        public convertField = (fieldName: string, direction: "up" | "down", value: any): any => {
            switch (fieldName) {

                case "date":
                    const formattingHelper = Ambrero.AB.Application.Instance!.getSystemComponent('FormattingHelper') as Simplex.Components.FormattingHelper;
                    switch (direction) {
                        case "up": //get
                            if (value !== null && value !== "") {
                                value = moment(value, 'YYYY-MM-DD').format(formattingHelper.dateFormat)
                            }
                            break;
                        case "down": //set

                            if (value === "" || value === null) {
                                value = null;
                            } else {
                                if (value.indexOf("T") !== -1) {
                                    value = moment(value, 'YYYY-MM-DD[T]HH:mm:ss').format('YYYY-MM-DD');
                                } else {
                                    value = moment(value, formattingHelper.dateFormat).format('YYYY-MM-DD');    
                                }
                                
                            }
                            break;
                    }
                    return value;
                case "type":
                    if (direction === "down") {
                        value = value === "" ? null : value;
                    }
                    break;
            }
            return value;
        }
    }
}