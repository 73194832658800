namespace Simplex.WebComponents.LayoutComponents {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;


    @WebComponent("ui-page-header")
    export class PageHeader extends ABWebComponent {

        private readonly _contentTemplate;
        private readonly _tabs: HeaderTabs|null = null;
        private readonly _button: string = "";
        private _rendered: boolean;
        private _isFixed: boolean;
        private _originalTop: number;
        private _scrollingElement?: Document;
        private readonly _headerTabs?: HTMLElement;
        
        public constructor() {
            super();
            this._rendered = false;
            this._isFixed = false;
            this._contentTemplate = this.app.getTemplate('WebComponents/LayoutComponents/PageHeader', 'PageHeader') as TemplateCallback;
            this._headerTabs = this.querySelector('ui-header-tabs') as HTMLElement;
            this._originalTop = 80;
            if (this._headerTabs){
                this._tabs = this._headerTabs.cloneNode(true) as HeaderTabs;
            }
            const button = this.querySelector('ui-header-button');
            if (button) {
                this._button = button.innerHTML;
            }
            this.setEventListeners();
            
        }

        private onScroll = (evt:Event): void => {
            if (!this.isConnected || !this._headerTabs) {
                return;
            }
            this._scrollingElement = evt.target as Document;
            
            if(evt.target) {

                const scrollTop = this._scrollingElement.scrollingElement?.scrollTop || 0;
                let slideinoffset = 130 - scrollTop;
                if(scrollTop > 80) {
                    slideinoffset = 50;
                }
                this._scrollingElement.documentElement.style.setProperty('--actual-header-height',`${slideinoffset}px`);
                if (scrollTop > this._originalTop) {
                    if (this._isFixed) {
                        this._headerTabs.dataset.originalTop = `${this._originalTop}`;
                        return;
                    }
                    this.classList.add('is--fixed');
                    this._isFixed = true;
                } else {
                    if (!this._isFixed) {
                        return;
                    }
                    this.classList.remove('is--fixed');
                    this._isFixed = false;
                }
            }

        }
        
        render() {
            this.innerHTML = this._contentTemplate({
                breadcrumb: this.getAttribute("breadcrumb"),
                title: this.getAttribute("title"),
                headerTitle: this.getAttribute("headerTitle"),
                backlink: this.getAttribute("backlink"),
                button: this._button
            });
            const header = this.querySelector('header');
            
            if (this._tabs && header) {
                header.appendChild(this._tabs);
            }
            
            this._rendered = true;
        }

        private removeEventListeners = () => {
            document.removeEventListener("scroll", this.onScroll);
        };
        private setEventListeners = () => {
            document.addEventListener("scroll", this.onScroll);
        };

        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if(!this._rendered) {
                this.render();
            }
        }
        
        disconnectedCallback() {
            this.removeEventListeners();
        }
    }
}
