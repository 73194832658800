namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class ReferenceValidationError extends ABModel {

        public index: number;
        public errorType: ReferenceValidationErrorType;

        constructor(index: number,
                    errorType: ReferenceValidationErrorType) {
            super();
            this.index = index;
            this.errorType = errorType;
        }
    }
    export enum ReferenceValidationErrorType {
        SelfReference = "SelfReference",
        CurrentScopeNotSelected= "CurrentScopeNotSelected",
    }
}