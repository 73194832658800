namespace Simplex.WebComponents {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    // import Upload = Simplex.Components.Upload;
    import Inject = Simplex.Decorators.Inject;
    import EventArgs = Ambrero.AB.Components.EventArgs;


    @WebComponent("ui-fileupload-button")
    export class FileUploadButton extends ABWebComponent {
        private readonly contentTemplate;
       
     
        private fileInput: HTMLInputElement | null = null;
        private currentSize: string|null = null;
        private state: "idle" | "uploading" | "success" | "error" | "existing" | "analyzing" = "idle";
        private activeRequest?: XMLHttpRequest | null;
        private _rendered: boolean = false;


        public isSuccess = () => this.state === "success";
        public currentFileId?: string;
        public currentFilename?: string;
        
        public constructor() {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/FormElements/FileUploadButton', 'FileUploadButton') as TemplateCallback;
            
        }

        onFileChanged = async (event: Event): Promise<void> => {
            const input = event.target as HTMLInputElement;
            if (!input || !input.files) {
                return;
            }
            if (input.files.length > 1) {
                return;
            }

            await this.startUpload(input.files[0]);

        };

        private setState = (): void => {
            this.classList.forEach(c => {
                if (c.startsWith("state--")) {
                    this.classList.remove(c);
                }
            })
            this.classList.add("state--"+this.state);
            this.dispatchEvent(new Event("stateChanged"));
        }
        
        startUpload = async (file: File): Promise<void> => {
            const data = new FormData()
            data.append('file', file);

            this.currentSize = file.size.toString();

            this.state = "uploading";
            this.setState();

            this.activeRequest = new XMLHttpRequest();
            this.activeRequest.open('POST', '/api/resource/temp');

            this.activeRequest.upload.addEventListener('progress', (e) => {
                if (!this.activeRequest) {
                    return;
                }
                let percent_completed = (e.loaded / e.total) * 100;
                const progressElement = this.querySelector("div.uploaditem__progress") as HTMLElement;
                if (progressElement) {
                    progressElement.style.setProperty('--upload-progress', `${percent_completed}`);
                }

            });

            this.activeRequest.addEventListener('load', (e) => {
                if (!this.activeRequest) {
                    return;
                }
                if (this.activeRequest.status >= 200 && this.activeRequest.status < 300) {
                    try {
                        const responseBody = JSON.parse(this.activeRequest.response);
                        this.currentFileId = responseBody.data[0].fileId;
                        this.currentFilename = responseBody.data[0].filename;
                        this.state = "success";
                        
                    } catch (error) {
                        this.state = "error";
                    }
                } else {
                    this.state = "error";
                }
                if (this.fileInput) {
                    this.fileInput.value = "";
                }
                this.setState();
            
            });

            this.activeRequest.send(data);
        }
        private onDragOver = (event:Event): void => {
            event.preventDefault();
            if (this.classList.contains("is--dragging")) {
                return;
            }
            this.classList.add('is--dragging');

            this.removeEventListener('drop', this.onDragDrop);
            this.addEventListener('drop', this.onDragDrop);

            const fileDragOver = event as DragEvent;

            const file = fileDragOver.dataTransfer?.items[0];
            if(file == null) {
                return;
            }


            if(!this.validFileType(file.type)) {
                this.classList.add('is--error');
            }

        }
        private onDragLeave = (event:Event):void => {

            // @ts-ignore
            if(event.relatedTarget != null && this.contains(event.relatedTarget)) {
                return;
            }
            this.removeEventListener('drop', this.onDragDrop);
            this.classList.remove('is--dragging', 'is--error');
        }

        private onDragDrop = async (event:Event):Promise<void> => {
            event.preventDefault();

            this.classList.remove('is--dragging', 'is--error');
            this.removeEventListener('drop', this.onDragDrop);
            const droppedEvent = event as DragEvent;

            const file = droppedEvent.dataTransfer?.files[0];
            if(file == null) {
                return;
            }

            if(!this.validFileType(file.type)) {
                return;
            }

            await this.startUpload(file);
        }
        private validFileType = (type:string):boolean => {

            const accepts = this.getAttribute("accept");
            if (!accepts) {
                return true;
            }
            const acceptSplit = accepts.split(",");
            return acceptSplit.indexOf(type)!==-1;
        }
        bindInputEvents = (): void => {
            if (!this.fileInput) {
                return;
            }
            this.fileInput.addEventListener("change", this.onFileChanged);

            this.addEventListener('dragleave', this.onDragLeave);
            this.addEventListener('dragover', this.onDragOver);
        }

        async connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if(!this._rendered) {
                const maxSize = this.getAttribute("max-size");

                this.innerHTML = this.contentTemplate({
                    name: this.getAttribute("name"),
                    required: this.getAttribute("required") === "",
                    label: this.getAttribute("label"),
                    class: this.getAttribute("class"),
                    disabled: this.getAttribute("disabled") === "",
                    accept: this.getAttribute("accept") ?? "*",
                    maxSize: maxSize,
                });
                this.fileInput =  this.querySelector("input[type='file']");
                this.bindInputEvents();
            }
        }
        
        onRender = async (): Promise<void> => {
           
        }
    }
}
