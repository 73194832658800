namespace Simplex.WebComponents {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;

    @WebComponent("ui-selected-scope")
    export class SelectedScope extends ABWebComponent {
        private readonly _color: number;
        private readonly _name: string;
        private readonly contentTemplate;
        private _rendered: boolean = false;

        public constructor(name: string, color: number) {
            super();
            this._name = name;
            this._color = color;
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/Budget', 'SelectedScope') as TemplateCallback;
        }
        
        render() {
            this.innerHTML = this.contentTemplate(
                {
                    scopeName:  this._name,
                    color: this._color
                }
            );
            this._rendered = true;
        }
        
        connectedCallback() {
            if (!this.isConnected) {
                return;
            }
            if (!this._rendered) {
                this.render();
            }
        }
    }
}