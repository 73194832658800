namespace Simplex.WebComponents.Project.Models {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class UpdateScopeEndDateRequest extends ABModel {

        public endDate?: string;

        constructor(endDate: string) {
            super();
            this.endDate = endDate;
        }
    }
}