namespace Simplex.WebComponents.Project {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import ModelBinder = Ambrero.AB.Components.ModelBinder;
    import ContentNotifier = Simplex.Components.ContentNotifier;
    import Inject = Simplex.Decorators.Inject;
    import APIResult = Simplex.Utils.APIResult;
    import ConfirmDialog = Simplex.Components.ConfirmDialog;
    import EventArgs = Ambrero.AB.Components.EventArgs;
    import InputText = Simplex.WebComponents.FormElements.InputText;
    import CostCategorySummary = Simplex.Models.Project.CostCategorySummary;
    import Button = Simplex.WebComponents.FormElements.Button;
    import IBoundModel = Ambrero.AB.Models.IBoundModel;
    import InputNumber = Simplex.WebComponents.FormElements.InputNumber;
    import InputDate = Simplex.WebComponents.FormElements.InputDate;
    import ReferenceValidationErrorType = Simplex.Models.Project.ReferenceValidationErrorType;
    
    @WebComponent("ui-edit-scope-form")
    export class EditScopeForm extends ABWebComponent {
        private readonly contentTemplate;
        private readonly _createModel: Simplex.Models.Project.CreateScopeRequest;
        private readonly _boundCreateModel: IBoundModel;
        private readonly _scope?: Simplex.Models.Project.Scope;
        private readonly _allScopes?: Simplex.Models.Project.Scope[];
        @Inject("ModelBinder")
        private readonly _binder!: ModelBinder;
        @Inject("FormattingHelper")
        private readonly _formattingHelper!: Simplex.Components.FormattingHelper;
        @Inject("ContentNotifier")
        private readonly _contentNotifier!: ContentNotifier;
        private readonly _projectId: string;
        private navigateToBudgetTab: Button | null = null;
        private submit: Button | null = null;
        private cancel: Button | null = null;
        private delete: Button | null = null;
        private readonly _multipleYears: boolean = false;
        private _rendered: boolean = false;
        private readonly notice: any;
        
        public constructor(projectId: string, allScopes: Simplex.Models.Project.Scope[], scope?: Simplex.Models.Project.Scope, multipleYears?:boolean) {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/EditScopeForm', 'EditScopeForm') as TemplateCallback;
            if (projectId === undefined) {
                this._projectId = this.getAttribute("project-id") ?? "";  
            } else {
                this._projectId =  projectId;
            }
            this._multipleYears = multipleYears ?? false;
            this._scope = scope;
            this._allScopes = allScopes;
            this._createModel = new Simplex.Models.Project.CreateScopeRequest(scope);
            this._boundCreateModel = this._binder.bind(this._createModel, this._contentNotifier)
                .on("PropertyChanged", this.propertyChanged);
            this.notice = this.app.getComponent('AmbreroComponents.Notice');
        }

        get scope() {
            return this._createModel;
        }   
        
        private hasChildScopesWithBudgetDefined = ():boolean => {
            if (!this._scope|| this._scope.children.length === 0) {
                return false;
            }
            const childWithBudget = this._scope.children.findIndex(c=>c.budget !== null);
            return childWithBudget !== -1;
        }
        
        private render() {
            this.innerHTML = this.contentTemplate({
                    projectId: this._projectId,
                    model: this._createModel,
                    scope: this._scope,
                    scopes: this._allScopes,
                    budgetReadonly: this.hasChildScopesWithBudgetDefined(),
                    multipleYears: this._multipleYears
                }
            );
            this.fillScopeReferencesList();
            this._contentNotifier.notifyDraw(this);
            this.showAndHideFieldsAndClearFields();
            this._rendered = true;
        }

        private fillScopeReferencesList = ():void => {
            const scopeReferencesContainer = this.querySelector('.JsScopeReferences');
            if(scopeReferencesContainer) {
                scopeReferencesContainer.innerHTML = '';
                for (let i = 0; i < this._createModel.references.length; i++) {
                    const reference = this._createModel.references[i];
                    const scopeReferenceEditRow = new ScopeReferenceEditRow(reference, i, i + 1 < this._createModel.references.length, this._allScopes!);
                    scopeReferencesContainer.appendChild(scopeReferenceEditRow);
                    scopeReferenceEditRow.addEventListener('delete', this.onDeleteScopeReferenceRow);
                }
            }
        };
        
        public getFormHeight = (): number => {
            return this.getBoundingClientRect().height;
        }
        
        reset = ():void => {
            this._binder.reset(this._createModel);
        }

        private validateReferences = ():boolean => {
            if(!this._scope?.id) {
                return true;
            }
            const invalidReferenceValidationErrors = this._createModel.validateReferences(this._scope.id);
            if (invalidReferenceValidationErrors.length > 0) {
                for (const invalidReferenceValidationError of invalidReferenceValidationErrors) {
                    this._binder.setFieldError(this._createModel, `references.${invalidReferenceValidationError.index}.startScopeId`,
                        invalidReferenceValidationError.errorType === ReferenceValidationErrorType.CurrentScopeNotSelected ? 
                            'planning.form.field.reference.error.current_not_selected' : 'planning.form.field.reference.error.self_reference_not_allowed');
                }
                return false;
            }
            return true
        }

        onNavigateToBudgetTab = async (event:MouseEvent):Promise<void> => {
            event.preventDefault();
            event.stopPropagation();

            let budgetUrl = `/project/${this._projectId}/budget`;
            if(this._scope?.id){
                sessionStorage.setItem("budget_active_scopeId", this._scope?.id);
            }
            this.app.navigateTo(budgetUrl);
        }
        
        onSubmit = async (event:MouseEvent):Promise<void> => {
            event.preventDefault();
            event.stopPropagation();

            if(!this._boundCreateModel.validate() || !this.validateReferences()){
                return;
            }
            const [datesValid, dateField, dateError] = this._createModel.validateDates();
            if (!datesValid) {
                this._binder.setFieldError(this._createModel,dateField,dateError);
                return;
            }
            if (this._createModel.budgetBreakdown && !this._binder.validate(this._createModel)) {
                return;
            }
            if (this.submit) {
                this.submit.loading = true;
            }
            if(this._scope) {
                const updateResult = await this.request.put<APIResult<void>>(`/api/project/${this._projectId}/scopes/${this._scope.id}`, this._createModel);
                if (updateResult.isSuccess) {
                    this.dispatchEvent(new Event("scopeChanged",{bubbles:true}));
                } else {
                    if(updateResult.data?.messages && updateResult.data?.messages.length > 0) {
                        this.notice.addMessage(Messages(updateResult.data.messages[0].key), 'warning');
                    }
                }
            } else {
                const createResult = await this.request.post<APIResult<void>>(`/api/project/${this._projectId}/scopes`, this._createModel);
                if (createResult.isSuccess) {
                    this.dispatchEvent(new CustomEvent("scopeCreated", {bubbles: true, detail: new Simplex.Models.Project.ScopeCreated(this._createModel.parentScopeId)} ) );
                } else {
                    if(createResult.data?.messages && createResult.data?.messages.length > 0) {
                        this.notice.addMessage(Messages(createResult.data.messages[0].key), 'warning');
                    }
                }
            }
            if (this.submit) {
                this.submit.loading = false;
            }
        }

        public cancelEdit = async ():Promise<void> => {
            this.removeAttribute("open");
            this.dispatchEvent(new Event("cancel", {bubbles: true}));
        }
        
        onCancel = async (event:MouseEvent):Promise<void> => {
            event.preventDefault();
            event.stopPropagation();
            
            this.removeAttribute("open");
            this.dispatchEvent(new Event("cancel", {bubbles: true}));
        }
        

        onDelete = async (event:MouseEvent):Promise<void> => {
            event.preventDefault();
            event.stopPropagation();

            const confirmDialog = this.app.getComponent('ConfirmDialog',
                Messages('planning.delete.confirm.title'),
                Messages(this._scope!.children.length > 0 ? 'planning_with_children.delete.confirm.text' : 'planning.delete.confirm.text', this._scope!.name),
                null, null, 'warning'
            ) as ConfirmDialog;
            confirmDialog.show();
            confirmDialog.on('yes', this.onDeleteScopeConfirmed);
        }

        onDeleteScopeConfirmed = async (event:EventArgs) => {
            event.handled = true;
            this.dispatchEvent(new CustomEvent('deleteScope',{bubbles: true, detail: {id: this._scope!.id!}}));
        }

        private changeVisibilityElement(selector:string, show:boolean) : void {
            const element = this.querySelector(selector) as HTMLElement;
            if(element !== null){
                element.style.display = show ? "" : "none";
            }
        }

        private propertyChanged = (eventArgs: EventArgs) : void => {
            this.showAndHideFieldsAndClearFields();
            switch (eventArgs.data.field) {
                case "startDate":
                case "endDate":
                    const boundModel = eventArgs.source as IBoundModel;
                    boundModel.clearFieldError("startDate");
                    boundModel.clearFieldError("endDate");
                    const startDate = eventArgs.data.field === "startDate" ? eventArgs.data.element.value : this._createModel.startDate;
                    const endDate = eventArgs.data.field === "endDate" ? eventArgs.data.element.value : this._createModel.endDate;
                    this.setDurationInDays(startDate, endDate);
                    this.showAndOverrideWarning();
                    break;
                case "durationInDays":
                    this.changeEndDateBasedOnStartDateAndDurationInDays(eventArgs.data.element.value);
                    break;
                case "startDateMilestone":
                    const startMilestoneName = this.querySelector("ui-input-text[name='startDateMilestoneName']") as InputText;
                    if (startMilestoneName) {
                        startMilestoneName.focus();
                    }
                    break;
                case "endDateMilestone":
                    const endMilestoneName = this.querySelector("ui-input-text[name='endDateMilestoneName']") as InputText;
                    if (endMilestoneName) {
                        endMilestoneName.focus();
                    }
                    break;
            }
        }

        private setDurationInDays = (startDate: string | null, endDate: string | null): void => {
            if(!startDate || !endDate) {
                return;
            }
            const startDateMoment = moment(startDate, this._formattingHelper.dateFormat);
            const endDateMoment = moment(endDate, this._formattingHelper.dateFormat);
            const duration = endDateMoment.diff(startDateMoment, 'days') + 1;
            const durationInDaysField = this.querySelector('ui-input-number[name="durationInDays"]') as InputNumber;
            if(durationInDaysField) {
                durationInDaysField.value = duration;
            }
        }

        private changeEndDateBasedOnStartDateAndDurationInDays = (durationInDays: number | null): void => {
            if(!this._createModel.startDate || !durationInDays) {
                return;
            }
            const startDateMoment = moment(this._createModel.startDate, this._formattingHelper.dateFormat);
            const endDateElement = this.querySelector("ui-input-date[name='endDate']") as InputDate;
            if (endDateElement) {
                endDateElement.value = startDateMoment.add(durationInDays - 1, 'days');
            }
        }
        
        private showAndOverrideWarning = () : void => {
            if(this._scope?.parentScopeId === Simplex.Utils.GUID_EMPTY) {
                return;
            }
            const hasStartDateOverride = this._createModel.isStartDateBeforeParentStartDate(this._scope?.parentScopeStartDate ?? null);
            const hasEndDateOverride = this._createModel.isEndDateAfterParentStartDate(this._scope?.parentScopeEndDate ?? null);
            this.changeVisibilityElement('.startDateOverrideWarning', hasStartDateOverride);
            this.changeVisibilityElement('.endDateOverrideWarning', hasEndDateOverride);
            this.changeVisibilityElement('.globalOverrideWarning', hasStartDateOverride || hasEndDateOverride);
        }
        
        private showAndHideFieldsAndClearFields = () : void => {
            this.changeVisibilityElement("[name='startDateMilestone']", this._createModel.startDate !== null && this._createModel.startDate !== '');
            this.changeVisibilityElement("[name='startDateMilestoneName']", this._createModel.startDateMilestone !== null && this._createModel.startDateMilestone);
            this.changeVisibilityElement("[name='endDateMilestone']", this._createModel.endDate !== null && this._createModel.endDate !== '');
            this.changeVisibilityElement("[name='endDateMilestoneName']", this._createModel.endDateMilestone !== null && this._createModel.endDateMilestone);
            if (!this._createModel.startDateMilestone && this._createModel.startDateMilestoneName !== "") {
                this._createModel.startDateMilestoneName = "";
            }
            if (!this._createModel.endDateMilestone && this._createModel.endDateMilestoneName !== "") {
                this._createModel.endDateMilestoneName = "";
            }            
            const startHint = this.querySelector(`span.scope__form__hint.start`) as HTMLElement;
            if(
                (this._createModel.startDate !== null && this._createModel.startDate !== '') &&
                (this._createModel.startDateMilestone === null || !this._createModel.startDateMilestone)
            ) {
                if(startHint){
                    startHint.style.display = "inline";
                }
            } else {
                if(startHint){
                    startHint.style.display = "none";
                }
            }
            
            const endHint = this.querySelector(`span.scope__form__hint.end`) as HTMLElement;
            if(
                (this._createModel.endDate !== null && this._createModel.endDate !== '') &&
                (this._createModel.endDateMilestone === null || !this._createModel.endDateMilestone)
            ) {
                if(endHint){
                    endHint.style.display = "inline";
                }
            } else {
                if(endHint){
                    endHint.style.display = "none";
                }
            }
            
        }

        private onCostCategorySelected =  (event:Event):void => {
            if (!this._createModel) {
                return;
            }
            const customEvent = event as CustomEvent<CostCategorySummary>;
            const costCategorySummary = customEvent.detail;
            const existing = this._createModel.budgets.findIndex(u => u.costCategoryId === costCategorySummary.id);
            if (existing !== -1) {
                return;
            }
        }
        
        private bindElementEventListeners(){
            this.navigateToBudgetTab = this.querySelector(".JsNavigateToBudgetTab") as Button;
            this.cancel = this.querySelector(".cancelButton") as Button;
            this.submit = this.querySelector(".submitButton") as Button;
            this.delete = this.querySelector(".deleteButton") as Button;
            if (this.navigateToBudgetTab) {
                this.navigateToBudgetTab.addEventListener("click", this.onNavigateToBudgetTab);
            }
            if (this.submit) {
                this.submit.addEventListener("click", this.onSubmit);
            }
            if (this.cancel) {
                this.cancel.addEventListener("click", this.onCancel);
            }
            if (this.delete) {
                this.delete.addEventListener("click", this.onDelete);
            }
            const deleteButtons = this.querySelectorAll('.JsDeleteReferenceRow');
            for (const deleteButton of deleteButtons) {
                deleteButton.addEventListener("click", this.onDeleteScopeReferenceRow);
            }
        }
        private onDeleteScopeReferenceRow = async (event:Event):Promise<void> => {
            event.preventDefault();
            event.stopPropagation();

            const customEvent = event as CustomEvent;
            this._createModel.references.splice(customEvent.detail.index, 1);
            this.fillScopeReferencesList();
        }
        
        private removeElementEventListeners(): void {
            this.cancel = this.querySelector(".cancelButton");
            this.submit = this.querySelector(".submitButton");
            if (this.submit) {
                this.submit.removeEventListener("click", this.onSubmit);
            }
            if (this.cancel) {
                this.cancel.removeEventListener("click", this.onCancel);
            }
        }
        
        connectedCallback() {
            if (!this.isConnected) {
                return;
            }

            if(!this._rendered) {
                this.render();
            }
            this.bindElementEventListeners();
        }

        disconnectedCallback() {
            this.removeElementEventListeners();
        }
    }
}
