namespace Simplex.WebComponents.FormElements.DateRangePicker.Models {
    import ABModel = Ambrero.AB.Models.ABModel;
    import IBoundModel = Ambrero.AB.Models.IBoundModel;

    export class Period extends ABModel {

        public startDate: string|null;
        public endDate: string|null;

        constructor(startDate: string|null = null, endDate: string|null = null) {
            super();
            this.startDate = startDate;
            this.endDate = endDate;
        }

        public validateDates = ():boolean => {
            if (this.endDate === null && this.startDate !== null) {
                return false;
            }
            return !(this.startDate === null && this.endDate !== null);

        }

        validateField = (fieldName: string, element: HTMLElement, value: string, boundModel: IBoundModel): string | undefined => {

            switch(fieldName) {
                case "startDate":
                    if (value === "" || value === null) {
                        if (this.endDate === null) {
                            boundModel.clearFieldError("endDate");
                        }
                    } else {
                        if (this.endDate !== null) {
                            const formattingHelper = Ambrero.AB.Application.Instance!.getSystemComponent('FormattingHelper') as Simplex.Components.FormattingHelper;
                            const tmpEnd = moment(this.endDate, formattingHelper.dateFormat);
                            const tmpStart = moment(value, formattingHelper.dateFormat);
                            if (tmpEnd.isBefore(tmpStart)) {
                                return "error.date.before.start";
                            }
                            boundModel.clearFieldError("endDate");
                        }

                    }
                    break;
                case "endDate":
                    if (value === "" || value === null) {
                        if (this.startDate === null) {
                            boundModel.clearFieldError("startDate");
                        } else {
                            return "error.date.start_and_end.required";
                        }
                    } else {
                        if (this.startDate !== null) {
                            const formattingHelper = Ambrero.AB.Application.Instance!.getSystemComponent('FormattingHelper') as Simplex.Components.FormattingHelper;
                            const tmpEnd = moment(value, formattingHelper.dateFormat);
                            const tmpStart = moment(this.startDate, formattingHelper.dateFormat);
                            if (tmpEnd.isBefore(tmpStart)) {
                                return "error.date.before.end";
                            }
                        } else {
                            return "error.date.start_and_end.required";
                        }
                    }
                    break;
            }

            return;
        }

        public convertField = (fieldName: string, direction: "up" | "down", value: any): any => {
            switch (fieldName) {
                case "startDate":
                case "endDate":
                    const formattingHelper = Ambrero.AB.Application.Instance!.getSystemComponent('FormattingHelper') as Simplex.Components.FormattingHelper;
                    
                    switch (direction) {
                        case "up": //get
                            if (value !== null && value !== "") {
                                value = moment(value, 'YYYY-MM-DD').format(formattingHelper.dateFormat)
                            }
                            break;
                        case "down": //set
                            if (value === "" || value === null) {
                                value = null;
                            } else {
                                value = moment(value, formattingHelper.dateFormat).format('YYYY-MM-DD');
                            }
                            break;
                    }
                    return value;
                case "budget":
                    if (direction === "down") {
                        value = value !== null && value !== "" ? parseFloat(value) : null;
                    }
            }
            return value;
        }
    }
}