namespace Simplex.Models.Project {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class BudgetGraphDataPeriod extends ABModel {

        public period: Period;
        public totalAmountCumulative: number;
        public totalAmountSnapshotCumulative: number;
        public data: Data[];
    
        constructor(
            period: Period,
            totalAmountCumulative: number,
            totalAmountSnapshotCumulative: number,
            data: Data[]) {
            super();
            this.period = period;
            this.totalAmountCumulative = totalAmountCumulative;
            this.totalAmountSnapshotCumulative = totalAmountSnapshotCumulative;
            this.data = data;

        }
    }
}