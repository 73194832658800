namespace Simplex.WebComponents.Project.Report {
    import WebComponent = Simplex.Decorators.WebComponent;
    import FinanceEntrySummary = Simplex.Models.Project.FinanceEntrySummary;
    import ListView = Simplex.WebComponents.LayoutComponents.ListView;
    import ListViewOptions = Simplex.WebComponents.LayoutComponents.ListViewOptions;
    import ConfirmDialog = Simplex.Components.ConfirmDialog;
    import FinanceEntryFilter = Simplex.Models.Project.FinanceEntryFilter;
    import ListViewEvents = Simplex.WebComponents.LayoutComponents.ListViewEvents;
    import FinanceEntrySlidein = Simplex.Components.FinanceEntrySlidein;
    import Inject = Simplex.Decorators.Inject;
    @WebComponent("ui-project-finance-list")
    export class FinanceList extends ListView<FinanceEntrySummary> {
        
        
        @Inject("FinanceEntrySlidein")
        private readonly _financeEntrySlidein!: FinanceEntrySlidein;
        
        
        public constructor() {
            super();
            this.addEventListener("action", this.onContextMenuClick);
            this._financeEntrySlidein.on("close", this.onSlideinClose); 
        }

        private onSlideinClose = async (): Promise<void> => { 
            await this.reload();
        }
        
        public setType = async (type: string, reload: boolean): Promise<void> => {
            const filter = this.getFilter();
            if (filter) {
                filter.type = type;
                if (reload) {
                    await this.setPageNumber(0);
                }
            }
        }
        public getType = (): string|undefined => {
            return this.getFilter()?.type;
        }
        
        public setStatus = async (status: string, reload: boolean): Promise<void> => {
            const filter = this.getFilter();
            if (filter) {
                filter.status = status;
                if (reload) {
                    await this.setPageNumber(0);
                }
            }
        }
        public getStatus = (): string|undefined => {
            return this.getFilter()?.status;
        }
        
        public setStartDate = async (startDate: string, reload: boolean): Promise<void> => {
            const filter = this.getFilter();
            if (filter) {
                filter.startDate = startDate;
                if (reload) {
                    await this.setPageNumber(0);
                }
            }
        }
        public getStartDate = (): string|undefined => {
            return this.getFilter()?.startDate;
        }
        public setEndDate = async (endDate: string, reload: boolean): Promise<void> => {
            const filter = this.getFilter();
            if (filter) {
                filter.endDate = endDate;
                if (reload) {
                    await this.setPageNumber(0);
                }
            }
        }
        public getEndDate = (): string|undefined => {
            return this.getFilter()?.endDate;
        }

        protected onCustomEvent = async (eventName: ListViewEvents, data: any):Promise<void> => {
            this._financeEntrySlidein.setListView(this);
            switch(eventName) {
                case "ActiveItemSelected":
                case "ItemSelected":
                    await this._financeEntrySlidein.showEditFinanceEntry(this.dataset.projectId!, data.id);
                    break;
            }
        }
        
        
        
        private onContextMenuClick = async (event: Event): Promise<void> => {
            event.stopPropagation();
            const customEvent = event as CustomEvent;
            const target = customEvent.target as HTMLElement;
            if (!target) {
                return;
            }
            const projectId = target.getAttribute('project-id');
            if (!projectId) {
                return;
            }
            const entryId = target.getAttribute('entry-id');
            if (!entryId) {
                return;
            }
            const action = customEvent.detail;
            switch(action) {
                case 'delete':
                {
                    const confirmDialog = this.app.getComponent('ConfirmDialog',
                        Messages('finance.listview.action.confirm.delete.title'),
                        Messages('finance.listview.action.confirm.delete.text')) as ConfirmDialog;
                    confirmDialog.show();
                    confirmDialog.on('yes', async () => {

                        await this.request.delete(`/api/project/${projectId}/finance/${entryId}`);
                        await this.reload();
                    });
                    break;
                }
                case 'ignore':
                {
                    await this.request.put(`/api/project/${projectId}/finance/${entryId}/ignore`, {});
                    await this.reload();
                    break;
                }
                case 'unignore':
                {
                    await this.request.delete(`/api/project/${projectId}/finance/${entryId}/ignore`);
                    await this.reload();
                    break;
                }
            }
    
        }

        protected getListViewSettings(): ListViewOptions {
            return new ListViewOptions('jsTableContainer', true, Messages('finance.listView.noResults'), null, {
                    property: 'date',
                    direction: 'ASC'
                }, {
                    listview: 'overview__listview'
                }, {
                    path: 'WebComponents/Project/Finance/FinanceList',
                    file: 'ListView/itemRow'
                }, {
                    path: 'WebComponents/Project/Finance/FinanceList',
                    file: 'ListView/headerTemplate'
                }, new FinanceEntryFilter()
            )
        }
    }
}
