namespace Simplex.Models.Admin {
    import ABModel = Ambrero.AB.Models.ABModel;

    export class Tenant extends ABModel {

        public id: string;
        public name: string;
        public subdomain: string;
        public emailDomain: string;
        public chamberOfCommerce: string;
        public vatNumber: string;
        public houseNumber: string;
        public zipcode: string;
        public street: string;
        public city: string;
        public firstName: string;
        public lastName: string;
        public email: string;
        public language: string;
        public numberFormat: string;
        public dateFormat: string;
        public timeFormat: string;
    
        constructor(
            id: string,
            name: string,
            subdomain: string,
            emailDomain: string,
            chamberOfCommerce: string,
            vatNumber: string,
            houseNumber: string,
            zipcode: string,
            street: string,
            city: string,
            firstName: string,
            lastName: string,
            email: string,
            language: string,
            numberFormat: string,
            dateFormat: string,
            timeFormat: string
            ) {
            super();
            
            this.id = id;
            this.name = name;
            this.subdomain = subdomain;
            this.emailDomain = emailDomain;
            this.chamberOfCommerce = chamberOfCommerce;
            this.vatNumber = vatNumber;
            this.houseNumber = houseNumber;
            this.zipcode = zipcode;
            this.street = street;
            this.city = city;
            this.firstName = firstName;
            this.lastName = lastName;
            this.email = email;
            this.language = language;
            this.numberFormat = numberFormat;
            this.dateFormat = dateFormat;
            this.timeFormat = timeFormat;
        }
    }
}