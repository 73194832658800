namespace Simplex.WebComponents.FormElements {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import ModelBinder = Ambrero.AB.Components.ModelBinder;
    import ContentNotifier = Simplex.Components.ContentNotifier;
    import Inject = Simplex.Decorators.Inject;
    import ContextMenu = Simplex.WebComponents.LayoutComponents.ContextMenu;
    import IModel = Ambrero.AB.Models.IModel;
    
    export interface AutocompleteItemMutationEvent<T> {
        item: T,
        action: string
    }
    
    @WebComponent("ui-autocomplete-item")
    export class AutocompleteItem<T extends IModel> extends ABWebComponent {
        private readonly _contentTemplate;
        private _rendered: boolean = false;
        private readonly _binder: ModelBinder;
        private readonly _item: T;
        @Inject("ContentNotifier")
        private readonly _contentNotifier!: ContentNotifier;

        public constructor(item: T, templatePath:string, templateName:string) {
            super();
            this._binder = this.app.getComponentType<ModelBinder>("ModelBinder")!;
            this._contentTemplate = this.app.getTemplate(templatePath, templateName) as TemplateCallback;
            this._item = item;
            this._binder.bind(this._item, this._contentNotifier);
        }

        render() {
            this.innerHTML = this._contentTemplate({
                item: this._item
            });
            this._rendered = true;
            this._contentNotifier.notifyDraw(this);
        }
        
        onTAction = (event:Event):void => {
            event.preventDefault();
            event.stopPropagation();
            const customEvent = event as CustomEvent;
            const action = customEvent.detail;
            this.dispatchEvent(new CustomEvent<AutocompleteItemMutationEvent<T>>("AutocompleteItemChange", { detail:{ item: this._item, action: action }, bubbles: true }))
        }

        onItemActionClick = (event:Event):void => {
            event.preventDefault();
            event.stopPropagation();
            const target = event.currentTarget as HTMLElement;
            const action = target.dataset['action']!;
            this.dispatchEvent(new CustomEvent<AutocompleteItemMutationEvent<T>>("AutocompleteItemChange", { detail:{ item: this._item, action: action }, bubbles: true }))
        }
        
        public valid = ():boolean => {
            return this._binder.validate(this._item);
        }
        
        connectedCallback() {
            if (!this.isConnected) {
                return;
            }

            if(!this._rendered) {
                this.render();
                const link = this.querySelector("ui-context-menu") as ContextMenu;
                if (link) {
                    link.addEventListener("action", this.onTAction);
                }

                const actionItems = this.querySelectorAll("[data-action]");
                [...actionItems].forEach(item => {
                    item.addEventListener("click", this.onItemActionClick);
                });
                
                this.addEventListener("change", this.onTAction);

                
                
            }
        }
    }
}
