namespace Simplex.WebComponents.Project {
    import WebComponent = Simplex.Decorators.WebComponent;
    import TemplateCallback = Ambrero.AB.Components.TemplateCallback;
    import ABWebComponent = Simplex.Components.ABWebComponent;
    import APIResult = Simplex.Utils.APIResult;
    import TenantUser = Simplex.Models.Users.TenantUser;
    import PopupContainer = Simplex.Components.PopupContainer;
    import ModelBinder = Ambrero.AB.Components.ModelBinder;
    import EventArgs = Ambrero.AB.Components.EventArgs;
    import PagedResult = Simplex.Models.PagedResult;
    import InputText = Simplex.WebComponents.FormElements.InputText;
    import Button = Simplex.WebComponents.FormElements.Button;


    @WebComponent("ui-user-autocomplete")
    export class UserAutocomplete extends ABWebComponent {
        private readonly contentTemplate;
        private input: HTMLInputElement | null = null;
        private resultsElement: HTMLElement | null = null;
        private resultsListElement: HTMLUListElement | null = null;
        private closeTimer?: number;
        private _searching: boolean = false;
        private _triggerSearchAgain: boolean = false;
        private readonly results: TenantUser[] = [];
        private readonly noResultsItem: HTMLLIElement;
        private readonly _popupTemplate;
        private readonly _popupContainer: PopupContainer;
        private readonly _newUserModel: Simplex.Models.Project.NewUser;
        private readonly _binder: ModelBinder;
        private newUserButton: Button | null = null;
        private _newUserPopupInput?: InputText;
        private visible: boolean = false;
        private feedbackTarget: HTMLElement|null = null;
        
        public constructor() {
            super();
            this.contentTemplate = this.app.getTemplate('WebComponents/Project/UserAutocomplete', 'UserAutocomplete') as TemplateCallback;
            this.noResultsItem = document.createElement("LI") as HTMLLIElement;
            this.noResultsItem.classList.add("noresults");
            this.noResultsItem.innerHTML = Messages("project.user.autocomplete.no_result");

            this._newUserModel = new Simplex.Models.Project.NewUser();
            this._binder = this.app.getComponentType<ModelBinder>("ModelBinder")!;
            
            
            this._popupTemplate = this.app.getTemplate('WebComponents/Project/UserAutocomplete', 'NewUserPopup') as TemplateCallback;
            const popupContext = {};
            this._popupContainer = this.app.getComponentType<PopupContainer>("PopupContainer","leftof", this._popupTemplate, popupContext)!;
            this._popupContainer.on("closed", this.onCloseNewUser);
            this._popupContainer.on("created", this.onNewUserPopupCreated);
            this._binder.bind(this._newUserModel, this._popupContainer);
        }

        onNewUserPopupCreated =  (eventArgs: EventArgs):void => {
            const element = eventArgs.data.element as HTMLElement;
            const button = element.querySelector("ui-button") as Button;
            if (button) {
                button.addEventListener("click", this.onPopupAddClicked)
            }
            this._newUserPopupInput = element.querySelector("ui-input-text") as InputText;
            
        }

        onPopupAddClicked = ():void => {
            if (!this._binder.validate(this._newUserModel)) {
                return;
            }

            const tenantUser = new TenantUser(null,null,null, this._newUserModel.email, this._newUserModel.projectManager);
            this._binder.reset(this._newUserModel);
            
            this.dispatchEvent(new CustomEvent<TenantUser>("selected", {detail: tenantUser}));
            this._popupContainer.hidePopup();
            this.closeResults();
        }
        
        onCloseNewUser = ():void => {
            if (this.results.length === 0) {
                this.closeResults();    
            }
            
        }
        
        focus = (): void => {
            if (this.input) {
                this.input.focus();
            }
        }


        async render() {
            this.innerHTML = this.contentTemplate({
                name: this.getAttribute("name"),
                label: this.getAttribute("label"),
                placeholder: this.getAttribute("placeholder"),
                help: this.getAttribute("help"),
                tabindex: this.getAttribute("tabindex"),
                disabled: this.getAttribute("disabled") === "",
                required: this.getAttribute("required") === ""
            });

            this.input = this.querySelector("input");
            this.resultsElement = this.querySelector(".input__results");
            this.resultsListElement = this.querySelector("ul");
            this.newUserButton = this.querySelector("ui-button") as Button;
            this.feedbackTarget = this.querySelector(".input__feedback");
            
            if (this.input) {
                this.input.addEventListener("blur", this.onBlur);
                this.input.addEventListener("focus", this.onFocus);
                this.input.addEventListener("keyup", this.onKeyUp);
            }
            if (this.newUserButton) {
                this.newUserButton.addEventListener("click", this.onAddNewUser);
            }
            if (this.input) {
                this.input.addEventListener("Valid", this.inputValidated);
                this.input.addEventListener("Invalid", this.inputInvalid);
            }
        }

        onAddNewUser = (event:MouseEvent):void => {
            event.preventDefault();
            event.stopPropagation();
            // this.closeResults();
            clearTimeout(this.closeTimer);
            this._popupContainer.showPopup(this.resultsElement!);
            if (this.input) {
                this._newUserModel.email = this.input.value;
            }
            if (this._newUserPopupInput) {
                this._newUserPopupInput.focus();
            }
        }
        

        updateResults = (newResults: TenantUser[]) => {
            this.results.length = 0;
            for (let k in newResults) {
                this.results.push(newResults[k]);
            }
            const currentResultElements = this.resultsListElement!.querySelectorAll('li');
            currentResultElements.forEach(element => element.remove());

            if (this.results.length === 0) {
                if (this.input!.value.length > 0) {
                    this.resultsListElement!.appendChild(this.noResultsItem)
                }
            } else {
                if (this.noResultsItem.parentElement) {
                    this.noResultsItem.remove();
                }
                for (let i = 0; i < this.results.length; i++) {
                    const element = document.createElement("LI") as HTMLLIElement;
                    element.innerHTML = `${this.results[i].firstName} ${this.results[i].lastName}`;
                    element.addEventListener("click", this.onSelectUser.bind(this, this.results[i]));
                    this.resultsListElement!.appendChild(element)

                }
            }

            //<li class="noresults">Deze gebruiker bestaat (nog) niet</li>
        }

        onSelectUser = (result: TenantUser, event: MouseEvent): void => {
            if (this.input) {
                this.input.value = "";
            }
            this.dispatchEvent(new CustomEvent<TenantUser>("selected", {detail: result}));
            this.closeResults();
        }


        findUsers = async (): Promise<void> => {
            if (this._searching) {
                this._triggerSearchAgain = true;
                return;
            }
            const value = this.input!.value;
            if(value === '') {
                if (this.resultsElement) {
                    this.resultsElement.classList.remove("is--search_active");
                }
                return;
            }
            const searchResult = await this.request.post<APIResult<PagedResult<TenantUser>>>(`/api/user`, { searchTerm: value });
            if (this.resultsElement) {
                this.resultsElement.classList.add("is--search_active");
            }
            if (searchResult.isSuccess && searchResult.data.data) {
                this.updateResults(searchResult.data.data.items);
            } else {
                this.updateResults([]);
            }


            this._searching = false;
            if (this._triggerSearchAgain) {
                this._triggerSearchAgain = false;
                await this.findUsers();
            }
        }

        onKeyUp = async (event: KeyboardEvent) => {
            await this.findUsers();
        }

        onBlur = (event: Event): void => {
            if (this.closeTimer) {
                clearTimeout(this.closeTimer);
            }
            this.closeTimer = setTimeout(this.closeResults, 100);
        }

        closeResults = (): void => {
            if (this.closeTimer) {
                clearTimeout(this.closeTimer);
            }
            if (this.resultsElement) {
                this.resultsElement.classList.remove("is--open");
                this.resultsElement.classList.remove("is--search_active");
            }
            this.visible = false;
            this._triggerSearchAgain = false;
            document.removeEventListener('click', this.onDocumentClick);
        }

        onFocus = (event: Event): void => {
            if (this.closeTimer) {
                clearTimeout(this.closeTimer);
            }
            if (this.resultsElement) {
                this.resultsElement.classList.add("is--open");
                if(this.input && this.input.value !== '') {
                    this.resultsElement.classList.add("is--search_active");
                }
            }
            this.visible = true;
            document.addEventListener('click', this.onDocumentClick);
        }

        private isClickInPopup = (node: HTMLElement): boolean => {
            while (node !== document.body && node.parentElement !== null) {
                if (node == this || node == this._popupContainer.containerElement) {
                    return true;
                }
                node = node.parentElement;
            }
            return false;
        }

        private inputValidated = (_:Event):void => {
            if (this.feedbackTarget) {
                this.feedbackTarget.innerHTML = "";
            }
        }

        private inputInvalid = (_:Event):void => {
            if (this.feedbackTarget && this.input) {
                this.feedbackTarget.innerHTML = Messages(this.input.dataset.validationMessage ?? "");
            }
        }

        private onDocumentClick = (event: MouseEvent): void => {
            if (!this.isClickInPopup(event.target as HTMLElement)) {
                this.closeResults();
            }
        };


        async connectedCallback() {
            if (!this.isConnected) {
                return;
            }

            await this.render();
        }
    }
}
